export const GRADE_LIST = [
  [1, '1학년'],
  [2, '2학년'],
  [3, '3학년'],
  [4, '4학년'],
  [5, '5학년'],
  [6, '6학년'],
];
export const CLASS_GROUP_LIST = [
  [1, '1반'],
  [2, '2반'],
  [3, '3반'],
  [4, '4반'],
  [5, '5반'],
  [6, '6반'],
  [7, '7반'],
  [8, '8반'],
  [9, '9반'],
  [10, '10반'],
  [11, '11반'],
  [12, '12반'],
  [13, '13반'],
  [14, '14반'],
  [15, '15반'],
  [16, '16반'],
  [17, '17반'],
  [18, '18반'],
  [19, '19반'],
  [20, '20반'],
  [21, '21반'],
  [22, '22반'],
  [23, '23반'],
  [24, '24반'],
  [25, '25반'],
  [26, '26반'],
  [27, '27반'],
  [28, '28반'],
  [29, '29반'],
  [30, '30반'],
  [31, '31반'],
  [32, '32반'],
  [33, '33반'],
  [34, '34반'],
  [35, '35반'],
  [36, '36반'],
  [37, '37반'],
  [38, '38반'],
  [39, '39반'],
  [40, '40반'],
  [41, '41반'],
  [42, '42반'],
  [43, '43반'],
  [44, '44반'],
  [45, '45반'],
  [46, '46반'],
  [47, '47반'],
  [48, '48반'],
  [49, '49반'],
  [50, '50반'],
];
export const CLASS_TIME_LIST = [
  [1, '1교시'],
  [2, '2교시'],
  [3, '3교시'],
  [4, '4교시'],
  [5, '5교시'],
  [6, '6교시'],
  [7, '7교시'],
  [8, '8교시'],
  [9, '9교시'],
];
export const WEEKDAYS = [
  [1, '월요일'],
  [2, '화요일'],
  [3, '수요일'],
  [4, '목요일'],
  [5, '금요일'],
];

export const TIME_LIST = [
  ['rRule', '반복 안함'],
  ['FREQ=DAILY', '매일'],
  ['FREQ=WEEKLY', '매주'],
  // ['FREQ=DAILY;BYDAY=MO,TU,WE,TH,FR', '주중 매일(월-금)'],
];
