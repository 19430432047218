import React, { useState, useEffect } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ModalAssembleBand.module.scss';
import Select from 'react-select';
import CheckableStudentList from '../Layout/CheckableStudentList';
import SearchInput from '../Input/SearchInput';
import { Tooltip } from '@mui/material';

const ModalAssembleBand = ({
  confirmModal,
  setConfirmModal,
  onAssembleBandActivity,
  onRefuseBandActivity,
  studentsWithGroup,
  students,
  previousBandName,
  previousBandMember,
  editMode,
}) => {
  const onKick = onAssembleBandActivity;
  const onDelete = onRefuseBandActivity;
  const [bandName, setBandName] = useState(previousBandName);

  const bandActivityListStyles = {
    option: (provided, state) => ({
      ...provided,
      display: 'flex',
      fontSize: '12px',
      lineHeight: '28px',
      letterSpacing: '1.25px',
      color: 'rgba(0, 0, 0, 0.87)',
    }),
    control: (provided, state) => ({
      ...provided,
      width: 240,
    }),
  };
  const [bandedStudents, setBandedStudents] = useState(previousBandMember);
  const [errorMessage, setErrorMessage] = useState('');

  const teammateOptions = [...Array(20).keys()].map((x) => ({
    value: x + 1,
    label: `${x + 1}명`,
  }));
  const [teamsOptions, setTeamsOptions] = useState(
    [...Array(students.length - studentsWithGroup.length).keys()].map((x) => ({
      value: x + 1,
      label: `${x + 1}개`,
    }))
  );
  const [numTeammate, setNumTeammate] = useState(1);
  const [numTeams, setNumTeams] = useState(1);

  const [searchStudent, setSearchStudent] = useState('');

  const onHide = () => {
    setConfirmModal(false);
  };

  const [isDirectMatching, setIsDirectMatching] = useState(true);

  const handleMatchingChange = (e) => {
    if (e.target.checked) setIsDirectMatching(e.target.value === 'direct');
    setErrorMessage('');
  };

  useEffect(() => {
    setTeamsOptions(
      [
        ...Array(
          Math.ceil((students.length - studentsWithGroup.length) / numTeammate)
        ).keys(),
      ].map((x) => ({ value: x + 1, label: `${x + 1}개` }))
    );
  }, [numTeammate, students.length, studentsWithGroup.length]);

  useEffect(() => {
    if (
      !isDirectMatching &&
      numTeammate * numTeams < students.length - studentsWithGroup.length
    ) {
      setErrorMessage(
        `현재 설정으로 ${students.length -
          studentsWithGroup.length -
          numTeammate * numTeams}명이 모둠에 속하지 않을 수 있습니다.`
      );
    } else setErrorMessage('');
  }, [
    isDirectMatching,
    numTeammate,
    numTeams,
    students.length,
    studentsWithGroup.length,
  ]);

  return (
    <Modal
      show={confirmModal}
      onHide={() => {
        setConfirmModal(false);
      }}
      centered
      className={styles['editInfomation-container']}
    >
      <div onSubmit={onKick} className={styles['modal-cotainer']}>
        <div className={styles['infoContainer']}>
          <div className={styles['title-css']}>
            {editMode ? '모둠 수정하기' : '모둠 만들기'}
          </div>
          <div className={styles['bandName']}>
            모둠 이름을 입력해 주세요.
            <input
              className={styles['inputContainer']}
              type={'text'}
              placeholder={'모둠 이름'}
              value={bandName}
              onChange={(e) => {
                setBandName(e.target.value);
              }}
              /*ref={
              (node) => (inputGroupActivityNameNode = node)
            }*/
            />
          </div>
          {!editMode && (
            <div className={styles['assemble-method']}>
              <form>
                <Tooltip
                  title="직접 학생을 선택하여 모둠에 참여시킵니다."
                  arrow
                >
                  <label
                    htmlFor="direct-matching"
                    className={styles['radio-label']}
                  >
                    <input
                      type={'radio'}
                      name={'matching'}
                      id={'direct-matching'}
                      checked={isDirectMatching}
                      value={'direct'}
                      onChange={handleMatchingChange}
                    />
                    <span>직접 선택</span>
                  </label>
                </Tooltip>
                <Tooltip title="무작위로 학생을 모둠에 참여시킵니다." arrow>
                  <label
                    htmlFor="random-matching"
                    className={styles['radio-label']}
                  >
                    <input
                      type={'radio'}
                      name={'matching'}
                      id={'random-matching'}
                      checked={!isDirectMatching}
                      value={'random'}
                      onChange={handleMatchingChange}
                    />
                    <span>무작위 편성</span>
                  </label>
                </Tooltip>
              </form>
            </div>
          )}
          {editMode || isDirectMatching ? (
            <div className={styles['direct-matching-container']}>
              <div className={styles['search-container']}>
                <div className={styles['search-tooltip']}>
                  모둠에 들어갈 학생을 선택해 주세요.
                </div>
                <Tooltip
                  title="학생 이름으로 검색을 할 수 있습니다. 학생 이름을 입력해주세요."
                  arrow
                >
                  <div className={styles['search-tooltip-right']}>
                    <SearchInput
                      style={{ width: 170 }}
                      value={searchStudent}
                      onChange={(e) => setSearchStudent(e.target.value)}
                      placeholder="학생 이름으로 찾기"
                    />
                  </div>
                </Tooltip>
              </div>
              <div className={styles['list-container']}>
                <CheckableStudentList
                  students={students}
                  studentFilter={(s) => s.userName.includes(searchStudent)}
                  studentsWithGroup={studentsWithGroup}
                  defaultChecked={previousBandMember}
                  initialStudents={bandedStudents}
                  onCheckedChange={(studentClientIdList) => {
                    setBandedStudents(studentClientIdList);
                  }}
                />
              </div>
              <div className={styles['error-container']}>{errorMessage}</div>
              <div className={styles['candidates-container']}>
                {students
                  .filter((x) => bandedStudents.includes(x.clientId))
                  .map((x) => x.userName)
                  .join(', ')}
              </div>
            </div>
          ) : (
            <div className={styles['random-matching-container']}>
              <div className={styles['random-matching-num-teammates']}>
                <div className={styles['random-matching-num-teammates-desc']}>
                  <div
                    className={
                      styles['random-matching-num-teammates-desc-maintext']
                    }
                  >
                    몇 명으로 모둠을 편성할까요?
                  </div>
                  <div
                    className={
                      styles['random-matching-num-teammates-remainder']
                    }
                  >
                    모둠에 속하지 않은 인원:{' '}
                    {students.length - studentsWithGroup.length}명
                  </div>
                </div>
                <Select
                  styles={bandActivityListStyles}
                  defaultValue={teammateOptions[0]}
                  isSearchable={false}
                  options={teammateOptions}
                  onChange={(e) => {
                    setNumTeammate(e.value);
                  }}
                  isClearable={false}
                />
              </div>
              <div className={styles['random-matching-num-teams']}>
                <div className={styles['random-matching-num-teams-desc']}>
                  몇 개의 모둠을 만들까요?
                </div>
                <Select
                  styles={bandActivityListStyles}
                  defaultValue={teamsOptions[0]}
                  isSearchable={false}
                  options={teamsOptions}
                  onChange={(e) => {
                    setNumTeams(e.value);
                  }}
                  isClearable={false}
                />
              </div>
              <div className={styles['error-container']}>{errorMessage}</div>
            </div>
          )}
        </div>
        <div className={styles['buttons-container']}>
          {editMode ? (
            <button
              type="button"
              className={styles['del-button']}
              onClick={() => {
                onDelete(bandName);
              }}
            >
              모둠 삭제
            </button>
          ) : (
            <div>&nbsp;</div>
          )}
          <div className={styles['button-container']}>
            <button
              type="button"
              className={styles['cancle-button']}
              onClick={() => {
                onHide();
              }}
            >
              취소
            </button>
            <button
              type="button"
              className={styles['kick-button']}
              onClick={() => {
                if (isDirectMatching || editMode) {
                  if (bandName.length > 0 && bandedStudents.length > 0)
                    onKick(bandName, bandedStudents);
                  else {
                    setErrorMessage(
                      (!bandName.length ? '모둠 이름을 입력해 주세요.' : '') +
                        (!bandName.length && !bandedStudents.length
                          ? ' 그리고, '
                          : '') +
                        (!bandedStudents.length
                          ? '모둠에 들어갈 학생을 선택해 주세요.'
                          : '')
                    );
                  }
                } else {
                  if (
                    bandName.length > 0 &&
                    numTeams <= students.length - studentsWithGroup.length
                  ) {
                    let remainingPeopleCount =
                      students.length - studentsWithGroup.length;
                    let remainingPeople = students
                      .filter((x) => !studentsWithGroup.includes(x.clientId))
                      .map((x) => x.clientId);
                    let teams = new Array(numTeams).fill([]);
                    console.log('initial');
                    console.log(teams);

                    console.log(
                      `remainingPeopleCount: ${remainingPeopleCount}, students.length: ${students.length}`
                    );

                    for (let i = 0; i < remainingPeopleCount; i++) {
                      let randomIndex = Math.floor(
                        Math.random() * remainingPeople.length
                      );
                      console.log(remainingPeople);
                      console.log(`randomIndex = ${randomIndex}`);
                      teams[i % numTeams] = teams[i % numTeams].concat(
                        remainingPeople[randomIndex]
                      );
                      remainingPeople.splice(randomIndex, 1);
                      console.log(remainingPeople);
                      console.log(
                        `teams[${i % numTeams}] = ${teams[
                          i % numTeams
                        ].toString()}`
                      );
                    }

                    for (let i = 0; i < numTeams; i++)
                      onKick(`${bandName} ${i + 1}`, teams[i]);
                  } else if (!bandName.length)
                    setErrorMessage('모둠 이름을 입력해 주세요.');
                  else setErrorMessage('모둠이 너무 많습니다.');
                }
              }}
            >
              {editMode ? '수정하기' : '만들기'}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAssembleBand;
