import { call, put, takeLeading } from 'redux-saga/effects';
import {
  registerDevicePromise,
  registerDeviceSuccess,
  registerDeviceFail,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* registerDevicePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const device = action.payload;
      const response = yield call(api.registerDevice, device);
      yield put(registerDeviceSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(registerDeviceFail(error));
    }
  });
}

function* deviceSaga() {
  yield takeLeading(registerDevicePromise, registerDevicePromiseHandler);
}

export default deviceSaga;
