import { ButtonEvents } from './events';

let buttonEventHistory =
  JSON.parse(localStorage.getItem('buttonEventHistory')) || [];

export const clearButtonEventHistory = () => {
  buttonEventHistory = []; // 초기화
  localStorage.removeItem('buttonEventHistory');
};

export const recordButtonEvent = (
  eventType,
  userClientId,
  groupId
) => {
  const now = new Date();
  const buttonEvent = ButtonEvents[eventType] || 'unknown';

  buttonEventHistory =
    JSON.parse(localStorage.getItem('buttonEventHistory')) || []; // 초기화
  const event = groupId
    ? {
        clientId: userClientId,
        occurredAt: now,
        buttonEvent,
        groupId,
      }
    : { clientId: userClientId, occurredAt: now, buttonEvent };

  buttonEventHistory.push(event);
  localStorage.setItem(
    'buttonEventHistory',
    JSON.stringify(buttonEventHistory)
  );
};
