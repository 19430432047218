// import { REACT_APP_STATIC_ADDR } from '@env';
import AWS from 'aws-sdk'
import axios from 'axios';
import { format } from "date-fns";

const StaticAddrSlash = process.env.REACT_APP_STATIC_ADDR;
const StaticAddr = StaticAddrSlash.substring(0, StaticAddrSlash.length - 1);
const KTCloudAddr = 'https://ss1.cloud.kt.com:1000';
// const KTCloudAddr = 'https://kr.object.ncloudstorage.com';

export const defaultIconPath = () => {
  return 'https://i.natgeofe.com/n/3861de2a-04e6-45fd-aec8-02e7809f9d4e/02-cat-training-NationalGeographic_1484324_square.jpg';
}
export const programIconPath = programAlias => {
  return StaticAddr + '/media/programs/' + programAlias + '.png';
}

export const getIcon = iconPath => {
  return StaticAddr + '/media/icons/' + iconPath + '.png';
}

export const getStickerIcon = iconPath => {
  return StaticAddr + '/media/stickers/' + iconPath + '.png';
}

// export const profileIconImgPath = iconName => {
//   return StaticAddr + '/media/icons-tmp/' + iconName + '.png';
// }

export const getProfileImageKey = (clientId) => {
  return `users/${clientId}.png`
}

export const getQuizImageKey = (clientId) => {
  // use ms timestamp
  return `users/${clientId}/quiz/${new Date().getTime()}.png`
}

export const resolveImgPath = (imageKey) => {
  return `${KTCloudAddr}/focuspang-media/${imageKey}`
}

export const profileImgPath = (clientId) => {
  return resolveImgPath(getProfileImageKey(clientId))
}

export const uploadImage = async (file, key) => {
  const S3_BUCKET ='focuspang-media';
  const REGION ='kr-standard';
  const endpoint = new AWS.Endpoint(KTCloudAddr);
  const access_key = process.env.REACT_APP_ACCESS_KEY;
  const secret_key = process.env.REACT_APP_SECRET_KEY;
  const myBucket = new AWS.S3({
    endpoint: endpoint,
    params: { Bucket: S3_BUCKET },
    region: REGION,
    credentials: {
      accessKeyId : access_key,
      secretAccessKey: secret_key
    },
    s3ForcePathStyle: true
  })

  const params = {
      ACL: 'public-read',
      Body: file,
      Bucket: S3_BUCKET,
      Key: key,
  };

  return await myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
      })
      .send((err) => {
          if (err) console.log(err)
      })
}

export const uploadNoticeFile = async (file, clientId) => {
  const { name } = file;

  const S3_BUCKET = "focuspang-media";
  const REGION = "kr-standard";
  const endpoint = new AWS.Endpoint(KTCloudAddr);
  const access_key = process.env.REACT_APP_ACCESS_KEY;
  const secret_key = process.env.REACT_APP_SECRET_KEY;
  const myBucket = new AWS.S3({
    endpoint: endpoint,
    params: { Bucket: S3_BUCKET },
    region: REGION,
    credentials: {
      accessKeyId: access_key,
      secretAccessKey: secret_key,
    },
    s3ForcePathStyle: true
  });
  const fileKey = `notice/${clientId}/${format(new Date(), "yyMMdd-HHmm")}-${name}`;
  const params = {
    Bucket: S3_BUCKET,
    Key: fileKey,
    ACL: "public-read",
    Body: file,
  };

  await myBucket
    .putObject(params)
    .on("httpUploadProgress", (evt) => {})
    .send((err, data) => {
      if (err) console.log('[media - myBucket]', err);
    });

  return `${KTCloudAddr}/focuspang-media/${fileKey}`;
};
