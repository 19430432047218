import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import {
  Body2,
  Column,
  HorizontalSpace8,
  InputGray,
  Row,
} from '@app/pages/QuizPang/common'
import { Button } from '@mui/material'

export const SubjectModal = ({ open, onCancel, onAdd }) => {
  const [category, setCategory] = useState('')
  const [subject, setSubject] = useState('')
  return <Modal open={open}>
    <Container>
      <Body2
        style={{ marginTop: 8, marginBottom: 8, color: 'rgba(0, 0, 0, 0.87)' }}>
        문제 과목 추가
      </Body2>
      <InputGray placeholder="과목명"
                 value={subject}
                 onChange={(e) => setSubject(e.target.value)}
      />
      <Column style={{ flex: 1, justifyContent: 'flex-end' }}>
        <Row style={{ alignSelf: 'flex-end' }}>
          <Button
            onClick={() => {
              onCancel()
              setSubject('')
            }}>취소</Button>
          <HorizontalSpace8/>
          <Button
            onClick={() => {
              onAdd(subject)
              setSubject('')
            }}>추가</Button>
        </Row>
      </Column>
    </Container>
  </Modal>
}

const Container = styled(Column)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04),
  0 36px 41px rgba(0, 0, 0, 0.04),
  0 4.50776px 5.13384px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  width: 360px;
  height: 200px;
  padding: 16px;
`
