import React, { memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { purgeAll, setSidebarState, updateUserPromise } from '@store/actions';
import { useHistory } from 'react-router-dom';
import Sidebar from '@components/Layout/Sidebar';
import styles from './index.module.scss';
import { profileImgPath } from '@api';
import BaseCard from '@components/Card/baseCard';
import Password from '@components/Input/password';
// import MdInput from '@components/Input/MdInput';
import { Button } from '@mui/material';
import ModalUpdateProfileImage from '@components/Modal/ModalUpdateProfileImage';
import ProfileImage from '@components/ProfileImage';
import SignoutImg from '@assets/images/icons/signout.png';
import { signInPromise } from '@app/store/actions';
import NameInput from '@app/components/Input/NameInput';

const Settings = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [pw, setPw] = useState('');
  const [confirmPw, setConfirmedPw] = useState('');
  const [modalShow, setModalShow] = useState(false);
  const control = useSelector((state) => state.control);
  const user = useSelector((state) => state.user);
  const [PWdisabled, setPWDisabled] = useState(false);
  const [PWcurrent, setPWCurrent] = useState(false);
  const [ChangeNext, setChangeNext] = useState(false);

  const onCurrentPWCheck = () => {
    dispatch(
      signInPromise({
        email: user?.email,
        password: PWcurrent,
        role: 'TEACHER',
      })
    ).then((res) => {
      if (res) {
        // console.log('선생님으로 로그인성공', res);
        setChangeNext(true);
      } else {
        dispatch(
          signInPromise({
            email: user?.email,
            password: PWcurrent,
            role: 'ADMIN',
          })
        ).then((res) => {
          if (res) {
            // console.log('어드민으로 로그인성공', res);
            setChangeNext(true);
          } else {
            // console.log('로그인실패 > 비번틀림');
            alert('비밀번호가 일치하지 않습니다. 다시 입력해 주세요.');
            setChangeNext(false);
          }
        });
      }
    });
  };

  const onChangePWCheck = useCallback((e) => {
    const pwRegex = /^.*(?=^.{8,16}$)(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&+=]).*$/;
    if (!pwRegex.test(e.target.value)) {
      setPWDisabled(true);
    } else {
      setPWDisabled(false);
    }
  }, []);

  const onPurgeClick = () => {
    if (window.confirm('로그아웃 하시겠어요?')) {
      dispatch(purgeAll());
      localStorage.clear();
      history.replace('/auth');
    }
  };

  useEffect(() => {
    if (user.state === 'REGISTERED_APPROVED') {
      if (control.sidebarState === false) dispatch(setSidebarState());
      setUserName(user.userName);
      setEmail(user.email);
    } else {
      //dispatch(setSidebarState(false));
    }
  }, [user, control.sidebarState, dispatch]);

  const sendChagnedInfo = () => {
    if (!userName) {
      alert('이름을 입력해주세요.');
      return;
    }
    if (userName.length > 35) {
      alert('이름은 35자 이내로 입력해주세요.');
      return;
    }
    dispatch(
      updateUserPromise({
        accountId: user.accountId,
        userName: userName,
      })
    )
      .then((response) => {
        console.log(response);
        alert(`정보가 성공적으로 변경되었습니다.`);
      })
      .catch((error) => {
        console.log(error);
        alert(`정보 변경에 실패했습니다. 입력하신 정보를 확인해주세요.`);
      });
  };
  const sendChangedPassword = () => {
    if (!pw) {
      alert('비밀번호를 입력해주세요.');
      return;
    }
    if (PWdisabled) {
      alert('비밀번호는 8~16자 문자, 숫자, 특수문자를 사용하세요.');
      return;
    }
    dispatch(
      updateUserPromise({
        accountId: user.accountId,
        password: pw,
      })
    )
      .then((response) => {
        console.log(response);
        alert(`비밀번호가 성공적으로 변경되었습니다!`);
        setChangeNext(false);
      })
      .catch((error) => {
        console.log(error);
        alert(`로그인에 실패했습니다. 이메일 또는 비밀번호를 확인해주세요.`);
      });
  };

  return (
    <>
      <ModalUpdateProfileImage
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
        currentProfileImage={profileImgPath(user.clientId)}
      />
      <div className="flex w-100vw h-100vh">
        <Sidebar />
        <div
          style={{
            marginLeft: '280px',
            width: '100%',
            padding: 14,
            background: '#f9f9f9',
            minHeight: '100vh',
          }}
          className={styles['settings-container']}
        >
          <BaseCard style={{ minHeight: '100%', padding: '20px 40px' }}>
            {/* <span className={styles['settings-title']}>설정</span> */}
            {/* <hr /> */}
            <div className={styles['userInfo-container']}>
              <div className={styles['settings-subContainer']}>
                <span className={styles['settings-subtitle']}>내 정보</span>
                <button
                  className={styles['signout-button']}
                  onClick={onPurgeClick}
                >
                  <img src={SignoutImg} width={15} height={17} />
                  <div>로그아웃</div>
                </button>
              </div>
              <div className={styles['profile-wrapper']}>
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: 24,
                  }}
                >
                  <ProfileImage
                    size={44}
                    url={profileImgPath(user.clientId)}
                    subscribeImageChange={user.subscribeImageChange}
                  />
                  <button
                    className={styles['profile-image-button']}
                    onClick={() => setModalShow(true)}
                  >
                    프로필 사진 변경
                  </button>
                </div>
              </div>
              {/* <div className={styles["pencil-icon"]}>
                <EditOutlinedIcon onClick={navigateToEditMode} />
              </div> */}
              <NameInput
                name="name"
                label="이름"
                value={userName}
                style={{ marginTop: 24 }}
                onChange={(e) => setUserName(e.target.value)}
              />
              <NameInput label="이메일" value={user.email} disabled />
              <div className={styles['password-button-wrapper']}>
                <Button
                  variant="contained"
                  disabled={userName === '' || email === ''}
                  onClick={sendChagnedInfo}
                >
                  정보 변경
                </Button>
              </div>
            </div>
            <div className={styles['passwordChange-container']}>
              <span className={styles['settings-subtitle']}>비밀번호 변경</span>
              <div style={{ marginTop: 10, fontSize: 14 }}>
                먼저 현재 비밀번호를 입력해주세요
              </div>
              <Password
                label="현재 비밀번호 입력"
                onChange={(e) => {
                  setPWCurrent(e.target.value);
                }}
                style={{ marginTop: 6 }}
              />
              <div className={styles['password-button-wrapper']}>
                <Button variant="contained" onClick={onCurrentPWCheck}>
                  다음
                </Button>
              </div>
              {ChangeNext ? (
                <>
                  <Password
                    label="새 비밀번호 입력"
                    onChange={(e) => {
                      setPw(e.target.value);
                      onChangePWCheck(e);
                    }}
                    style={{ marginTop: 16 }}
                  />
                  <div style={{ fontSize: 13, color: 'red', marginBottom: 8 }}>
                    {PWdisabled
                      ? '비밀번호는 8~16자 문자, 숫자, 특수문자를 사용하세요.'
                      : null}
                  </div>
                  <Password
                    label="비밀번호 입력 확인"
                    onChange={(e) => setConfirmedPw(e.target.value)}
                  />
                  <div className={styles['password-button-wrapper']}>
                    {pw === confirmPw && pw !== '' && !PWdisabled ? (
                      <Button variant="contained" onClick={sendChangedPassword}>
                        비밀번호 변경
                      </Button>
                    ) : pw === '' ? (
                      <Button variant="contained">비밀번호 변경</Button>
                    ) : (
                      <Button variant="contained">
                        비밀번호가 일치하지 않습니다.
                      </Button>
                    )}
                  </div>
                </>
              ) : null}
            </div>
            <div style={{ marginTop: 50 }}>
              문의사항이 있으시면 이메일(care@focuspang.com)이나 즉문즉답으로
              문의 부탁드립니다.
            </div>
            <div
              style={{ display: 'flex', alignItems: 'center', marginTop: 10 }}
            >
              <Button variant="contained">
                <a href="https://focuspang.com/ask.html" target="_black">
                  포커스팡 즉문즉답
                </a>
              </Button>
              <Button variant="contained" style={{ marginLeft: 20 }}>
                <a href="https://focuspang.com/guide_2.html" target="_black">
                  포커스팡 매뉴얼
                </a>
              </Button>
            </div>
            <div className={styles['version']}>ver.2.0.0426.4</div>
          </BaseCard>
        </div>
      </div>
    </>
  );
};

export default memo(Settings);
