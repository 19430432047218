import {
  createClassroomPromise,
  createCommentsLayerPromise,
  deleteClassroomPromise,
  getActiveClassroomPromise,
  setClassroomState,
  setSocketData,
} from '@app/store/actions';
import {
  selectActiveClassroom,
  selectClassroomGroup,
  selectGroupById,
} from '@app/store/selectors';
import { Tooltip } from '@material-ui/core';
import { Padding } from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

const StartClassButtonOn = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-top: 8px; */
  height: 54px;
  background: ${(props) => (props.disabled ? '#e0e0e0' : '#0ba1ae')};
  border-radius: 4px;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  transition: all 0.2s ease;

  &:hover {
    opacity: ${(props) => (props.disabled ? '1' : '0.7')};
  }
`;

const Checkbox = styled.input.attrs({ type: 'checkbox' })`
  width: 18px;
  height: 18px;
`;

const StartClassButtonOff = styled.button`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 8px;
  height: 54px;
  color: #ff5252;
  background: #ffffff;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
`;
const ClassroomStateChangeButton = () => {
  const DEFAULT_DURATION = 45;
  const { groupId } = useParams();
  const user = useSelector((state) => state.user);
  const group = useSelector((state) => selectGroupById(state, groupId));
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const activeClassroomGroup = useSelector((state) =>
    selectClassroomGroup(state, activeClassroom)
  );
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);

  const sendCreatedClassroomBySocket = (classroomId) => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: 'CLASS_START',
        data: classroomId,
      })
    );
  };

  const sendDeletedClassroomBySocket = () => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: 'CLASS_END',
        data: '',
      })
    );
  };

  const clickClassStart = () => {
    if (!isChecked) {
      alert('수업 시작 전에 체크박스를 체크해주세요.');
      return;
    }

    const confirmed = window.confirm(
      '실제 수업시간이 아닌 경우에, 수업을 시작하게 되면 수업 중인 선생님과 학생들을 방해하게 됩니다. 이 점 양해 바랍니다.'
    );

    if (confirmed) {
      if (activeClassroom && activeClassroom?.groupId !== group?.groupId) {
        const activeGroup = activeClassroomGroup;
        alert(
          `${activeGroup?.grade}학년 ${activeGroup?.groupName}반 ${activeGroup?.subjectName} 수업이 아직 진행중이에요. ${activeGroup.subjectName} 수업을 먼저 끝내고 다시 시도해주세요`
        );
        return;
      }

      if (!activeClassroom) {
        dispatch(
          createClassroomPromise({
            groupId: group.groupId,
            startAt: moment().toISOString(),
            finishAt: moment()
              .add(DEFAULT_DURATION, 'minutes')
              .toISOString(),
          })
        )
          .then((createdClassroom) => {
            dispatch(
              createCommentsLayerPromise({
                classroomId: createdClassroom.classroomId,
                clientId: user.clientId,
                layerWidth: 720,
                layerHeight: 720,
                isActivated: true,
              })
            );
            sendCreatedClassroomBySocket(createdClassroom.classroomId);
            dispatch(setClassroomState(true));
          })
          .then(() => {
            return dispatch(getActiveClassroomPromise(user.clientId));
          })
          .catch((err) => {
            console.error(err);
          });
      }
    } else {
      setIsChecked(false);
    }
  };

  const clickClassEnd = () => {
    dispatch(deleteClassroomPromise(activeClassroom?.classroomId)).then(() => {
      dispatch(setClassroomState(false));
      sendDeletedClassroomBySocket();
      setIsChecked(false);
      return dispatch(getActiveClassroomPromise(user.clientId));
    });
  };

  useEffect(() => {
    if (activeClassroom) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [activeClassroom]);

  if (activeClassroom) {
    return (
      <Tooltip title="수업 종료를 하실 수 있는 버튼입니다. 클릭시 수업이 종료됩니다.">
        <StartClassButtonOff onClick={clickClassEnd}>
          수업 종료
        </StartClassButtonOff>
      </Tooltip>
    );
  } else {
    return (
      <>
        <StartClassButtonOn disabled={!isChecked} onClick={clickClassStart}>
          수업 시작
        </StartClassButtonOn>
        <label
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: '8px',
          }}
        >
          <span
            style={{
              fontSize: '12px',
              marginRight: '8px',
            }}
          >
            수업을 시작하시기 전에 체크해주세요.
          </span>
          <Checkbox
            checked={isChecked}
            onChange={(event) => setIsChecked(event.target.checked)}
          />
        </label>
      </>
    );
  }
};

export default ClassroomStateChangeButton;
