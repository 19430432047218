import React, { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './StudentList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createInviteCodePromise } from '@store/actions';
import {
  selectActiveClassroom,
  selectStudentsByGroup,
  selectParticipationsByClassroom,
} from '@store/selectors';
import ModalAddStudent from '@components/Modal/ModalAddStudent';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import StudentRow from './StudentRow';
import { Tooltip } from '@mui/material';
import studentListEmpty from '@assets/images/studentList/studentListEmpty.png';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

const SECONDS = 1000;

const StudentList = ({ groupId, students }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const _participations = useSelector((state) =>
    selectParticipationsByClassroom(state, activeClassroom?.classroomId)
  );

  const filteredParticipations = useMemo(() => {
    //조건식 설명: 상태가 "ABSENT"이고, 선생님 본인은 제외 >> 그러면 상태가 "ATTEND"이고 학생인 계정만 필터됨
    return (
      _participations?.filter(
        (participation) =>
          participation.state !== 'ABSENT' &&
          participation.clientId !== user.clientId
      ) || []
    );
  }, [_participations, user.clientId]);

  students?.sort((a, b) => {
    if (a.studentNumber > b.studentNumber) {
      return 1;
    } else if (a.studentNumber < b.studentNumber) {
      return -1;
    } else {
      return 0;
    }
  });

  const calcAttendedAt = (attendedAt) => {
    const diff =
      (new Date(activeClassroom?.startAt) - new Date(attendedAt)) / SECONDS;
    if (parseInt(Math.abs(diff)) > 5) {
      return true;
    }
    return false;
  };

  return (
    <div className={styles['wrapper']}>
      <div className={styles['title-container2']}>
        <div className={styles['title-container']}>
          <div className={styles['title-container-text']}>
            학생명단 (총원 {students?.length}명)
          </div>

          <div className={styles['display']}>
            <div className={styles['check-box']}>
              <PersonIcon className={styles['check']} />
              <div className={styles['attent-text']}>
                로그인된 인원 {filteredParticipations?.length}명
              </div>
            </div>
          </div>
        </div>

        <div className={styles['download-page']}>
          <CloudDownloadIcon />
          <a href="https://focuspang.com/_dn_.html" target="_black">
            다운로드 페이지로 이동하기
          </a>
        </div>
      </div>
      <div className={styles['header']}>
        <div className={styles['student-number']}>학번</div>
        <div className={styles['student-name']}>이름</div>
        <div className={styles['student-attendTime']}>출석시간</div>
        <div className={styles['student-edit']}>학생 정보 수정</div>
      </div>
      {/* {students.length === 0 && (
        <>
          <div className={styles['studentList-empty']}>
            <div className={styles['studentList-empty-title']}>
              학생명단에 학생이 없습니다.
            </div>
            <div className={styles['studentList-empty-subText']}>
              등록을 원하시면 학생 추가하기 버튼을 눌러서 학생에게 초대코드를
              알려주세요.
            </div>
            <div className={styles['studentList-empty-img']}>
              <img src={studentListEmpty} width={600} />
            </div>
          </div>
        </>
      )} */}

      <div className={styles['student-list']}>
        {students.map((student, idx) => {
          return (
            <StudentRow
              key={student.clientId}
              attendTime={
                _participations?.find((participation) => {
                  return participation.clientId === student.clientId;
                })?.attendedAt
              }
              student={student}
              groupId={groupId}
              exposeEdit={true}
              late={calcAttendedAt(
                _participations?.find((participation) => {
                  return participation.clientId === student.clientId;
                })?.attendedAt
              )}
            />
          );
        })}
      </div>
      {/* <div className={styles['student-list']}>
        {absentStudents.map((student, idx) => {
          return (
            <>
              <StudentRow
                key={student.clientId}
                group={groupId}
                student={student}
                classInfo={classInfo}
                exposeEdit={true}
                absent
              />
            </>
          );
        })}
      </div> */}

      <ModalAddStudent
        show={modalShow}
        onHide={() => setModalShow(false)}
        groupId={groupId}
        setModalShow={setModalShow}
      />
    </div>
  );
};

export default StudentList;
