import { Tooltip } from '@mui/material';
import React from 'react';
import styled from 'styled-components';

const StudentNameWrapper = styled.div`
  max-width: 78px;
  height: 28px;

  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  margin-left: 8px;
`;

const StudentNameBox = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const StudentName = ({ studentName }) => {
  return (
    <Tooltip title={studentName} arrow>
      <StudentNameWrapper>
        <StudentNameBox>{studentName}</StudentNameBox>
      </StudentNameWrapper>
    </Tooltip>
  );
};

export default StudentName;
