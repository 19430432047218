import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import LogoutIcon from '@mui/icons-material/Logout';
import { purgeAll } from '@app/store/actions';

const StyledButton = styled.button`
  background: #8a8a8a;
  border-radius: 4px;
  padding: 6px 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 1.25px;
  color: #ffffff;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.7;
  }
`;

const StyledLogoutIcon = styled(LogoutIcon)`
  font-size: 20px;
`;

function LogoutButton() {
  const dispatch = useDispatch();
  const history = useHistory();

  const onPurgeClick = () => {
    if (window.confirm('로그아웃 하시겠어요?')) {
      dispatch(purgeAll());
      localStorage.clear();
      history.replace('/auth');
    }
  };

  return (
    <StyledButton onClick={onPurgeClick}>
      <StyledLogoutIcon />
      로그아웃
    </StyledButton>
  );
}

export default LogoutButton;
