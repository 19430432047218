import { createInviteCodePromise } from '@app/store/actions';
import SensorsIcon from '@mui/icons-material/Sensors';
import { Tooltip } from '@mui/material';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import styled from 'styled-components';
import FullScreenInviteCodeModal from '../Modal/FullScreenInviteCode';

const GradeClassStatusWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 8px 8px 8px;
  height: 100%;
  gap: 8px;
  color: #f57f17;
  /* border-top: 1px solid #ccc; */
  border-bottom: 1px solid #ccc;
`;

const InviteCodeTitleWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

const InviteCodeTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
`;

const ModalInviteCodeTextWrapper = styled.div`
  display: flex;
  align-items: center;
  font-weight: 800;
  color: #f57f17;

  @media (min-width: 2560px) {
    font-size: 320px;
  }

  @media (min-width: 1980px) and (max-width: 2559px) {
    font-size: 300px;
  }

  @media (min-width: 1440px) and (max-width: 1979px) {
    font-size: 250px;
  }

  @media (max-width: 1439px) {
    font-size: 220px;
  }
`;

function InclassInviteCode() {
  const invCode = useSelector((state) => state.groups.invCode);
  const [open, setOpen] = useState(false);

  const formattedCode = useMemo(() => {
    if (!invCode) {
      return '';
    }

    return invCode.match(/.{1,3}/g).join('-');
  }, [invCode]);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip
        title="학생들이 수업에 참여할 수 있도록하는 초대코드입니다. 클릭하여 전체화면으로 전환해보세요!"
        arrow
      >
        <GradeClassStatusWrapper onClick={handleClick}>
          <InviteCodeTitleWrapper>학생초대코드</InviteCodeTitleWrapper>
          <InviteCodeTextWrapper>{formattedCode}</InviteCodeTextWrapper>
          <FullscreenIcon fontSize="medium" onClick={handleClick} />
        </GradeClassStatusWrapper>
      </Tooltip>
      <FullScreenInviteCodeModal open={open} onClose={handleClose}>
        <ModalInviteCodeTextWrapper>{formattedCode}</ModalInviteCodeTextWrapper>
      </FullScreenInviteCodeModal>
    </>
  );
}

export default React.memo(InclassInviteCode);
