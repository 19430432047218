import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  GET_NOTIFICATION,
  GET_NOTIFICATION_SUCCESS,
  GET_CREATED_NOTIFICATION,
  PROCESS_NOTIFICATION,
} from './actionTypes';
import {
  getNotificationFail,
  getNotificationSuccess,
  processNotificationSuccess,
  processNotificationFail,
  getCreatedNotificationFail,
  getCreatedNotificationSuccess,
  getGroup,
  getGroupSuccess,
  updateGroupSuccess,
  setApprovalCompleted,
  setClassroomState,
  setFilelinkState,
} from '@store/actions';
import * as api from '@api';

function* getNotificationHandler(action) {
  try {
    const response = yield call(api.getNotification, action.notificationId);
    yield put(getNotificationSuccess(response.data));
  } catch (error) {
    yield put(getNotificationFail(error));
  }
}

function* getNotificationSuccessHandler(action) {
  try {
    // switch notification kind
    // console.log("noti action : ", action);
    const payload = action.notification;
    switch (action.notification.kind) {
      // payload: {'classroom': {}, 'group: {}}
      case 'APPROVRAL_COMPLETE':
        // yield put(reloadUserInfoPromise());
        yield put(setApprovalCompleted());
        break;
      case 'CLASSROOM_START':
        yield put(setClassroomState(true));
        break;

      case 'CLASSROOM_END':
        yield put(setClassroomState(false));
        break;

      case 'DELETE_GROUP':
      case 'UPDATE_GROUP':
      case 'KICK_STUDENT':
        // todo
        // yield put()
        break;
      case 'CREATE_FILELINK':
      case 'DELETE_FILELINK':
        yield put(setFilelinkState());
        break;

      //payload: watchdog config instance
      case 'PROGRAM_CONFIG_UPDATE':
      case 'PROGRAM_CON_IG_UPDATE':
        // yield put(getProgramConfigSuccess(payload.teacher));
        // yield put(getProgramConfigSuccess(payload.student));
        break;

      case 'PROGRAM_CONFIG_UPDATE_PLEASE': // for student
      case 'PROGRAM_CON_IG_UPDATE_PLEASE': // for student
        // todo
        break;

      // 학생이 반에 들어갈때 백엔드
      case 'JOIN_STUDENT':
        // console.log("join student : ", payload);
        // yield put(updateGroupSuccess(payload));
        // yield call(api.processNotification, action.notification.id);
        break;
      default:
        console.log(
          `Get notification but strange notification kind: ${action.notification.kind}`
        );
        break;
    }
  } catch (error) {
    console.error(error);
  }
}

function* processNotificationHandler(action) {
  try {
    const response = yield call(api.updateNotification, action.notificationId);
    yield put(processNotificationSuccess(response.data));
  } catch (error) {
    yield put(processNotificationFail(error));
  }
}

function* getCreatedNotificationHandler(action) {
  try {
    const response = yield call(api.getNotifications, action.payload);
    if (response.data.length !== 0)
      yield call(getNotificationSuccessHandler, {
        notification: response.data[response.data.length - 1],
      });
    // console.log("response: ", response.data);
    yield put(getCreatedNotificationSuccess(response.data));
  } catch (error) {
    yield put(getCreatedNotificationFail(error));
  }
}

function* notificationSaga() {
  yield takeLeading(GET_NOTIFICATION, getNotificationHandler);
  yield takeEvery(GET_NOTIFICATION_SUCCESS, getNotificationSuccessHandler);
  yield takeLeading(GET_CREATED_NOTIFICATION, getCreatedNotificationHandler);
  yield takeLeading(PROCESS_NOTIFICATION, processNotificationHandler);
}

export default notificationSaga;
