import Sidebar from '@components/Layout/Sidebar';
import { ThemeProvider } from 'styled-components';
import ChatBot from 'react-simple-chatbot';
import styles from './edit-classroom.module.scss';
import { useHistory, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
import { selectActiveClassroom, selectMyGroupsAsAdmin, } from '@store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { deleteGroupPromise, getMembershipByClientPromise, updateGroupPromise } from '@store/actions';
import { setToken } from '@api';
import BaseCard from '@components/Card/baseCard';

const EditClassroom = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();

  const user = useSelector((state) => state.user);

  const allMyGroups = useSelector((state) =>
    selectMyGroupsAsAdmin(state, state.user)
  );

  // added test
  const classInfo = location.state.classInfo;

  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const classroomGroupId = activeClassroom?.groupId;

  useEffect(() => {
    if (activeClassroom || classroomGroupId) {
      alert(`수업이 아직 진행중이에요.\n수업을 먼저 끝내고 다시 시도해주세요`);
      return history.replace('/home');
    }
  });

  //

  if (user.token !== undefined && user.token !== null) {
    setToken(user.token).then(() => {
    });
  }

  const Review = (inputs) => {
    // console.log(inputs.steps);
    const location = useLocation();
    //   console.log(location.state.classInfo);

    let beforeEdit;
    let afterEdit;

    switch (inputs.steps.editType.value) {
      case 'grade':
        beforeEdit = location.state.classInfo.grade;
        afterEdit = inputs.steps.grade.value;
        break;
      case 'classRoomName':
        beforeEdit = location.state.classInfo.class_name;
        afterEdit = inputs.steps.userInput.value;
        break;
      case 'subjectName':
        beforeEdit = location.state.classInfo.name;
        afterEdit = inputs.steps.subjectName.value;
        break;
      default:
        beforeEdit = '수정 전';
        afterEdit = '수정 후';
        break;
    }

    return (
      <div style={{ width: '100%' }}>
        <h3>다음 정보를 수정할 거예요</h3>
        <br />
        <p>
          수정하는 곳: <strong>{inputs.steps.editType.message}</strong>{' '}
        </p>
        <p>수정하기 전: {beforeEdit}</p>
        <p>
          수정 후: <strong>{afterEdit}</strong>
        </p>
      </div>
    );
  };

  const InitClassinfo = (props) => {
    const location = useLocation();

    const grade = location.state.classInfo.grade;
    const className = location.state.classInfo.class_name;
    const subjectName = location.state.classInfo.name;

    return (
      <div className={styles['init-information-container']}>
        <div className={styles['init-information']}>
          안녕하세요. 선택하신 반의 정보는 다음과 같습니다.
        </div>
        <div className={styles['init-information']}>
          {grade}학년 {className}반 {subjectName}
        </div>
      </div>
    );
  };

  const steps = [
    {
      id: '1',
      component: <InitClassinfo />,
      trigger: '2',
    },
    {
      id: '2',
      message: '어떤 정보를 수정하고 싶으세요?',
      trigger: 'editType',
    },
    {
      id: 'editType',
      options: [
        { value: 'grade', label: '학년', trigger: '3' },
        { value: 'classRoomName', label: '반 이름', trigger: '4' },
        { value: 'subjectName', label: '과목 이름', trigger: '5' },
        { value: 'deleteSubject', label: '수업 삭제', trigger: '6' },
      ],
    },
    {
      id: '3',
      message: '학년을 선택해주세요',
      trigger: 'grade',
    },
    {
      id: '6',
      component: <DeleteClassroom />,
    },
    {
      id: 'grade',
      options: [
        { value: 1, label: '1', trigger: 'Review' },
        { value: 2, label: '2', trigger: 'Review' },
        { value: 3, label: '3', trigger: 'Review' },
        { value: 4, label: '4', trigger: 'Review' },
        { value: 5, label: '5', trigger: 'Review' },
        { value: 6, label: '6', trigger: 'Review' },
      ],
    },
    {
      id: '4',
      message: '반 이름을 다시 입력해주세요',
      trigger: 'userInput',
    },
    {
      id: '5',
      message: '과목 이름을 다시 입력해주세요',
      trigger: 'subjectName',
    },
    {
      id: 'userInput',
      user: true,
      trigger: 'Review',
    },
    {
      id: 'subjectName',
      user: true,
      trigger: 'Review',
    },
    {
      id: 'Review',
      component: <Review />,
      asMessage: true,
      trigger: 'check-review',
    },
    {
      id: 'check-review',
      options: [
        { value: 1, label: '예', trigger: 'end-message' },
        { value: 2, label: '아니오', trigger: '1' },
      ],
    },
    {
      id: 'end-message',
      message: '선생님 감사합니다! 입력하신 정보가 수정되었습니다.',
      end: true,
    },
  ];
  const handleEnd = ({ steps }) => {
    const classInfo = location.state.classInfo;

    const updatedGroup = {
      ...classInfo,
      grade: steps.grade === undefined ? classInfo.grade : steps.grade.value,
      class_name:
        steps.userInput === undefined
          ? classInfo.class_name
          : steps.userInput?.value,
      name:
        steps.subjectName === undefined
          ? classInfo.name
          : steps.subjectName.value,
    };

    console.log('updatedGroup : ', updatedGroup);
    dispatch(updateGroupPromise(updatedGroup));
  };

  return (
    <div className="flex w-100vw h-100vh">
      <Sidebar />
      <div
        style={{
          marginLeft: '280px',
          width: '100%',
          padding: 14,
          background: '#f9f9f9',
          minHeight: '100vh',
        }}
      >
        <BaseCard style={{ minHeight: '100%', padding: '20px 40px' }}>
          <span className={styles['title']}>가르치는 반 정보 변경하기</span>
          <hr />
          <ThemeProvider theme={chatBotTheme}>
            <div className={styles['chatbot']}>
              <ChatBot
                background="#fff"
                headerTitle="포커스팡 챗봇"
                width="400px"
                handleEnd={handleEnd}
                steps={steps}
                placeholder="내용을 입력하세요"
                hideBotAvatar={true}
                customStyle={customStyle}
                bubbleOptionStyle={bubbleOptionStyle}
              />
            </div>
          </ThemeProvider>
        </BaseCard>
      </div>
    </div>
  );
};

const DeleteClassroom = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const allMyGroups = useSelector((state) =>
    selectMyGroupsAsAdmin(state, state.user)
  );
  const user = useSelector((state) => state.user);
  // const deleteClass = () => {
  //   dispatch(deleteGroupPromise(location.state.classInfo.id)).then(
  //     (response) => {
  //       console.log('[deleteClass]:', response);
  //       // getmy -> redux에 그룹이 쌓임
  //       dispatch(getMyGroupsPromise()).then((response) => {
  //         console.log('[Getmygroup]:', response);
  //         history.replace('/home');
  //       });
  //     }
  //   );
  // };
  // if (window.confirm('정말 삭제합니까?')) {
  //   deleteClass();
  // }

  useEffect(() => {
    deleteClass();
  }, []);

  const deleteClass = () => {
    console.log('deleteClass발생');

    if (window.confirm('정말 삭제하시겠습니까?')) {
      dispatch(deleteGroupPromise(location.state.classInfo.id)).then(
        (response) => {
          console.log('[deleteClass]:', response);
          dispatch(getMembershipByClientPromise(user.clientId)).then((response) => {
            console.log('[Getmygroup]:', response);
            history.replace('/home');
          });
        }
      );
      console.log('삭제');
    } else {
      history.replace('/home');
      console.log('취소');
    }
  };

  // useEffect(() => {
  //   dispatch(getMyGroups());
  // }, []);

  return null;
};

export default EditClassroom;

const chatBotTheme = {
  background: '#faf9f7',
  headerBgColor: '#dee07e',
  headerFontColor: '#353433',
  userBubbleColor: '#ffffff',
  // botBubbleColor: "#d5ddab",
  botBubbleColor: '#dee07e',
  footerBgColor: '#f4f2ef',
  headerFontSize: '16px',
};

const customStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  borderRadius: '0',
  boxShadow: '0 0 0 0',
  padding: '0',
  justifyContent: 'flex-start',
};

const bubbleOptionStyle = {
  backgroundColor: chatBotTheme.userBubbleColor,
  border: '2px solid #dee07e',
};
