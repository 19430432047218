import { makeAutoObservable } from 'mobx'

export const quizTypeToText = {
  MULTIPLE: '선다형',
  SHORT: '단답형',
  OX: 'O/X',
}

export const quizTypes = Object.keys(quizTypeToText)

export class QuizModalStore {
  quizId = null // for edit
  grade = 1
  subject = -1
  category = -1
  keyword = ''
  type = 'MULTIPLE'
  title = ''
  choices = [{ description: '', point: 0 }]
  timeLimit = 5
  description = ''
  noInfringementAccepted = false
  sharingAccepted = false
  copyrightMandateAccepted = false
  uploadedImgPath = null
  imageFile = null

  forcePreview = false

  constructor(data) {
    makeAutoObservable(this)
    this.setData(data)
  }

  setData(data) {
    if (!data) return
    this.quizId = data.quizId
    this.grade = data.grade
    this.subject = data.quizSubject
    this.category = data.quizCategory
    this.keyword = data.keyword
    this.type = data.quizType
    this.title = data.title
    this.choices = data.choices
    this.timeLimit = data.timelimit
    this.description = data.description
    this.noInfringementAccepted = data.noInfringementAccepted
    this.sharingAccepted = data.sharingAccepted
    this.copyrightMandateAccepted = data.copyrightMandateAccepted
    this.uploadedImgPath = data.link
  }

  setForcePreview() {
    this.forcePreview = true
  }

  get isEdit() {
    return !!this.quizId
  }

  get imgPath() {
    return this.uploadedImgPath ||
      (this.imageFile !== null ? URL.createObjectURL(this.imageFile) : null)
  }

  setGrade(grade) {
    this.grade = grade
  }

  setSubject(subject) {
    this.subject = subject
  }

  setCategory(category) {
    this.category = category
  }

  setKeyword (keyword) {
    this.keyword = keyword
  }

  setType(type) {
    this.type = type
    if (this.type === 'OX') {
      this.choices = [
        { description: 'O', point: 0 },
        { description: 'X', point: 0 },
      ]
    } else {
      this.choices = [
        { description: '', point: 0 },
      ]
    }
  }

  setTitle(title) {
    this.title = title
  }

  setChoice(index, description, point) {
    this.choices[index] = { description, point }
  }

  addChoice() {
    this.choices.push({ description: '', point: 0 })
  }

  deleteChoice(index) {
    this.choices = this.choices.filter((x, i) => {
      return i !== index
    })
  }

  setTimeLimit(timeLimit) {
    this.timeLimit = timeLimit
  }

  setDescription(description) {
    this.description = description
  }

  toggleNoInfringementAccepted() {
    this.noInfringementAccepted = !this.noInfringementAccepted
  }

  toggleSharingAccepted() {
    this.sharingAccepted = !this.sharingAccepted
  }

  toggleCopyrightMandateAccepted(v) {
    this.copyrightMandateAccepted = !this.copyrightMandateAccepted
  }

  setImageFile(f) {
    this.imageFile = f
  }

  removeImage() {
    this.imageFile = null
    this.uploadedImgPath = null
  }

  get data() {
    const {
      quizId,
      grade,
      title,
      subject,
      category,
      keyword,
      type,
      description,
      timeLimit,
      choices,
      noInfringementAccepted,
      sharingAccepted,
      copyrightMandateAccepted,
      uploadedImgPath,
      imageFile,
    } = this
    return {
      quizId,
      grade,
      title,
      quizSubject: subject === -1 ? '과목 없음' : subject,
      quizCategory: category === -1 ? '해당 없음' : category,
      keyword: keyword ? keyword : '키워드 없음',
      quizType: type,
      description,
      timelimit: timeLimit,
      choices: JSON.stringify(choices.map((x, i) => ({
        ...x, index: i + 1, isAnswer: x.point > 0,
      }))),
      noInfringementAccepted,
      sharingAccepted,
      copyrightMandateAccepted,
      uploadedImgPath,
      imageFile,
    }
  }
}

