export const selectAllClients = (state) => {
  if (state.clients.allIds.length === 0) {
    return [];
  }
  return state.clients.allIds?.map((clientId) => state.clients.byId[clientId]);
};

export const selectAllStudents = (state) => {
  if (state.clients.allIds.length === 0) {
    return [];
  }
  const clients = state.clients.allIds?.map((clientId) => state.clients.byId[clientId]);
  return clients.filter((client) => client.role === "STUDENT");
};

export const selectStudentByClientId = (state, clientId) => {
  if (state.clients.allIds.length === 0) {
    return [];
  }
  const student = state.clients.byId[clientId]
  return student;
}