import {
  getManagedsByClientPromise,
  getManagingsByGroupPromise,
  getMembershipByClientPromise,
  getTimetablesByGroupListPromise,
} from '@app/store/actions';
import {
  selectAllManaging,
  selectAllMyGroups,
  selectSubjectGroups,
} from '@app/store/selectors';
import {
  getMembershipsByGroupPromise,
  getProgramsPromise,
} from '@store/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

const GroupManager = () => {
  const user = useSelector((state) => state.user);
  const myGroups = useSelector((state) => selectAllMyGroups(state));
  const subjectGroup = useSelector((state) => selectSubjectGroups(state));
  const membership = useSelector((state) => state.membership);
  const networkCounter = useSelector((state) => state.control.networkState);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !user.signedIn) {
      return;
    }
    dispatch(getMembershipByClientPromise(user?.clientId));
    dispatch(getManagedsByClientPromise(user?.clientId));
  }, [dispatch, user, networkCounter]);

  useEffect(() => {
    if (!user || !user.signedIn) {
      return;
    }
    dispatch(getProgramsPromise());
  }, [dispatch, user, networkCounter]);

  useDeepCompareEffect(() => {
    if (!user || !user.signedIn || !myGroups) {
      return;
    }
    if (subjectGroup) {
      dispatch(
        getTimetablesByGroupListPromise(
          subjectGroup.map((group) => group.groupId)
        )
      );
    }
    if (myGroups) {
      myGroups?.map((group) => {
        dispatch(getMembershipsByGroupPromise(group?.groupId));
        dispatch(getManagingsByGroupPromise(group?.groupId));
      });
    }
  }, [myGroups, membership, subjectGroup, networkCounter]);

  return <div />;
};

export default GroupManager;
