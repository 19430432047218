import React from 'react'
import styled from 'styled-components'
import {
  Caption,
  CaptionMedium,
  Column,
  HorizontalSpace4,
  Row,
  Subtitle2,
  VerticalSpace4,
  VerticalSpace8,
} from '@app/pages/QuizPang/common'
import { Button, Checkbox, Radio } from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import AddIcon from '@mui/icons-material/Add'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { quizTypeToText } from '@app/pages/QuizPang/quiz-modal-store'
import moment from 'moment'
import { Tooltip } from '@mui/material';
import focuspangLogo from '@app/assets/images/focuspang.png';

export const QuizCard = ({ actions, data, isActive = false, onClick }) => {
  return <Container isActive={isActive}>
    {data.link == 'NotSet' || data.link == "" ? <Image src={focuspangLogo} /> : <Image src={data.link} />}
    <Column>
      <TopRow onClick={onClick}>
        <Chip1>{quizTypeToText[data.quizType]}</Chip1>
        <Subtitle2
          dangerouslySetInnerHTML={{ __html: data.title }}
          style={{ whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden', width: '40vw' }}
        />
      </TopRow>
      <Caption onClick={onClick} style={{ display: 'flex', alignItems: 'center' }}>
        <div>{data.grade}학년 ·</div>
        <DivNowrap>{data.quizSubject}</DivNowrap>
        <div>·</div>
        <DivNowrap>{data.quizCategory}</DivNowrap>
        <div>·</div>
        <DivNowrap>{data.keyword}</DivNowrap>
        <div>·</div>
        <DivNowrap>{data.clientName}</DivNowrap>
        <div>·</div>
        <div style={{ marginLeft: 4, marginRight: 4 }}>
          제한시간 {data.timelimit >= 60 ? data.timelimit / 60 + '분' : data.timelimit + '초'} · </div>
        <div>{moment(data.createdAt).format('YYYY-MM-DD')} 제작</div>
      </Caption>
      <VerticalSpace4 />
      <Caption onClick={onClick} style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ width: 60 }}>보충 설명 :</div>
        <div style={{ whiteSpace: 'normal', textOverflow: 'ellipsis', overflow: 'hidden', width: '40vw', marginLeft: 4 }}>
          {data.description ? data.description : '보충 설명 없음'}</div>
      </Caption>
      {actions?.props?.onDelete && actions}
    </Column>
    {!actions?.props?.onDelete && actions}
  </Container>
}

export const QuizCardMyActions = ({ onEdit, onDelete }) => {
  return <ActionRow>
    <Button style={{ width: 70, fontSize: 12}} startIcon={<CreateIcon />} onClick={onEdit}>
      수정
    </Button>
    <HorizontalSpace4 />
    <Button style={{ width: 70, fontSize: 12 }} startIcon={<DeleteIcon />} onClick={onDelete}>
      삭제
    </Button>
  </ActionRow>
}

export const QuizCardSelectActions = ({ onSelect, index }) => {
  const isSelected = index >= 0
  return <ActionRow>
    <Checkbox onClick={onSelect} checked={isSelected} />
    {isSelected && <Chip>{index + 1}</Chip>}
  </ActionRow>
}

export const QuizCardChooseActions = ({ onChoose, isChosen }) => {
  return <ActionRow>
    <Radio onClick={onChoose} checked={isChosen} />
  </ActionRow>
}

export const QuizCardOtherSchoolActions = ({ onPreview, onAdd }) => {
  return <ActionRow>
    <Button style={{ width: 100 }} startIcon={<PlayCircleIcon />} onClick={onPreview}>
      미리보기
    </Button>
    <HorizontalSpace4 />
    <Button style={{ width: 160 }} startIcon={<AddIcon />} onClick={onAdd}>
      퀴즈용 문제에 추가
    </Button>
    <HorizontalSpace4 />
  </ActionRow>
}

const Container = styled(Row)`
  flex: 1;
  border: 1px solid ${p => p.isActive ? '#0894A0' : '#e0e0e0'};
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  background-color: ${p => p.isActive ? '#E4F6F8' : ''};
  transition: all 0.1s ease-out;
`

const Image = styled.img`
  min-width: 100px;
  min-height: 100px;
  max-width: 100px;
  max-height: 100px;
  border-radius: 4px;
  margin-right: 16px;
  object-fit: scale-down;
  border-width: 1px;
`

const BoxImage = styled.div`
  width: 120px;
  height: 80px;
  border-radius: 4px;
  margin-right: 16px;
  border-width: 1px;
`

const DivNowrap = styled.div`
white-space: nowrap;
text-overflow: ellipsis;
overflow: hidden;
max-width: 6vw;
margin-left:4px;
margin-right:4px;
`

const Chip1 = styled(CaptionMedium)`
display: flex;
min-width: 50px;
align-items: center;
justify-content: center;
  background: #f5f5f5;
  border-radius: 4px;
  margin-right: 8px;
`
const Chip = styled(CaptionMedium)`
  padding: 2px 8px;
  background: #f5f5f5;
  border-radius: 4px;
  margin-right: 8px;
`

const TopRow = styled(Row)`
  margin-bottom: 12px;
`

const ActionRow = styled(Row)`
  flex: 1;
  align-self: flex-start;
  justify-content: flex-end;
`
