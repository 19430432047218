import { makeFreshAllIds } from '../helpers';
import {
  PURGE_QUIZSET,
  PURGE_OTHER_SCHOOL_QUIZSET,
  GET_QUIZSET_LIST_ALL_SUCCESS,
  GET_QUIZSET_LIST_ALL_FAIL,
  GET_QUIZSET_LIST_BY_CLIENT_SUCCESS,
  GET_QUIZSET_LIST_BY_CLIENT_FAIL,
  GET_QUIZSET_LIST_BY_SCHOOL_SUCCESS,
  GET_QUIZSET_LIST_BY_SCHOOL_FAIL,
  CREATE_QUIZSET_SUCCESS,
  CREATE_QUIZSET_FAIL,
  UPDATE_QUIZSET_SUCCESS,
  UPDATE_QUIZSET_FAIL,
  DELETE_QUIZSET_SUCCESS,
  DELETE_QUIZSET_FAIL,
} from './actionTypes';

const initialState = {
  // quizset owned by user
  byId: {},
  allIds: [],
  // quizset from other school
  otherSchoolById: {},
  otherSchoolAllIds: [],
};

const quizset = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZSET:
      return initialState;
    case PURGE_OTHER_SCHOOL_QUIZSET:
      return {
        ...state,
        otherSchoolById: {},
        otherSchoolAllIds: [],
      };

    case GET_QUIZSET_LIST_BY_CLIENT_SUCCESS:
      return {
        ...state,
        byId: action.byId,
        allIds: action.allIds,
      };

    case GET_QUIZSET_LIST_ALL_SUCCESS:
    case GET_QUIZSET_LIST_BY_SCHOOL_SUCCESS:
      return {
        ...state,
        otherSchoolById: action.otherSchoolById,
        otherSchoolAllIds: action.otherSchoolAllIds,
      };

    case CREATE_QUIZSET_SUCCESS:
    case UPDATE_QUIZSET_SUCCESS:
      // 아예 quizzes 가 존재하지 않는 경우, 기존에 있던 값을 활용
      // 옵션만 업데이트 하는 경우 현재 서버에서 값을 내려주지 않고 있음
      if (!action.quizset.quizzes) {
        action.quizset.quizzes = state.byId[action.quizset.quizsetId].quizzes
      }
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.quizset.quizsetId]: action.quizset,
        },
        allIds: makeFreshAllIds(state.allIds, [action.quizset.quizsetId]),
      };

    case DELETE_QUIZSET_SUCCESS:
      const { [action.quizsetId]: _, ...otherById } = state.byId;
      return {
        ...state,
        byId: otherById,
        allIds: state.allIds.filter((quizsetId) => quizsetId !== action.quizsetId),
      };

    case CREATE_QUIZSET_FAIL:
    case UPDATE_QUIZSET_FAIL:
    case DELETE_QUIZSET_FAIL:
      console.error('[Quizset Reducer] ', action.payload);
      return state;

    case GET_QUIZSET_LIST_BY_CLIENT_FAIL:
    case GET_QUIZSET_LIST_ALL_FAIL:
    case GET_QUIZSET_LIST_BY_SCHOOL_FAIL:
      console.error('[Quizset Reducer] ', 'list fetching failed');
      return state;

    default:
      break;
  }
  return state;
};

export default quizset;
