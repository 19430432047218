import { Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

const history = createBrowserHistory();

Sentry.init({
  dsn: 'https://b5928d71793040d28a37fa3fcb8b755d@o1343937.ingest.sentry.io/6619386',
  integrations: [
    new BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  // https://develop.sentry.dev/sdk/performance/#tracessamplerate
  tracesSampleRate: 1.0,
});

export const SentryRoute = Sentry.withSentryRouting(Route);
