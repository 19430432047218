import React, { useMemo, useState } from 'react';
import styles from './SubgroupActivityList.module.scss';
import EditIcon from '@mui/icons-material/Edit';
import { SubgroupActivityStudentList } from './SubgroupActivityStudentList';
import ModalAssembleBand from '../Modal/ModalAssembleBand';
import ModalGenericConfirm from '../Modal/ModalGenericConfirm';
import {
  deleteSubgroupActivityPromise,
  updateSubgroupActivityPromise,
} from '@app/store/actions';
import { useDispatch } from 'react-redux';
import { Tooltip } from '@mui/material';

export const SubgroupActivityList = ({
  searchStudent,
  studentList,
  subgroupList,
}) => {
  const dispatch = useDispatch();
  const [editSubgroup, setEditSubgroup] = useState();
  const [deleteModal, setDeleteModal] = useState(false);

  const filterStudentgroup = useMemo(() => {
    if (!subgroupList) {
      return [];
    }
    if (searchStudent) {
      return subgroupList
        ?.map((subgroup) => ({
          ...subgroup,
          students: subgroup?.students.filter((student) =>
            student.userName.match(new RegExp(searchStudent))
          ),
        }))
        .filter((subgroup) => subgroup.students.length);
    }
    return subgroupList;
  }, [searchStudent, subgroupList]);

  const onEdit = (name, clientIds) => {
    const students = JSON.stringify(
      clientIds.map((clientId) => ({ clientId }))
    );
    dispatch(
      updateSubgroupActivityPromise({
        subgroupActivityId: editSubgroup.subgroupActivityId,
        subgroupId: editSubgroup.subgroupId,
        name,
        students,
      })
    ).then(() => {
      setEditSubgroup();
    });
  };
  const onDelete = () => {
    dispatch(deleteSubgroupActivityPromise(editSubgroup.subgroupId)).then(
      () => {
        setEditSubgroup();
        setDeleteModal(false);
      }
    );
  };

  if (!filterStudentgroup.length && searchStudent) {
    return (
      <div className={styles['noResult']}>검색 결과가 존재하지 않습니다.</div>
    );
  }
  return (
    <div>
      {filterStudentgroup.map((subgroup) => (
        <div className={styles['subgroupContainer']} key={subgroup.subgroupId}>
          <div className={styles['subgroupHeader']}>
            <div className={styles['subgroupTitle']}>{subgroup.name}</div>
            <Tooltip title="모둠의 이름, 참여 학생을 추가 제거 등의 수정이 가능합니다.">
              <div
                className={styles['subgroupEdit']}
                onClick={() => setEditSubgroup(subgroup)}
              >
                <EditIcon />
                수정
              </div>
            </Tooltip>
          </div>
          <SubgroupActivityStudentList studentList={subgroup?.students} />
        </div>
      ))}
      {editSubgroup && (
        <ModalAssembleBand
          confirmModal={true}
          setConfirmModal={setEditSubgroup}
          onHide={() => setEditSubgroup()}
          onAssembleBandActivity={onEdit}
          onRefuseBandActivity={() => setDeleteModal(true)}
          students={studentList}
          studentsWithGroup={studentList
            .filter(
              (std) =>
                std.subgroupId && editSubgroup.subgroupId !== std.subgroupId
            )
            .map((student) => student.clientId)}
          editMode={true}
          previousBandName={editSubgroup.name}
          previousBandMember={editSubgroup.students.map((std) => std.clientId)}
        />
      )}
      {deleteModal && editSubgroup && (
        <ModalGenericConfirm
          confirmModal={true}
          setConfirmModal={setDeleteModal}
          title={`'${editSubgroup.name}' 모둠을 정말 삭제하시겠어요?`}
          onHide={() => setDeleteModal(false)}
          confirmText="삭제"
          onFire={onDelete}
          dimmed
        />
      )}
    </div>
  );
};
