import { combineReducers } from 'redux';
import user from './user/reducer';
import clients from './clients/reducer';
import groups from './groups/reducer';
import notification from './notification/reducer';
import programs from './programs/reducer';
import programconfigs from './programconfigs/reducer';
import classrooms from './classrooms/reducer';
import managing from './managing/reducer';
import schools from './school/reducer';
import control from './control/reducer';
import timetable from './timetable/reducer';
// import { firebaseReducer } from "react-redux-firebase";
// import { firestoreReducer } from "redux-firestore"; // <- needed if using firestore
// import classtimetable from './classtimetable/reducer';
import filelinks from './filelinks/reducer';
import kakaocert from './kakaocert/reducer';
import membership from './membership/reducer';
import participations from './participation/reducer';
import device from './devices/reducer';
import managed from './managed/reducer';
import quiz from './quiz/reducer';
import quizset from './quizset/reducer';
import quizsetSession from './quizsetSession/reducer';
import groupActivity from './groupActivity/reducer';
import reportList from './report/reducer';
import reportEvents from './reportEvents/reducer';
import commentsLayer from './commentsLayer/reducer';

// export const history = createBrowserHistory();
const rootReducer = combineReducers({
  // public
  user,
  clients,
  groups,
  notification,
  programs,
  programconfigs,
  classrooms,
  managing,
  schools,
  control,
  timetable,
  kakaocert,
  filelinks,
  membership,
  participations,
  device,
  managed,
  quiz,
  quizset,
  quizsetSession,
  groupActivity,
  reportList,
  reportEvents,
  commentsLayer,
  // firebase: firebaseReducer,
  // firestore: firestoreReducer, // <- needed if using firestore
});

export default rootReducer;
