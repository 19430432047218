import React from 'react'
import styled from 'styled-components'
import {
  Body2,
  Caption,
  Column,
  HorizontalSpace4,
  Row,
  Subtitle1,
  VerticalSpace4,
} from '@app/pages/QuizPang/common'
import { Button } from '@mui/material'
import CreateIcon from '@mui/icons-material/Create'
// import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import AddIcon from '@mui/icons-material/Add'
import DeleteIcon from '@mui/icons-material/Delete'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import moment from 'moment/moment'
import { selectActiveClassroom } from '@app/store/selectors'
import { useSelector } from 'react-redux'

export const QuizsetCard = ({ data, actions, onClickBottomButton }) => {
  const activeClassroom = useSelector((state) => selectActiveClassroom(state))
  return <Container>
    <Row>
      <Subtitle1>{data.title} ({data.quizzes.length}개의 문제)</Subtitle1>
      <HorizontalSpace4/>
      <HorizontalSpace4/>
      <QuizsetTimeInfo data={data}/>
      <HorizontalSpace4/>
      <HorizontalSpace4/>
      {activeClassroom ? actions : actions?.props?.onDelete || actions?.props?.onAdd ? actions : null}
    </Row>
    <VerticalSpace4/>
    <MiddleCaption>
      {data.grade}학년 · {data?.subject} · {data?.keyword} · {data.clientName} · {moment(data.createdAt).format('YYYY-MM-DD')} 제작
    </MiddleCaption>
    <BottomButton
      variant="contained"
      color="secondary"
      endIcon={<ExpandMoreIcon/>}
      onClick={() => onClickBottomButton()}
    >
      {data.quizzes.length}개의 문제 모두 보기
    </BottomButton>
  </Container>
}

export const QuizsetTimeInfo = ({ data }) => {
  return <Row>
    <TimerIcon>
      <TimerOutlinedIcon fontSize="small"/>
    </TimerIcon>
    <TimerText>
      {data.timeDisplay} 소요
    </TimerText>
  </Row>
}

export const QuizsetCardMyActions = ({ onEdit, onDelete }) => {
  return <>
    <Button startIcon={<CreateIcon/>} onClick={onEdit}>
      수정
    </Button>
    <HorizontalSpace4/>
    <Button startIcon={<DeleteIcon/>} onClick={onDelete}>
      삭제
    </Button>
    {/*<HorizontalSpace4/>*/}
    {/*<Button startIcon={<PlayCircleIcon/>}>*/}
    {/*  미리보기*/}
    {/*</Button>*/}
  </>
}

export const QuizsetCardOtherSchoolActions = ({ onAdd }) => {
  return <>
    {/*<Button startIcon={<PlayCircleIcon/>}>*/}
    {/*  미리보기*/}
    {/*</Button>*/}
    {/*<HorizontalSpace4/>*/}
    <Button startIcon={<AddIcon/>} onClick={onAdd}>
      퀴즈목록에 추가
    </Button>
  </>
}

export const QuizsetCardDuringClassActions = ({ onStart }) => {
  return <>
    <Button
      onClick={onStart}
      variant="contained"
      endIcon={<ChevronRightIcon/>}
      style={{ marginLeft: 8 }}
    >
      퀴즈 시작
    </Button>
  </>
}

const Container = styled(Column)`
  background-color: #fafafa;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  padding: 16px;
  margin-bottom: 8px;
`

const TimerIcon = styled(Body2)`
  line-height: 20px;
  margin-right: 4px;
`

const TimerText = styled(Body2)`
  line-height: 20px;
`

const MiddleCaption = styled(Caption)`
  margin-bottom: 16px;
`

const BottomButton = styled(Button)`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 36px;
  letter-spacing: 1.25px;
`
