import { makeFreshAllIds } from '../helpers';
import {
  PURGE_CLASSROOM,
  GET_CLASSROOM_SUCCESS,
  GET_CLASSROOM_FAIL,
  GET_ACTIVE_CLASSROOM_SUCCESS,
  GET_ACTIVE_CLASSROOM_FAIL,
  CREATE_CLASSROOM_SUCCESS,
  CREATE_CLASSROOM_FAIL,
  UPDATE_CLASSROOM_LOADING,
  UPDATE_CLASSROOM_SUCCESS,
  UPDATE_CLASSROOM_FAIL,
  DELETE_CLASSROOM_SUCCESS,
  DELETE_CLASSROOM_FAIL,
  SET_ACTIVE_CLASSROOM_NOT_EXIST,
  SET_SCREEN_SORT_OPTION,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
  fetchedAllGroups: false,
  selectedGroupId: null,
  isClassSession: false,
  classSession: null,
  isClassroomLoading: false,
  screenSortOption: 1,
  participation: null,
};

const classrooms = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CLASSROOM:
      return initialState;

    case SET_SCREEN_SORT_OPTION:
      return {
        ...state,
        screenSortOption: action.payload,
      };

    case UPDATE_CLASSROOM_LOADING:
      return {
        ...state,
        isClassroomLoading: action.payload,
      };
    case GET_CLASSROOM_SUCCESS:
    case CREATE_CLASSROOM_SUCCESS:
    case UPDATE_CLASSROOM_SUCCESS:
      const activeClassroom =
        action.classroom.state === 'IN_CLASS'
          ? { isClassSession: true, classSession: action.classroom }
          : {};
      return {
        ...state,
        ...activeClassroom,
        byId: {
          ...state.byId,
          [action.classroom.classroomId]: action.classroom,
        },
        allIds: makeFreshAllIds(state.allIds, [action.classroom.classroomId]),
      };

    case DELETE_CLASSROOM_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.classroom.classroomId]: action.classroom,
        },
        allIds: state.allIds.filter(
          (classroomId) => classroomId !== action.classroom.classroomId
        ),
      };

    case GET_ACTIVE_CLASSROOM_SUCCESS:
      return {
        ...state,
        isClassSession: true,
        classSession: action.classroom,
        participation: action.participation,
      };

    case GET_CLASSROOM_FAIL:
    case CREATE_CLASSROOM_FAIL:
    case UPDATE_CLASSROOM_FAIL:
    case DELETE_CLASSROOM_FAIL:
      console.error('[Classroom Reducer] ', action.payload);
      return state;

    // todo only exception for 404 Not found
    case GET_ACTIVE_CLASSROOM_FAIL:
    case SET_ACTIVE_CLASSROOM_NOT_EXIST:
      return {
        ...state,
        byId: {},
        allIds: [],
        isClassSession: false,
        classSession: null,
      };

    default:
      break;
  }
  return state;
};

export default classrooms;
