import React from 'react';
import Sidebar from '@components/Layout/Sidebar';
import {
  Body2,
  Column,
  Heading5,
  MainContentLayer,
  Row,
  SideBarRest,
} from '@app/pages/QuizPang/common';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Spinner } from '@app/pages/QuizPang/spinner';
import { useDispatch, useSelector } from 'react-redux';
import { selectClientQuizset } from '@app/store/quizset/selector';
import { Image, ImageContainer } from '@app/pages/QuizPang/quiz-image';
import { selectClientQuiz } from '@app/store/quiz/selector';
import { QuizInput } from '@app/pages/QuizPang/quiz-input';
import { QuizChoice } from '@app/pages/QuizPang/quiz-choice';
import { QuizSessionResult } from '@app/pages/QuizPang/InClass/quizset-session-result';
import { setSocketData } from '@app/store/actions';

const QuizsetSession = () => {
  const { quizsetId, index: _index, result, sessionId } = useParams();
  const index = Number(_index);
  const history = useHistory();
  const quizset = useSelector((state) => selectClientQuizset(state, quizsetId));
  const dispatch = useDispatch();
  const activeClassroom = useSelector((state) => state.classrooms.classSession);
  const user = useSelector((state) => state.user);
  const session = useSelector(
    (state) => state.quizsetSession.sessionsById[sessionId]
  );

  const quizsetSession = session.quizsetSession;

  const quizNext = () => {
    history.replace(String(index + 1));
    const quizNextData =
      quizsetSession.quizsetSessionId + ',' + String(index + 1);
    if (activeClassroom == null || activeClassroom == undefined) return
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: activeClassroom.groupId,
        clientId: user.clientId,
        type: 'QUIZ_NEXT',
        data: quizNextData,
      })
    );
  };

  return (
    <div className="flex w-100vw h-100vh">
      <Sidebar inClassroom />
      <SideBarRest>
        <MainContentLayer>
          {index === 0 ? (
            <Row
              style={{
                flex: 1,
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <Spinner start={5} onZero={() => quizNext()} />
            </Row>
          ) : result ? (
            <QuizSessionResult
              sessionId={sessionId}
              index={index}
              isLast={quizset.quizzes.length === index}
              quizId={quizset.quizzes[index]?.quizId}
            />
          ) : (
            <QuizDisplay
              sessionId={sessionId}
              quizId={quizset.quizzes[index - 1].quizId}
              index={index}
              total={quizset.quizzes.length}
              isLast={quizset.quizzes.length === index}
            />
          )}
        </MainContentLayer>
      </SideBarRest>
    </div>
  );
};

const QuizDisplay = ({ quizId, index, total, sessionId, isLast }) => {
  const quiz = useSelector((state) => selectClientQuiz(state, quizId));
  const history = useHistory();
  const location = useRouteMatch();
  const dispatch = useDispatch();
  const activeClassroom = useSelector((state) => state.classrooms.classSession);
  const user = useSelector((state) => state.user);
  const result = useSelector(
    (state) => state.quizsetSession.resultsById[sessionId]
  );
  const session = useSelector(
    (state) => state.quizsetSession.sessionsById[sessionId]
  );

  const quizsetSession = session.quizsetSession;

  const quizSummary = () => {
    // history.replace(`${location.url}/result`);
    const quizSummaryData = quizsetSession.quizsetSessionId + ',' + String(index);
    if (activeClassroom == null || activeClassroom == undefined) return
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: activeClassroom.groupId,
        clientId: user.clientId,
        type: 'QUIZ_SUMMARY',
        data: quizSummaryData,
      })
    );
  };

  return (
    <Row style={{ marginTop: 56, alignItems: 'flex-start' }}>
      <Column style={{ width: 160 }}>
        <Heading5 style={{ textAlign: 'center' }}>
          {index}/{total}
        </Heading5>
      </Column>
      <Column style={{ alignItems: 'center', marginLeft: 36 }}>
        {/* <Heading5 style={{ margin: '8px 0 24px 0', width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign:'center' }}>
          {quiz.title}</Heading5> */}
          <Heading5 
            dangerouslySetInnerHTML={{ __html: quiz.title }}
            style={{ margin: '8px 0 24px 0', width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign:'center' }}
          />
          {quiz.link !== 'NotSet' && quiz.link !== "" ?  
          <ImageContainer><Image src={quiz.link} /></ImageContainer> 
          : null }
        {/* {quiz.description && quiz.description.length > 0 && (
          <Body2
            style={{
              marginTop: 16,
              color: 'rgba(0, 0, 0, 0.87)',
              width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign:'center'
            }}
          >
            {quiz.description}
          </Body2>
        )} */}
        <div style={{ height: 24 }} />
        {quiz.quizType === 'SHORT' ? (
          <>
            {quiz.choices.map((x, i) => {
              return <QuizInput key={i} disabled value={`${x.description === "Wrong Answer" ? "오답" : x.description} (${x.point}점)`} />
            })}
          </>
        ) : (
          <>
            {quiz.choices.map((x, i) => {
              const index = i + 1;
              return (
                <QuizChoice
                  key={index}
                  index={index}
                  description={x.description}
                  active={x.isAnswer}
                  onClick={() => {}}
                />
              );
            })}
          </>
        )}
      </Column>
      <Column style={{ marginLeft: 80 }}>
        {/* <Button
          onClick={() => {
            quizSummary();
          }}
        >
          통계 결과로 이동
        </Button>
        {!isLast && (
          <Button
            onClick={() => {
              quizNext();
            }}
          >
            다음 문제로 이동
          </Button>
        )} */}

        <Spinner
          start={quiz.timelimit}
          onZero={() => {
            history.replace(`${location.url}/result`);
            quizSummary();
          }}
          isSmall
        />
      </Column>
    </Row>
  );
};

export default QuizsetSession;
