import styled from "styled-components";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/locale";

import "react-datepicker/dist/react-datepicker.css";

export const DateTimePicker = ({ value, onChange }) => {
  return (
    <StyledWrap>
      <DatePicker
        locale={ko}
        dateFormat='yyyy년 MM월 dd일 HH시 mm분'
        showTimeInput={true}
        selected={value}
        onChange={onChange}
      />
    </StyledWrap>
  );
};
const StyledWrap = styled.span`
  input {
    width: 210px;
    text-align: center;
  }
`;
