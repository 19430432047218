import React from "react";

function Review(data) {
  // console.log("Review is: ");
  // console.log(data);
  return (
    <div style={{ width: "100%" }}>
      <strong>{data.data.schoolName}</strong>
      {
        <p>
          {" "}
          <strong>{data.data.grade}</strong>
          학년{" "}
          <strong>
            {/* {inputs.steps.classNum?.value === undefined
                ? inputs.steps.classNumHR?.value
                : inputs.steps.classNum?.value} */}
            {data.data.classNum}
          </strong>{" "}
          반{" "}
        </p>
      }
      <strong>
        {data.data.subjectName === undefined
          ? "담임"
          : `${data.data.subjectName} `}
      </strong>
      수업
    </div>
  );
}

export default Review;
