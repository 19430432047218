import { deleteGroupActivityPromise } from '@app/store/actions';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import ModalGenericConfirm from '../Modal/ModalGenericConfirm';
import styles from './GroupActivityDelete.module.scss';
import DeleteIcon from '@mui/icons-material/Delete';
import { Tooltip } from '@mui/material';

export const GroupActivityDelete = ({ selectedActivity, onDelete }) => {
  const dispatch = useDispatch();
  const [confirmModal, setConfirmModal] = useState(false);

  const onSubmit = () => {
    setConfirmModal(false);
    dispatch(
      deleteGroupActivityPromise(selectedActivity.subgroupActivityId)
    ).then(() => {
      onDelete();
    });
  };

  if (!selectedActivity) {
    return null;
  }

  return (
    <>
      <Tooltip
        title="모둠 활동 주제를 삭제합니다. 삭제 시 현재 모둠 활동 주제에 생성되어있는 모둠 또한 삭제됩니다."
        arrow
      >
        <div
          className={styles['headerGroupDelete']}
          onClick={() => setConfirmModal(true)}
        >
          <DeleteIcon className={styles['deleteIcon']} />
          <button className={styles['deleteText']}>모둠 활동 주제 삭제</button>
        </div>
      </Tooltip>

      {confirmModal && (
        <ModalGenericConfirm
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
          title={`${selectedActivity.name} 모둠 활동 주제를 정말 삭제하시겠어요?`}
          description={'활동 주제 안에 있는 모둠도 같이 삭제됩니다.'}
          onHide={() => setConfirmModal(false)}
          onFire={onSubmit}
          confirmText={'삭제'}
        />
      )}
    </>
  );
};
