import * as React from 'react'
import { useEffect, useRef, useState } from 'react'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import {
  Column,
  Heading5,
  Heading6,
  HorizontalSpace8,
  InputGray,
  InputGrayScroll,
  InputWhite,
  InputWhiteScroll,
  MiddleMenuBar,
  Row,
  Row2,
  Subtitle1,
  SubTitle2,
  Subtitle3,
  Subtitle4,
  Subtitle5,
  TabButtonInteractive,
  VerticalSpace8,
  VerticalSpace4,
} from '@app/pages/QuizPang/common'
import { Button } from '@mui/material'
import { Select } from '@app/pages/QuizPang/select'
import MenuItem from '@mui/material/MenuItem'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import CloseIcon from '@mui/icons-material/Close'
import { subjectStore } from '@app/pages/QuizPang/subject-store'
import { observer } from 'mobx-react'
import { QuizChoice } from '@app/pages/QuizPang/quiz-choice'
import { SearchInput } from '@app/pages/QuizPang/search-input'
import { QuizCard, QuizCardSelectActions } from '@app/pages/QuizPang/quiz-card'
import {
  quizTypes,
  quizTypeToText,
} from '@app/pages/QuizPang/quiz-modal-store'
import { QuizFilterStore } from '@app/pages/QuizPang/quiz-filter-store'
import { useDispatch, useSelector } from 'react-redux'
import { selectClientQuizList } from '@app/store/quiz/selector'
import {
  createQuizsetPromise,
  updateQuizsetPromise,
} from '@app/store/quizset/actions'
import filterIcon from '@app/assets/images/icons/tune.svg';
import { SubjectModal } from './subject-modal'

const Modify = observer(({ store }) => {
  const filterStore = useRef(new QuizFilterStore())
  const quizList = useSelector(selectClientQuizList)
  useEffect(() => {
    filterStore.current.setData(quizList)
  }, [quizList])
  const counts = filterStore.current.typeCounts
  const [filterButton, setFilterButton] = useState(false);
  const [subjectModal, setSubjectModal] = useState(false);

  return <Column style={{ flex: 1 }}>
    <Column style={{ padding: '0 24px' }}>
      <Subtitle3>1. 퀴즈의 제목을 만들어주세요. 나중에 수정할 수 있습니다.</Subtitle3>
      <VerticalSpace4 />
      {/* <InputGray placeholder="퀴즈 제목 입력"
                 style={{ marginBottom: 20, width: 688 }}
                 value={store.title}
                 onChange={(e) => store.setTitle(e.target.value)}
      /> */}
      <InputWhiteScroll placeholder="퀴즈 제목 입력"
                 style={{ marginBottom: 20, width: '100%' }}
                 value={store.title}
                 onChange={(e) => store.setTitle(e.target.value)}
                 rows={1}
      />
      {/* <Subtitle3>2. 퀴즈를 사용할 학년을 선택해 주세요.</Subtitle3> */}
      <Subtitle3>2. 퀴즈의 카테고리를 선택해 주시면 퀴즈 검색 시 카테고리를 적용하여 검색할 수 있습니다. (선택)</Subtitle3>
      <VerticalSpace4 />
      <Row style={{ marginBottom: 24 }}>
        <Select width={110} value={store.grade}
                onChange={(e) => store.setGrade(e.target.value)}>
          {[1, 2, 3].map(x =>
            <MenuItem key={x} value={x}>{x}학년</MenuItem>,
          )}
        </Select>
        <HorizontalSpace8/>
        <Select width={160} value={store.subject}
                onChange={(e) => store.setSubject(e.target.value)}
        >
          <MenuItem value={-1}>과목 선택</MenuItem> 
          {[...new Set([store.subject, ...subjectStore.data].filter(x => x !== -1))]?.map(x =>
            <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
          )}
          <Button endIcon={<AddCircleIcon/>}
                  onClick={() => setSubjectModal(true)}
                  style={{
                    fontSize: 16,
                    lineHeight: '24px',
                    width: '100%',
                    padding: '6px 16px',
                  }}
          >
            과목 추가
          </Button>
        </Select>
        <HorizontalSpace8/>
        <Row>
          <InputWhite placeholder="키워드 입력"
                      value={store.keyword}
                      onChange={(e) => store.setKeyword(e.target.value)}
                      style={{ width: 165 }}
          />
        </Row>
      </Row>



      {/* <Subtitle1 style={{ marginBottom: 8 }}>문제 창고에서 선택</Subtitle1> */}
      <Subtitle3>3. 퀴즈에 포함할 문제들을 찾아 이번 퀴즈에 포함할 문제를 선택해주세요.</Subtitle3>
      <VerticalSpace4 />
    </Column>
    <MiddleMenuBar style={{flexDirection: 'column', alignItems:'flex-start'}}>
      <Row2>
        <button 
          style={{backgroundColor: '#fff', padding: 10, borderWidth: 1, borderRadius: 4}}
          onClick={() => {filterButton ? setFilterButton(false) : setFilterButton(true)}}
        >
          <img src={filterIcon} />
        </button>
        <HorizontalSpace8/>
        <SearchInput label="문제 찾기" value={filterStore.current.query} width='100%'
                     onChange={(e) => filterStore.current.setQuery(e.target.value)}/>
      </Row2>
      {filterButton ? 
      <Row style={{marginTop: 8}}>
      <Subtitle5>검색필터 설정</Subtitle5>
      
        <HorizontalSpace8/>
        <Select width={110} value={filterStore.current.grade}
                  onChange={(e) => filterStore.current.setGrade(e.target.value)}>
            <MenuItem value={-1}>학년 전체</MenuItem>
            {filterStore.current.availableGrades.map(x =>
              <MenuItem key={x} value={x}>{x}학년</MenuItem>,
            )}
          </Select>
          <HorizontalSpace8/>
          <Select width={150} value={filterStore.current.subject}
                  onChange={(e) => filterStore.current.setSubject(e.target.value)}>
            <MenuItem value={-1}>과목 전체</MenuItem>
            {filterStore.current.availableSubjects.map(x =>
              <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
            )}
          </Select>
          <HorizontalSpace8/>
          <Select width={180} value={filterStore.current.category}
                  onChange={(e) => filterStore.current.setCategory(e.target.value)}>
            <MenuItem value={-1}>교과서 출판사 전체</MenuItem>
            {filterStore.current.availableCategories.map(x =>
              <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
            )}
          </Select>
          <HorizontalSpace8/>
          <Select width={170} value={filterStore.current.keyword}
                  onChange={(e) => filterStore.current.setKeyword(e.target.value)}>
            <MenuItem value={-1}>키워드 전체</MenuItem>
            {filterStore.current.availableKeywords.map(x =>
              <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
            )}
          </Select>
          <HorizontalSpace8/>
          <Select width={140} value={filterStore.current.type}
                  onChange={(e) => filterStore.current.setType(e.target.value)}>
            <MenuItem value={-1}>문제 유형 전체</MenuItem>
            {quizTypes.map(x =>
              <MenuItem key={x} value={x}>{quizTypeToText[x]}</MenuItem>,
            )}
          </Select>
      </Row> : null}
      {/* <Row style={{paddingLeft: 102}}>
      <TabButtonInteractive isSelected={filterStore.current.type === -1}
                              onClick={() => {
                                filterStore.current.setType(-1)
                              }}>전체 ({counts.TOTAL})</TabButtonInteractive>
        {quizTypes.map(x => {
          return <React.Fragment key={x}>
            <HorizontalSpace8/>
            <TabButtonInteractive
              isSelected={filterStore.current.type === x}
              onClick={() => filterStore.current.setType(x)}>
              {quizTypeToText[x]} ({counts[x]})
            </TabButtonInteractive>
          </React.Fragment>
        })}
      </Row> */}
    </MiddleMenuBar>
    <VerticalSpace4/>
    <Column style={{ padding: '0 24px' }}>
      {/* <Subtitle3>4. 이번 퀴즈에 포함할 문제를 선택해주세요. 문제 선택 순서를 보실 수 있습니다.</Subtitle3> */}
      <VerticalSpace4 />
      {
        filterStore.current.filtered.length > 0 ?
          filterStore.current.filtered.map(x => <QuizCard
            key={x.quizId}
            actions={<QuizCardSelectActions
              onSelect={() => store.toggleQuiz(x.quizId)}
              index={store.getQuizIndex(x.quizId)}
            />}
            data={x}/>) :
          <SubTitle2>선택 가능한 문제가 없습니다.</SubTitle2>
      }
    </Column>
    <SubjectModal 
      open={subjectModal}
      onCancel={() => setSubjectModal(false)}
      onAdd={(v) => {
        subjectStore.add(v)
        setSubjectModal(false)
      }}
    />
  </Column>
})

const Preview = observer(({ store }) => {
  return <Column style={{ flex: 1, alignItems: 'center' }}>
    <Heading5 style={{ margin: '8px 0 24px 0' }}>{store.title}</Heading5>
    <ImageContainer style={{ marginBottom: 24 }}>
      {store.imgPath !== null && <Image src={store.imgPath}/>}
    </ImageContainer>
    {store.choices.map((x, i) =>
      <QuizChoice index={i + 1} description={x.description}/>,
    )}
  </Column>
})

export const QuizsetModal = ({ isOpen, onClose, store }) => {
  const [isPreview, setIsPreview] = useState(false)
  const { clientId, schoolId } = useSelector((state) => state.user)
  const dispatch = useDispatch()
  const actionName = store.isEdit ? '수정하기' : '만들기'
  
  const postQuizset = async () => {
    const data = store.data
    if (!data.title) {
      alert('퀴즈 질문을 입력해주세요.')
      return
    }
    if (data.quizzes.length === 0) {
      alert('문제를 하나 이상 선택해주세요.')
      return
    }
    // ok to proceed
    const payload = {
      clientId,
      schoolId,
      ...data,
    }
    if (store.isEdit) {
      delete payload.clientId
      delete payload.schoolId
    } else {
      delete payload.quizsetId
    }
    try {
      await dispatch(store.isEdit ? updateQuizsetPromise(payload) : createQuizsetPromise(payload))
      onClose()
    } catch (e) {
      alert(`퀴즈 ${actionName} 실패!\n${e}`)
    }
  }

  const onClickClose = () => {
    if (window.confirm(`퀴즈 ${actionName}를 취소하시겠어요?`)) {
      onClose()
    }
  };

  return <Modal open={isOpen}>
    <Container>
      <HeaderSection style={{alignItems: 'flex-end'}}>
        <Heading6>
          {isPreview ? '퀴즈 미리보기' : `퀴즈 ${actionName}`}
        </Heading6>
        <HorizontalSpace8/><HorizontalSpace8/>
        <Subtitle4 style={{marginBottom: 2}}>학생들이 함께 푸는 문제들로 구성합니다.</Subtitle4>
      </HeaderSection>
      <BodySection style={{ flex: 1, alignItems: 'flex-start' }}>
        {isPreview ? <Preview store={store}/> : <Modify store={store}/>}
      </BodySection>
      <BottomSection>
        <Row style={{ flex: 1, justifyContent: 'space-between' }}>
          <Row>
            {/* <Button
             startIcon={isPreview ? <CloseIcon/> : <PlayCircleIcon/>}
             onClick={() => setIsPreview(!isPreview)}
             sx={isPreview ? { color: '#FF5252' } : {}}
            >
             {isPreview ? '미리보기 종료' : '퀴즈 미리보기'}
            </Button> */}
          </Row>
          {!isPreview &&
            <Row>
              <Button onClick={onClickClose}>취소</Button>
              <HorizontalSpace8/>
              <Button
                onClick={() => postQuizset()}>{actionName}</Button>
            </Row>
          }
        </Row>
      </BottomSection>
    </Container>
  </Modal>
}

const Container = styled(Column)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04),
  0 36px 41px rgba(0, 0, 0, 0.04),
  0 4.50776px 5.13384px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  height: 620px;
  width: 1208px;
  justify-content: space-between;
`

const HeaderSection = styled(Row)`
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
`

const BodySection = styled(Row)`
  padding: 16px 0;
  overflow-y: scroll;
`

const BottomSection = styled(Row)`
  padding: 16px 24px;
  border-top: 1px solid #eee;
`

const ImageContainer = styled(Column)`
  background: #F5F5F5;
  border-radius: 8px;
  width: 480px;
  height: 320px;
  position: relative;
  overflow: hidden;
`

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
