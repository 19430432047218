import { profileImgPath } from '@api';
import ProfileImage from '../ProfileImage';
import styles from './ScreenButtons.module.scss';

import LockIcon from '@mui/icons-material/Lock';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ReplyIcon from '@mui/icons-material/Reply';
import ClassroomTimer from '../Card/CardClassroomTimer';
import { useSelector } from 'react-redux';
import { Tooltip } from '@mui/material';

const ScreenButtons = ({
  currentStudent,
  isStdLocked,
  clickLockScreen,
  clickScreenShare,
  isScreenShared,
  showModal,
}) => {
  const user = useSelector((state) => state.user);
  return (
    <div className={styles['screen-buttons-container']}>
      <div className={styles['screen-student-name']}>
        <ProfileImage
          size={26}
          url={
            currentStudent?.clientId
              ? profileImgPath(currentStudent.clientId)
              : profileImgPath(user?.clientId)
          }
        />
          <Tooltip title={currentStudent?.userName || user?.userName + ' 선생님'} arrow>
        <div
          className={styles['screen-student-name-text']}
          style={{ marginLeft: 8 }}
        >
          {currentStudent?.userName || user?.userName + ' 선생님'}
        </div>
        </Tooltip>
        <ClassroomTimer />
      </div>
      <div className={styles['screen-btn-container']}>
        <Tooltip title='수업 중에 학생들의 화면을 잠그어 학생들이 선생님에게 집중할 수 있도록 도움을 줍니다.' arrow>
          <div className={styles['screen-btn']} onClick={clickLockScreen}>
            <div className={styles['inner-div']}>
              {isStdLocked ? (
                <>
                  <div className={styles['lock-icon-active-box']}>
                    <LockIcon className={styles['lock-icon']} />
                  </div>
                  <div>학생 화면 잠금 해제</div>
                </>
              ) : (
                <>
                  <div className={styles['lock-icon-disabled-box']}>
                    <LockIcon className={styles['lock-icon']} />
                  </div>
                  <div>학생 화면 잠금</div>
                </>
              )}
              {/* <div>{isStdLocked ? '학생 화면 잠금 해제' : '학생 화면 잠금'}</div> */}
            </div>
          </div>
        </Tooltip>

          <div className={styles['screen-btn']} onClick={clickScreenShare}>
            <div className={styles['inner-div']}>
              {isScreenShared ? (
                <>
                    <div className={styles['reply-icon-active-box']}>
                  <Tooltip title='실시간으로 공유 중인 선생님이 지정하신 화면을 중단할 수 있습니다.' arrow>  
                      <ReplyIcon className={styles['reply-icon']} />
                  </Tooltip>
                    </div>
                    <div className={!isScreenShared ? styles['long-text'] : ''}>
                      공유 해제
                    </div>
                </>
              ) : (
                <>
                    <div className={styles['reply-icon-disabled-box']}>
                    <Tooltip title='선생님이 지정하신 화면을 학생들에게 실시간으로 공유할 수 있습니다.' arrow>  
                      <ReplyIcon className={styles['reply-icon']} />
                    </Tooltip>
                    </div>
                    <div className={!isScreenShared ? styles['long-text'] : ''}>
                      학생들에게 공유
                    </div>
                </>
              )}
            </div>
          </div>
      
        <div className={styles['screen-btn']}>
          <div
           onClick={
            showModal
          }
          className={styles['inner-div']}>
            <div className={styles['reply-icon-disabled-box']}>
              <Tooltip title='선생님의 대화면을 전체화면으로 볼 수 있습니다.' arrow>
                <FullscreenIcon className={styles['fullscreen-icon']} />
              </Tooltip>
            </div>
            <div>전체 화면 보기</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScreenButtons;
