import React from 'react';
import styled from 'styled-components';
import { Button } from '@mui/material';

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const CreateButtonWrapper = styled(Row)`
  flex: 1;
  border: 1px dashed #0ba1ae;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  background-color: #fafafa;
  transition: all 0.1s ease-out;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  &:hover {
    background-color: #f0f0f0;
  }
`;

const CreateButton = ({ onClick, startIcon, buttonText }) => {
  return (
    <CreateButtonWrapper onClick={onClick}>
      <Button startIcon={startIcon}>{buttonText}</Button>
    </CreateButtonWrapper>
  );
};

export default CreateButton;
