import { createGroupActivityPromise } from '@app/store/actions';
import { Tooltip } from '@mui/material';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import styles from './GroupActivityCreate.module.scss';

export const GroupActivityCreate = ({ groupId }) => {
  const dispatch = useDispatch();
  const [name, setName] = useState('');

  const onSubmit = () => {
    if (!name) {
      alert('모둠명을 입력해주세요.');
      return;
    }
    dispatch(
      createGroupActivityPromise({
        groupId,
        name,
      })
    );
  };

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <div className={styles['createDesc']}>
        <b>새 모둠 활동 주제 만들기</b>
        <div>먼저, 모둠 활동 주제명을 짓고 다음 화면에서 모둠을 편성해 주세요.</div>
      </div>
      <input
        className={styles['inputName']}
        placeholder="예) 중간 수행평가 모둠"
        value={name}
        onChange={(e) => setName(e.target.value)}
      />
      <Tooltip
        title="모둠 활동 주제의 이름을 작성하셨다면
       모둠 활동 주제 만들기를 눌러주세요."
        arrow
      >
        <button type="submit" className={styles['submitButton']}>
          모둠 활동 주제 만들기
        </button>
      </Tooltip>
    </form>
  );
};
