import React, { useState } from 'react'
import Sidebar from '@components/Layout/Sidebar'
import {
  ArrowBackIconContainer,
  HeaderSection,
  HeaderSectionHeading,
  MainContentLayer,
  SideBarRest,
  Subtitle1,
  Subtitle4,
  VerticalSpace4,
  VerticalSpace8,
} from '@app/pages/QuizPang/common'
import { Tab, Tabs } from '@mui/material'
import { OtherSchoolQuizTab } from '@app/pages/QuizPang/OtherSchool/quiz-tab'
import {
  OtherSchoolQuizsetTab,
} from '@app/pages/QuizPang/OtherSchool/quizset-tab'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useHistory } from 'react-router-dom'

const OtherSchool = () => {
  const [tabIndex, setTabIndex] = useState(0)
  const history = useHistory()
  return <div className="flex w-100vw h-100vh">
    <Sidebar auth={true}/>
    <SideBarRest>
      <MainContentLayer>
        <HeaderSection>
          <ArrowBackIconContainer onClick={() => history.goBack()}>
            <ArrowBackIcon/>
          </ArrowBackIconContainer>
          <HeaderSectionHeading>커뮤니티</HeaderSectionHeading>
          {/* todo: 문제랑 퀴즈 한페이지로 합치기 */}
          {/* <Tabs value={tabIndex}>
            <Tab label="문제" onClick={() => setTabIndex(0)}/>
            <Tab label="퀴즈" onClick={() => setTabIndex(1)}/>
          </Tabs> */}
          <Subtitle4 style={{marginLeft: -10}}>
          퀴즈를 공유하는 곳입니다. 사용하고 싶은 퀴즈와 문제를 찾으실 수 있습니다.</Subtitle4>
        </HeaderSection>
        <VerticalSpace8 />
        {tabIndex === 0 ? <OtherSchoolQuizTab/> : <OtherSchoolQuizsetTab/>}
      </MainContentLayer>
    </SideBarRest>
  </div>
}

export default OtherSchool
