import {
  BottomContentLayer,
  HorizontalSpace8,
  HorizontalSpace4,
  VerticalSpace8,
  VerticalSpace4,
  MiddleMenuBar,
  Row,
  Row2,
  Subtitle1,
  SubTitle2,
  Subtitle4,
  Subtitle5,
} from '@app/pages/QuizPang/common';
import { SearchInput } from '@app/pages/QuizPang/search-input';
import React, { useEffect, useRef, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { QuizsetModal } from '@app/pages/QuizPang/quizset-modal';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteQuizsetPromise,
  getQuizsetListByClientPromise,
} from '@app/store/quizset/actions';
import { QuizsetModalStore } from '@app/pages/QuizPang/quizset-modal-store';
import { Select } from '@app/pages/QuizPang/select';
import MenuItem from '@mui/material/MenuItem';
import { selectClientQuizsetList } from '@app/store/quizset/selector';
import {
  QuizsetCard,
  QuizsetCardMyActions,
} from '@app/pages/QuizPang/quizset-card';
import { observer } from 'mobx-react';
import { QuizsetFilterStore } from '@app/pages/QuizPang/quizset-filter-store';
import { useHistory } from 'react-router-dom';
import { getQuizCategoryAllPromise } from '@app/store/actions';
import filterIcon from '@app/assets/images/icons/tune.svg';
import styled from 'styled-components';
import CreateButton from './Buttons/createButton';

export const QuizsetTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalStore = useRef(new QuizsetModalStore());
  const filterStore = useRef(new QuizsetFilterStore());
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.user.clientId);
  const quizsetList = useSelector(selectClientQuizsetList);
  const history = useHistory();
  const [filterButton, setFilterButton] = useState(false);
  const [activeButton, setActiveButton] = useState(0);

  useEffect(() => {
    dispatch(getQuizsetListByClientPromise(clientId)).then(() =>
      setIsLoading(false)
    );
  }, [dispatch, clientId]);

  useEffect(() => {
    filterStore.current.setData(quizsetList);
  }, [quizsetList]);

  useEffect(() => {
    dispatch(getQuizCategoryAllPromise())
      .then((res) => console.log('[CategoryAll response]'))
      .catch((error) => console.log('[CategoryAll error]', error));
  }, []);

  return (
    <>
      <MiddleMenuBar
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <Row style={{ alignItems: 'flex-end' }}>
          <Subtitle1 style={{ fontSize: 20 }}>My Quizzes</Subtitle1>
          <HorizontalSpace8 />
          <HorizontalSpace8 />
          <Subtitle4>
            수업 시간에 사용할 퀴즈를 검색하고 준비할 수 있습니다.
          </Subtitle4>
        </Row>
        <VerticalSpace8 />
        <VerticalSpace8 />
        <Row2>
          <button
            style={{
              backgroundColor: '#fff',
              padding: 10,
              borderWidth: 1,
              borderRadius: 4,
            }}
            onClick={() => {
              filterButton ? setFilterButton(false) : setFilterButton(true);
            }}
          >
            <img src={filterIcon} alt="filterIcon" />
          </button>
          <HorizontalSpace8 />
          <SearchInput
            label="퀴즈 찾기"
            value={filterStore.current.query}
            width="100%"
            onChange={(e) => filterStore.current.setQuery(e.target.value)}
          />
        </Row2>
        <VerticalSpace8 />
        {filterButton ? (
          <Row>
            <Subtitle5>검색필터 설정</Subtitle5>
            <HorizontalSpace8 />
            <Select
              width={110}
              value={filterStore.current.grade}
              onChange={(e) => filterStore.current.setGrade(e.target.value)}
            >
              <MenuItem value={-1}>학년 전체</MenuItem>
              {filterStore.current.availableGrades.map((x) => (
                <MenuItem key={x} value={x}>
                  {x}학년
                </MenuItem>
              ))}
            </Select>
            <HorizontalSpace8 />
            <Select
              width={150}
              value={filterStore.current.subject}
              onChange={(e) => filterStore.current.setSubject(e.target.value)}
            >
              <MenuItem value={-1}>과목 전체</MenuItem>
              {filterStore.current.availableSubjects.map((x) => (
                <MenuItem style={{ maxWidth: 300 }} key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
            <HorizontalSpace8 />
            <Select
              width={170}
              value={filterStore.current.keyword}
              onChange={(e) => filterStore.current.setKeyword(e.target.value)}
            >
              <MenuItem value={-1}>키워드 전체</MenuItem>
              {filterStore.current.availableKeywords.map((x) => (
                <MenuItem style={{ maxWidth: 300 }} key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </Row>
        ) : null}
      </MiddleMenuBar>
      <BottomContentLayer>
        <Row>
          <Subtitle1>검색결과 설정</Subtitle1>
          <HorizontalSpace8 />
          <SortButton
            style={{
              color:
                activeButton === 0 || activeButton === 1
                  ? '#0894A0'
                  : '#00000099',
            }}
            onClick={() => setActiveButton(activeButton === 0 ? 1 : 0)}
          >
            문제 제작일{activeButton === 0 ? ' ▲' : ' ▼'}
          </SortButton>
          <HorizontalSpace4 />
          <HorizontalSpace4 />
          <SortButton
            style={{
              color:
                activeButton === 2 || activeButton === 3
                  ? '#0894A0'
                  : '#00000099',
            }}
            onClick={() => setActiveButton(activeButton === 2 ? 3 : 2)}
          >
            문제 질문{activeButton === 2 ? ' ▲' : ' ▼'}
          </SortButton>
          <HorizontalSpace4 />
        </Row>
        <VerticalSpace8 />
        <CreateButton
          startIcon={<AddCircleIcon />}
          onClick={() => {
            modalStore.current = new QuizsetModalStore();
            setIsModalOpen(true);
          }}
          buttonText={'퀴즈 만들기'}
        />
        <VerticalSpace8 />

        {isLoading ? (
          <SubTitle2>퀴즈 목록 로딩 중입니다...</SubTitle2>
        ) : filterStore.current.filtered.length > 0 ? (
          activeButton === 0 ? (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
              .map((x) => (
                <QuizsetCard
                  key={x.quizsetId}
                  actions={
                    <QuizsetCardMyActions
                      onEdit={() => {
                        modalStore.current = new QuizsetModalStore(x);
                        setIsModalOpen(true);
                      }}
                      onDelete={() => {
                        const res = window.confirm('정말 퀴즈를 삭제할까요?');
                        if (res) dispatch(deleteQuizsetPromise(x.quizsetId));
                      }}
                    />
                  }
                  data={x}
                  onClickBottomButton={() => {
                    history.push(`/quiz-pang/quizset-detail/${x.quizsetId}`);
                  }}
                />
              ))
          ) : activeButton === 1 ? (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
              .map((x) => (
                <QuizsetCard
                  key={x.quizsetId}
                  actions={
                    <QuizsetCardMyActions
                      onEdit={() => {
                        modalStore.current = new QuizsetModalStore(x);
                        setIsModalOpen(true);
                      }}
                      onDelete={() => {
                        const res = window.confirm('정말 퀴즈를 삭제할까요?');
                        if (res) dispatch(deleteQuizsetPromise(x.quizsetId));
                      }}
                    />
                  }
                  data={x}
                  onClickBottomButton={() => {
                    history.push(`/quiz-pang/quizset-detail/${x.quizsetId}`);
                  }}
                />
              ))
          ) : activeButton === 2 ? (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((x) => (
                <QuizsetCard
                  key={x.quizsetId}
                  actions={
                    <QuizsetCardMyActions
                      onEdit={() => {
                        modalStore.current = new QuizsetModalStore(x);
                        setIsModalOpen(true);
                      }}
                      onDelete={() => {
                        const res = window.confirm('정말 퀴즈를 삭제할까요?');
                        if (res) dispatch(deleteQuizsetPromise(x.quizsetId));
                      }}
                    />
                  }
                  data={x}
                  onClickBottomButton={() => {
                    history.push(`/quiz-pang/quizset-detail/${x.quizsetId}`);
                  }}
                />
              ))
          ) : (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => b.title.localeCompare(a.title))
              .map((x) => (
                <QuizsetCard
                  key={x.quizsetId}
                  actions={
                    <QuizsetCardMyActions
                      onEdit={() => {
                        modalStore.current = new QuizsetModalStore(x);
                        setIsModalOpen(true);
                      }}
                      onDelete={() => {
                        const res = window.confirm('정말 퀴즈를 삭제할까요?');
                        if (res) dispatch(deleteQuizsetPromise(x.quizsetId));
                      }}
                    />
                  }
                  data={x}
                  onClickBottomButton={() => {
                    history.push(`/quiz-pang/quizset-detail/${x.quizsetId}`);
                  }}
                />
              ))
          )
        ) : (
          <SubTitle2>
            퀴즈 만들기 버튼을 눌러서 퀴즈를 만들어보세요. 만약 선택 가능한
            문제가 없다면 문제 창고로 이동하여 문제를 만들어보세요.
          </SubTitle2>
        )}
      </BottomContentLayer>
      <QuizsetModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        store={modalStore.current}
      />
    </>
  );
});

const SortButton = styled.button`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-size: 12px;
`;
