import { useState, useCallback, useEffect } from 'react';
import {
  Stack,
  Typography,
  Divider,
  Button,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from '@mui/material';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { Modal } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Error as ErrorIcon } from '@mui/icons-material';
import { NoticeClass } from './NoticeClass';
import axios from 'axios';
import { TextEditor } from '@app/components/Input/TextEditor';
import ReactGA from 'react-ga';

export const NoticeCreate = ({
  groupId,
  show,
  setShow,
  getGroupnoticeByGroup,
}) => {
  const { clientId } = useSelector((state) => state.user);

  const [type, setType] = useState(0);
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [groupIds, setGroupIds] = useState([
    {
      groupId,
      key: new Date().getTime(),
      submitAt: new Date(),
      releaseAt: new Date(),
    },
  ]);
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (body && title) setValidation(false);
  }, [body, title]);

  const createGroupnotice = useCallback(async () => {
    if (!title || !body) return setValidation(true);
    try {
      // 공지 등록
      const requests = groupIds.map((groupId) =>
        axios.post('https://ktor.focuspang.com/v2/groupnotice', {
          groupId: groupId.groupId,
          state: 'CREATED',
          noticeType: type === 0 ? 'NOTICE' : 'SUBMISSION',
          title,
          body,
          submitAt: groupId.submitAt,
          releaseAt: groupId.releaseAt,
        })
      );
      await axios.all(requests);

      // 모달 닫기
      setShow(false);
      // 공지 목록 조회
      getGroupnoticeByGroup();
    } catch (error) {
      console.log(error);
    }
  }, [type, title, body, groupIds, clientId]);

  // ------------------------------------
  // notification 생성, 공지 생성 완료 후 실행
  // ------------------------------------
  // const createNotification = useCallback(async () => {
  //   try {
  //     const body = {
  //       state: "CREATED",
  //       notificationType: "COMMAND",
  //       notificationKind: "NOTIFY_NOTICE",
  //       fromClientId: clientId,
  //     };
  //     const requests = students.map((student) => axios.post("https://ktor.focuspang.com/v2/notification", { ...body, toClientId: student.clientId }));
  //     // notification 생성 api 일괄 처리 responses
  //     const responses = await axios.all(requests);
  //     console.log(responses);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [clientId, students]);

  const handleClickAddClass = useCallback(() => {
    setGroupIds((prev) => [
      ...prev,
      {
        groupId: groupId,
        key: new Date().getTime(),
        submitAt: new Date(),
        releaseAt: new Date(),
      },
    ]);
  }, [groupId]);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered size="xl">
      <Stack maxHeight="90vh" overflow="overlay">
        <Stack p={3} spacing={3}>
          <Typography variant="h6">새 공지를 등록합니다.</Typography>
          <Stack direction="row" alignItems="center" spacing={3}>
            <Stack direction="row" spacing={3} alignItems="center" flex={1}>
              {/* <Tooltip
                title="공지하실 공지의 종류를 선택하실 수 있습니다. 공지의 종류에는 일반 공지와 과제 공지가 있으며 선택하신 공지에 맞게 양식이 변경됩니다."
                placement="top"
                arrow
              >
                <FormControl>
                  <InputLabel>공지</InputLabel>

                  <Select
                    size="small"
                    value={type}
                    onChange={(e) => setType(e.target.value)}
                    label="공지"
                  >
                    <MenuItem value={0}>일반 공지</MenuItem>
                    <MenuItem value={1}>과제 공지</MenuItem>
                  </Select>
                </FormControl>
              </Tooltip> */}
              <Stack flex={1}>
                <Tooltip title="공지의 제목을 입력해주세요." arrow>
                  <TextField
                    variant="standard"
                    size="small"
                    fullWidth
                    placeholder="공지 제목 입력"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  />
                </Tooltip>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
        <Divider />
        <Stack p={3}>
          <Typography>공지할 반을 선택해주세요.</Typography>
        </Stack>

        {groupIds.map((groupId) => (
          <NoticeClass
            key={groupId.key}
            type={type}
            groupId={groupId}
            groupIds={groupIds}
            setGroupIds={setGroupIds}
          />
        ))}

        <Stack p={3}>
          <Tooltip
            title="공지할 반을 추가로 등록할 수 있으며 추가할 경우 작성하신 내용이 추가 된 반에 같은 내용으로 공지됩니다."
            arrow
          >
            <Button
              startIcon={<AddCircleIcon />}
              sx={{ width: 'fit-content' }}
              onClick={handleClickAddClass}
              style={{
                borderRadius: '24px',
                backgroundColor: '#0894A0',
                color: '#ffffff',
                height: '28px',
              }}
            >
              공지할 반 추가하기
            </Button>
          </Tooltip>
        </Stack>
        <Stack p={3} spacing={3}>
          <Typography>
            {type === 0
              ? '공지 내용을 입력해주세요.'
              : '과제 내용을 입력해주세요.'}
          </Typography>
          <TextEditor value={body} onChange={setBody} />
        </Stack>
        <Divider />
        <Stack
          direction="row"
          p={1}
          justifyContent="flex-end"
          spacing={1}
          alignItems="center"
        >
          {validation && (
            <Typography
              variant="body2"
              color="#FF1744"
              display="flex"
              alignItems="center"
            >
              <ErrorIcon fontSize="10px" sx={{ mr: 0.5 }} />
              {!title ? '제목을 입력해주세요' : '내용을 입력해주세요'}
            </Typography>
          )}
          <Button
            style={{
              border: '1px solid #e0e0e0',
            }}
            color="_red"
            onClick={() => setShow(false)}
          >
            취소
          </Button>
          <Button
            style={{
              border: '1px solid #e0e0e0',
              marginRight: '16px',
            }}
            onClick={() => {
              createGroupnotice();
              // ReactGA.event({
              //   action: "Create General Notice Click",
              //   category: "Button",
              //   label: "Notice",
              // });
            }}
          >
            등록
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
