import { all, fork } from 'redux-saga/effects';

import userSaga from './user/saga';
import clientSaga from './clients/saga';
import groupSaga from './groups/saga';
import notificationSaga from './notification/saga';
import programSaga from './programs/saga';
import programConfigSaga from './programconfigs/saga';
import classroomSaga from './classrooms/saga';
import managingSaga from './managing/saga';
import schoolSaga from './school/saga';
import timetableSaga from './timetable/saga';
import filelinkSaga from './filelinks/saga';
import kakaocertSaga from './kakaocert/saga';
import accountSaga from './account/saga';
import membershipSaga from './membership/saga';
import deviceSaga from './devices/saga';
import participationSaga from './participation/saga';
import managedSaga from './managed/saga';
import quizSaga from './quiz/saga';
import quizsetSaga from './quizset/saga';
import quizsetSessionSaga from './quizsetSession/saga';
import groupActivitySaga from './groupActivity/saga';
import reportListSaga from './report/saga';
import reportEventsSaga from './reportEvents/saga';
import commentsLayerSaga from './commentsLayer/saga';

export default function* rootSaga() {
  yield all([
    fork(userSaga),
    fork(clientSaga),
    fork(groupSaga),
    fork(notificationSaga),
    fork(programSaga),
    fork(programConfigSaga),
    fork(classroomSaga),
    fork(managingSaga),
    fork(filelinkSaga),
    fork(schoolSaga),
    fork(timetableSaga),
    fork(kakaocertSaga),
    fork(accountSaga),
    fork(membershipSaga),
    fork(deviceSaga),
    fork(participationSaga),
    fork(managedSaga),
    fork(quizSaga),
    fork(quizsetSaga),
    fork(quizsetSessionSaga),
    fork(groupActivitySaga),
    fork(reportListSaga),
    fork(reportEventsSaga),
    fork(commentsLayerSaga),
  ]);
}
