import React, { memo, useEffect, useMemo, useState } from 'react';
import DefaultProfile from 'assets/images/icons/default-profile.png';
import styled from 'styled-components';

const Wrapper = styled.div`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
  background: #ffffff;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.04), 0px 2px 6px rgba(0, 0, 0, 0.06),
    0px 0px 1px rgba(0, 0, 0, 0.08);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.size * 0.04}px;
`;

const Image = styled.img`
  border-radius: 50%;
  width: 100%;
  height: 100%;
`;

const ProfileImage = ({ size, url, subscribeImageChange = 0 }) => {
  const [isError, setIsError] = useState(true);
  const [loaded, setLoaded] = useState(true);

  const memoizedSubscribeImageChange = useMemo(() => subscribeImageChange, [
    subscribeImageChange,
  ]);

  useEffect(() => {
    if (!url) {
      return;
    }

    setLoaded(false);
    setIsError(false);

    const img = new window.Image();
    img.onload = () => {
      setLoaded(true);
      setIsError(false);
    };
    img.onerror = () => {
      setLoaded(false);
      setIsError(true);
    };
    img.src = url;
  }, [url, memoizedSubscribeImageChange]);

  return (
    <Wrapper size={size}>
      {(!url || isError) && <Image src={DefaultProfile} />}
      {loaded && !isError && url && <Image src={url} />}
    </Wrapper>
  );
};

export default memo(ProfileImage);
