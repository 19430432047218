import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Stack,
  Typography,
  Divider,
  Select,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  FormControl,
  InputLabel,
  Tooltip,
} from '@mui/material';
import { DoDisturbOnOutlined as DoDisturbOnOutlinedIcon } from '@mui/icons-material';
import { selectAllGroups } from '@store/selectors';
import 'react-datepicker/dist/react-datepicker.css';
import { DateTimePicker } from '@app/components/Input/DateTimePicker';

export const NoticeClass = ({ type, groupId, groupIds, setGroupIds }) => {
  const groups = useSelector((state) => selectAllGroups(state));
  const sortGroups = useMemo(
    () => 
      groups.sort((a, b) => {
        if(a.grade === b.grade) {
          return a.groupName - b.groupName;
        }
        return a.grade - b.grade;
      }),
    [groups]
  );

  const { groupId: originGroupId, key, submitAt, releaseAt } = { ...groupId };

  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (checked)
      setGroupIds((prev) => {
        const array = [...prev];
        const index = array.findIndex((v) => v.key === key);
        array[index].releaseAt = new Date();
        return array;
      });
  }, [checked]);

  const handleChangeGroup = useCallback((e) => {
    setGroupIds((prev) => {
      const array = [...prev];
      const index = array.findIndex((v) => v.key === key);
      array[index].groupId = e.target.value;
      return array;
    });
  }, []);

  const handleClickDelete = useCallback(() => {
    if (groupIds?.length <= 1) return;
    setGroupIds((prev) => [...prev].filter((v) => v.key !== key));
  }, [groupIds]);

  const handleChangeReleaseAt = useCallback((date) => {
    setGroupIds((prev) => {
      const array = [...prev];
      const index = array.findIndex((v) => v.key === key);
      array[index].releaseAt = date;
      return array;
    });
  }, []);

  const handleChangeSubmitAt = useCallback((date) => {
    setGroupIds((prev) => {
      const array = [...prev];
      const index = array.findIndex((v) => v.key === key);
      array[index].submitAt = date;
      return array;
    });
  }, []);

  return (
    <>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={3}
      >
        <Stack flex={1}>
          <Stack direction="row" spacing={3}>
            <Stack>
              <Typography>공지할 반 선택</Typography>
              <Stack height={80} justifyContent="center">
                <FormControl>
                  <InputLabel>반</InputLabel>
                  <Tooltip
                    title="공지할 반을 선택이 가능하며 등록 시 선택하신 반에 공지가 나가게 됩니다."
                    placement="top"
                    arrow
                  >
                    <Select
                      size="small"
                      label="반"
                      value={originGroupId}
                      onChange={(e) => handleChangeGroup(e)}
                    >
                      {sortGroups.map((group) => {
                        const {
                          groupId,
                          groupName,
                          grade,
                          subjectName,
                        } = group;
                        return (
                          <MenuItem
                            key={groupId}
                            value={groupId}
                          >{`${grade}학년 ${groupName}반 ${subjectName}`}</MenuItem>
                        );
                      })}
                    </Select>
                  </Tooltip>
                </FormControl>
              </Stack>
            </Stack>

            {type === 1 && (
              <Stack>
                <Tooltip
                  title="과제 제출 기한을 설정하여 설정 기간 내 과제가 제출이 되는지 확인이 가능합니다."
                  arrow
                >
                  <Typography>과제 제출 기한</Typography>
                </Tooltip>
                <Stack height={80} justifyContent="center">
                  <Stack border="1px solid #bbb" borderRadius="4px" p={1}>
                    <DateTimePicker
                      value={submitAt}
                      onChange={handleChangeSubmitAt}
                    />
                  </Stack>
                </Stack>
              </Stack>
            )}

            <Stack>
              <Typography>
                {type === 0 ? '공지 공개 일시' : '과제 공개 일시'}
              </Typography>
              <Stack height={80} direction="row" alignItems="center">
                <Tooltip
                  title="'등록 시 바로 공개'를 선택 시 등록 직후 바로 공지가 공개되며 오른쪽의 공지 공개 일시를 설정이 사라집니다."
                  placement="top"
                  arrow
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={checked}
                        onChange={(e) => setChecked(e.target.checked)}
                      />
                    }
                    label="등록 시 바로 공개"
                  />
                </Tooltip>
                {!checked && (
                  <Tooltip
                    title="클릭하시게 되면 공지 공개 일시를 설정하실 수 있습니다. 설정을 하실 경우 설정된 일시에 공지가 학생들에게 공개됩니다."
                    placement="top"
                    arrow
                  >
                    <Stack border="1px solid #bbb" borderRadius="4px" p={1}>
                      <DateTimePicker
                        value={releaseAt}
                        onChange={handleChangeReleaseAt}
                      />
                    </Stack>
                  </Tooltip>
                )}
              </Stack>
            </Stack>
          </Stack>
        </Stack>

        <IconButton
          onClick={handleClickDelete}
          disabled={groupIds?.length <= 1}
          sx={{ color: '#FF1744' }}
        >
          <DoDisturbOnOutlinedIcon />
        </IconButton>
      </Stack>
      <Divider />
    </>
  );
};
