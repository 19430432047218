import { makeFreshAllIds } from '../helpers';
import {
  PURGE_PARTICIPATION,
  GET_PARTICIPATION_SUCCESS,
  GET_PARTICIPATION_FAIL,
  GET_PARTICIPATIONS_BY_CLIENT_SUCCESS,
  GET_PARTICIPATIONS_BY_CLIENT_FAIL,
  GET_PARTICIPATIONS_BY_CLASSROOM_SUCCESS,
  GET_PARTICIPATIONS_BY_CLASSROOM_FAIL,
  CREATE_PARTICIPATION_SUCCESS,
  CREATE_PARTICIPATION_FAIL,
  UPDATE_PARTICIPATION_SUCCESS,
  UPDATE_PARTICIPATION_FAIL,
  DELETE_PARTICIPATION_SUCCESS,
  DELETE_PARTICIPATION_FAIL,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const participations = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_PARTICIPATION:
      return initialState;

    case GET_PARTICIPATION_SUCCESS:
    case CREATE_PARTICIPATION_SUCCESS:
    case UPDATE_PARTICIPATION_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.participation.clientId]: action.participation,
        },
        allIds: makeFreshAllIds(state.allIds, [action.participation.clientId]),
      };
    case DELETE_PARTICIPATION_SUCCESS:
      return state;

    case GET_PARTICIPATIONS_BY_CLIENT_SUCCESS:
    case GET_PARTICIPATIONS_BY_CLASSROOM_SUCCESS:
      return {
        // ...state,
        // byId: { ...state.byId, ...action.byId },
        // allIds: makeFreshAllIds(state.allIds, action.allIds),
        byId: action.byId,
        allIds: action.allIds,
      };

    case GET_PARTICIPATION_FAIL:
    case GET_PARTICIPATIONS_BY_CLIENT_FAIL:
    case GET_PARTICIPATIONS_BY_CLASSROOM_FAIL:
    case CREATE_PARTICIPATION_FAIL:
    case UPDATE_PARTICIPATION_FAIL:
    case DELETE_PARTICIPATION_FAIL:
      console.error('[Participation Reducer] ', action.payload);
      return state;

    default:
      break;
  }
  return state;
};

export default participations;
