import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '@components/Layout/Sidebar';
import ClassManagementSmall from '@components/Layout/ClassManagementSmall';
import FileLink from '@components/Layout/FileLink';
import {
  selectActiveClassroom,
  selectStudentsByGroup,
  selectGroupById,
  selectGroupTimetables,
} from '@store/selectors';
import styles from './index.module.scss';
import StudentScreenList from '@components/Layout/StudentScreenList';
import { useParams } from 'react-router-dom';
import BaseCard from '@components/Card/baseCard';
import classNames from 'classnames';
import ModalSharedScreenFull from '@components/Modal/ModalSharedScreenFull';
import TwoPeopleImg from '@assets/images/icons/two-people.png';
import { Rnd } from 'react-rnd';
import {
  updateClassroomPromise,
  setScreenData,
  setSocketData,
} from '@store/actions';
import {
  selectAllPrograms,
  selectGroupFilelinks,
  selectGroupProgramconfigs,
  selectParticipationsByClassroom,
} from '@app/store/selectors';
import ScreenButtons from '@app/components/ClassRoom/ScreenButtons';
import {
  createInviteCodePromise,
  getMembershipsByGroupPromise,
  setSelectedStudent,
  setSelectedGroup,
  setSelectedPage,
} from '@app/store/actions';
import ModalAddStudent from '@app/components/Modal/ModalAddStudent';
import {
  createReportEventsPromise,
  setReportEventPromise,
} from '@app/store/reportEvents/actions';
import { useMemo } from 'react';
import {
  CLICK_SCREEN_SHARE_OFF_DATA,
  CLICK_SCREEN_SHARE_ON_DATA,
  RESPONSE_SCREEN_SHARE_OFF_DATA,
  RESPONSE_SCREEN_SHARE_ON_DATA,
  SHARING_SCREEN_DATA,
} from '@app/Constants/eventHistoryData';
import {
  CLICK_SCREEN_SHARE_OFF,
  CLICK_SCREEN_SHARE_ON,
  ERROR_SCREEN_SHARE_OFF,
  ERROR_SCREEN_SHARE_ON,
  RESPONSE_SCREEN_SHARE_OFF,
  RESPONSE_SCREEN_SHARE_ON,
  SHARING_SCREEN,
} from '@app/Constants/eventHistoryType';
import { STUDENT, TEACHER } from '@app/Constants/eventHistoryRole';
import { CommentsLayer } from '@app/components/CommentsLayer/CommentsLayer';

const Classroom = React.memo((props) => {
  const [gridState, setGridState] = useState('TWO_COLUMN');
  const [screenOverflow, setScreenOverflow] = useState(false);
  const screenRef = useRef(null);
  const { groupId } = useParams();
  const user = useSelector((state) => state.user);
  const group = useSelector((state) => selectGroupById(state, groupId));
  const commentsLayerState = useSelector(
    (state) => state.commentsLayer.commentsLayerState
  );
  const dispatch = useDispatch();
  const groupTimetables = useSelector((state) =>
    selectGroupTimetables(state, groupId)
  );
  const timetable = groupTimetables[0];
  const programconfigs = useSelector((state) =>
    selectGroupProgramconfigs(state, group?.groupId)
  );

  const programs = useSelector((state) => selectAllPrograms(state));
  const groupFileLinks = useSelector((state) =>
    selectGroupFilelinks(state, group?.groupId)
  );

  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const students = useSelector((state) =>
    selectStudentsByGroup(state, group?.groupId)
  );

  const stdUpdateRequest = useSelector(
    (state) => state.control.stdUpdateRequest
  );

  const _participations = useSelector((state) =>
    selectParticipationsByClassroom(state, activeClassroom?.classroomId)
  );

  const filteredParticipations = useMemo(() => {
    //조건식 설명: 상태가 "ABSENT"이고, 선생님 본인은 제외 >> 그러면 상태가 "ATTEND"이고 학생인 계정만 필터됨
    return (
      _participations?.filter(
        (participation) =>
          participation.state !== 'ABSENT' &&
          participation.clientId !== user.clientId
      ) || []
    );
  }, [_participations, user.clientId, stdUpdateRequest]);

  const [inviteCodeModal, setInviteCodeModal] = useState(false);
  const [inviteCode, setInviteCode] = useState('');
  useEffect(() => {
    dispatch(createInviteCodePromise(group?.groupId)).then((invCode) => {
      setInviteCode(invCode);
    });
  }, []);

  const [studentGrade, setStudentGrade] = useState();
  const [studentClass, setStudentClass] = useState();

  useEffect(() => {
    setStudentGrade(group?.grade);
    setStudentClass(group?.class_name);
  }, []);

  const [onceClicked, setOnceClicked] = useState(false);
  const [screenlockedOnceClicked, setScreenlockedOnceClicked] = useState(false);
  const [screenShareOn, setScreenShareOn] = useState(false);
  const [imageTeacher, setImageTeacher] = useState({});
  const stdImages = useSelector((state) => state.control.images);
  const [selectedStd, setSelectedStd] = useState();
  const [bigURI, setBigURI] = useState('');
  const [modalShow, setModalShow] = useState(false);

  useEffect(() => {
    dispatch(setSelectedGroup(group.groupId));
    dispatch(setSelectedPage('CLASSROOM'));
  }, [group.groupId]);

  useEffect(() => {
    if (!activeClassroom) {
      setScreenShareOn(false);
    }
  }, [activeClassroom]);

  const sendUpdatedClassroomBySocket = useCallback(() => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: 'CLASS_UPDATE',
        data: '',
      })
    );
  }, [dispatch, group.groupId, user.clientId]);

  const sendScreenShareOnBySocket = useCallback(() => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: 'SCREEN_SHARE_ON',
        data: '',
      })
    );
  }, [dispatch, group.groupId, user.clientId]);

  const sendScreenShareOffBySocket = useCallback(() => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: 'SCREEN_SHARE_OFF',
        data: '',
      })
    );
  }, [dispatch, group.groupId, user.clientId]);

  const sendFullScreenChromeExtensionSocket = () => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: group?.groupId,
        clientId: user?.clientId,
        type: 'FULL_SCREEN',
        data: '',
      })
    );
  };

  const clickLockScreen = () => {
    if (!activeClassroom) {
      return;
    }
    if (screenlockedOnceClicked) {
      return;
    }
    setScreenlockedOnceClicked(true);
    setTimeout(() => {
      setScreenlockedOnceClicked(false);
    }, 1000);
    const newScreenLocked = !activeClassroom.screenLocked;

    dispatch(
      updateClassroomPromise({
        classroomId: activeClassroom.classroomId,
        screenLocked: newScreenLocked,
      })
    )
      .then(() => {
        if (!newScreenLocked) {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: groupId,
              clientId: user?.clientId,
              type: 'UNLOCK_SCREEN',
              data: '',
            })
          );
        }
        if (newScreenLocked) {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: groupId,
              clientId: user?.clientId,
              type: 'LOCK_SCREEN',
              data: '',
            })
          );
        }
        sendUpdatedClassroomBySocket();
      })
      .catch((error) => console.error(error));
  };

  const capture = (video, scaleFactor = 0.5) => {
    var w = 1600;
    var h = 900;
    var canvas = document.createElement('canvas');
    canvas.id = 'canvas';
    canvas.width = w;
    canvas.height = h;
    var ctx = canvas.getContext('2d');
    ctx.drawImage(video, 0, 0, w, h);

    return canvas;
  };

  // const capture = (video, scaleFactor = 0.5) => {
  //   var w = video.videoWidth;
  //   var h = video.videoHeight;
  //   var canvas = document.createElement('canvas');
  //   canvas.id = 'canvas';
  //   canvas.width = w;
  //   canvas.height = h;
  //   var ctx = canvas.getContext('2d');
  //   ctx.drawImage(video, 0, 0, w, h);

  //   return canvas;
  // };

  // const capture = (video) => {
  //   var w = video.videoWidth;
  //   var h = video.videoHeight;
  //   var canvas = document.createElement('canvas');
  //   canvas.id = 'canvas';

  //   // Calculate scaleFactor to maintain aspect ratio
  //   var shorterSide = Math.min(w, h);
  //   var base = shorterSide / 2; // Change this to modify scaling
  //   var scaleFactor = 1 / (shorterSide / base);

  //   canvas.width = w * scaleFactor;
  //   canvas.height = h * scaleFactor;
  //   var ctx = canvas.getContext('2d');
  //   ctx.drawImage(video, 0, 0, w * scaleFactor, h * scaleFactor);

  //   return canvas;
  // };

  const [screenCaptureIntervalId, setScreenCaptureIntervalId] = useState(0);

  const stopCapture = (videoElem, emit = false) => {
    try {
      if (videoElem.srcObject === null) return;

      let tracks = videoElem.srcObject.getTracks();

      tracks.forEach((track) => track.stop());
      videoElem.srcObject = null;
    } catch (err) {
      console.error('Error when stop capturing screen : ', err);
    }

    clearInterval(screenCaptureIntervalId);
    setScreenCaptureIntervalId(0);
  };

  const streamScreen = async () => {
    const displayMediaOptions = {
      video: {
        cursor: 'always',
      },
      audio: false,
    };
    var display;
    try {
      display = await navigator.mediaDevices.getDisplayMedia(
        displayMediaOptions
      );
    } catch (error) {
      return;
    }
    const videoElem = document.getElementById('video');
    videoElem.srcObject = display;
    videoElem.srcObject.getVideoTracks()[0].addEventListener('ended', () => {
      var timer = null;
      stopCapture(videoElem);
      clearInterval(timer);
    });
    setScreenCaptureIntervalId(
      setInterval(async () => {
        const track = display.getVideoTracks()[0];
        const canvas = capture(videoElem);
        const img = canvas
          .toDataURL('image/webp', 0.5)
          .replace('data:image/webp;base64,', '');

        if (img.length <= 22) {
          setImageTeacher({ key: user.clientId, value: null });
        }
        if (img.length >= 22) {
          setImageTeacher({ key: user.clientId, value: img });
        }
      }, 1000)
    );
  };

  useEffect(() => {
    if (imageTeacher.key === selectedStd) {
      setBigURI(imageTeacher.value);
    }
  }, [imageTeacher, selectedStd]);

  useEffect(() => {
    if (imageTeacher.key !== selectedStd) setBigURI(stdImages[selectedStd]);
  }, [stdImages]);

  useEffect(() => {
    if (bigURI && activeClassroom?.screenShared) {
      dispatch(
        setScreenData({
          method: 'POST',
          uri: '/classroom/sendImage',
          type: 'IMAGE_TEACHER',
          clientId: user.clientId,
          groupId: activeClassroom?.groupId,
          data: bigURI,
        })
      );
      if (filteredParticipations.length > 0) {
        const reportEvents = [];
        filteredParticipations.forEach((filteredParticipation) => {
          reportEvents.push({
            groupId: activeClassroom?.groupId,
            sender: user.clientId,
            receiver: filteredParticipation?.clientId,
            senderRole: TEACHER,
            receiverRole: STUDENT,
            eventType: SHARING_SCREEN,
            data: SHARING_SCREEN_DATA,
            reportedAt: new Date(),
          });
        });
        dispatch(setReportEventPromise(reportEvents));
      }
    }
  }, [activeClassroom?.screenShared, bigURI]);

  useEffect(() => {
    if (activeClassroom?.classroomId) {
      if (onceClicked) streamScreen();
    } else {
      const videoElem = document.getElementById('video');
      stopCapture(videoElem);
      setOnceClicked((prevState) => false);
      setSelectedStd((prevState) => user.clientId);
      setBigURI(null);
      setImageTeacher({});
    }
  }, [activeClassroom?.classroomId, onceClicked]);

  const clickScreenShare = useCallback(() => {
    if (!activeClassroom?.screenShared) {
      if (filteredParticipations.length > 0) {
        const reportEvents = [];
        filteredParticipations.forEach((filteredParticipation) => {
          reportEvents.push({
            groupId: activeClassroom?.groupId,
            sender: user.clientId,
            receiver: filteredParticipation?.clientId,
            senderRole: TEACHER,
            receiverRole: STUDENT,
            eventType: CLICK_SCREEN_SHARE_ON,
            data: CLICK_SCREEN_SHARE_ON_DATA,
            reportedAt: new Date(),
          });
        });
        dispatch(createReportEventsPromise(reportEvents));
      }
      dispatch(
        updateClassroomPromise({
          classroomId: activeClassroom?.classroomId,
          screenShared: true,
          sharedClientId: selectedStd,
        })
      )
        .then((res) => {
          console.log('RESPONSE_SCREEN_SHARE_ON : ', res);
          setTimeout(() => {
            setScreenShareOn(true);
          }, 500);
          sendFullScreenChromeExtensionSocket();
          // sendUpdatedClassroomBySocket();
          sendScreenShareOnBySocket();
          if (filteredParticipations.length > 0) {
            const reportEvents = [];
            filteredParticipations.forEach((filteredParticipation) => {
              reportEvents.push({
                groupId: activeClassroom?.groupId,
                sender: user.clientId,
                receiver: filteredParticipation?.clientId,
                senderRole: TEACHER,
                receiverRole: STUDENT,
                eventType: RESPONSE_SCREEN_SHARE_ON,
                data: RESPONSE_SCREEN_SHARE_ON_DATA,
                reportedAt: new Date(),
              });
            });
            dispatch(createReportEventsPromise(reportEvents));
          }
        })
        .catch((error) => {
          if (filteredParticipations.length > 0) {
            const reportEvents = [];
            filteredParticipations.forEach((filteredParticipation) => {
              reportEvents.push({
                groupId: activeClassroom?.groupId,
                sender: user.clientId,
                receiver: filteredParticipation.clientId,
                senderRole: TEACHER,
                receiverRole: STUDENT,
                eventType: ERROR_SCREEN_SHARE_ON,
                data: error,
                reportedAt: new Date(),
              });
            });
            dispatch(createReportEventsPromise(reportEvents));
          }

          console.error(error);
        });
      // .catch((error) => alert(error));
    } else {
      if (filteredParticipations.length > 0) {
        const reportEvents = [];
        filteredParticipations.forEach((filteredParticipation) => {
          reportEvents.push({
            groupId: activeClassroom?.groupId,
            sender: user.clientId,
            receiver: filteredParticipation.clientId,
            senderRole: TEACHER,
            receiverRole: STUDENT,
            eventType: CLICK_SCREEN_SHARE_OFF,
            data: CLICK_SCREEN_SHARE_OFF_DATA,
            reportedAt: new Date(),
          });
        });
        dispatch(createReportEventsPromise(reportEvents));
      }
      dispatch(
        updateClassroomPromise({
          classroomId: activeClassroom?.classroomId,
          screenShared: false,
          sharedClientId: user.clientId,
        })
      )
        .then((res) => {
          console.log('RESPONSE_SCREEN_SHARE_OFF : ', res);
          // sendUpdatedClassroomBySocket();
          setTimeout(() => {
            setScreenShareOn(false);
          }, 500);
          sendScreenShareOffBySocket();
          if (filteredParticipations.length > 0) {
            const reportEvents = [];
            filteredParticipations.forEach((filteredParticipation) => {
              console.log('filteredParticipation!', filteredParticipation);
              reportEvents.push({
                groupId: activeClassroom?.groupId,
                sender: user.clientId,
                receiver: filteredParticipation.clientId,
                senderRole: TEACHER,
                receiverRole: STUDENT,
                eventType: RESPONSE_SCREEN_SHARE_OFF,
                data: RESPONSE_SCREEN_SHARE_OFF_DATA,
                reportedAt: new Date(),
              });
            });
            dispatch(createReportEventsPromise(reportEvents));
          }
        })
        .catch((error) => {
          if (filteredParticipations.length > 0) {
            const reportEvents = [];
            filteredParticipations.forEach((filteredParticipation) => {
              reportEvents.push({
                groupId: activeClassroom?.groupId,
                sender: user.clientId,
                receiver: filteredParticipation.clientId,
                senderRole: TEACHER,
                receiverRole: STUDENT,
                eventType: ERROR_SCREEN_SHARE_OFF,
                data: error,
                reportedAt: new Date(),
              });
            });
            dispatch(createReportEventsPromise(reportEvents));
          }
          console.error(error);
        });
    }
  }, [
    activeClassroom,
    dispatch,
    selectedStd,
    sendUpdatedClassroomBySocket,
    user.clientId,
  ]);

  const currentStudent = students.find((x) => x.clientId === selectedStd);

  useEffect(() => {
    dispatch(setSelectedStudent(selectedStd));
  }, [dispatch, selectedStd]);
  useEffect(() => {
    dispatch(getMembershipsByGroupPromise(groupId));
  }, []);

  if (!timetable) {
    return null;
  }

  return (
    <>
      <div className={styles['full']}>
        <div className={styles['main-container']}>
          <Sidebar inClassroom />
          <div
            onScroll={(e) => {
              const { y, height } = screenRef.current?.getBoundingClientRect();
              const overflow = y + height < 0;
              if (overflow !== screenOverflow) {
                setScreenOverflow(overflow);
              }
            }}
            className={
              gridState === 'TWO_COLUMN' || !activeClassroom
                ? styles['wrapper-grid']
                : styles['wrapper']
            }
            style={{
              marginLeft: '280px',
              width: '100%',
              padding: 14,
              backgroundColor: '#f9f9f9',
            }}
          >
            <BaseCard
              radius={8}
              style={gridState === 'TWO_COLUMN' ? {} : { marginBottom: 8 }}
            >
              <div className={styles['container']}>
                <div
                  className={styles['header']}
                  style={{
                    marginBottom: gridState === 'ONE_COLUMN' ? 0 : 18,
                  }}
                >
                  <div className={styles['header-left']}>
                    <div className={styles['header-class-name']}>
                      {group.grade}학년 {group.groupName}반
                    </div>
                    <div className={styles['header-subject']}>
                      {group.subjectName}
                    </div>
                    <div className={styles['header-class-students']}>
                      <img
                        className={styles['header-class-students-img']}
                        src={TwoPeopleImg}
                      />
                      총원 {students?.length}
                    </div>
                  </div>
                </div>
                <div
                  ref={screenRef}
                  className={classNames(
                    styles['big-screen'],
                    activeClassroom?.screenShared && styles['screen-glow']
                  )}
                >
                  {activeClassroom ? (
                    !!bigURI ? (
                      <img
                        className={styles['big-img']}
                        src={`data:image/webp;base64,${bigURI}`}
                        alt="screenshot"
                      />
                    ) : commentsLayerState ? null : (
                      <span className={styles['big-img']}>
                        교사 컴퓨터 아이콘이나 학생 썸네일을 클릭하면 여기서
                        크게 볼 수 있으며
                        <br /> 학생 전체에게 공유할 수 있습니다.
                      </span>
                    )
                  ) : (
                    <span className={styles['big-img']}>
                      수업 시작하기 버튼을 눌러 수업을 시작해주세요
                    </span>
                  )}
                  {activeClassroom && (
                    <>
                      <ScreenButtons
                        isStdLocked={activeClassroom?.screenLocked}
                        currentStudent={currentStudent}
                        clickLockScreen={clickLockScreen}
                        clickScreenShare={clickScreenShare}
                        isScreenShared={activeClassroom?.screenShared}
                        showModal={() => setModalShow(true)}
                      />
                      <CommentsLayer
                        screenImg={bigURI}
                        isVisible={!modalShow}
                      />
                    </>
                  )}
                </div>
                <ModalSharedScreenFull
                  show={modalShow}
                  screenImg={bigURI}
                  currentStudent={currentStudent}
                  clickLockScreen={clickLockScreen}
                  selectedStd={selectedStd}
                  sendUpdatedClassroomBySocket={sendUpdatedClassroomBySocket}
                  setModalShow={setModalShow}
                />
                <video
                  autoPlay
                  id="video"
                  className="hidden w-2/3 mt-5 mb-10 border-2"
                  // controls="false"
                  style={{
                    display: 'none',
                  }}
                />
              </div>
            </BaseCard>
            {screenOverflow && activeClassroom && (
              <Rnd
                default={
                  {
                    // x: -20,
                    // y: 300,
                    // width: 320,
                    // height: 180,
                  }
                }
                size={{
                  width: 640,
                  height: 360,
                }}
                style={{ zIndex: 10000000 }}
              >
                <div
                  className={classNames(
                    styles['big-screen'],
                    activeClassroom?.screenShared && styles['screen-glow']
                  )}
                  style={{
                    width: 640,
                    height: 360,
                  }}
                >
                  {activeClassroom ? (
                    !!bigURI ? (
                      <img
                        className={styles['big-img']}
                        src={`data:image/webp;base64,${bigURI}`}
                        alt="screenshot"
                      />
                    ) : commentsLayerState ? null : (
                      <span className={styles['big-img']}>
                        교사 컴퓨터 아이콘이나 학생 썸네일을 클릭하면 여기서
                        크게 볼 수 있으며
                        <br /> 학생 전체에게 공유할 수 있습니다.
                      </span>
                    )
                  ) : (
                    <span className={styles['big-img']}>
                      수업 시작하기 버튼을 눌러 수업을 시작해주세요
                    </span>
                  )}
                  {activeClassroom && (
                    <ScreenButtons
                      isStdLocked={activeClassroom?.screenLocked}
                      currentStudent={currentStudent}
                      clickLockScreen={clickLockScreen}
                      clickScreenShare={clickScreenShare}
                      isScreenShared={screenShareOn}
                      showModal={() => setModalShow(true)}
                    />
                  )}
                </div>
              </Rnd>
            )}
            <div>
              <ClassManagementSmall
                group={group}
                programconfigs={programconfigs}
                programs={programs}
              />
              <FileLink groupFileLinks={groupFileLinks} group={group} />
            </div>
          </div>
        </div>

        {activeClassroom && (
          <>
            {gridState === 'TWO_COLUMN' ? (
              <div className={styles['student-container']}>
                <StudentScreenList
                  group={group}
                  students={students}
                  images={stdImages}
                  activeClassroom={activeClassroom}
                  studentClass={studentClass}
                  onceClicked={onceClicked}
                  stopCapture={stopCapture}
                  isScreenShared={activeClassroom?.screenShared}
                  streamScreen={streamScreen}
                  setOnceClicked={setOnceClicked}
                  setSelectedStd={setSelectedStd}
                  selectedStd={selectedStd}
                  setBigURI={setBigURI}
                  studentGrade={studentGrade}
                  gridState={gridState}
                  setGridState={setGridState}
                />
              </div>
            ) : (
              <div className={styles['student-container-oneColumn']}>
                <StudentScreenList
                  group={group}
                  students={students}
                  images={stdImages}
                  activeClassroom={activeClassroom}
                  studentClass={studentClass}
                  onceClicked={onceClicked}
                  stopCapture={stopCapture}
                  isScreenShared={activeClassroom?.screenShared}
                  streamScreen={streamScreen}
                  setOnceClicked={setOnceClicked}
                  setSelectedStd={setSelectedStd}
                  selectedStd={selectedStd}
                  setBigURI={setBigURI}
                  studentGrade={studentGrade}
                  gridState={gridState}
                  setGridState={setGridState}
                />
              </div>
            )}
          </>
        )}
        <ModalAddStudent
          show={inviteCodeModal}
          onHide={() => setInviteCodeModal(false)}
          groupId={group.groupId}
          setModalShow={setInviteCodeModal}
        />
      </div>
    </>
  );
});

export default Classroom;
