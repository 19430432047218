import BaseInput from "./BaseInput";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";

const Layout = styled.div`
  position: relative;
`;

const SearchIconLayer = styled.div`
  position: absolute;
  top: calc(50% - 12px);
  left: 10px;
  svg {
    fill: #757575;
    width: 20px;
    height: 20px;
  }
`;
const StyledBaseInput = styled(BaseInput)`
  padding-left: 40px;
`;

const SearchInput = (props) => {
  return (
    <Layout style={props.style}>
      <StyledBaseInput {...props} />
      <SearchIconLayer>
        <SearchIcon />
      </SearchIconLayer>
    </Layout>
  );
};

export default SearchInput;
