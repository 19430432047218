import * as React from 'react'
import InputAdornment from '@mui/material/InputAdornment'
import SearchIcon from '@mui/icons-material/Search'
import _TextField from '@mui/material/TextField'
import styled from 'styled-components'

export const SearchInput = ({ value, onChange, label, width }) => {
  return <TextField
    style={{width: width ? width : 'auto'}}
    value={value}
    onChange={onChange}
    InputProps={{
      startAdornment: (
        <InputAdornment position="start">
          <SearchIcon sx={{ color: '#757575' }}/>
        </InputAdornment>
      ),
      placeholder: label,
    }}
  />
}

const TextField = styled(_TextField)`
  background-color: #fff;

  .MuiInputBase-root {
    padding: 0 12px;
  }

  input {
    font-family: 'Noto Sans KR', 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.6);
    padding: 10px 0;
    height: unset;
  }

  fieldset {
    top: 0;
    border-color: #e0e0e0;

    legend {
      display: none;
    }
  }
`
