import React from "react";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Sidebar from "@components/Layout/Sidebar";
import styles from "./index.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { updateClassroom } from "@store/actions";
import { capitalize } from "@material-ui/core";

const { useRef, useEffect } = React;
const TestButton = (props) => {
  const [approve, setApprove] = useState(true);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const dispatch = useDispatch();
  var class_enable = true;
  const class_id = 1120;
  const moveForms = () => {};
  const [strMsg, setMsg] = useState("Hello");

  // const bindEvent = async () => {
  //   if (typeof window.CefSharp !== "undefined") {
  //     console.log("is defined");
  //     await window.CefSharp.BindObjectAsync("cAPI");
  //   }
  // };

  // const useEffectHandler = () => {
  //   bindEvent();
  //   console.log("useEffectHanlder");
  // };

  // useEffect(useEffectHandler, [window]);

  //const ref = useRef();
  //const bind = async () => await CefSharp.BindObjectAsync("cAPI");

  const updateClassroom1 = async () => {
    //await bind();

    // console.log("cAPI =", cAPI);
    window.initial_data = "hello_react";
    console.log("window = ", window.initial_data);
    // console.log("Toggle watchdog allowed");
    // //const program = findProgram(pressedWatchdog.program);
    // var newClassroomState;
    // //console.log('enabled :' ,enabled);
    // if (class_enable) {
    //   newClassroomState = false;
    //   class_enable = false;
    //   //enableWatchdog(program.android, program.url);
    // } else {
    //   newClassroomState = true;
    //   class_enable = true;
    //   //disableWatchdog(program.android, program.url);
    // }
    // const newClassroomInfo = {
    //   id: class_id,
    //   screen_locked: newClassroomState,
    //   screen_shared: newClassroomState,
    // };
    // //class_enable = !class_enable;
    // console.log("class_state :", newClassroomState);
    // dispatch(updateClassroom(newClassroomInfo));
    // this.cAPI.showMsg("hello");
  };

  const approved = () => {
    history.push("/auth");
  };
  return (
    <div>
      <div></div>
      <br />
      <button onClick={updateClassroom1} className={styles["move-home-button"]}>
        test button
      </button>
      <br />

      {/* <button onClick={approved} name="confirm"></button><br /> */}
    </div>
  );
};

export default TestButton;
