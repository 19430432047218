import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './ManagedStudentRow.module.scss';
import { useSelector } from 'react-redux';
import ModalStudentUpdate from '@components/Modal/ModalStudentUpdate';
import ModalConfirm from '@components/Modal/ModalConfirm';
import ProfileImage from '../ProfileImage';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import LateIcon from '@assets/images/icons/late.png';
import Pencil from '@assets/images/icons/pencil.png';
import { profileImgPath } from '@api';
import { selectActiveManaging } from '@app/store/selectors';
import { Tooltip } from '@mui/material';
import StudentName from './StudentList/StudentName';

const ManagedStudentRow = ({
  studentNum,
  studentName,
  groupId,
  student,
  attendTime,
  absent,
  late,
  exposeEdit,
  ...rest
}) => {
  // const [student, setStudents] = useState([]);
  const user = useSelector((state) => state.user);
  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const [updateModal, setUpdateModal] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [studentUsername, setStudentUsername] = useState(studentName);
  const [studentId, setStudentId] = useState(student.id);

  useEffect(() => {
    setStudentUsername(studentName);
    setStudentId(student.id);
  }, [student]);

  return (
    <div
      className={styles['div-std-list']}
      style={absent ? { backgroundColor: '#FAFAFA' } : {}}
    >
      <div className={styles['left']}>
        <div className={styles['name-box']}>
          <div className={styles['std-number']}>
            {Number(student.studentNumber)}
          </div>
          <div className={styles['profile-box']}>
            <div className={styles['std-profile-wrapper']}>
              <ProfileImage size={28} url={profileImgPath(student.clientId)} />
            </div>
            <StudentName studentName={student.userName} />
          </div>
        </div>
        <div
          className={styles['time-box']}
          style={absent ? { display: 'flex', alignItems: 'center' } : {}}
        >
          <div className={styles['time-box-text']}>
            {attendTime ? (
              moment(attendTime).format('HH시 mm분')
            ) : late ? (
              moment(attendTime).format('HH시 mm분') + ' 지각'
            ) : (
              <div></div>
            )}
          </div>
        </div>

        {/* absent 안들어온애는 불참(비로그인), attendTime 들어온애는 그냥 들어온 시간, late 지각한 애는 지각+들어온시간 */}

        {/* <div className={styles["std-time"]}>{attendTime !== undefined ? attendTime?.getHours() +":"+ attendTime?.getMinutes() : ""}</div> */}

        {exposeEdit && (
          <div className={styles['informaion-container']}>
            <Tooltip title="학생 정보를 수정하실 수 있습니다." arrow>
              <div
                className={styles['edit-box']}
                type="button"
                onClick={() => {
                  console.log('EditModal');
                  setUpdateModal(true);
                }}
              >
                <img src={Pencil} width={13.5} height={13.5} />
              </div>
            </Tooltip>
            <Tooltip title="학생을 해당 수업에서 내보낼 수 있습니다." arrow>
              <div className={styles['delete-box']}>
                <CloseIcon
                  className={styles['close']}
                  type="button"
                  onClick={() => {
                    console.log('confirmModal', activeManaging);
                    console.log('activeManaging: ', Boolean(activeManaging));
                    if (activeManaging) {

                      if(!activeManaging.state !== 'IN_MANAGING'){
                        return
                      }
                      alert('선생님, 관리중일 땐 학생을 내보낼 수 없습니다.');
                      return;
                    }
                    setConfirmModal(true);
                  }}
                />
              </div>
            </Tooltip>
          </div>
        )}
      </div>
      {updateModal ? (
        <ModalStudentUpdate
          updateModal={updateModal}
          setUpdateModal={setUpdateModal}
          propUsername={student.userName}
          propStudentNum={student.studentNumber}
          propUserId={student.clientId}
          groupId={groupId}
          user={user}
        />
      ) : null}

      {confirmModal ? (
        <ModalConfirm
          confirmModal={confirmModal}
          setConfirmModal={setConfirmModal}
          propUsername={student.userName}
          propStudentNum={student.studentNumber}
          propUserId={student.clientId}
          groupId={groupId}
          user={user}
        />
      ) : null}
    </div>
  );
};

export default ManagedStudentRow;
