export const PURGE_ALL = 'PURGE_ALL';

export const PURGE_USER = 'PURGE_USER';

export const SIGN_IN_SUCCESS = 'SIGN_IN_SUCCESS';
export const SIGN_IN_FAIL = 'SIGN_IN_FAIL';

export const SIGN_OUT = 'SIGN_OUT';
export const RESTORE_USER = 'RESTORE_USER';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS';
export const UPDATE_USER_FAIL = 'UPDATE_USER_FAIL';

// export const GET_USER_SUCCESS = 'GET_USER_SUCCESS';
// export const GET_USER_FAIL = 'GET_USER_FAIL';