import {
  PURGE_CONTROL,
  SET_DIRTY,
  SET_SELECTED_PAGE,
  SET_SELECTED_CLASS,
  SET_APPROVAL_COMPLETED,
  SET_SIDEBAR_STATE,
  SET_DEV_MODE,
  SET_CLASSROOM_STATE,
  SET_FILELINK_STATE,
  SET_SOCKET_DATA,
  SET_REPORT_DATA,
  CLEAR_REPORT_DATA,
  SET_SCREEN_DATA,
  SET_STUDENT_IMAGES,
  SET_SELECTED_STUDENT,
  SET_STUDENTLIST_UPDATE_REQUEST,
  CLEAR_SCREEN_DATA,
  CLEAR_STUDENT_IMAGES,
  SET_BIG_URI,
  CLEAR_BIG_URI,
  SET_REFRESH_STATE,
  SET_SELECTED_GROUP,
  SET_NETWORK_STATE,
  CLEAR_NETWORK_STATE,
} from './actionTypes';

///// PURGE_CONTROL
export const purgeControl = () => ({
  type: PURGE_CONTROL,
});

///// SET_DIRTY
export const setDirty = () => ({
  type: SET_DIRTY,
});

///// SET_SELECTED_PAGE
export const setSelectedPage = (selectedPage) => {
  return {
    type: SET_SELECTED_PAGE,
    selectedPage: selectedPage,
  };
};

///// SET_SELECTED_CLASS
export const setSelectedClass = (selectedClassId) => {
  return {
    type: SET_SELECTED_CLASS,
    selectedClassId: selectedClassId,
  };
};

///// SET_APPROVAL_COMPLETED
export const setApprovalCompleted = () => {
  return {
    type: SET_APPROVAL_COMPLETED,
  };
};

///// SET_SIDEBAR_STATE
export const setSidebarState = () => {
  return {
    type: SET_SIDEBAR_STATE,
  };
};

///// SET_DEV_MODE
export const setDevMode = (devMode) => {
  return {
    type: SET_DEV_MODE,
    devMode: devMode,
  };
};

//// SET_CLASSROOM_STATE
export const setClassroomState = (classState) => {
  return {
    type: SET_CLASSROOM_STATE,
    classState: classState,
  };
};

//// SET_FILELINK_STATE
export const setFilelinkState = () => {
  return {
    type: SET_FILELINK_STATE,
  };
};

//// SET_SOCKET_DATA
export const setSocketData = (socketData) => {
  return {
    type: SET_SOCKET_DATA,
    socketData: socketData,
  };
};

/**
 * clientId
: 
"b5e420f4-be7d-49ee-b27e-6718f997695a"
data
: 
"com.sec.android.app.launcher"
groupId
: 
"f3a73a71-8798-4269-b94b-3a84772a6928"
type
: 
"REPORT"
 */

//// SET_REPORT_DATA
export const setReportData = (reportData) => {
  const checkRereportData = reportData ?? 0;
  if (
    checkRereportData === null ||
    checkRereportData === undefined ||
    checkRereportData === {} ||
    checkRereportData === false
  ) {
    return;
  }

  return {
    type: SET_REPORT_DATA,
    reportData: checkRereportData,
  };
};

///CLEAR_REPORT_DATA
export const clearReportData = () => {
  return {
    type: CLEAR_REPORT_DATA,
  };
};

//// SET_SCREEN_DATA
export const setScreenData = (screenData) => {
  return {
    type: SET_SCREEN_DATA,
    screenData: screenData,
  };
};

export const clearScreenData = () => {
  return {
    type: CLEAR_SCREEN_DATA,
  };
};

//// SET_STUDENT_IMAGES
export const setStudentImages = (images) => {
  return {
    type: SET_STUDENT_IMAGES,
    images: images,
  };
};

export const clearStudentImages = () => {
  return {
    type: CLEAR_STUDENT_IMAGES,
  };
};

//// SET_SELECTED_STUDENT
export const setSelectedStudent = (selectedStd) => {
  return {
    type: SET_SELECTED_STUDENT,
    selectedStd: selectedStd,
  };
};

export const setStudentListUpdate = (stdUpdateRequest) => {
  return {
    type: SET_STUDENTLIST_UPDATE_REQUEST,
    stdUpdateRequest: stdUpdateRequest,
  };
};

export const setBigURI = (bigURI) => {
  return {
    type: SET_BIG_URI,
    bigURI: bigURI,
  };
};

export const clearBigURI = () => {
  return {
    type: CLEAR_BIG_URI,
  };
};

export const setRefreshstate = (date) => {
  return {
    type: SET_REFRESH_STATE,
    date: date,
  };
};

export const setSelectedGroup = (selectedGroup) => {
  return {
    type: SET_SELECTED_GROUP,
    selectedGroup: selectedGroup,
  };
};

export const setNewtorkState = (networkState) => {
  return {
    type: SET_NETWORK_STATE,
    networkState: networkState,
  };
};

export const clearNetworkState = () => {
  return {
    type: CLEAR_NETWORK_STATE,
    networkState: 0,
  };
};
