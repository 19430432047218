import React, { memo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import styles from './Sidebar.module.scss';
import imgHomeIcon from '@images/home/ic-home-24.svg';
import imgHomeActiveIcon from '@images/home/ic_round-home.svg';
import { setSidebarState } from '@store/actions';
import SidebarClassRoom from '@components/Layout/SidebarClassRoom';
import { selectGroupById } from '@store/selectors';
import SettingsIcon from '@mui/icons-material/Settings';
import Loading from '../Loading/Loading';
import { selectActiveClassroom } from '@app/store/selectors';
import LogoutButton from '../Buttons/Logout/LogoutButton';
import ClassroomStateChangeButton from '../Buttons/ClassroomState/ClassroomStateChangeButton';
import TopUserSection from '../Drawer/TopUserSection';
import GradeClassStatus from '../Drawer/GradeClassStatus';
import HomeButtonList from '../Buttons/Sidebar/HomeButtonList';
import InClassInformation from '../Drawer/InclassInformation';
import InclassInviteCode from '../Drawer/InclassInviteCode';

const Sidebar = memo(({ inClassroom }) => {
  const { groupId } = useParams();
  const group = useSelector((state) => selectGroupById(state, groupId));
  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);
  const isLoading = useSelector((state) => state.classrooms.isClassroomLoading);
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const location = useLocation();

  const { commentsLayer } = useSelector((state) => state.commentsLayer);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));

  useEffect(() => {
    if (user.state === 'REGISTERED_APPROVED') {
      if (control.sidebarState === false) dispatch(setSidebarState());
    } else {
    }
  }, [user, control.sidebarState, dispatch]);

  const onPressHome = () => {
    history.replace('/home');
    return;
  };

  const onPressAfter = () => {
    history.replace(`/managing`);
    return;
  };

  const onPressSettings = () => {
    history.replace('/settings');
    return;
  };

  return (
    <div className={styles['side-screen']}>
      <div className={styles['inner-side']}>
        <TopUserSection />
        <div
          className={classNames(
            control.sidebarState !== true && styles['block-click']
          )}
        >
          {inClassroom && activeClassroom ? (
            <GradeClassStatus
              grade={group.grade}
              groupName={group.groupName}
              subjectName={group.subjectName}
            />
          ) : (
            <div className={styles['home-block']}>
              <div className={styles['home-block-inner-box']}>
                <div
                  className={styles['home-button']}
                  style={
                    location.pathname === '/home'
                      ? { backgroundColor: '#05869225' }
                      : {}
                  }
                  onClick={onPressHome}
                >
                  <img
                    className={styles['img-set']}
                    src={
                      location.pathname === '/home'
                        ? imgHomeActiveIcon
                        : imgHomeIcon
                    }
                    alt="home"
                  />
                  <div
                    className={styles['span-sidebar']}
                    style={
                      location.pathname === '/home' ? { color: '#35B5C2' } : {}
                    }
                  >
                    처음으로
                  </div>
                </div>
              </div>
              {group && <InClassInformation group={group} />}
            </div>
          )}

          <div className={styles['menu-block']}>
            {inClassroom ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '100%',
                }}
              >
                <ClassroomStateChangeButton />
                <InclassInviteCode />
              </div>
            ) : (
              <div>
                <HomeButtonList
                  location={location}
                  history={history}
                  onPressAfter={onPressAfter}
                />
              </div>
            )}
          </div>
        </div>
        {inClassroom ? <SidebarClassRoom /> : null}

        {!inClassroom && (
          <div className={styles['bottom-container']}>
            <div>
              <LogoutButton />
            </div>
            <div
              className={styles['setting-block']}
              onClick={() => onPressSettings()}
            >
              <SettingsIcon
                style={{
                  color: '#ffffff',
                  width: 20,
                  height: 20,
                }}
              />
            </div>
          </div>
        )}
      </div>
      {isLoading && <Loading />}
    </div>
  );
});

export default Sidebar;
