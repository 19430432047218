import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '@components/Layout/Sidebar';
import ClassList from '@components/Layout/ClassList';
import NotiPanel from '@components/Layout/NotiPanel';
import EventPanel from '@components/Layout/EventPanel';
import { setToken, getStorageData, useInterval } from '@api';
import { purgeAll } from '@app/store/actions';
import { useHistory } from 'react-router-dom';
import winterIsComming from 'assets/images/winterIsComming.png';

const Home = (props) => {
  const dispatch = useDispatch();
  console.log('home : render');
  const user = useSelector((state) => state.user);
  const updated = useSelector((state) => state.user.updated);
  const history = useHistory();
  // useEffect(() => {
  //   dispatch(getMyGroups(user.clientId));
  // }, [user]);

  try {
    // window.testjs();
  } catch (error) {
    console.error(error);
  }
  useEffect(() => {
    if (user.token !== undefined && user.token !== null) {
      setToken(user.token).then(() => {});
    }
  }, []);

  useEffect(() => {
    if (!updated) {
      dispatch(purgeAll());
      history.replace('/renewal');
    }
  }, [updated]);

  return (
    <div className="flex w-100vw h-100vh">
      <Sidebar />
      <div
        style={{
          marginLeft: '280px',
          width: '100vw',
          minHeight: '100vh',
          padding: 14,
          background: '#f9f9f9',
        }}
      >
        <NotiPanel />
        <ClassList />
      </div>
    </div>
  );
};

export default Home;
