import styled from 'styled-components';
import { Button } from '@mui/material';

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Row2 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  flex-grow: 1;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BaseText = styled.span`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Heading5 = styled(BaseText)`
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
`;

export const Heading6 = styled(BaseText)`
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
`;

export const Caption = styled(BaseText)`
  font-size: 12px;
  line-height: 20px;
  letter-spacing: 0.4px;
  color: rgba(0, 0, 0, 0.6);
`;

export const CaptionMedium = styled(Caption)`
  font-weight: 500;
`;

export const Subtitle1 = styled(BaseText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Subtitle2 = styled(BaseText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.87);
`;

export const Subtitle3 = styled(BaseText)`
  font-weight: 500;
  font-size: 14px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: #353433;
`;
export const Subtitle4 = styled(BaseText)`
  font-weight: 500;
  font-size: 12px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #00000099;
`;

export const Subtitle5 = styled(BaseText)`
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.4px;
  color: #00000099;
`;

export const Body2 = styled(BaseText)`
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.6);
`;

export const SideBarRest = styled(Row)`
  flex: 1;
  min-height: 100vh;
  margin-left: 280px;
  background-color: #fafafa;
  padding: 0 16px;
`;

export const MainContentLayer = styled(Column)`
  flex: 1;
  height: 100%;
  background-color: #fff;
  border: 1px solid #eee;
`;

export const HeaderSection = styled(Row)`
  padding: 16px 24px 0 24px;
`;

export const HeaderSectionHeading = styled(Heading6)`
  margin: 8px 24px 8px 0;
`;

export const MiddleMenuBar = styled(Row)`
  background-color: #fafafa;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
  padding: 12px 24px;
  justify-content: space-between;
`;

export const TabButton = styled(Button).attrs({
  variant: 'outlined',
})`
  width: 120px;
  height: 40px;
  background-color: #fff;
  border: 1px solid #e0e0e0;
  color: rgba(0, 0, 0, 0.6);

  :hover {
    color: #0ba1ae;
  }
`;

export const TabButtonSmall = styled(TabButton)`
  width: 96px;
`;

export const TabButtonFocused = styled(TabButton)`
  background-color: #0ba1ae;
  color: #fff;
  border: none;

  :hover {
    background-color: #0ba1ae;
    color: #fff;
    border: none;
  }
`;

export const TabButtonInteractive = ({ isSelected, children, onClick }) => {
  if (isSelected)
    return (
      <TabButtonFocused
        onClick={onClick}
        style={{ backgroundColor: '#0BA1AE', color: '#fff' }}
      >
        {children}
      </TabButtonFocused>
    );
  return <TabButton onClick={onClick}>{children}</TabButton>;
};

export const TabButtonFocusedSmall = styled(TabButtonFocused)`
  width: 96px;
`;

export const SubTitle2 = styled.span`
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.1px;
  color: rgba(0, 0, 0, 0.37);
`;

export const BottomContentLayer = styled(Column)`
  padding: 16px 24px;
`;

export const VerticalSpace4 = styled.div`
  height: 4px;
`;

export const VerticalSpace8 = styled.div`
  height: 8px;
`;

export const HorizontalSpace4 = styled.div`
  width: 4px;
`;

export const HorizontalSpace8 = styled.div`
  width: 8px;
`;

export const HorizontalSpace16 = styled.div`
  width: 16px;
`;

export const InputGray = styled.input`
  padding: 16px 12px;
  background: rgba(97, 97, 97, 0.08);
  border-radius: 4px;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const InputWhite = styled.input`
  padding: 9px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #fff;
  flex: 1;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const InputGrayScroll = styled.textarea`
  padding: 16px 12px;
  background: rgba(97, 97, 97, 0.08);
  border-radius: 4px;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: rgba(0, 0, 0, 0.87);
  resize: vertical; // 세로 방향으로만 resize 가능하도록 설정
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const InputWhiteScroll = styled.textarea`
  padding: 9px 16px;
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #fff;

  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
  resize: vertical; // 세로 방향으로만 resize 가능하도록 설정
  &::placeholder {
    color: rgba(0, 0, 0, 0.6);
  }
`;

export const ArrowBackIconContainer = styled.div`
  padding: 4px;
  margin-right: 8px;

  :hover {
    cursor: pointer;
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: #eeeeee;
`;
