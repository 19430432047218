import { makeAutoObservable } from 'mobx'

export class QuizFilterStore {
  data = []

  grade = -1
  subject = -1
  category = -1
  keyword = -1
  type = -1
  query = ''

  constructor() {
    makeAutoObservable(this)
  }

  setData(data) {
    this.data = data || []
  }

  get availableGrades() {
    return [...new Set(this.data.map(x => x.grade).filter(x => typeof x === 'number'))].sort()
  }

  get availableSubjects() {
    return [...new Set(this.data.map(x => x.quizSubject))]
  }

  get availableCategories() {
    return [...new Set(this.data.map(x => x.quizCategory))]
  }

  get availableKeywords() {
    return [...new Set(this.data.map(x => x.keyword))]
  }

  get typeCounts() {
    let data = this.data
    if (this.grade !== -1) {
      data = data.filter(x => x.grade === this.grade)
    }
    if (this.subject !== -1) {
      data = data.filter(x => x.quizSubject === this.subject)
    }
    if (this.category !== -1) {
      data = data.filter(x => x.quizCategory === this.category)
    }
    if (this.keyword !== -1) {
      data = data.filter(x => x.keyword === this.keyword)
    }
    const query = this.query.trim()
    if (query.length > 0) {
      data = data.filter(x => x.title.includes(query))
    }
    return {
      TOTAL: data.length,
      MULTIPLE: data.filter(x => x.quizType === 'MULTIPLE').length,
      SHORT: data.filter(x => x.quizType === 'SHORT').length,
      OX: data.filter(x => x.quizType === 'OX').length,
    }
  }

  get filtered() {
    let data = this.data
    if (this.grade !== -1) {
      data = data.filter(x => x.grade === this.grade)
    }
    if (this.subject !== -1) {
      data = data.filter(x => x.quizSubject === this.subject)
    }
    if (this.category !== -1) {
      data = data.filter(x => x.quizCategory === this.category)
    }
    if (this.keyword !== -1) {
      data = data.filter(x => x.keyword === this.keyword)
    }
    if (this.type !== -1) {
      data = data.filter(x => x.quizType === this.type)
    }
    const query = this.query.trim()
    if (query.length > 0) {
      data = data.filter(x => x.title.includes(query))
    }
    return data
  }

  setGrade(grade) {
    this.grade = grade
  }

  setSubject(subject) {
    this.subject = subject
  }

  setCategory(category) {
    this.category = category
  }

  setKeyword(keyword) {
    this.keyword = keyword
  }

  setType(type) {
    this.type = type
  }

  setQuery(query) {
    this.query = query
  }
}
