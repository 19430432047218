import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveClassroom,
  selectClassroomGroup,
  selectGroupTimetables,
  selectAllManaging,
} from '@store/selectors';
import {
  getActiveClassroomPromise,
  setSelectedClass,
  setSelectedPage,
  updateManagingPromise,
} from '@store/actions';
import { useHistory, useLocation } from 'react-router-dom';
import { useInterval } from '@api';
import moment from 'moment';
import {
  createManagingPromise,
  getActiveManagingPromise,
} from '@app/store/managing/actions';
import {
  clearScreenData,
  clearStudentImages,
  getCommentsLayerPromise,
  getManagedsByManagingPromise,
  purgeClassroom,
  setSocketData,
} from '@app/store/actions';
import { selectActiveManaging } from '@app/store/selectors';

const ClassManager = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const activeClassroomGroup = useSelector((state) =>
    selectClassroomGroup(state, activeClassroom)
  );
  const groupTimetables = useSelector((state) =>
    selectGroupTimetables(state, activeClassroomGroup?.groupId)
  );
  const user = useSelector((state) => state.user);

  useEffect(() => {
    if (!user || !user.signedIn) {
      return;
    }
    dispatch(getActiveClassroomPromise(user?.clientId));
    dispatch(getActiveManagingPromise(user?.clientId));
  }, [dispatch, user.clientId, user.signedIn]);

  useEffect(() => {
    if (!activeClassroom || !activeClassroom?.classroomId) {
      return;
    }
    dispatch(getCommentsLayerPromise(activeClassroom.classroomId));
  }, [activeClassroom?.classroomId, dispatch]);

  useEffect(() => {
    if (!user || !user.signedIn) {
      return;
    }

    if (!activeClassroom) {
      // for drawer highlight
      dispatch(setSelectedClass(null));
      // dispatch(setSelectedPage(''));
      // dispatch(purgeClassroom());
      dispatch(clearScreenData());
      dispatch(clearStudentImages());
      return;
    }

    // for drawer highlight
    dispatch(setSelectedClass(activeClassroom.groupId));
    // dispatch(setSelectedPage('CLASS'));
  }, [activeClassroom, dispatch, user.signedIn]);

  useEffect(() => {
    if (!user || !activeManaging || !activeManaging?.managingId) {
      return;
    }
    dispatch(getManagedsByManagingPromise(activeManaging.managingId));
  }, [activeManaging]);

  useEffect(() => {
    if (!activeClassroomGroup?.groupType) {
      return;
    }
    if (location.pathname.includes('students')) {
      return;
    }

    const group = activeClassroomGroup;
    const timetable = groupTimetables[0];
    const state = { group, timetable };

    switch (activeClassroomGroup.groupType) {
      case 'SUBJECT':
        console.log('[ClassManager]', 'navigating to ClassInformation');
        history.push({
          pathname: `/classroom/${activeClassroomGroup.groupId}`,
          state,
        });
        break;
      case 'HR':
        console.log('[ClassManager]', 'navigating to ClassInformation');
        history.push({
          pathname: `/classroom/${activeClassroomGroup.groupId}`,
          state,
        });
        break;
      default:
        console.log(
          '[ClassManager] unknown group.groupType:',
          activeClassroomGroup.groupType
        );
        console.log('[ClassManager]', 'navigating to Home');
        history.push({
          pathname: '/home',
          state,
        });
        break;
    }
  }, [activeClassroomGroup]);

  const managingSchedule = useSelector((state) => selectAllManaging(state));

  //trigger managing Start
  useInterval(() => {
    const managingForUpdate = managingSchedule
      .filter((schedule) => schedule.state === 'FINISHED')
      .filter(
        (schedule) =>
          moment(schedule.startDate) - moment() < 0 &&
          moment(schedule.endDate) - moment() > 0
      );
    if (!managingForUpdate || managingForUpdate.length === 0) {
      return;
    }
    managingForUpdate.forEach((managing) => {
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: managing.startDate,
        endDate: managing.endDate,
        state: 'IN_MANAGING',
      };
      dispatch(updateManagingPromise(updateData))
        .then((res) => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: 'MANAGING_START',
              data: managing.managingId,
            })
          );
        })
        .then(() => dispatch(getActiveManagingPromise(user?.clientId)));
    });
  }, 3000);

  //trigger managing End
  useInterval(() => {
    const managingForUpdate = managingSchedule
      .filter((schedule) => schedule.state === 'IN_MANAGING')
      .filter(
        (schedule) =>
          (moment(schedule.endDate) - moment() < 0 &&
            moment(schedule.endDate) - moment() > -3000) ||
          moment(schedule.startDate) - moment() > 0
      );

    if (!managingForUpdate || managingForUpdate.length === 0) {
      return;
    }
    managingForUpdate.forEach((managing) => {
      let newStartDate = managing.startDate;
      let newEndDate = managing.endDate;

      if (managing.rRule === 'FREQ=DAILY') {
        newStartDate = moment(managing.startDate)
          .add(24, 'hours')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(24, 'hours')
          .toISOString();
      }
      if (managing.rRule === 'FREQ=WEEKLY') {
        newStartDate = moment(managing.startDate)
          .add(7, 'days')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(7, 'days')
          .toISOString();
      }
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: newStartDate,
        endDate: newEndDate,
        state: 'FINISHED',
      };
      const fillData = {
        title: managing.title,
        rRule: 'rRule',
        startDate: managing.startDate,
        endDate: managing.endDate,
        groupId: managing.groupId,
      };
      dispatch(updateManagingPromise(updateData))
        .then(() => {
          dispatch(getActiveManagingPromise(user.clientId));
          if (managing.rRule !== 'rRule') {
            dispatch(createManagingPromise(fillData));
          }
        })
        .then(() => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: 'MANAGING_END',
              data: '',
            })
          );
        });
    });
  }, 3000);

  useInterval(() => {
    const managingForUpdateTwo = managingSchedule
      .filter((schedule) => schedule.state === 'IN_MANAGING')
      .filter(
        (schedule) =>
          moment(schedule.startDate) - moment() < 0 &&
          moment(schedule.endDate) - moment() < 0
      );

    if (!managingForUpdateTwo || managingForUpdateTwo.length === 0) {
      return;
    }
    managingForUpdateTwo.forEach((managing) => {
      let newStartDate = managing.startDate;
      let newEndDate = managing.endDate;

      if (managing.rRule === 'FREQ=DAILY') {
        newStartDate = moment(managing.startDate)
          .add(24, 'hours')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(24, 'hours')
          .toISOString();
      }
      if (managing.rRule === 'FREQ=WEEKLY') {
        newStartDate = moment(managing.startDate)
          .add(7, 'days')
          .toISOString();
        newEndDate = moment(managing.endDate)
          .add(7, 'days')
          .toISOString();
      }
      const updateData = {
        managingId: managing.managingId,
        title: managing.title,
        rRule: managing.rRule,
        startDate: newStartDate,
        endDate: newEndDate,
        state: 'FINISHED',
      };
      const fillData = {
        title: managing.title,
        rRule: 'rRule',
        startDate: managing.startDate,
        endDate: managing.endDate,
        groupId: managing.groupId,
      };
      dispatch(updateManagingPromise(updateData))
        .then(() => {
          dispatch(getActiveManagingPromise(user.clientId));
          // dispatch(createManagingPromise(fillData));
        })
        .then(() => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: managing.groupId,
              clientId: user?.clientId,
              type: 'MANAGING_UPDATE',
              data: '',
            })
          );
        });
    });
  }, 3000);

  return <div />;
};

export default ClassManager;
