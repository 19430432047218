import {
  PURGE_MANAGED,
  GET_MANAGED_SUCCESS,
  GET_MANAGED_FAIL,
  GET_MANAGEDS_BY_CLIENT_SUCCESS,
  GET_MANAGEDS_BY_CLIENT_FAIL,
  GET_MANAGEDS_BY_MANAGING_SUCCESS,
  GET_MANAGEDS_BY_MANAGING_FAIL,
  CREATE_MANAGED_SUCCESS,
  CREATE_MANAGED_FAIL,
  UPDATE_MANAGED_SUCCESS,
  UPDATE_MANAGED_FAIL,
  DELETE_MANAGED_SUCCESS,
  DELETE_MANAGED_FAIL,
  GET_MANAGEDS_BY_GROUP_FAIL,
  GET_MANAGEDS_BY_GROUP_SUCCESS,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeManaged = () => ({
  type: PURGE_MANAGED,
});

///// GET_MANAGED

export const getManagedPromise = createPromiseAction('GET_MANAGED_PROMISE');

export const getManagedSuccess = (managedData) => ({
  type: GET_MANAGED_SUCCESS,
  managed: managedData,
});

export const getManagedFail = (error) => ({
  type: GET_MANAGED_FAIL,
  payload: error,
});

export const getManagedsByClientPromise = createPromiseAction(
  'GET_MANAGEDS_BY_CLIENT_PROMISE'
);

export const getManagedsByClientSuccess = (managedsData) => {
  let byId = {};

  managedsData.forEach((managedData) => {
    if (managedData.managed.state !== 'DELETED') {
      byId[`${managedData.managed.clientId}`] = managedData.managed;
    }
  });

  const allIds = managedsData
    .filter((managedData) => managedData.managed.state !== 'DELETED')
    .map((managedData) => managedData.managed.clientId);

  return {
    type: GET_MANAGEDS_BY_CLIENT_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getManagedsByClientFail = (error) => ({
  type: GET_MANAGEDS_BY_CLIENT_FAIL,
  payload: error,
});

export const getManagedsByManagingPromise = createPromiseAction(
  'GET_MANAGEDS_BY_MANAGING_PROMISE'
);

export const getManagedsByManagingSuccess = (clientManagedArray) => {
  let byId = {};
  clientManagedArray.clientManageds.forEach((clientManagedData) => {
    byId[
      `${clientManagedData.managed.clientId},${clientManagedData.managed.classroomId}`
    ] = clientManagedData.managed;
  });

  const allIds = clientManagedArray.clientManageds.map((clientManagedData) => {
    return `${clientManagedData.managed.clientId},${clientManagedData.managed.classroomId}`;
  });

  return {
    type: GET_MANAGEDS_BY_MANAGING_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getManagedsByManagingFail = (error) => ({
  type: GET_MANAGEDS_BY_MANAGING_FAIL,
  payload: error,
});

export const getManagedsByGroupPromise = createPromiseAction(
  'GET_MANAGEDS_BY_GROUP_PROMISE'
);

export const getManagedsByGroupSuccess = (groupManagedArray) => {
  const managingData = groupManagedArray.managingManageds[0];
  let byId = {};
  groupManagedArray.clients.forEach((clientManagedData) => {
    byId[`${clientManagedData.clientId}`] = {
      client: clientManagedData,
      managedData: managingData,
    };
  });

  const allIds = groupManagedArray.clients.map((clientManagedData) => {
    return `${clientManagedData.clientId}`;
  });

  return {
    type: GET_MANAGEDS_BY_GROUP_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getManagedsByGroupFail = (error) => ({
  type: GET_MANAGEDS_BY_GROUP_FAIL,
  payload: error,
});

export const createManagedPromise = createPromiseAction(
  'CREATE_MANAGED_PROMISE'
);

export const createManagedSuccess = (managedData) => ({
  type: CREATE_MANAGED_SUCCESS,
  managed: managedData,
});

export const createManagedFail = (error) => ({
  type: CREATE_MANAGED_FAIL,
  payload: error,
});

export const updateManagedPromise = createPromiseAction(
  'UPDATE_MANAGED_PROMISE'
);

export const updateManagedSuccess = (managedData) => ({
  type: UPDATE_MANAGED_SUCCESS,
  managed: managedData,
});

export const updateManagedFail = (error) => ({
  type: UPDATE_MANAGED_FAIL,
  payload: error,
});

export const deleteManagedPromise = createPromiseAction(
  'DELETE_MANAGED_PROMISE'
);

export const deleteManagedSuccess = (managedData) => ({
  type: DELETE_MANAGED_SUCCESS,
  managed: managedData,
});

export const deleteManagedFail = (error) => ({
  type: DELETE_MANAGED_FAIL,
  payload: error,
});
