import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import styles from "./ModalGenericSimpleText.module.scss";

const ModalGenericSimpleText = ({
  title,
  description,
  placeholder,
  decideText,
  confirmModal,
  setConfirmModal,
  onFire,
}) => {
  const onKick = onFire;
  const [targetText, setTargetText] = useState("");

  const onHide = () => {
    setConfirmModal(false);
  };

  return (
    <Modal
      show={confirmModal}
      onHide={() => {
        setConfirmModal(false);
      }}
      centered
      className={styles["editInfomation-container"]}
    >
      <div onSubmit={onKick} className={styles["modal-cotainer"]}>
        <div className={styles["infoContainer"]}>
          <div className={styles["title-css"]}>{title}</div>
          <div className={styles["studentInfo"]}>
            {description}
            <input
              className={styles["inputContainer"]}
              type={"text"}
              placeholder={placeholder}
              onChange={(e) => {
                setTargetText(e.target.value);
              }}
            />
          </div>
        </div>
        <div className={styles["button-container"]}>
          <button
            type='button'
            className={styles["cancle-button"]}
            onClick={() => {
              onHide();
            }}
          >
            취소
          </button>
          <button
            type='button'
            className={styles["kick-button"]}
            onClick={() => {
              onKick(targetText);
            }}
          >
            {decideText}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ModalGenericSimpleText;
