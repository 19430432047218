import React from 'react';
import styled from 'styled-components';

import KakaoChannelBanner from 'assets/images/notice/kakaoAdd.png';
import Title from 'assets/images/notice/title.png';
import notePang from 'assets/images/notice/notePang.png';
import notePangBeta from 'assets/images/notice/penDrawingBetaBanner.png'
import kakaoBanner from 'assets/images/notice/kakaoBanner.png'

const NotiPanelWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 8px;
  padding: 8px 0;
`;

const Banner = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #eeeeee;
  border-radius: 8px;
  width: 100%;
  // 반응형 높이

  @media (min-width: 3840px) {
    height: 500px;
  }
  @media (min-width: 2560px) and (max-width: 3839px) {
    height: 300px;
  }

  @media (min-width: 1980px) and (max-width: 2559px) {
    height: 240px;
  }

  @media (min-width: 1440px) and (max-width: 1979px) {
    height: 150px;
  }

  @media (max-width: 1439px) {
    height: 120px;
  }
  cursor: pointer;
`;

const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 70%;
`;

const ImageContainerFullImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  width: 100%;
  height: 100%;
  overflow: hidden;
`;

const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const NotiPanel = () => {
  return (
    <NotiPanelWrapper>
      <Banner
      // onClick={() => {
      //   window.open('#');
      // }}
      >
        <ImageContainerFullImage>
          <Image src={notePangBeta} alt="banner" />
        </ImageContainerFullImage>
      </Banner>
      <Banner
        onClick={() => {
          window.open('http://pf.kakao.com/_xjxgGDxj');
        }}
        style={{ backgroundColor: '#f7e600' }}
      >
        <ImageContainer>
          <Image src={kakaoBanner} alt="banner" />
        </ImageContainer>
      </Banner>
    </NotiPanelWrapper>
  );
};

export default NotiPanel;
