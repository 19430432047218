import {
  PURGE_DEVICE,
  REGISTER_DEVICE_SUCCESS,
  REGISTER_DEVICE_FAIL,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

///// PURGE_DEVICE
export const purgeDevice = () => ({
  type: PURGE_DEVICE,
});

export const registerDevicePromise = createPromiseAction(
  'REGISTER_DEVICE_PROMISE'
);

///// REGISTER_DEVICE_SUCCESS
export const registerDeviceSuccess = (deviceData) => {
  return {
    type: REGISTER_DEVICE_SUCCESS,
    device: deviceData,
  };
};

///// REGISTER_DEVICE_FAIL
export const registerDeviceFail = (error) => {
  return {
    type: REGISTER_DEVICE_FAIL,
    payload: error,
  };
};
