import React, { useEffect, useRef, useState } from 'react'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import {
  Column,
  Heading6,
  HorizontalSpace8,
  MiddleMenuBar,
  Row,
  Subtitle1,
  SubTitle2,
  TabButtonInteractive,
  VerticalSpace8,
} from '@app/pages/QuizPang/common'
import { Button } from '@mui/material'
import { Select } from '@app/pages/QuizPang/select'
import MenuItem from '@mui/material/MenuItem'
import { observer } from 'mobx-react'
import { SearchInput } from '@app/pages/QuizPang/search-input'
import { QuizCard, QuizCardChooseActions } from '@app/pages/QuizPang/quiz-card'
import {
  QuizModalStore,
  quizTypes,
  quizTypeToText,
} from '@app/pages/QuizPang/quiz-modal-store'
import { QuizFilterStore } from '@app/pages/QuizPang/quiz-filter-store'
import { useSelector } from 'react-redux'
import { selectClientQuizList } from '@app/store/quiz/selector'
import CloseIcon from '@mui/icons-material/Close'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { QuizPreview } from '@app/pages/QuizPang/quiz-preview'

const QuizChooseList = observer(({ store, previewStore }) => {
  const filterStore = useRef(new QuizFilterStore())
  const quizList = useSelector(selectClientQuizList)
  useEffect(() => {
    filterStore.current.setData(quizList)
  }, [quizList])
  const counts = filterStore.current.typeCounts
  return <Column style={{ flex: 1 }}>
    <Column style={{ padding: '0 24px' }}>
      <Subtitle1 style={{ marginBottom: 8 }}>문제 창고에서 선택</Subtitle1>
    </Column>
    <MiddleMenuBar style={{ flex: 1, justifyContent: 'space-between', borderBottom: 0 }}>
      <Row>
      <Select width={120} value={filterStore.current.grade}
                onChange={(e) => filterStore.current.setGrade(e.target.value)}>
          <MenuItem value={-1}>학년 전체</MenuItem>
          {filterStore.current.availableGrades.map(x =>
            <MenuItem key={x} value={x}>{x}학년</MenuItem>,
          )}
        </Select>
        <HorizontalSpace8/>
        <Select width={180} value={filterStore.current.subject}
                onChange={(e) => filterStore.current.setSubject(e.target.value)}>
          <MenuItem value={-1}>과목 전체</MenuItem>
          {filterStore.current.availableSubjects.map(x =>
            <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
          )}
        </Select>
        <HorizontalSpace8/>
        <Select width={200} value={filterStore.current.category}
                onChange={(e) => filterStore.current.setCategory(e.target.value)}>
          <MenuItem value={-1}>교과서 출판사 전체</MenuItem>
          {filterStore.current.availableCategories.map(x =>
            <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
          )}
        </Select>
        <HorizontalSpace8/>
        <Select width={200} value={filterStore.current.keyword}
                onChange={(e) => filterStore.current.setKeyword(e.target.value)}>
          <MenuItem value={-1}>키워드 전체</MenuItem>
          {filterStore.current.availableKeywords.map(x =>
            <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
          )}
        </Select>
      </Row>
      <Row>
        <SearchInput label="문제 찾기" value={filterStore.current.query}
                     onChange={(e) => filterStore.current.setQuery(e.target.value)}/>
      </Row>
    </MiddleMenuBar>
    <MiddleMenuBar style={{borderTopWidth: 0, paddingTop: 0}}>
      <Row>
      <TabButtonInteractive isSelected={filterStore.current.type === -1}
                              onClick={() => {
                                filterStore.current.setType(-1)
                              }}>전체 ({counts.TOTAL})</TabButtonInteractive>
        {quizTypes.map(x => {
          return <React.Fragment key={x}>
            <HorizontalSpace8/>
            <TabButtonInteractive
              isSelected={filterStore.current.type === x}
              onClick={() => filterStore.current.setType(x)}>
              {quizTypeToText[x]} ({counts[x]})
            </TabButtonInteractive>
          </React.Fragment>
        })}
      </Row>
    </MiddleMenuBar>
    <VerticalSpace8/>
    <VerticalSpace8/>
    <Column style={{ padding: '0 24px' }}>
      {
        filterStore.current.filtered.length > 0 ?
          filterStore.current.filtered.map(x => {
            const isChosen = store.getQuizIndex(x.quizId) >= 0
            return <QuizCard
              key={x.quizId}
              actions={<QuizCardChooseActions
                onChoose={() => {
                  store.chooseQuiz(x.quizId)
                  previewStore.setData(x)
                }}
                isChosen={isChosen}
              />}
              isActive={isChosen}
              data={x}
            />
          }) :
          <SubTitle2>선택 가능한 문제가 없습니다.</SubTitle2>
      }
    </Column>
  </Column>
})

export const StartOneQuizModal = observer(({ isOpen, onClose, onStart, store }) => {
  const [isPreview, setIsPreview] = useState(false)
  const previewStore = useRef(new QuizModalStore()).current
  return <Modal open={isOpen}>
    <Container>
      <HeaderSection>
        <Heading6>
          문제 하나만 시작
        </Heading6>
      </HeaderSection>
      <BodySection style={{ flex: 1, alignItems: 'flex-start' }}>
        {isPreview ? <QuizPreview store={previewStore}/> :
          <QuizChooseList store={store} previewStore={previewStore}/>}
      </BodySection>
      <BottomSection>
        <Row style={{ flex: 1, justifyContent: 'space-between' }}>
          <Row>
            <Button
              disabled={!store.hasQuiz}
              startIcon={isPreview ? <CloseIcon/> : <PlayCircleIcon/>}
              onClick={() => setIsPreview(!isPreview)}
              sx={isPreview ? { color: '#FF5252' } : {}}
            >
              {isPreview ? '미리보기 종료' : '문제 미리보기'}
            </Button>
          </Row>
          {!isPreview &&
            <Row>
              <Button onClick={onClose}>취소</Button>
              <HorizontalSpace8/>
              <Button onClick={onStart} disabled={!store.hasQuiz}>시작</Button>
            </Row>
          }
        </Row>
      </BottomSection>
    </Container>
  </Modal>
})

const Container = styled(Column)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04),
  0 36px 41px rgba(0, 0, 0, 0.04),
  0 4.50776px 5.13384px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  height: 620px;
  width: 1208px;
  justify-content: space-between;
`

const HeaderSection = styled(Row)`
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
`

const BodySection = styled(Row)`
  padding: 16px 0;
  overflow-y: scroll;
`

const BottomSection = styled(Row)`
  padding: 16px 24px;
  border-top: 1px solid #eee;
`
