import { makeAutoObservable } from 'mobx'

export class QuizsetModalStore {
  quizsetId = null // for edit
  title = ''
  grade = 1
  subject = -1
  keyword = ''
  quizIdList = []

  constructor(data) {
    makeAutoObservable(this)
    if (data) {
      this.quizsetId = data.quizsetId
      this.title = data.title
      this.grade = data.grade
      this.subject = data.subject
      this.keyword = data.keyword
      this.quizIdList = data.quizzes.map(x => x.quizId)
    }
  }

  get isEdit() {
    return !!this.data.quizsetId
  }

  setGrade(grade) {
    this.grade = grade
  }

  setTitle(title) {
    this.title = title
  }

  setSubject(subject) {
    this.subject = subject
  }

  setKeyword (keyword) {
    this.keyword = keyword
  }

  toggleQuiz(quizId) {
    const foundIndex = this.quizIdList.findIndex(x => x === quizId)
    if (foundIndex !== -1) {
      this.quizIdList.splice(foundIndex, 1)
    } else {
      this.quizIdList.push(quizId)
    }
  }

  chooseQuiz(quizId) {
    this.quizIdList = [quizId]
  }

  getQuizIndex(quizId) {
    return this.quizIdList.findIndex(x => x === quizId)
  }

  get hasQuiz() {
    return this.quizIdList.length > 0
  }

  get data() {
    const {
      quizsetId,
      title,
      grade,
      subject,
      keyword,
      quizIdList,
    } = this
    return {
      quizsetId,
      title,
      grade,
      subject: subject === -1 ? '과목 없음' : subject,
      keyword: keyword ? keyword : '키워드 없음',
      quizzes: JSON.stringify(quizIdList.map(x => ({ quizId: x }))),
    }
  }
}
