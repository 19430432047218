export const selectAllProgramconfigs = (state) => {
  if (state.programconfigs.allIds.length === 0) {
    return [];
  }

  return state.programconfigs.allIds.map(
    (programconfigId) => state.programconfigs.byId[programconfigId]
  );
};

export const selectClientProgramconfigs = (state) => {
  return selectAllProgramconfigs(state).filter(
    (programconfig) => programconfig?.clientId === state.user.clientId
  );
};

export const selectGroupProgramconfigs = (state, group) => {
  return selectAllProgramconfigs(state).filter(
    (programconfig) => programconfig?.groupId === group
  );
};
