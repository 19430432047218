import React from 'react';
import ErrorMessage from './ErrorMessage';

const ShowError = ({ type, input }) => {
  let errorMessage = '';

  switch (type) {
    case 'stdNumber':
      errorMessage =
        input.length > 0 && input.length < 5
          ? '5자 이상 입력해주세요.'
          : input.length > 5
          ? '5자 이하로 입력해주세요.'
          : '';
      break;
    case 'stdName':
      errorMessage =
        input.length > 35 ? '이름은 35자 이하로 입력해주세요.' : '';
      break;
    default:
      break;
  }

  if (errorMessage) {
    return <ErrorMessage message={errorMessage} />;
  }

  return null;
};

export default ShowError;
