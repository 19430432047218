import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './ManagingSidebarTap.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { ReactComponent as ProfileCardImg } from '../../assets/images/icons/profile-card.svg';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import appTimeConfig from '@images/icons/appTimeConfig.svg';

import { selectManagingGroup } from '@app/store/selectors';

const tabs = [
  {
    id: '학생 명단',
    text: '학생 명단',
    iconWidth: 24,
    path: '/students-managed',
  },
  {
    id: '앱/웹 사이트 사용관리',
    text: '앱/웹 사이트 사용관리',
    iconWidth: 24,
    path: '/managing',
  },
];

const TabItem = ({ tab, onClick, open, subTab, isActive }) => {
  const Icon = ({ color }) => {
    switch (tab.id) {
      case '학생 명단':
        return <ProfileCardImg fill={color} />;
      case '앱/웹 사이트 사용관리':
        return <img fill={color} src={appTimeConfig} alt='home' />;
    }
  };

  return (
    <div
      className={styles[subTab ? 'side-class-menu-subTab' : 'side-class-menu']}
      key={tab.id}
    >
      <div
        className={classNames(
          styles['side-class-menu-wrapper'],
          isActive && styles['wrapper-active']
        )}
        onClick={onClick}
      >
        <div className={styles['side-class-menu-left']}>
          <div className={styles['side-class-menu-icon-wrapper']}>
            <div style={{ marginLeft: (24 - tab.iconWidth) / 2 }}>
              <Icon color={isActive ? '#35B5C2' : '#EEEEEE'} />
            </div>
          </div>
          <div className={styles['side-class-menu-title']}>{tab.text}</div>
        </div>
        {tab.subTabs && (
          <KeyboardArrowDownIcon
            fontSize='medium'
            style={{
              transform: `rotate(${open ? 180 : 0}deg)`,
              color: '#ffffff',
            }}
          />
        )}
      </div>
    </div>
  );
};

const ManagingSidebarTap = ({ groupId }) => {
  const [openTabs, setOpenTabs] = useState([]);
  const history = useHistory();
  const { state } = useLocation();
  const managingSchoolGroup = useSelector((state) =>
    selectManagingGroup(state)
  );
  // const group = useSelector((state) => selectGroupById(state, groupId));
  // const afterSchoolGroup = useSelector((state) =>
  //   selectAfterSchoolGroup(state)
  // );

  const toggleTab = (tabId) => {
    setOpenTabs((prev) => {
      if (prev.includes(tabId)) {
        return prev.filter((_tabId) => _tabId !== tabId);
      }
      return prev.concat(tabId);
    });
  };

  const getIsOpen = (tabId) => {
    return openTabs.includes(tabId);
  };

  return (
    <div className={styles['side-class-menu-wrap']}>
      {tabs.map((tab) => {
        return (
          <div key={tab.id}>
            <TabItem
              tab={tab}
              onClick={() => {
                if (tab.subTabs) {
                  toggleTab(tab.id);
                } else {
                  if (history.location.pathname === tab.path) {
                    return;
                  }
                  if (!tab.path) {
                    return;
                  }
                  if (tab.path.includes('/managing')) {
                    tab.path = `/managing/${groupId}`;
                  }
                  if (tab.path.includes('/students-managed')) {
                    tab.path = `/students-managed/${groupId}`;
                  }

                  history.push({
                    pathname: tab.path,
                    state,
                  });
                }
              }}
              open={getIsOpen(tab.id)}
              isActive={history.location.pathname === tab.path}
            />
            {tab.subTabs && getIsOpen(tab.id) && (
              <div style={{ background: '#090909', margin: 16 }}>
                {tab.subTabs.map((subTab) => {
                  return (
                    <TabItem
                      tab={subTab}
                      onClick={() => {}}
                      subTab
                      isActive={history.location.pathname === subTab.path}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
      {/* <div className={styles["side-class-menu"]}>{classes(allMyGroups)}</div> */}
    </div>
  );
};

export default ManagingSidebarTap;
