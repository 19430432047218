import { createPromiseAction } from "@adobe/redux-saga-promise";
import {
  SET_COMMENTS_STUDENT,
  SET_COMMENTS_LAYER_PATH,
  SET_COMMENTS_SEND_CLIENT,
  SET_COMMENTS_SHARED_SCREEN,
  SET_COMMENTS_STROKE_COLOR,
  GET_COMMENTS_LAYER_SUCCESS,
  GET_COMMENTS_LAYER_FAIL,
  CREATE_COMMENTS_LAYER_SUCCESS,
  CREATE_COMMENTS_LAYER_FAIL,
  UPDATE_COMMENTS_LAYER_SUCCESS,
  UPDATE_COMMENTS_LAYER_FAIL,
  CREATE_COMMENTARY_SUCCESS,
  CREATE_COMMENTARY_FAIL,
  UPDATE_COMMENTARY_SUCCESS,
  UPDATE_COMMENTARY_FAIL,
} from "./actionTypes";

export const setCommentsSendClient = (payload) => ({
  type: SET_COMMENTS_SEND_CLIENT,
  payload,
});
export const setCommentsLayerPath = (payload) => ({
  type: SET_COMMENTS_LAYER_PATH,
  payload,
});
export const setCommentsStudent = (payload) => ({
  type: SET_COMMENTS_STUDENT,
  payload,
});
export const setCommentsSharedScreen = (payload) => ({
  type: SET_COMMENTS_SHARED_SCREEN,
  payload,
});
export const setCommentsStrokeColor = (payload) => ({
  type: SET_COMMENTS_STROKE_COLOR,
  payload,
});
export const getCommentsLayerPromise = createPromiseAction(
  "GET_COMMENTS_LAYER_PROMISE"
);
export const getCommentsLayerSuccess = ({ commentsLayers }) => ({
  type: GET_COMMENTS_LAYER_SUCCESS,
  payload: {
    commentsLayers,
  },
});
export const getCommentsLayerFail = (error) => ({
  type: GET_COMMENTS_LAYER_FAIL,
  payload: error,
});

export const createCommentsLayerPromise = createPromiseAction(
  "CREATE_COMMENTS_LAYER_PROMISE"
);
export const createCommentsLayerSuccess = (payload) => ({
  type: CREATE_COMMENTS_LAYER_SUCCESS,
  payload,
});
export const createCommentsLayerFail = (error) => ({
  type: CREATE_COMMENTS_LAYER_FAIL,
  payload: error,
});
export const updateCommentsLayerPromise = createPromiseAction(
  "UPDATE_COMMENTS_LAYER_PROMISE"
);
export const updateCommentsLayerSuccess = (payload) => ({
  type: UPDATE_COMMENTS_LAYER_SUCCESS,
  payload,
});
export const updateCommentsLayerFail = (error) => ({
  type: UPDATE_COMMENTS_LAYER_FAIL,
  payload: error,
});

export const createCommentaryPromise = createPromiseAction(
  "CREATE_COMMENTARY_PROMISE"
);
export const createCommentarySuccess = (payload) => ({
  type: CREATE_COMMENTARY_SUCCESS,
  payload,
});
export const createCommentaryFail = (error) => ({
  type: CREATE_COMMENTARY_FAIL,
  payload: error,
});
export const updateCommentaryPromise = createPromiseAction(
  "UPDATE_COMMENTARY_PROMISE"
);
export const updateCommentarySuccess = (payload) => ({
  type: UPDATE_COMMENTARY_SUCCESS,
  payload,
});
export const updateCommentaryFail = (error) => ({
  type: UPDATE_COMMENTARY_FAIL,
  payload: error,
});
