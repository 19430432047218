import {
  Body2,
  CaptionMedium,
  Column,
  HorizontalSpace8,
  InputWhite,
  InputWhiteScroll,
  Row,
  VerticalSpace8,
} from '@app/pages/QuizPang/common'
import { Select } from '@app/pages/QuizPang/select'
import MenuItem from '@mui/material/MenuItem'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Button } from '@mui/material'
import AddIcon from '@mui/icons-material/Add'
import * as React from 'react'
import styled from 'styled-components'
import { observer } from 'mobx-react'

export const MultipleChoices = observer(({ store }) => {
  return <Column>
    <Row style={{width: 628, display:'flex', justifyContent:'space-between'}}>
      <Body2 style={{ flex: 1 }}>보기</Body2>
      <Body2 style={{ width: 144 }}>배점</Body2>
    </Row>
    <VerticalSpace8/>
    {store.choices.map((x, i) => <React.Fragment key={i}>
      <Row style={{width: 628}}>
        <CaptionMedium>{i + 1}번</CaptionMedium>
        <HorizontalSpace8/>
        <HorizontalSpace8/>
        {/* <InputWhite
          placeholder={`${i + 1}번 보기 입력`}
          value={x.description}
          onChange={(e) => store.setChoice(i, e.target.value, x.point)}
        /> */}
        <InputWhiteScroll
          placeholder={`${i + 1}번 보기 입력`}
          value={x.description}
          onChange={(e) => store.setChoice(i, e.target.value, x.point)}
          style={{width: 440}}
          rows={1}
        />
        <HorizontalSpace8/>
        <Score value={x.point}
               setValue={(v) => store.setChoice(i, x.description, v)}/>
        <HorizontalSpace8/>
        <DeleteIconContainer onClick={() => store.deleteChoice(i)}>
          <RemoveCircleOutlineIcon sx={{ color: '#FF1744' }}/>
        </DeleteIconContainer>
      </Row>
      <VerticalSpace8/>
    </React.Fragment>)}
    <Row>
      <Button startIcon={<AddIcon/>} onClick={() => store.addChoice()}>
        보기 추가
      </Button>
    </Row>
  </Column>
})

export const ShortAnswerChoices = observer(({ store }) => {
  return <Column>
    <Row style={{width: 628, display:'flex', justifyContent:'space-between'}}>
      <Body2 style={{ flex: 1 }}>정답</Body2>
      <Body2 style={{ width: 144 }}>배점</Body2>
    </Row>
    <VerticalSpace8/>
    {store.choices.map((x, i) => <React.Fragment key={i}>
      <Row style={{width: 628}}>
        {/* <InputWhite
          placeholder="단답형 정답 입력"
          value={x.description}
          onChange={(e) => store.setChoice(i, e.target.value, x.point)}
        /> */}
        <InputWhiteScroll
          placeholder="단답형 정답 입력"
          value={x.description}
          onChange={(e) => store.setChoice(i, e.target.value, x.point)}
          style={{width: 480}}
          rows={1}
        />
        <HorizontalSpace8/>
        <Score value={x.point}
               setValue={(v) => store.setChoice(i, x.description, v)}/>
        <HorizontalSpace8/>
        <DeleteIconContainer onClick={() => store.deleteChoice(i)}>
          <RemoveCircleOutlineIcon sx={{ color: '#FF1744' }}/>
        </DeleteIconContainer>
      </Row>
      <VerticalSpace8/>
    </React.Fragment>)}
    <Row>
      <Button startIcon={<AddIcon/>} onClick={() => store.addChoice()}>
        정답 추가
      </Button>
    </Row>
  </Column>
})

export const TrueFalseChoices = observer(({ store }) => {
  return <Column>
    <Row style={{width: 628, display:'flex', justifyContent:'space-between'}}>
      <Body2 style={{ flex: 1 }}>보기</Body2>
      <Body2 style={{ width: 144 }}>배점</Body2>
    </Row>
    <VerticalSpace8/>
    {store.choices.map((x, i) => <React.Fragment key={i}>
      <Row style={{width: 628}}>
        <InputWhite value={x.description} disabled />
        <HorizontalSpace8/>
        <Score value={x.point}
               setValue={(v) => store.setChoice(i, x.description, v)}/>
        <HorizontalSpace8 style={{ marginRight: 40 }}/>
      </Row>
      <VerticalSpace8/>
    </React.Fragment>)}
  </Column>
})

const Score = ({ value, setValue }) => {
  return <Select width={96} value={value}
                 onChange={(e) => setValue(e.target.value)}>
    {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50]
    .map(x =>
      <MenuItem key={x} value={x}>{x}점</MenuItem>,
    )}
  </Select>
}

const DeleteIconContainer = styled(Row)`
  padding: 8px;

  :hover {
    cursor: pointer;
  }
`
