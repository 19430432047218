import React, { useEffect, useState } from 'react';
import Sidebar from '@components/Layout/Sidebar';
import {
  HeaderSection,
  HeaderSectionHeading,
  HorizontalSpace8,
  MainContentLayer,
  SideBarRest,
} from '@app/pages/QuizPang/common';
import { Button, Tab, Tabs } from '@mui/material';
import SchoolIcon from '@mui/icons-material/School';
import { QuizTab } from '@app/pages/QuizPang/quiz-tab';
import { QuizsetTab } from '@app/pages/QuizPang/quizset-tab';
import { useHistory } from 'react-router-dom';
import { useQuery } from '@app/infra/hooks';

const QuizPang = () => {
  const query = useQuery();
  const tab = query.get('tab');
  const [tabIndex, setTabIndex] = useState(0);
  useEffect(() => {
    setTabIndex(tab === 'quiz' ? 1 : 0);
  }, [tab]);
  const history = useHistory();
  return (
    <div className="flex w-100vw h-100vh">
      <Sidebar auth={true} />
      <SideBarRest>
        <MainContentLayer>
          <HeaderSection>
            <HeaderSectionHeading>퀴즈팡 관리</HeaderSectionHeading>
            <Tabs value={tabIndex}>
              <Tab
                label="관리 홈"
                onClick={() => {
                  setTabIndex(0);
                  history.push({ search: '?tab=quizset' });
                }}
              />
              <Tab
                label="퀴즈용 문제"
                onClick={() => {
                  setTabIndex(1);
                  history.push({ search: '?tab=quiz' });
                }}
              />
            </Tabs>
            {/* <HorizontalSpace8 />
            <HorizontalSpace8 /> */}
            {/* <Button
              startIcon={<SchoolIcon />}
              onClick={() => history.push('/quiz-pang/other-school')}
            >
              커뮤니티
            </Button> */}
          </HeaderSection>
          {tabIndex === 0 ? <QuizsetTab /> : <QuizTab />}
        </MainContentLayer>
      </SideBarRest>
    </div>
  );
};

export default QuizPang;
