import React, { useEffect, useState } from 'react';
import {
  Body2,
  Column,
  Heading6,
  Row,
  Subtitle1,
} from '@app/pages/QuizPang/common';
import { Spinner } from '@app/pages/QuizPang/spinner';
import styled from 'styled-components';
import rightIcon from '@images/quiz-pang/right.png';
import wrongIcon from '@images/quiz-pang/wrong.png';
import { useElementSize } from 'usehooks-ts';
import { QuizsetSessionResultRankingItem } from '@app/pages/QuizPang/InClass/quizset-session-result-ranking';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { Button } from '@mui/material';
import { getQuizsetSessionResultPromise } from '@app/store/quizsetSession/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setSocketData } from '@app/store/actions';
import { finishQuizsetSession } from '@api'
import Loading from '@app/components/Loading/Loading';

export const QuizSessionResult = ({ sessionId, index, isLast, quizId }) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const location = useRouteMatch();
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const result = useSelector(
    (state) => state.quizsetSession.resultsById[sessionId]
  );
  // const activeClassroom = useSelector((state) => state.classrooms.classSession);
  const session = useSelector(
    (state) => state.quizsetSession.sessionsById[sessionId]
  );
  const [isLoading, setIsLoading] = useState(true);

  const quizsetSession = session.quizsetSession;
  useEffect(() => {
    // delay 2s for server to process students result
    setTimeout(() => {
      dispatch(getQuizsetSessionResultPromise({
        quizsetSessionId: sessionId,
        quizIndex: index,
      })).then(() => {
        setIsLoading(false);
      });
    }, 2000);
  }, [dispatch, sessionId, index]);

  const nextQuiz = () => {
    const newUrl = location.url.replace(/\d+\/result/, '') + String(index + 1);
    history.replace(newUrl);
    const quizNextData =
      quizsetSession.quizsetSessionId + ',' + String(index + 1);

    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: groupId,
        clientId: user.clientId,
        type: 'QUIZ_NEXT',
        data: quizNextData,
      })
    );
  };

  const quizEnd = () => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: groupId,
        clientId: user.clientId,
        type: 'QUIZ_END',
        data: '',
      })
    );
  };

  useEffect(() => {
    if (isLast && sessionId) {
      finishQuizsetSession(sessionId);
    }
  }, [isLast, sessionId]);

  return (
    <Row style={{ marginLeft: 48, marginTop: 32, alignItems: 'start' }}>
      {isLoading && <Loading/>}
      <Column style={{ width: 700, marginRight: 40 }}>
        <Row
          style={{
            justifyContent: 'space-between',
            marginBottom: 24,
            height: 48,
          }}
        >
          <Heading6>퀴즈 결과</Heading6>
          {!isLast ? (
            <Row>
              {!isLoading && session.quizset.isAutoPassed ? (
                <>
                  <Heading6 style={{ marginRight: 16 }}>다음 퀴즈까지</Heading6>
                  <Spinner start={10} onZero={nextQuiz} isSmall />
                </>
              ) : (
                <Button onClick={nextQuiz}>다음 퀴즈</Button>
              )}
            </Row>
          ) : (
            <Row>
              <Button
                onClick={() => {
                  finishQuizsetSession(sessionId);
                  history.replace(`/quiz-pang/${groupId}`);
                  quizEnd();
                }}
              >
                퀴즈팡 끝내기
              </Button>
            </Row>
          )}
        </Row>
        <Column>
          {result &&
            result.quizresults.map((x, i) => (
              <QuizResult data={x} key={i} />
            ))}
        </Column>
      </Column>
      <Column
        style={{
          justifyContent: 'flex-start',
          minHeight: '100%',
          marginTop: 16,
        }}
      >
        <Heading6 style={{ marginBottom: 16 }}>순위</Heading6>
        {result &&
          result.leaderboards.map((x, i) => (
            <QuizsetSessionResultRankingItem data={x} index={i + 1} rank={x.rank} />
          ))}
      </Column>
    </Row>
  );
};

const QuizResult = ({ data }) => {
  const totalParticipants = data.choices.reduce(
    (sum, choice) => sum + choice.chooserCount,
    0
  );
  return (
    <Column style={{ width: '100%' }}>
      {/* <Subtitle1 style={{fontWeight: 500,marginBottom: 16,width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}>
        {data.title}
      </Subtitle1> */}
      <Subtitle1 
        dangerouslySetInnerHTML={{ __html: data.title }}
        style={{fontWeight: 500,marginBottom: 16,width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}
      />
      {data.choices.map((x) => (
        <QuizResultChoice
          data={x}
          key={x.choiceId}
          totalParticipants={totalParticipants}
        />
      ))}
      {data.description?.length > 0 ? (
        <>
          <Heading6 style={{ marginTop: 24, marginBottom: 8 }}>
            해설</Heading6>
          <Body2 style={{ marginBottom: 40, width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
            {data.description}</Body2>
        </>
      ) : (
        <div style={{ height: 24 }} />
      )}
    </Column>
  );
};

const QuizResultChoice = ({ data, totalParticipants }) => {
  const [ref, { width }] = useElementSize();
  const isRight = data.point > 0;
  const percentage = (data.chooserCount / totalParticipants) || 0;
  const innerWidth = Math.min(width * percentage, width - 10);
  return (
    <Column style={{ width: '100%', marginBottom: 16 }}>
      <Body2 style={{ marginBottom: 8,width: 700, wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
        {data.index}. {data.description === "Wrong Answer" ? "오답" : data.description} ({data.point}점)
      </Body2>
      <ChoiceBarOuter ref={ref}>
        <ChoiceBarInner
          style={{
            backgroundColor: isRight ? '#388E3C' : '#fff',
            minWidth: innerWidth,
          }}
        >
          <img
            src={isRight ? rightIcon : wrongIcon}
            style={{ marginRight: 8, width: 20, height: 20 }}
          />
          <Body2 style={{ color: isRight ? '#fff' : 'rgba(0, 0, 0, 0.87)' }}>
            {data.chooserCount}명 ({Math.round(percentage * 100)}%)
          </Body2>
        </ChoiceBarInner>
      </ChoiceBarOuter>
    </Column>
  );
};

const ChoiceBarOuter = styled(Row)`
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  background-color: #f5f5f5;
  padding: 4px;
`;

const ChoiceBarInner = styled(Row)`
  box-shadow: 0 0 6px rgba(0, 0, 0, 0.02), 0 2px 4px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  padding: 6px 12px;
`;
