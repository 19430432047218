import React from 'react';
import styled from 'styled-components';
import {
  Body2,
  Caption,
  CaptionMedium,
  Column,
  HorizontalSpace16,
  Row,
  Subtitle2,
} from '@app/pages/QuizPang/common';
import moment from 'moment';
import avatar from '@images/quiz-pang/avatar.png';

const ROW_WIDTHS = [72, 48, 120, 114];
const FULLHD_ROW_WIDTHS = [72, 48, 136, 200];

//해상도가 1920*1080 이상일 때에는 WIDTHS함수에 FULLHD_ROW_WIDTHS를 넣어준다. 그렇지 않다면 ROW_WIDTHS를 넣어준다.
const WIDTHS = window.innerWidth >= 1600 ? FULLHD_ROW_WIDTHS : ROW_WIDTHS;

console.log('WIDTHS', WIDTHS);

const LeaderBoardItem = ({ data, rank, total }) => {
  return (
    <LeaderBoardItemContainer>
      <Column style={{ width: WIDTHS[0] }}>
        <Body2Normal>{data.studentNumber}</Body2Normal>
      </Column>
      <Column style={{ width: WIDTHS[1] }}>
        <AvatarImg src={avatar} />
      </Column>
      <Column style={{ width: WIDTHS[2] }}>
        <Body2Normal
          style={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {data.studentName}
        </Body2Normal>
      </Column>
      <Column style={{ width: WIDTHS[3] }}>
        <Body2Normal>{data.participationCount}회</Body2Normal>
      </Column>
      <Row style={{ flex: 1 }}>
        <Body2Normal>{rank}위</Body2Normal>
        <HorizontalSpace16 />
        <AnswerCountText>{data.correctAnswerCount}문제 정답</AnswerCountText>
        <HorizontalSpace16 />
        <ScoreText>{data.score}점</ScoreText>
      </Row>
    </LeaderBoardItemContainer>
  );
};

export const QuizsetSessionHistory = ({ data }) => {
  return (
    <Container>
      <Row style={{ marginBottom: 16 }}>
        <Body2 style={{ marginRight: 16 }}>
          {moment(data.startAt).format('YYYY-MM-DD HH:mm')}
        </Body2>
        <Body2Normal>{data.quizsetTitle}</Body2Normal>
      </Row>
      <Header>
        <Column style={{ width: WIDTHS[0] }}>
          <HeaderText>학번</HeaderText>
        </Column>
        <Column style={{ width: WIDTHS[1] }} />
        <Column style={{ width: WIDTHS[2] }}>
          <HeaderText>이름</HeaderText>
        </Column>
        <Column style={{ width: WIDTHS[3] }}>
          <HeaderText>퀴즈팡 참여 횟수</HeaderText>
        </Column>
        <Column style={{ flex: 1 }}>
          <HeaderText>순위</HeaderText>
        </Column>
      </Header>
      <Column>
        {data.leaderboard.map((x, i) => (
          <LeaderBoardItem
            key={i}
            data={x}
            rank={x.rank}
            total={data.leaderboard.length}
          />
        ))}
      </Column>
    </Container>
  );
};

const Container = styled(Column)`
  margin-top: 24px;
`;

const Body2Normal = styled(Body2)`
  color: rgba(0, 0, 0, 0.87);
`;

const Header = styled(Row)`
  background-color: #424242;
  border-radius: 4px 4px 0 0;
  padding: 10px 16px;
`;

const HeaderText = styled(CaptionMedium)`
  color: #fafafa;
`;

const LeaderBoardItemContainer = styled(Row)`
  flex: 1;
  padding: 14px 16px;
  border-bottom: 1px solid #eeeeee;

  //fullHd이하 해상도 대응
  @media (max-width: 1920px) {
    padding: 12px 16px;
  }

  // tablet 해상도 대응
  @media (max-width: 1024px) {
    padding: 10px 16px;
  }
`;

const AnswerCountText = styled(Caption)`
  color: rgba(0, 0, 0, 0.6);
`;

const ScoreText = styled(Subtitle2)`
  color: #056b75;
`;

const AvatarImg = styled.img`
  width: 40px;
  height: 40px;
`;
