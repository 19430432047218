import React from 'react';
import Sidebar from '@components/Layout/Sidebar';
import {
  ArrowBackIconContainer,
  BottomContentLayer,
  Divider,
  HeaderSection,
  HeaderSectionHeading,
  HorizontalSpace16,
  MainContentLayer,
  SideBarRest,
} from '@app/pages/QuizPang/common';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { selectClientQuizset } from '@app/store/quizset/selector';
import { useDispatch, useSelector } from 'react-redux';
import { QuizsetTimeInfo } from '@app/pages/QuizPang/quizset-card';
import { Button } from '@mui/material';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import { QuizCard } from '@app/pages/QuizPang/quiz-card';
import { QuizsetOptionSwitch } from '@app/pages/QuizPang/quizset-option-switch';
import { createQuizsetSessionPromise } from '@app/store/quizsetSession/actions';
import moment from 'moment/moment';
import { setSocketData } from '@app/store/actions';
import { CatchingPokemonSharp } from '@mui/icons-material';
import { selectActiveClassroom } from '@app/store/selectors';

function handleMouseOver(event) {
  event.target.style.backgroundColor = '#05869225';
}
function handleMouseOut(event) {
  event.target.style.backgroundColor = 'transparent';
}

const QuizsetDetail = () => {
  const dispatch = useDispatch();
  const { groupId, quizsetId } = useParams();
  const history = useHistory();
  const location = useRouteMatch();
  const clientId = useSelector((state) => state.user.clientId);
  const quizset = useSelector((store) => selectClientQuizset(store, quizsetId));
  const activeClassroom = useSelector((state) => selectActiveClassroom(state))

  return (
    <div className="flex w-100vw h-100vh">
      <Sidebar inClassroom />
      <SideBarRest>
        <MainContentLayer>
          <HeaderSection>
            <ArrowBackIconContainer onClick={() => history.goBack()}>
              <ArrowBackIcon
                onMouseOver={handleMouseOver} 
                onMouseOut={handleMouseOut}  
                style={{color:'#0894A0'}} 
              />
            </ArrowBackIconContainer>
            <HeaderSectionHeading style={{ marginRight: 0 }}>
              {quizset.title} ({quizset.quizzes.length}개의 문제)
            </HeaderSectionHeading>
            <HorizontalSpace16 />
            <QuizsetTimeInfo data={quizset} />
            <HorizontalSpace16 />
            {activeClassroom &&
            <Button
              onClick={async () => {
                const res = await dispatch(
                  createQuizsetSessionPromise({
                    clientId,
                    groupId,
                    quizsetId,
                    startAt: moment().toISOString(),
                  })
                );
                if (res) {
                  const sessionId = res.quizsetSession.quizsetSessionId;
                  history.push(`${location.url}/session/${sessionId}/0`);
                  dispatch(
                    setSocketData({
                      method: 'POST',
                      uri: '/classroom/sendImage',
                      type: 'QUIZ_START',
                      clientId: clientId,
                      groupId: groupId,
                      data: res.quizsetSession.quizsetSessionId,
                    })
                  );
                }
              }}
              variant="contained"
              endIcon={<PlayCircleIcon />}
              style={{ marginLeft: 8 }}
            >
              퀴즈 시작
            </Button>}
          </HeaderSection>
          <HeaderSection style={{ paddingBottom: 16 }}>
            <QuizsetOptionSwitch quizset={quizset} />
          </HeaderSection>
          <Divider />
          <BottomContentLayer>
            {quizset.quizzes.map((x) => (
              <QuizCard key={x.quizId} data={x} />
            ))}
          </BottomContentLayer>
        </MainContentLayer>
      </SideBarRest>
    </div>
  );
};

export default QuizsetDetail;
