import { call, put, takeLeading, takeEvery } from 'redux-saga/effects';
import {
  getGroupFilelinkPromise,
  getGroupFilelinkSuccess,
  getGroupFilelinkFail,
  createFilelinkPromise,
  deleteFilelinkPromise,
  createFilelinkSuccess,
  deleteFilelinkSuccess,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getGroupFilelinkPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getGroupFilelink, action.payload);
      yield put(getGroupFilelinkSuccess(response.data.filelinks));
      return yield response.data;
    } catch (error) {
      yield put(getGroupFilelinkFail(error));
    }
  });
}

function* createFilelinkPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createFilelink, action.payload);
      yield put(createFilelinkSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getGroupFilelinkFail(error));
    }
  });
}

function* deleteFilelinkPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteFilelink, action.payload);
      yield put(deleteFilelinkSuccess(action.payload));
      return yield response.data;
    } catch (error) {
      yield put(getGroupFilelinkFail(error));
    }
  });
}

function* filelinkSaga() {
  yield takeLeading(createFilelinkPromise, createFilelinkPromiseHandler);
  yield takeEvery(getGroupFilelinkPromise, getGroupFilelinkPromiseHandler);
  yield takeLeading(deleteFilelinkPromise, deleteFilelinkPromiseHandler);
}

export default filelinkSaga;
