import * as React from 'react'
import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import styled from 'styled-components'
import {
  BaseText,
  Body2,
  Column,
  Heading6,
  HorizontalSpace8,
  InputGray,
  InputGrayScroll,
  InputWhite,
  InputWhiteScroll,
  Row,
  TabButtonFocusedSmall,
  TabButtonSmall,
  VerticalSpace8,
  VerticalSpace4,
  Subtitle1,
  Subtitle3
} from '@app/pages/QuizPang/common'
import { Button } from '@mui/material'
import PlayCircleIcon from '@mui/icons-material/PlayCircle'
import { Select } from '@app/pages/QuizPang/select'
import MenuItem from '@mui/material/MenuItem'
import Checkbox from '@mui/material/Checkbox'
import DeleteIcon from '@mui/icons-material/Delete'
import {
  MultipleChoices,
  ShortAnswerChoices,
  TrueFalseChoices,
} from '@app/pages/QuizPang/quiz-modal-choices'
import { observer } from 'mobx-react'
import {
  quizTypes,
  quizTypeToText,
} from '@app/pages/QuizPang/quiz-modal-store'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import CloseIcon from '@mui/icons-material/Close'
import { CategoryModal } from '@app/pages/QuizPang/category-modal'
import { SubjectModal } from './subject-modal'
import { categoryStore } from '@app/pages/QuizPang/category-store'
import { subjectStore } from '@app/pages/QuizPang/subject-store'
import { useDispatch, useSelector } from 'react-redux'
import { getQuizImageKey, resolveImgPath, uploadImage } from '@api'
import { createQuizPromise, getQuizCategoryAllPromise, updateQuizPromise } from '@app/store/quiz/actions'
import { Image, ImageContainer } from '@app/pages/QuizPang/quiz-image'
import { QuizPreview } from '@app/pages/QuizPang/quiz-preview'
import AddIcon from '@mui/icons-material/Add'
import InfoIcon from '@app/assets/images/icons/info.svg';
import { TextEditor } from '@app/components/Input/TextEditor';

const Modify = observer(({ store }) => {
  const dispatch = useDispatch();
  const [categoryModal, setCategoryModal] = useState(false)
  const [subjectModal, setSubjectModal] = useState(false);
  const [infoButton, setInfoButton] = useState(false);
  const [infoButton2, setInfoButton2] = useState(false);
  const [infoButton3, setInfoButton3] = useState(false);
  const quizPublisher = useSelector((state) => state.quiz.quizCategoryAll);
  const [selectPublisher, setSelectPublisher] = useState('교과서 출판사 선택');
  const [body, setBody] = useState('');
  
  useEffect(() => {
    dispatch(getQuizCategoryAllPromise())
    .then((res) => console.log('[QuizCategoryAll response]'))
    .catch((error) => console.log('[QuizCategoryAll error]', error));
  }, []);

  return <>
    <Column 
      // style={{ flex: 1, alignItems: 'center' }}
    >
      <Subtitle3 style={{fontWeight:0}}>1. 퀴즈용 문제의 질문과 지문을 입력해 주세요. 나중에 수정할 수 있습니다.</Subtitle3>
      <VerticalSpace4 />
      {/* <InputGray placeholder="문제 질문 입력"
                 style={{ width: 700 }}
                 value={store.title}
                 onChange={(e) => store.setTitle(e.target.value)}
      /> */}
      <div style={{ width: 700 }}>
        <TextEditor
          value={store.title}
          onChange={(html) => store.setTitle(html)}
          isFile={false} 
        />
      </div>
      <VerticalSpace8/><VerticalSpace8/>
      <Subtitle3>2. 이미지를 포함할 수 있습니다. (선택)</Subtitle3>
      <VerticalSpace4 />
      <Column style={{ width: 480 }}>
        {/* <Body2>이미지 첨부</Body2> */}
        {/* <VerticalSpace8/> */}
        <Row>
          <ImageContainer style={{ width: store.imgPath == null && 100, height: store.imgPath == null && 100}}>
            {store.imgPath !== null && <Image src={store.imgPath}/>}
            {store.imgPath !== null ?
              <ImageOverlayContainer>
                <Button
                  variant="contained"
                  startIcon={<DeleteIcon sx={{ color: '#FF5252' }}/>}
                  style={{ color: '#FF5252', background: '#fff' }}
                  onClick={() => store.removeImage()}
                >
                  이미지 삭제
                </Button>
              </ImageOverlayContainer> :
              <ImagePlaceholder htmlFor="quizImagePicker">
                <input
                  id="quizImagePicker"
                  accept="image/*"
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => store.setImageFile(e.target.files[0])}
                />
                <ImageUploadText style={{display:'flex', alignItems: 'center'}}>
                  <AddIcon style={{marginRight: 4}}/>
                  {/* 이미지 파일 선택하기 */}
                </ImageUploadText>
              </ImagePlaceholder>
            }
          </ImageContainer>
        </Row>
      </Column>
      <VerticalSpace8/>
      <VerticalSpace8/>
      <Subtitle3>3. 응답 유형과 응답 제한 시간을 선택해 주세요.</Subtitle3>
      <VerticalSpace4 />
      <Row style={{}}>
          <Row>
            <Select width={150} value={store.type}
                    onChange={(e) => store.setType(e.target.value)}>
              {quizTypes?.map(x =>
                <MenuItem key={x} value={x}>{quizTypeToText[x]}</MenuItem>,
              )}
            </Select>
          </Row>
          <HorizontalSpace8/>
          <Row>
            <Select width={140} value={store.timeLimit}
                    onChange={(e) => store.setTimeLimit(e.target.value)}>
              {[5, 10, 30, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600].map(x =>
                <MenuItem key={x} value={x}>{x >= 60 ? x / 60 + '분' : x + '초'}</MenuItem>,
              )}
            </Select>
          </Row>

        {/* <Column>
          <Body2 style={{ flex: 1 }}>문제유형</Body2>
          <VerticalSpace8/>
          <Row style={{marginLeft: -8}}>
            {quizTypes?.map(x => {
              const TabButton = store.type === x ? TabButtonFocusedSmall : TabButtonSmall
              return <React.Fragment key={x}>
                <HorizontalSpace8/>
                <TabButton
                  onClick={() => store.setType(x)}
                  style={{backgroundColor: store.type == x && '#0BA1AE', color: store.type == x && '#fff'}}
                >
                  {quizTypeToText[x]}
                </TabButton>
              </React.Fragment>
            })}
          </Row>
        </Column>
        <Column style={{marginLeft: 10}}>
          <Body2 style={{ flex: 1 }}>제한시간</Body2>
          <VerticalSpace8/>
          <Row>
            <Select width={145} value={store.timeLimit}
                    onChange={(e) => store.setTimeLimit(e.target.value)}>
              {[5, 10, 30, 60, 120, 180, 240, 300, 360, 420, 480, 540, 600].map(x =>
                <MenuItem key={x} value={x}>{x >= 60 ? x / 60 + '분' : x + '초'}</MenuItem>,
              )}
            </Select>
          </Row>
        </Column> */}
      </Row>
      <VerticalSpace8/>
      <VerticalSpace8/>
      {
        {
          MULTIPLE: <Subtitle3>4. 학생이 선택할 보기를 입력한 후 점수를 넣어주세요. 자동으로 채점이 됩니다.</Subtitle3>,
          SHORT: <Subtitle3>4. 정답을 입력한 후 점수를 넣어주세요. 자동으로 채점이 됩니다.</Subtitle3>,
          OX: <Subtitle3>4. 정답인 보기에 점수를 넣어주세요. 자동으로 채점이 됩니다.</Subtitle3>,
        }[store.type]
      }
      <VerticalSpace4 />
      {
        {
          MULTIPLE: <MultipleChoices store={store}/>,
          SHORT: <ShortAnswerChoices store={store}/>,
          OX: <TrueFalseChoices store={store}/>,
        }[store.type]
      }
      <VerticalSpace8/>
      <VerticalSpace8/>
      <Subtitle3>5. 보충설명을 넣어주시면 학생이 응답을 한 후에 제시됩니다. (선택)</Subtitle3>
      <VerticalSpace4 />
      <Column>
        {/* <Body2 style={{ flex: 1 }}>보충설명 (선택)</Body2>
        <VerticalSpace8/> */}
          {/* <InputWhite placeholder="보충 설명을 입력해주세요."
                      value={store.description}
                      onChange={(e) => store.setDescription(e.target.value)}
                      style={{width: 480}}
          /> */}
          <InputWhiteScroll placeholder="보충 설명을 입력해주세요."
                      value={store.description}
                      onChange={(e) => store.setDescription(e.target.value)}
                      style={{width: 628}}
                      rows={1}
          />
          {/* todo: 보충설명도 html박스로 수정하기
          <div style={{ width: 700 }}>
            <TextEditor
              value={store.title}
              onChange={(html) => store.setTitle(html)}
              isFile={false} 
            />
          </div> */}
      </Column>
      <VerticalSpace8/>
      <VerticalSpace8/>
      <Subtitle3>6. 문제의 카테고리를 선택해 주시면 검색 시 카테고리를 적용하여 검색할 수 있습니다. (선택)</Subtitle3>
      <VerticalSpace4 />
      <Row style={{ marginBottom: 10 }}>
        <Select width={110} value={store.grade}
                onChange={(e) => store.setGrade(e.target.value)}
        >
          {[1, 2, 3].map(x =>
            <MenuItem key={x} value={x}>{x}학년</MenuItem>,
          )}
        </Select>
        <HorizontalSpace8/>
        <Select width={160} value={store.subject}
                onChange={(e) => store.setSubject(e.target.value)}
        >
          <MenuItem value={-1}>과목 선택</MenuItem> 
          {[...new Set([store.subject, ...subjectStore.data].filter(x => x !== -1))]?.map(x =>
            <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
          )}
          <Button endIcon={<AddCircleIcon/>}
                  onClick={() => setSubjectModal(true)}
                  style={{
                    fontSize: 16,
                    lineHeight: '24px',
                    width: '100%',
                    padding: '6px 16px',
                  }}
          >
            과목 추가
          </Button>
        </Select>
        <HorizontalSpace8/>
        <Select width={170} value={selectPublisher}
                onChange={(e) => {
                  store.setCategory(e.target.value);
                  setSelectPublisher(e.target.value);
                }}
        >
          <MenuItem value={'교과서 출판사 선택'}>교과서 출판사 선택</MenuItem> 
          <MenuItem value={'해당 없음'}>해당 없음</MenuItem>
          {Array.isArray(quizPublisher) ? quizPublisher.map(x =>
            <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>
          ) : null}
          <Button endIcon={<AddCircleIcon/>}
                  onClick={() => setCategoryModal(true)}
                  style={{
                    fontSize: 16,
                    lineHeight: '24px',
                    width: '100%',
                    padding: '6px 16px',
                  }}
          >
            교과서 출판사 추가
          </Button>
        </Select>
        {/* <Select width={170} value={store.category}
                onChange={(e) => store.setCategory(e.target.value)}
        >
          <MenuItem value={-1}>교과서 출판사 선택</MenuItem> 
          <MenuItem value={'해당 없음'}>해당 없음</MenuItem>
          {[...new Set([store.category, ...categoryStore.data].filter(x => x !== -1))]?.map(x =>
            <MenuItem style={{maxWidth: 300}} key={x} value={x}>{x}</MenuItem>,
          )}
          <Button endIcon={<AddCircleIcon/>}
                  onClick={() => setCategoryModal(true)}
                  style={{
                    fontSize: 16,
                    lineHeight: '24px',
                    width: '100%',
                    padding: '6px 16px',
                  }}
          >
            교과서 출판사 추가
          </Button>
        </Select> */}
        <HorizontalSpace8/>
        <Row>
          <InputWhite placeholder="키워드 입력"
                      value={store.keyword}
                      onChange={(e) => store.setKeyword(e.target.value)}
                      style={{ width: 165 }}
          />
        </Row>
      </Row>
      {/* <Body2 style={{ flex: 1 }}>문제 만들기 동의</Body2>
      <VerticalSpace8/>
      <Column>
        <Row>
          <ConsentRow onClick={() => store.toggleNoInfringementAccepted()}>
            <ConsentCheckbox checked={store.noInfringementAccepted}/>
            <Body2 style={{display: 'flex', alignItems: 'center'}}>
              <div style={{color: '#FF1744', marginRight: 4}}>(필수)</div>
              이 퀴즈는 다른 사람의 저작권을 침해하지 않고 만들었습니다.
            </Body2>
          </ConsentRow>
          <button 
            style={{display: 'flex', alignItems: 'center', marginLeft: 4}}
            onClick={() =>{ infoButton ? setInfoButton(false) : setInfoButton(true) }}
          >
            <img src={InfoIcon} style={{width: 20, height: 20}}/> 
          </button>
        </Row>
        {infoButton ? 
        <div style={{fontSize: 14, color: '#0BA1AE', paddingLeft:40}}>
          문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 
        </div> : null}
        <Row>
          <ConsentRow onClick={() => store.toggleSharingAccepted()}>
            <ConsentCheckbox checked={store.sharingAccepted}/>
            <Body2>(선택) 만든 퀴즈를 선생님 커뮤니티에 공유합니다.</Body2>
          </ConsentRow>
          <button 
            style={{display: 'flex', alignItems: 'center', marginLeft: 4}}
            onClick={() =>{ infoButton2 ? setInfoButton2(false) : setInfoButton2(true) }}
          >
            <img src={InfoIcon} style={{width: 20, height: 20}}/> 
          </button>
        </Row>
        {infoButton2 ? 
        <div style={{fontSize: 14, color: '#0BA1AE', paddingLeft:40}}>
          문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 
        </div> : null}
        <Row>
          <ConsentRow onClick={() => store.toggleCopyrightMandateAccepted()}>
            <ConsentCheckbox checked={store.copyrightMandateAccepted}/>
            <Body2>(선택) 만든 퀴즈의 저작권 관리를 포커스팡에 위임합니다.</Body2>
          </ConsentRow>
          <button 
            style={{display: 'flex', alignItems: 'center', marginLeft: 4}}
            onClick={() =>{ infoButton3 ? setInfoButton3(false) : setInfoButton3(true) }}
          >
            <img src={InfoIcon} style={{width: 20, height: 20}}/> 
          </button>
        </Row>
        {infoButton3 ? 
        <div style={{fontSize: 14, color: '#0BA1AE', paddingLeft:40}}>
          문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 문제 만들기 동의에 대한 설명글 
        </div> : null}
      </Column> */}
    </Column>

    <CategoryModal 
      open={categoryModal}
      onCancel={() => setCategoryModal(false)}
      onAdd={(v) => {
        categoryStore.add(v)
        setCategoryModal(false)
      }}
    />
    <SubjectModal 
      open={subjectModal}
      onCancel={() => setSubjectModal(false)}
      onAdd={(v) => {
        subjectStore.add(v)
        setSubjectModal(false)
      }}
    />
  </>
})

export const QuizModal = observer(({ isOpen, onClose, store }) => {
  const [isPreview, setIsPreview] = useState(false)
  useEffect(() => {
    setIsPreview(store.forcePreview)
  }, [store.forcePreview])
  const { clientId, schoolId } = useSelector((state) => state.user)
  const dispatch = useDispatch()

  const postQuiz = async () => {
    const data = store.data
    if (!data.title) {
      alert('문제 질문을 입력해주세요.')
      return
    }
    // if (!data.noInfringementAccepted) {
    //   alert('문제 만들기 필수 항목을 동의해주세요.')
    //   return
    // }
    // ok to proceed
    let link = data.uploadedImgPath
    // upload image if exists

    if (link == null && data.imageFile == null) {
      link = ""
    }
    
    if (data.imageFile) {
      const imageKey = getQuizImageKey(clientId)
      await uploadImage(data.imageFile, imageKey)
      link = resolveImgPath(imageKey)
    }
    const payload = {
      clientId,
      schoolId,
      ...data,
      link,
    }
    delete payload.uploadedImgPath
    delete payload.imageFile
    if (store.isEdit) {
      delete payload.clientId
      delete payload.schoolId
    } else {
      delete payload.quizId
    }
    await dispatch(store.isEdit ? updateQuizPromise(payload) : createQuizPromise(payload))
    onClose()
    window.location.reload();
  }

  const onClickClose = () => {
    if (window.confirm(`${store.isEdit ? '퀴즈용 문제 수정하기' : '퀴즈용 문제 만들기'}를 취소하시겠어요?`)) {
      onClose()
    }
  };

  return <Modal open={isOpen}>
    <Container>
      <HeaderSection>
        <Heading6>
          {store.forcePreview ? '문제 미리보기' : `${store.isEdit ? '퀴즈용 문제 수정하기: ' : '퀴즈용 문제 만들기'}${isPreview ? ' - 미리보기' : ""}`}
        </Heading6>
      </HeaderSection>
      <BodySection style={{ alignItems: 'flex-start' }}>
        {isPreview ? <QuizPreview store={store}/> : <Modify store={store}/>}
      </BodySection>
      <BottomSection>
        <Row style={{ flex: 1, justifyContent: 'space-between' }}>
          <Row>
            {!store.forcePreview && <Button
              startIcon={isPreview ? <CloseIcon/> : <PlayCircleIcon/>}
              onClick={() => setIsPreview(!isPreview)}
              sx={isPreview ? { color: '#FF5252' } : {}}
            >
              {isPreview ? '미리보기 종료' : '문제 미리보기'}
            </Button>}
          </Row>
          {!isPreview &&
            <Row>
              <Button onClick={onClickClose}>취소</Button>
              <HorizontalSpace8/>
              <Button
                onClick={() => postQuiz()}>{store.isEdit ? '수정하기' : '만들기'}</Button>
            </Row>
          }
          {store.forcePreview &&
            <Row>
              <Button onClick={onClose}>닫기</Button>
            </Row>
          }
        </Row>
      </BottomSection>
    </Container>
  </Modal>
})

const Container = styled(Column)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.04),
  0 36px 41px rgba(0, 0, 0, 0.04),
  0 4.50776px 5.13384px rgba(0, 0, 0, 0.03);
  border-radius: 8px;
  height: 620px;
  width: 1208px;
  justify-content: space-between;
`

const HeaderSection = styled(Row)`
  padding: 16px 24px;
  border-bottom: 1px solid #eee;
`

const BodySection = styled(Row)`
  padding: 16px 24px;
  overflow-y: scroll;
`

const BottomSection = styled(Row)`
  padding: 16px 24px;
  border-top: 1px solid #eee;
`

const ImagePlaceholder = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
  flex: 1;
  border: 1px dashed #BDBDBD;
  border-radius: 4px;
  cursor: pointer;
`

const ImageOverlayContainer = styled(Row)`
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 16px;
  align-items: flex-end;
  justify-content: flex-end;
`

const ConsentRow = styled(Row)`
  height: 32px;
  cursor: pointer;
`

const ConsentCheckbox = styled(Checkbox)`
  padding-left: 0;
`

const ImageUploadText = styled(BaseText)`
  font-size: 16px;
  line-height: 28px;
  letter-spacing: 0.15px;
  color: rgba(0, 0, 0, 0.37);
`
