import { memo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './FileLink.module.scss';
import CardFileLink from '../Card/CardFileLink';
import { createFilelinkPromise, setSocketData } from '@store/actions';
import classNames from 'classnames';
import CollapseCard from '@components/Card/CollapseCard';
import BaseInput from '@components/Input/BaseInput';
import { Button, Tooltip } from '@mui/material';

const FileLink = ({ group, groupFileLinks }) => {
  const user = useSelector((state) => state.user);
  const [linkValid, setLinkValid] = useState(false);
  const dispatch = useDispatch();

  const handleLinkChange = (e) => {
    const link = e.target.value;
    const regex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/gi;

    if (regex.test(link)) {
      setLinkValid(true);
    } else {
      setLinkValid(false);
    }
  };
  const onSubmit = (e) => {
    const link = e.target.link.value;
    const title = e.target.title.value;
    var confirmLink = '';

    e.preventDefault();
    if (link.substr(0, 8) !== 'https://' && link.substr(0, 7) !== 'http://') {
      confirmLink = 'http://' + link;
    } else {
      confirmLink = link;
    }

    const linkData = {
      groupId: group?.groupId,
      title: title,
      link: confirmLink,
    };

    e.target.link.value = '';
    e.target.title.value = '';

    dispatch(createFilelinkPromise(linkData)).then((res) => {
      dispatch(
        setSocketData({
          method: 'POST',
          uri: '/classroom/sendImage',
          groupId: group.groupId,
          clientId: user.clientId,
          type: 'CREATE_FILELINK',
          data: '',
        })
      );
    });
  };

  return (
    <>
      <CollapseCard title={<div>학생에게 링크 보내기</div>} initialOpen={true}>
        <div
          name="fileLinkContainer"
          className={styles['file-link-container-column']}
        >
          <form onSubmit={onSubmit} className={styles['form-link']}>
            <Tooltip title="학생에게 보낼 링크를 입력해주세요" arrow>
              <BaseInput
                type="text"
                name="link"
                placeholder="url"
                onChange={handleLinkChange}
                className={styles['input-link']}
              />
            </Tooltip>
            <Tooltip title="적으신 링크의 제목을 입력해주세요" arrow>
              <BaseInput
                type="text"
                name="title"
                placeholder="링크 제목 입력"
                className={classNames(
                  styles['input-title'],
                  linkValid && styles['show']
                )}
              />
            </Tooltip>
              <Button
                variant="contained"
                type="submit"
                name="submit"
                disabled={linkValid === false}
                data-for="a"
                data-tip
              >
                전송
              </Button>
          </form>
          {groupFileLinks.length > 0 && (
            <div className={styles['file-link-wrapper']}>
              {groupFileLinks?.reverse().map((filelink) => (
                <CardFileLink
                  key={filelink.filelinkId}
                  filelink={filelink}
                  groupId={group.groupId}
                  clientId={user.clientId}
                />
              ))}
            </div>
          )}
        </div>
      </CollapseCard>
    </>
  );
};

export default memo(FileLink, (prev, next) => {
  return (
    JSON.stringify(prev.group) === JSON.stringify(next.group) &&
    prev.groupFileLinks.length === next.groupFileLinks.length
  );
});
