import {
  PURGE_TIMETABLE,
  GET_TIMETABLE_SUCCESS,
  GET_TIMETABLE_FAIL,
  GET_TIMETABLES_BY_GROUP_SUCCESS,
  GET_TIMETABLES_BY_GROUP_FAIL,
  CREATE_TIMETABLE_SUCCESS,
  CREATE_TIMETABLE_FAIL,
  UPDATE_TIMETABLE_SUCCESS,
  UPDATE_TIMETABLE_FAIL,
  DELETE_TIMETABLE_SUCCESS,
  DELETE_TIMETABLE_FAIL,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeTimetable = () => ({
  type: PURGE_TIMETABLE,
});

///// GET_TIMETABLE
export const getTimetablePromise = createPromiseAction('GET_TIMETABLE_PROMISE');

export const getTimetableSuccess = (timetableData) => ({
  type: GET_TIMETABLE_SUCCESS,
  timetable: timetableData,
});

export const getTimetableFail = (error) => ({
  type: GET_TIMETABLE_FAIL,
  payload: error,
});

///// GET_TIMETABLES_BY_GROUP

export const getTimetablesByGroupPromise = createPromiseAction('GET_TIMETABLES_BY_GROUP_PROMISE');
export const getTimetablesByGroupListPromise = createPromiseAction('GET_TIMETABLES_BY_GROUP_LIST_PROMISE');

export const getTimetablesByGroupSuccess = (timetablesData) => {
  let byId = {};
  timetablesData.forEach((timetableData) => {
    byId[timetableData.timetableId] = timetableData;
  });

  const allIds = timetablesData.map(
    (timetableData) => timetableData.timetableId
  );

  return {
    type: GET_TIMETABLES_BY_GROUP_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getTimetablesByGroupFail = (error) => ({
  type: GET_TIMETABLES_BY_GROUP_FAIL,
  payload: error,
});

///// CREATE_TIMETABLE
export const createTimetablePromise = createPromiseAction('CREATE_TIMETABLE_PROMISE');

export const createTimetableSuccess = (timetableData) => ({
  type: CREATE_TIMETABLE_SUCCESS,
  timetable: timetableData,
});

export const createTimetableFail = (error) => ({
  type: CREATE_TIMETABLE_FAIL,
  payload: error,
});

///// UPDATE_TIMETABLE
export const updateTimetablePromise = createPromiseAction('UPDATE_TIMETABLE_PROMISE');

export const updateTimetableSuccess = (timetableData) => ({
  type: UPDATE_TIMETABLE_SUCCESS,
  timetable: timetableData,
});

export const updateTimetableFail = (error) => ({
  type: UPDATE_TIMETABLE_FAIL,
  payload: error,
});

///// DELETE_TIMETABLE
export const deleteTimetablePromise = createPromiseAction('DELETE_TIMETABLE_PROMISE');

export const deleteTimetableSuccess = (timetableData) => ({
  type: DELETE_TIMETABLE_SUCCESS,
  timetable: timetableData,
});

export const deleteTimetableFail = (error) => ({
  type: DELETE_TIMETABLE_FAIL,
  payload: error,
});
