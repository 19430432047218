import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import closeIcon from '@assets/images/Buttons/ic_cancel_16.svg';
import { useDispatch } from 'react-redux';
import { getMembershipsByGroupPromise } from '@store/actions';
import styles from './ModalStudentUpdate.module.scss';
import { updateClientsPromise, setSocketData } from '@store/actions';
import Loading from '../Loading/Loading';
import ShowError from './ErrorMessage/ShowError';

const ModalStudentUpdate = ({
  updateModal,
  setUpdateModal,
  propUsername,
  propStudentNum,
  propUserId,
  groupId,
  user,
}) => {
  const dispatch = useDispatch();
  const numberRegex = /^\d{5,5}$/;

  const [name, setName] = useState(propUsername);
  const [stdNum, setStdNum] = useState(propStudentNum);
  const [loading, setLoading] = useState(false);

  const onUpdate = (e) => {
    if (numberRegex.test(stdNum) === false) {
      setLoading(false);
      alert('5자리 올바른 학번을 입력해주세요.');
      return;
    }
    setLoading(true);
    e.preventDefault();
    dispatch(
      updateClientsPromise({
        clientId: propUserId,
        userName: name,
        studentNumber: stdNum,
      })
    )
      .then(() => {
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: groupId,
            clientId: user?.clientId,
            type: 'UPDATE_STUDENT',
            data: propUserId,
          })
        );
        setLoading(false);
        setUpdateModal(false);
        alert('학생 정보 수정이 완료되었습니다.');
        window.location.reload();
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onHide = () => {
    setUpdateModal(false);
  };

  return (
    <Modal
      show={updateModal}
      centered
      className={styles['editInfomation-container']}
    >
      <form className={styles['modal-cotainer']}>
        <div className={styles['title-css']}>학생 정보를 수정합니다.</div>
        <div>
          <div className={styles['infoContainer']}>
            <div className={styles['studentNumber']}>학번</div>
            <input
              className={styles['inputContainer']}
              type={'int'}
              onChange={(e) => setStdNum(e.target.value)}
              placeholder={propStudentNum}
              maxLength="25"
            />
            <ShowError type="stdNumber" input={stdNum} />
            <div className={styles['studentName']}>이름</div>
            <input
              className={styles['inputContainer']}
              type={'text'}
              placeholder={propUsername}
              onChange={(e) => setName(e.target.value)}
              maxLength="35"
            />
            <ShowError type="stdName" input={name} />
          </div>
          <div className={styles['button-container']}>
            <button
              type="button"
              className={styles['cancle-button']}
              onClick={() => {
                onHide();
              }}
            >
              취소
            </button>
            <button
              type="submit"
              className={styles['edit-button']}
              onClick={(e) => {
                onUpdate(e);
              }}
            >
              수정
            </button>
          </div>
        </div>
      </form>
      {loading && <Loading />}
    </Modal>
  );
};

export default ModalStudentUpdate;
