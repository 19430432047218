import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteFilelinkPromise, setSocketData } from '@store/actions';
import styles from './CardFileLink.module.scss';
import imgNotiIcon from '@images/home/img_notice.png';
import moment from 'moment';
import BaseCard from './baseCard';
import CloseImg from '@assets/images/icons/close.png';
import { Tooltip } from '@mui/material';

const getFaviconImageUrl = (link) => {
  return `https://www.google.com/s2/favicons?sz=64&domain_url=${link}`;
};

const CardFileLink = ({ groupId, clientId, filelink }) => {
  const dispatch = useDispatch();

  const onClick = () => {
    window.open(filelink.link);
  };

  const onClickDeleteFilelink = () => {
    dispatch(deleteFilelinkPromise(filelink.filelinkId)).then((res) => {
      dispatch(
        setSocketData({
          method: 'POST',
          uri: '/classroom/sendImage',
          groupId: groupId,
          clientId: clientId,
          type: 'DELETE_FILELINK',
          data: filelink.filelinkId,
        })
      );
    });
  };

  return (
    <div name="CardFileLink" className={styles['link-list']} onClick={onClick}>
      <div className={styles['date']}>
        {moment(filelink.updatedAt).format('YYYY.MM.DD')}
      </div>
      <BaseCard radius={4} className={styles['card-wrapper']}>
        <div className={styles['icon-wrap']}>
          <img
            className={styles['icon']}
            src={getFaviconImageUrl(filelink.link)}
            alt="icon"
          />
          <div className={styles['title-link-container']}>
            <div className={styles['title']}>
              {filelink.title ?? '제목없음'}
            </div>
            <div className={styles['link']}>{filelink.link}</div>
          </div>
        </div>
        <Tooltip title="링크를 삭제하실 수 있는 버튼입니다." arrow>
          <div
            className={styles['del-btn']}
            onClick={(e) => {
              e.stopPropagation();
              onClickDeleteFilelink();
            }}
          >
            <img src={CloseImg} width={11} height={11} />
          </div>
        </Tooltip>
      </BaseCard>
    </div>
  );
};

export default CardFileLink;
