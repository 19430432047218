import {
  PURGE_CLASSROOM,
  SET_SCREEN_SORT_OPTION,
  GET_CLASSROOM_SUCCESS,
  GET_CLASSROOM_FAIL,

  GET_ACTIVE_CLASSROOM_SUCCESS,
  GET_ACTIVE_CLASSROOM_FAIL,

  SET_ACTIVE_CLASSROOM_EXIST,
  SET_ACTIVE_CLASSROOM_NOT_EXIST,

  CREATE_CLASSROOM_SUCCESS,
  CREATE_CLASSROOM_FAIL,

  UPDATE_CLASSROOM_SUCCESS,
  UPDATE_CLASSROOM_FAIL,

  DELETE_CLASSROOM_SUCCESS,
  DELETE_CLASSROOM_FAIL,
  UPDATE_CLASSROOM_LOADING,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeClassroom = () => ({
  type: PURGE_CLASSROOM,
});
export const setScreenSortOption = (method) => ({
  type: SET_SCREEN_SORT_OPTION,
  payload: method
})
///// GET_CLASSROOM
export const getClassroomPromise = createPromiseAction('GET_CLASSROOM_PROMISE');

export const getClassroomSuccess = (classroomData) => ({
  type: GET_CLASSROOM_SUCCESS,
  classroom: classroomData,
});

export const getClassroomFail = (error) => ({
  type: GET_CLASSROOM_FAIL,
  payload: error,
});

// GET_ACTIVE_CLASSROOM
export const getActiveClassroomPromise = createPromiseAction('GET_ACTIVE_CLASSROOM_PROMISE');

export const getActiveClassroomSuccess = (classroomSessionData) => {
  const classroom = classroomSessionData.classroom;
  const participation = classroomSessionData.participation;

  return {
    type: GET_ACTIVE_CLASSROOM_SUCCESS,
    classroom: classroom,
    participation: participation,
  };
};
export const getActiveClassroomFail = (error) => {
  return {
    type: GET_ACTIVE_CLASSROOM_FAIL,
    payload: error,
  };
};

export const setActiveClassroomExist = (classroomData) => ({
  type: SET_ACTIVE_CLASSROOM_EXIST,
  classroom: classroomData,
});

export const setActiveClassroomNotExist = () => ({
  type: SET_ACTIVE_CLASSROOM_NOT_EXIST,
});

//// CREATE_CLASSROOM
export const createClassroomPromise = createPromiseAction('CREATE_CLASSROOM_PROMISE');

export const createClassroomSuccess = (classroomData) => ({
  type: CREATE_CLASSROOM_SUCCESS,
  classroom: classroomData,
});

export const createClassroomFail = (error) => ({
  type: CREATE_CLASSROOM_FAIL,
  payload: error,
});

//// UPDATE_CLASSROOM
export const updateClassroomPromise = createPromiseAction('UPDATE_CLASSROOM_PROMISE');

export const updateClassroomLoading = (isClassroomLoading) => ({
  type: UPDATE_CLASSROOM_LOADING,
  payload: isClassroomLoading,
});
export const updateClassroomSuccess = (classroomData) => ({
  type: UPDATE_CLASSROOM_SUCCESS,
  classroom: classroomData,
});

export const updateClassroomFail = (error) => ({
  type: UPDATE_CLASSROOM_FAIL,
  payload: error,
});

//// DELETE_CLASSROOM
export const deleteClassroomPromise = createPromiseAction('DELETE_CLASSROOM_PROMISE');

export const deleteClassroomSuccess = (classroomData) => ({
  type: DELETE_CLASSROOM_SUCCESS,
  classroom: classroomData,
});

export const deleteClassroomFail = (error) => ({
  type: DELETE_CLASSROOM_FAIL,
  payload: error,
});