import {
  Body2,
  Column,
  Divider,
  HeaderSection,
  Heading6,
  HorizontalSpace16,
  MainContentLayer,
  Subtitle1,
} from '@app/pages/QuizPang/common';
import { SearchInput } from '@app/pages/QuizPang/search-input';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { getQuizsetSessionHistories } from '@api';
import { useInfiniteQuery } from '@tanstack/react-query';
import { QuizsetSessionHistory } from '../quizset-session-history';
import { useInView } from 'react-intersection-observer';

export const QuizHistory = () => {
  const { ref, inView } = useInView();
  const { groupId } = useParams();
  const clientId = useSelector((state) => state.user.clientId);

  const {
    data,
    isSuccess,
    isLoading,
    hasNextPage,
    fetchNextPage,
    refetch,
    isFetchingNextPage,
  } = useInfiniteQuery(
    ['quizsetSessionHistories', groupId],
    ({ pageParam = 0 }) => getQuizsetSessionHistories(groupId, pageParam),
    {
      getNextPageParam: (lastPage) => lastPage.nextId ?? undefined,
    }
  );

  React.useEffect(() => {
    if (inView) {
      fetchNextPage();
    }
  }, [inView]);

  return (
    <div>
      <Column style={{ padding: '0px 24px' }}>
        <Subtitle1>퀴즈팡 사용 기록</Subtitle1>
        {isLoading ? (
          <Body2 style={{ marginTop: 16 }}>
            퀴즈팡 기록을 가져오고 있습니다...
          </Body2>
        ) : isSuccess &&
          data.pages[0].data.quizsetSessionHistories.length > 0 ? (
          <>
            {data.pages.map((page, i) =>
              page.data.quizsetSessionHistories.map((history, j) => (
                <QuizsetSessionHistory key={j} data={history} />
              ))
            )}
            {isFetchingNextPage && (
              <Body2 style={{ textAlign: 'center', marginTop: '16px' }}>
                로딩중
              </Body2>
            )}
            {!isFetchingNextPage && !hasNextPage && (
              <Body2 style={{ textAlign: 'center', marginTop: '16px' }}>
                더 이상 불러올 퀴즈팡 기록이 없습니다.
              </Body2>
            )}
            <div ref={ref} />
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <RefetchButton onClick={() => refetch()}>
                기록 다시 불러오기
              </RefetchButton>
            </div>
          </>
        ) : (
          <Body2 style={{ marginTop: 16 }}>
            아직 퀴즈팡 사용 기록이 없습니다. 퀴즈팡을 사용해 보세요!
          </Body2>
        )}
      </Column>
    </div>
  );
};

export const BaseText = styled.span`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;
export const SubTitle = styled(BaseText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  padding: 0 24px;
  margin-bottom: 8px;
  margin-top: -6px;
`;
export const MovePage = styled.button`
  border-width: 1px;
  border-radius: 24px;
  padding: 8px 18px;
  background-color: #0894a0;
  color: #fff;
  &:hover {
    background-color: #16747cd7;
  }
`;

const RefetchButton = styled.button`
  width: 30%;
  height: 54px;
  background-color: #0ba1ae;
  padding: auto;
  border-radius: 8px;
  color: #fff;
  margin: 0 auto;
  margin-top: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
