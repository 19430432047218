import React from 'react';
import styles from './CardLectureInfo_Empty.module.scss';
import BaseCardTimetable from './baseCardTimeTable';
import { Tooltip } from '@mui/material';

const CardLectureInfo = ({
  timetable,
  group,
  modal_func,
  prop_tables,
  prop_weekday,
}) => {
  return (
    <>
      <Tooltip
        title="비어있는 시간을 클릭해서 수업을 추가하실 수 있습니다."
        arrow
      >
        <BaseCardTimetable
          className={styles['container']}
          style={{ backgroundColor: '#F5F5F5' }}
        >
          <div
            style={{
              cursor: 'pointer',
              backgroundColor: '#F5F5F5',
              textAlign: 'center',
            }}
            className={styles['empty_text']}
            onClick={() => {
              modal_func({
                timetables: prop_tables,
                weekDay: prop_weekday,
                period: timetable.period,
              });
            }}
          >
            {timetable.period}
          </div>
        </BaseCardTimetable>
        {/* <ModalEditClassTimetable
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> */}
      </Tooltip>
    </>
  );
};

export default CardLectureInfo;
