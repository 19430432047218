import styles from './Loading.module.scss';

const Loading = ({ className }) => {
  return (
    <div className={`${styles['loading-container']} ${className}`}>
      <div className={styles['loading-wrap']}>
        <div className={styles['loading']}></div>
        <div className={styles['loading-text']}>loading</div>
      </div>
    </div>
  )
}

export default Loading;