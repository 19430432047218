import { call, put, takeLeading } from 'redux-saga/effects';
// import {

// } from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import {
  getAccountTokenFail,
  getAccountTokenPromise,
  getAccountTokenSuccess,
} from './actions';

function* getAccountTokenPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getAccountToken, action.payload);
      console.log('response', response.data);
      yield call(api.setToken, response.data.token);
      yield put(getAccountTokenSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getAccountTokenFail(error));
    }
  });
}

function* accountSaga() {
  yield takeLeading(getAccountTokenPromise, getAccountTokenPromiseHandler);
}

export default accountSaga;
