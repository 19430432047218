import {
  BottomContentLayer,
  Divider,
  HeaderSection,
  Heading6,
  MainContentLayer,
  Row,
  Column,
  Body2,
  Subtitle1,
  SubTitle2,
  VerticalSpace8,
} from '@app/pages/QuizPang/common';
import { SearchInput } from '@app/pages/QuizPang/search-input';
import React, { useEffect, useRef, useState } from 'react';
import { Button } from '@mui/material';
import LooksOneIcon from '@mui/icons-material/LooksOne';
import { useDispatch, useSelector } from 'react-redux';
import { selectClientQuizsetList } from '@app/store/quizset/selector';
import { getQuizsetListByClientPromise } from '@app/store/quizset/actions';
import {
  QuizsetCard,
  QuizsetCardDuringClassActions,
} from '@app/pages/QuizPang/quizset-card';
import { useHistory, useParams, useRouteMatch } from 'react-router-dom';
import { getQuizListByClientPromise } from '@app/store/quiz/actions';
import { QuizsetModalStore } from '@app/pages/QuizPang/quizset-modal-store';
import { StartOneQuizModal } from '@app/pages/QuizPang/InClass/start-one-quiz-modal';
import {
  createQuizsetSessionByQuizPromise,
  createQuizsetSessionPromise,
} from '@app/store/quizsetSession/actions';
import moment from 'moment';
import { setSocketData } from '@app/store/actions';
import styled from 'styled-components';
import { selectActiveClassroom } from '@app/store/selectors';
import { BeforeClass } from './before-class';
import { QuizHistory } from './history';

export const DuringClass = () => {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.user.clientId);
  const _quizsetList = useSelector(selectClientQuizsetList);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const location = useRouteMatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { groupId } = useParams();
  const modalStore = useRef(new QuizsetModalStore());
  const [query, setQuery] = useState('');
  const queryTrimmed = query.trim();
  const quizsetList =
    queryTrimmed.length > 0
      ? _quizsetList.filter((x) => x.title.includes(queryTrimmed))
      : _quizsetList;

  useEffect(() => {
    dispatch(getQuizsetListByClientPromise(clientId)).then(() =>
      setIsLoading(false)
    );
    dispatch(getQuizListByClientPromise(clientId));
  }, [dispatch, clientId]);

  const onClickMovePage = () => {
    if (activeClassroom) {
      alert('퀴즈팡 관리 페이지로 이동하기 위해서는 수업을 끝내주세요.');
      return;
    }
    history.replace('/quiz-pang');
  };

  return (
    <MainContentLayer>
      <HeaderSection
        style={{
          alignItems: 'center',
          paddingBottom: 16,
        }}
      >
        <Row>
          <Heading6 style={{ marginRight: 16 }}>퀴즈팡</Heading6>
        </Row>
        <SearchInput
          label="퀴즈 찾기"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderSection>
      <div
        style={{
          fontSize: 14,
          color: '#00000099',
          paddingLeft: 24,
          marginBottom: 16,
        }}
      >
        학생들이 다 같이 풀어 보는 퀴즈 배틀입니다.
      </div>
      <Divider />
      <BottomContentLayer>
        {isLoading ? (
          <Body2 style={{ marginTop: 16 }}>로딩 중입니다...</Body2>
        ) : quizsetList.length ? (
          !activeClassroom ? (
            <div>
              <Subtitle1>
                수업을 시작하면 퀴즈팡을 이용할 수 있습니다!
              </Subtitle1>
              <VerticalSpace8 />
              {quizsetList.map((x) => (
                <QuizsetCard
                  key={x.quizsetId}
                  data={x}
                  actions={
                    <QuizsetCardDuringClassActions
                      onStart={async () => {
                        // history.push(`${location.url}/quizset-detail/${x.quizsetId}/session/test/0`)
                        const res = await dispatch(
                          createQuizsetSessionPromise({
                            clientId,
                            groupId,
                            quizsetId: x.quizsetId,
                            startAt: moment().toISOString(),
                          })
                        ).then((res) => {
                          dispatch(
                            setSocketData({
                              method: 'POST',
                              uri: '/classroom/sendImage',
                              type: 'QUIZ_START',
                              clientId: clientId,
                              groupId: groupId,
                              data: res.quizsetSession.quizsetSessionId,
                            })
                          );
                          if (res) {
                            const sessionId =
                              res.quizsetSession.quizsetSessionId;
                            history.push(
                              `${location.url}/quizset-detail/${x.quizsetId}/session/${sessionId}/0`
                            );
                          }
                        });
                      }}
                    />
                  }
                  onClickBottomButton={() => {
                    history.push(
                      `${location.url}/quizset-detail/${x.quizsetId}`
                    );
                  }}
                />
              ))}
            </div>
          ) : (
            <div className="flex w-100vw h-100vh">
              <MainContentLayer
                style={{
                  border: '1px solid #ffffff',
                }}
              >
                <Subtitle1>
                  퀴즈 시작을 클릭하여 퀴즈팡을 시작해 보세요!
                </Subtitle1>
                <VerticalSpace8 />

                {quizsetList.map((x) => (
                  <QuizsetCard
                    key={x.quizsetId}
                    data={x}
                    actions={
                      <QuizsetCardDuringClassActions
                        onStart={async () => {
                          // history.push(`${location.url}/quizset-detail/${x.quizsetId}/session/test/0`)
                          const res = await dispatch(
                            createQuizsetSessionPromise({
                              clientId,
                              groupId,
                              quizsetId: x.quizsetId,
                              startAt: moment().toISOString(),
                            })
                          ).then((res) => {
                            dispatch(
                              setSocketData({
                                method: 'POST',
                                uri: '/classroom/sendImage',
                                type: 'QUIZ_START',
                                clientId: clientId,
                                groupId: groupId,
                                data: res.quizsetSession.quizsetSessionId,
                              })
                            );
                            if (res) {
                              const sessionId =
                                res.quizsetSession.quizsetSessionId;
                              history.push(
                                `${location.url}/quizset-detail/${x.quizsetId}/session/${sessionId}/0`
                              );
                            }
                          });
                        }}
                      />
                    }
                    onClickBottomButton={() => {
                      history.push(
                        `${location.url}/quizset-detail/${x.quizsetId}`
                      );
                    }}
                  />
                ))}
              </MainContentLayer>
              <MainContentLayer
                style={{
                  border: '1px solid #ffffff',
                }}
              >
                <QuizHistory />
              </MainContentLayer>
            </div>
          )
        ) : (
          <Column>
            <Subtitle1>
              아직 학생들이 풀어 볼 퀴즈가 없습니다. 퀴즈팡 관리 페이지에서
              퀴즈를 만들어주세요.
            </Subtitle1>
            <div style={{ marginTop: 10 }}>
              <MovePage onClick={onClickMovePage}>
                퀴즈팡 관리 페이지로 이동하기
              </MovePage>
            </div>
          </Column>
        )}
      </BottomContentLayer>
      <StartOneQuizModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onStart={async () => {
          const quizId = modalStore.current.quizIdList[0];
          const res = await dispatch(
            createQuizsetSessionByQuizPromise({
              clientId,
              groupId,
              quizId,
              startAt: moment().toISOString(),
            })
          );
          await dispatch(getQuizsetListByClientPromise(clientId));
          if (res) {
            const quizsetId = res.quizset.quizsetId;
            const sessionId = res.quizsetSession.quizsetSessionId;
            history.push(
              `${location.url}/quizset-detail/${quizsetId}/session/${sessionId}/0`
            );
          }
        }}
        store={modalStore.current}
      />
    </MainContentLayer>
  );
};

export const BaseText = styled.span`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;
export const SubTitle = styled(BaseText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  padding: 0 24px;
  margin-bottom: 8px;
  margin-top: -6px;
`;
export const MovePage = styled.button`
  border-width: 1px;
  border-radius: 24px;
  padding: 8px 18px;
  background-color: #0894a0;
  color: #fff;
  &:hover {
    background-color: #16747cd7;
  }
`;
