import React from 'react'
import { observer } from 'mobx-react'
import { Body2, Column, Heading5 } from '@app/pages/QuizPang/common'
import { Image, ImageContainer } from '@app/pages/QuizPang/quiz-image'
import { QuizInput } from '@app/pages/QuizPang/quiz-input'
import { QuizChoice } from '@app/pages/QuizPang/quiz-choice'

export const QuizPreview = observer(({ store }) => {
  return <Column style={{ flex: 1, alignItems: 'center' }}>
    {/* <Heading5 style={{ margin: '8px 0 24px 0', width: 1100, wordWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign:'center' }}>
      {store.title}</Heading5> */}
      <Heading5 
        dangerouslySetInnerHTML={{ __html: store.title }} 
        style={{ margin: '8px 0 24px 0', width: 1100, wordWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign:'center' }} 
      />
    {/* <ImageContainer>
      {store.imgPath !== null && <Image src={store.imgPath}/>}
    </ImageContainer> */}
    {store.imgPath !== null &&
    <ImageContainer>
      <Image src={store.imgPath}/>
    </ImageContainer>}
    {/* {store.description && store.description.length > 0 &&
      <Body2 style={{
        marginTop: 16,
        color: 'rgba(0, 0, 0, 0.87)',
        width: 1100, wordWrap: 'break-word', whiteSpace: 'pre-wrap', textAlign:'center'
      }}>{store.description}</Body2>
    } */}
    <div style={{ height: 24 }}/>
    {store.type === 'SHORT' ?
      <QuizInput placeholder="정답 입력"/> :
      <>
        {
          store.choices.map((x, i) =>
            <QuizChoice key={i} index={i + 1} description={x.description}/>,
          )
        }
      </>
    }
  </Column>
})
