import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { QRCodeSVG } from 'qrcode.react';
import CopyToClipboard from 'react-copy-to-clipboard';
import styles from './ModalAddStudent.module.scss';
import { createInviteCodePromise } from '@store/actions';

const baseUrlLink = 'https://restapi.focuspang.com/invite/home?code=';

const ModalAddStudent = ({ onHide, groupId, ...props }) => {
  const dispatch = useDispatch();
  const [urlLink, setUrlLink] = useState(baseUrlLink);
  const [inviteCode, setInviteCode] = useState('');
  useEffect(() => {
    dispatch(createInviteCodePromise(groupId)).then((invCode) => {
      setInviteCode(invCode);
    });
  }, []);

  useEffect(() => {
    if (!inviteCode) {
      return;
    }
    if (Object.keys(inviteCode).length !== 0) {
      setUrlLink(baseUrlLink + inviteCode);
    }
  }, [inviteCode]);

  const inviteCode1 = inviteCode?.substring(0, 3);
  const inviteCode2 = inviteCode?.substring(3, 6);
  const inviteCode3 = inviteCode?.substring(6, 8);

  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles['AddApp-container']}
    >
      <Modal.Header className={styles['modal-bg-header']}>
        <div className={styles['title-css']}>
          학생에게 <span className={styles['primary-darken']}>초대코드</span>를
          알려주세요
        </div>
      </Modal.Header>
      <Modal.Body className={styles['modal-bg-body']}>
        <div className={styles['body-title']}>
          <span className={styles['yellow-darken']}>
            {inviteCode1 + ' ' + ' ' + inviteCode2 + ' ' + ' ' + inviteCode3}
          </span>
        </div>
        <div>
          <br></br>
        </div>
        {/* <div className={styles['body-subTitle']}>학생 초대하기</div> */}
        <div className={styles['close']}>
          <span
            className={styles['primary-darken']}
            type="button"
            onClick={() => {
              onHide();
              // window.location.reload();
            }}
          >
            닫기
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddStudent;
