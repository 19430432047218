import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectStudentByClientId } from '@app/store/selectors';
import styles from './CardReport.module.scss';
import momentTimezone from 'moment-timezone';

const CardReport = ({studentName, appName, time, status}) => {
  const stdName = useSelector((state) => 
    selectStudentByClientId(state, studentName)
  );

  const momentTime = momentTimezone(time).format('YY/MM/DD HH:mm');

  return (
    <div className={styles['cotainer']}>
      <div className={styles['row-time']}>{momentTime}</div>
      <div className={styles['row-name']}>{stdName?.userName}</div>
      <div className={styles['row-app']}>{appName}</div>
      <div className={styles['row-info']}>
        {status === 'ETC' ? '기타' : 
        status === 'ALLOWED' ? '허용중' : 
        status === 'DISALLOWED' ? '차단중 시도' : ''}
      </div>
    </div>
  );
};

export default CardReport;
