import { selectStudentsByGroup } from '../selectors';

export const selectGroupActivity = (state) => {
  return state.groupActivity.activityList;
};
export const selectSubroupActivity = (state) => {
  return state.groupActivity.subgroupList;
};
export const selectStudentsWithGroupActivity = (state, groupId) => {
  const students = selectStudentsByGroup(state, groupId);
  const flatSubgroupStudents = state.groupActivity.subgroupList.reduce(
    (prev, curr) => {
      return [
        ...prev,
        ...curr.students.map((student) => ({
          ...student,
          subgroupId: curr.subgroupId,
        })),
      ];
    },
    []
  );
  const studentWithGroup = students.map((student) => {
    const findStudent = flatSubgroupStudents.find(
      (item) => item.clientId === student.clientId
    );
    if (findStudent) {
      return findStudent;
    }
    return student;
  });
  return studentWithGroup;
};
