import React, { useState } from "react";
import "./FileDragDrop.scss";
import * as XLSX from "xlsx";
import { useDispatch } from "react-redux";

const FileDragDrop = (props) => {
  const [state, setState] = useState({
    trigger: false,
    drag: false,
    drop: false,
  });
  const dispatch = useDispatch();
  const [dragCounter, setDragCounter] = useState(0);
  const [studentList, setStudentList] = useState([]);
  const [checked, setChecked] = useState(false);

  const triggerNext = () => {
    setState({ ...state, trigger: true });
    props.triggerNextStep();
  };

  const onClickFunc = async (e) => {
    // TODO(gogo): replace
    // dispatch(signUpStudentsPromise(props.group, studentList));

    e.preventDefault();
    triggerNext();
  };

  const handleDrag = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDragIn = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(1);
    if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
      setState({ ...state, drag: true, drop: false });
    }
  };

  const handleDragOut = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragCounter(0);
    if (dragCounter === 0) {
      setState({ ...state, drag: false, drop: false });
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setState({ ...state, drag: false, drop: true });
    if (e.dataTransfer.files) {
      if (e.dataTransfer.files.length === 1) {
        let file = e.dataTransfer.files[0];
        // console.log(file.name);
        // console.log(file.name.includes('csv'))
        //
        // let reader = new FileReader();
        // reader.onload = function(event) {
        //   // The file's text will be printed here
        //   console.log(event.target.result)
        // };
        // reader.readAsText(file, "utf-8");
        // //reader.readAsDataURL(file);
        if (
          file.name.split(".")[file.name.split(".").length - 1].includes("csv")
        ) {
          handleCSVFiles(file);
        } else if (
          file.name.split(".")[file.name.split(".").length - 1].includes("xls")
        ) {
          handleXlsxFiles(file);
        }

        e.dataTransfer.clearData();
        setDragCounter(0);
      } else {
        alert("파일을 하나만 선택해주세요.");
      }
    }
  };

  const handleCSVFiles = (file) => {
    var reader = new FileReader();
    reader.onload = function(e) {
      const rows = reader.result.split("\n");
      console.log(rows);
      if (rows[0].slice(0, 11) === "성,이름,이메일 주소") {
        const studentsData = rows
          .map((row) => {
            const words = row.split(",");
            return {
              name: words[0] + words[1],
              email: words[2],
            };
          })
          .slice(3, -1);
        setStudentList(studentsData);
      } else if (rows[0].slice(0, 2) === "성명") {
        const studentsData = rows
          .filter((row) => row.length > 0)
          .map((row) => {
            const words = row.split("\t");
            return { name: words[0] };
          })
          .slice(1, -1);
        setStudentList(studentsData);
      } else {
        alert("파일 양식이 올바르지 않습니다.");
      }
    };
    reader.readAsText(file);
  };

  const handleXlsxFiles = (file) => {
    let reader = new FileReader();
    reader.onload = function(e) {
      let data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname = readedData.SheetNames[0];
      const ws = readedData.Sheets[wsname];
      const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });

      const nameColumnIdx = dataParse[0].findIndex((elem) => elem === "성명");
      const stdPersonalNumIdx = dataParse[0].findIndex(
        (elem) => elem === "학번"
      );
      if (nameColumnIdx === -1 || stdPersonalNumIdx === -1) {
        alert("파일 양식이 올바르지 않습니다. 성명, 학번을 포함시켜 주세요");
        return;
      }
      const studentsData = dataParse
        .filter((d) => d.length > 0)
        .map((row) => ({
          name: row[nameColumnIdx],
          studentNumber: row[stdPersonalNumIdx],
        }))
        .slice(1);

      // let fileContent = state.fileContents;
      // fileContent.push(dataParse);
      // setState({ ...state, fileContents: dataParse });
      setStudentList(studentsData);
    };
    reader.readAsBinaryString(file);
  };

  const handleCheck = () => {
    setChecked(!checked);
  };

  return (
    <div>
      {studentList.length === 0 && (
        <div
          style={{
            display: "inline-block",
            position: "relative",
            width: "300px",
            height: "200px",
          }}
          onDragOver={handleDrag}
          onDragEnter={handleDragIn}
          onDragLeave={handleDragOut}
          onDrop={handleDrop}
        >
          <div
            style={{
              border: "dashed grey 2px",
              backgroundColor: "rgba(255,255,255,.8)",
              position: "inherit",
              top: 0,
              bottom: 0,
              left: 0,
              right: 0,
              width: "100%",
              height: "100%",
              zIndex: 9999,
            }}
          >
            <div
              style={{
                position: "inherit",
                top: "50%",
                right: 0,
                left: 0,
                textAlign: "center",
                color: "grey",
                fontSize: 13,
              }}
            >
              파일을 끌어서 여기에 놓아주세요
            </div>
          </div>

          <button
            onClick={() => props.triggerNextStep({ value: null, trigger: "1" })}
            className={"retry-button"}
          >
            학생 등록 방법 다시 선택하기
          </button>
          {props.children}
        </div>
      )}
      <div>
        {studentList.length > 0 && (
          <table className="dragged-table">
            <thead>
              <tr>
                <th>학번</th>
                <th>성명</th>
              </tr>
            </thead>
            <tbody>
              {studentList.map((student, idx) => (
                <tr key={idx}>
                  <td>{student.studentNumber}</td>
                  <td>{student.name}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
      </div>
      {studentList.length > 0 && (
        <>
          <div className="checkbox">
            <input
              type="checkbox"
              className="input-button"
              checked={checked}
              onChange={handleCheck}
            />
            {/* TODO: change href url */}
            <a
              href="https://focuspang.com/tos.html"
              className="agreement-link"
              target="_blank"
              rel="noreferrer noopener"
            >
              제3자 정보 제공 동의
            </a>
            <p style={{ marginTop: "15px", marginLeft: "3px" }}>
              에 동의해주세요
            </p>
          </div>
          {checked ? (
            <>
              <button onClick={onClickFunc} className="button">
                이렇게 등록할래요
              </button>
              <button onClick={() => setStudentList([])} className="button">
                다시 등록할래요
              </button>
            </>
          ) : (
            <button onClick={onClickFunc} className="button" disabled>
              제 3자 정보제공에 동의해주세요
            </button>
          )}
        </>
      )}
    </div>
    // <div className="FileDragDrop">
    // <input
    //     type="file"
    //     id="fileUpload"
    //     style={{ display: "none" }} // label을 이용하여 구현하기에 없애줌
    //     multiple={true} // 파일 다중선택 허용
    // />

    // <label
    //     className={isDragging ? "DragDrop-File-Dragging" : "DragDrop-File"}
    //     // 드래그 중일때와 아닐때의 클래스 이름을 다르게 주어 스타일 차이

    //     htmlFor="fileUpload"
    //     ref={dragRef}
    // >
    //     <div>파일 첨부</div>
    // </label>
    // </div>
  );
};

export default FileDragDrop;
