import { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Typography,
  Divider,
  Button,
  TextField,
  Tooltip,
} from '@mui/material';
import axios from 'axios';
import { AddCircle as AddCircleIcon } from '@mui/icons-material';
import { Modal } from 'react-bootstrap';
import { AssignmentClass } from './AssignmentClass';
import { Error as ErrorIcon } from '@mui/icons-material';
import { TextEditor } from '@app/components/Input/TextEditor';
import ReactGA from 'react-ga';

export const AssignmentCreate = ({
  show,
  groupId,
  setShow,
  getGroupnoticeByGroup,
}) => {
  const [title, setTitle] = useState('');
  const [body, setBody] = useState('');
  const [groupIds, setGroupIds] = useState([
    {
      groupId,
      key: new Date().getTime(),
      submitAt: new Date(),
      releaseAt: new Date(),
    },
  ]);

  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (body && title) setValidation(false);
  }, [body, title]);

  const createGroupnotice = useCallback(async () => {
    if (!title || !body) return setValidation(true);
    try {
      // 공지 등록
      const requests = groupIds.map((groupId) =>
        axios.post('https://ktor.focuspang.com/v2/groupnotice', {
          groupId: groupId.groupId,
          state: 'CREATED',
          noticeType: 'SUBMISSION',
          title,
          body,
          submitAt: groupId.submitAt,
          releaseAt: groupId.releaseAt,
        })
      );
      await axios.all(requests);
      // 모달 닫기
      setShow(false);
      setTitle('');
      setBody('');

      // 공지 목록 조회
      getGroupnoticeByGroup();
    } catch (error) {
      console.log(error);
    }
  }, [title, body, groupIds, setShow, getGroupnoticeByGroup]);

  // ------------------------------------
  // notification 생성, 공지 생성 완료 후 실행
  // ------------------------------------
  // const createNotification = useCallback(async () => {
  //   try {
  //     const body = {
  //       state: "CREATED",
  //       notificationType: "COMMAND",
  //       notificationKind: "NOTIFY_ASSIGNMENT",
  //       fromClientId: clientId,
  //     };
  //     const requests = students.map((student) => axios.post("https://ktor.focuspang.com/v2/notification", { ...body, toClientId: student.clientId }));
  //     // notification 생성 api 일괄 처리 responses
  //     const responses = await axios.all(requests);
  //     console.log(responses);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }, [clientId, students]);

  const handleClickAddClass = useCallback(() => {
    setGroupIds((prev) => [
      ...prev,
      {
        groupId,
        key: new Date().getTime(),
        submitAt: new Date(),
        releaseAt: new Date(),
      },
    ]);
  }, [groupId]);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered size="xl">
      <Stack>
        <Stack p={3} spacing={3}>
          <Typography variant="h6">새 과제를 등록합니다.</Typography>
          <Tooltip title="과제의 제목을 입력할 수 있습니다." arrow>
            <TextField
              variant="standard"
              size="small"
              fullWidth
              placeholder="과제 제목 입력"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
            />
          </Tooltip>
        </Stack>
        <Divider />

        <Stack p={3}>
          <Typography>과제를 공지할 반을 선택해주세요.</Typography>
        </Stack>

        {groupIds.map((groupId) => (
          <AssignmentClass
            key={groupId.key}
            groupId={groupId}
            groupIds={groupIds}
            setGroupIds={setGroupIds}
          />
        ))}

        <Stack p={3}>
          <Tooltip
            title="클릭하실 경우 과제를 공지할 반을 추가하실 수 있으시며 추가 된 반에 같은 내용으로 과제 공지가 가능합니다."
            placement="top"
            arrow
          >
            <Button
              startIcon={<AddCircleIcon />}
              sx={{ width: 'fit-content' }}
              onClick={handleClickAddClass}
              style={{
                borderRadius: '24px',
                backgroundColor: '#0894A0',
                color: '#ffffff',
                height: '28px',
              }}
            >
              공지할 반 추가하기
            </Button>
          </Tooltip>
        </Stack>

        <Stack p={3} spacing={3}>
          <Typography>과제 내용을 입력해주세요.</Typography>
          <TextEditor value={body} onChange={setBody} />
        </Stack>
        <Divider />
        <Stack
          direction="row"
          p={1}
          justifyContent="flex-end"
          spacing={1}
          alignItems="center"
        >
          {validation && (
            <Typography
              variant="body2"
              color="#FF1744"
              display="flex"
              alignItems="center"
            >
              <ErrorIcon fontSize="10px" sx={{ mr: 0.5 }} />
              {!title ? '공지 제목을 입력해주세요' : '공지 내용을 입력해주세요'}
            </Typography>
          )}
          <Button
            style={{
              border: '1px solid #e0e0e0',
            }}
            color="_red"
            onClick={() => setShow(false)}
          >
            취소
          </Button>
          <Button
            style={{
              border: '1px solid #e0e0e0',
              marginRight: '16px',
            }}
            onClick={() => {
              createGroupnotice();
              // ReactGA.event({
              //   action: "Create Assignment Notice Click",
              //   category: "Button",
              //   label: "Assignment Notice",
              // });
            }}
          >
            등록
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};
