import { Modal } from 'react-bootstrap';
import styles from './ModalAddManage.module.scss';
import { GRADE_LIST } from '@app/Constants/groupTimetable';
import { Dropdown } from '../Modal/ModalEditClassTimetable';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  createGroupPromise,
  createMembershipPromise,
  getMembershipByClientPromise,
  setSocketData,
  updateGroupPromise,
} from '@app/store/actions';

const ModalAddManage = ({
  onHide,
  isUpdateModal,
  managingGroupForUpdate,
  setManagingGroupForUpdate,
  ...props
}) => {
  const dispatch = useDispatch();
  const { schoolId } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);

  const [grade, setGrade] = useState();
  const [groupName, setGroupName] = useState();

  const createNewManageGroup = async () => {
    if (!grade || !groupName) {
      alert('학년과 자기관리 그룹 이름을 지어주세요.');
      return;
    }
    const groupData = {
      schoolId: schoolId,
      state: 'CREATED',
      groupType: 'AFTER',
      subjectName: groupName,
      groupName: '1',
      grade: grade,
    };

    await dispatch(createGroupPromise(groupData)).then(async (createdGroup) => {
      dispatch(
        createMembershipPromise({
          groupId: createdGroup.groupId,
          clientId: user.clientId,
          role: 'TEACHER',
        })
      );
      await dispatch(getMembershipByClientPromise(user.clientId));
    });
  };

  const updateManageGroup = async () => {
    if (!grade || !groupName) {
      alert('학년과 자기관리 그룹 이름을 지어주세요.');
      return;
    }
    const updatedGroupData = {
      groupId: managingGroupForUpdate.groupId,
      groupType: 'AFTER',
      subjectName: groupName,
      groupName: '1',
      grade: grade,
    };
    dispatch(updateGroupPromise(updatedGroupData)).then(() => {
      dispatch(
        setSocketData({
          method: 'POST',
          uri: '/classroom/sendImage',
          groupId: managingGroupForUpdate?.groupId,
          clientId: user.clientId,
          type: 'MANAGE_GROUP_UPDATE',
          data: '',
        })
      );
      alert('수정을 완료하였습니다.');
    });
  };

  useEffect(() => {
    setGrade(managingGroupForUpdate?.grade.toString());
  }, [managingGroupForUpdate]);

  return (
    <Modal
      {...props}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles['AddApp-container']}
    >
      <Modal.Header className={styles['modal-bg-header']}>
        <div className={styles['title-css']}>
          수업 시간 외 앱/웹 관리 그룹을 {isUpdateModal ? '수정' : '추가'}
          합니다.
        </div>
      </Modal.Header>
      <Modal.Body className={styles['modal-bg-body']}>
        <div className={styles['body-title']}>
          <div className={styles['choice-title']}>
            {isUpdateModal ? '수정' : '관리'}하실 학년을 선택해주세요.
          </div>
          <div className={styles['grade-drop']}>
            <Dropdown
              title="학년"
              name="grade"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              data={GRADE_LIST}
            />
          </div>
          <div className={styles['choice-title2']}>
            {isUpdateModal && '수정하실 '}관리 그룹 이름을 지어주세요.
          </div>
          <input
            className={styles['group-input']}
            name="subjectName"
            placeholder={managingGroupForUpdate?.subjectName}
            onChange={(e) => setGroupName(e.target.value)}
            maxLength="25"
          />
        </div>
        <div className={styles['button-container']}>
          <span
            className={styles['cancel-button']}
            type="button"
            onClick={() => {
              onHide();
              setGrade();
              setGroupName();
              setManagingGroupForUpdate();
            }}
          >
            취소
          </span>
          <span
            className={styles['add-button']}
            type="button"
            onClick={() => {
              {
                isUpdateModal ? updateManageGroup() : createNewManageGroup();
              }
              setGrade();
              setGroupName();
              onHide();
              setManagingGroupForUpdate();
            }}
          >
            {isUpdateModal ? '수정' : '추가'}
          </span>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddManage;
