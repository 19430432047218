import { call, put, takeLatest, takeEvery } from "redux-saga/effects";
import {
  getCommentsLayerPromise,
  getCommentsLayerSuccess,
  getCommentsLayerFail,
  createCommentsLayerPromise,
  createCommentsLayerSuccess,
  createCommentsLayerFail,
  updateCommentsLayerPromise,
  updateCommentsLayerSuccess,
  updateCommentsLayerFail,
} from "@store/actions";
import * as api from "@api";
import { implementPromiseAction } from "@adobe/redux-saga-promise";
import { createCommentaryFail, createCommentaryPromise, createCommentarySuccess, updateCommentaryFail, updateCommentaryPromise, updateCommentarySuccess } from "./actions";

/**
 * CommentsLayer 활성 상태
 * @param { groupId } action
 */
function* getCommentsLayer(action) {
  // yield put(updateGroupActivityLoading(true));
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getCommentsLayer, action.payload);
      yield put(getCommentsLayerSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getCommentsLayerFail(error));
    } finally {
      // yield put(updateGroupActivityLoading(false));
    }
  });
}

/**
 * CommentsLayer 생성
 * @param { classroomId, clientId, layerWidth, layerHeight, isActivated } action
 */
function* createCommentsLayerPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createCommentsLayer, action.payload);
      yield put(createCommentsLayerSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createCommentsLayerFail(error));
    }
  });
}

/**
 * CommentsLayer 수정
 * @param { classroomId, isActivated } action
 */
function* updateCommentsLayerPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateCommentsLayer, action.payload);
      yield put(updateCommentsLayerSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateCommentsLayerFail(error));
    }
  });
}

/**
 * Commentary 생성
 
 * @param {
 *  "commentaryFrom": "XXXX-XXXX-XXXX-XXXX", // UUID
 *  "commentaryState": "PERSONAL |PRIVATE | PUBLIC", // String
 *  "commentaryTo": "ClientId, ClientId List, SubgroupId, ClassroomId", // JSON formatted String
 *  "commentaryType": "PEN | TXT | BTN", // String
 *  "commentaries": "CAN BE COORDINATE ARRAY, TEXT, ", // JSON formatted String
  } action
 */
function* createCommentaryPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createCommentary, action.payload);
      yield put(createCommentarySuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createCommentaryFail(error));
    }
  });
}

/**
 * Commentary 수정

 * @param { 
 * commentaryId: XXXX-XXXX-XXXX-XXXX (required)
 * commentaryFrom: XXXX-XXXX-XXXX-XXXX (opional)
 * commentaryState: XXXX (optional, String)
 * commentaryTo: xxxx (optional, Stringified JSON)
 * commentaryType: xxxx (optional, String)
 * commentaries: xxxx (optional, Stringified JSON)
 * } action
 */
function* updateCommentaryPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateCommentary, action.payload);
      yield put(updateCommentarySuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateCommentaryFail(error));
    }
  });
}

function* commentsLayerSaga() {
  yield takeLatest(getCommentsLayerPromise, getCommentsLayer);
  yield takeEvery(
    createCommentsLayerPromise,
    createCommentsLayerPromiseHandler
  );
  yield takeEvery(
    updateCommentsLayerPromise,
    updateCommentsLayerPromiseHandler
  );
  yield takeEvery(
    createCommentaryPromise,
    createCommentaryPromiseHandler
  );
  yield takeEvery(
    updateCommentaryPromise,
    updateCommentaryPromiseHandler
  );
}

export default commentsLayerSaga;
