import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signInPromise } from '@store/actions';
import styles from './index.module.scss';
import BaseCard from '@components/Card/baseCard';
import { Button } from '@mui/material';
import Password from '@components/Input/password';
import MdInput from '@components/Input/MdInput';
import { useState } from 'react';
import { updateUserPromise } from '@app/store/actions';

const isEmailValid = (email) => {
  const emailPrefix = email.split('@')[0];
  const regex = /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i;
  if (!email || regex.test(email) === false) {
    console.log('entered email: ', email);
    alert('이메일 형식이 올바르지 않습니다.');
    return false;
  }
  if (emailPrefix.length > 30) {
    alert('이메일 앞자리는 30자 이내로 입력해주세요.');
    return false;
  }
  return true;
};

const SignIn = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const user = useSelector((state) => state.user);
  const [checkedList, setCheckedList] = useState(false);
  const [LoginFailure, setLoginFailure] = useState(0);
  const [inputEmail, setInputEmail] = useState();

  useEffect(() => {
    if (LoginFailure >= 10) {
      alert(
        '로그인 시도가 10회 이상 실패하여 접속이 제한되었습니다. 문의해 주시기 바랍니다.'
      );
      dispatch(
        updateUserPromise({
          email: inputEmail,
          state: 'ACCOUNT_SUSPENSION',
        })
      ).then((res) => console.log('[ACCOUNT_SUSPENSION]', res));
      return;
    }
  }, [LoginFailure]);

  const checkOnlyOne = (checkThis) => {
    const checkboxes = document.getElementsByName('select');
    for (let i = 0; i < checkboxes.length; i++) {
      if (checkboxes[i] !== checkThis) {
        checkboxes[i].checked = false;
      }
    }
    setCheckedList(checkThis.value);
  };

  useEffect(() => {
    if (
      user.state === 'REGISTERED_APPROVED' ||
      user.state === 'REGISTERED_CERT' ||
      user.state === 'REGISTERED_PENDING' ||
      user.state === 'REGISTERED_GOOGLEFORMS' ||
      user.state === 'ACCOUNT_SUSPENSION'
    ) {
      // redirect to auth manager
      history.replace('/auth-manager');
      // window.location.href = "/auth-manager";
    }
  }, [user.state]);

  const onSubmit = (e) => {
    console.log('submit email');
    const email = e.target.email.value;
    const email_trim = email.replace(/ /gi, '');
    setInputEmail(email_trim);
    const password = e.target.password.value;
    e.preventDefault();
    if (!isEmailValid(email_trim)) {
      return;
    }
    if (password.trim() === '') {
      alert('비밀번호를 입력해 주세요!');
      return;
    }
    dispatch(
      signInPromise({
        email: email_trim,
        password: password,
        role: 'TEACHER',
      })
    )
      .then((clientInfo) => {
        console.log('TEACHER login', clientInfo);
        if (clientInfo === undefined) {
          throw new Error('login failed');
        }
        history.replace('/auth-manager');
      })
      .catch((error) => {
        console.log('TEACHER login catch', error);
        dispatch(
          signInPromise({
            email: email_trim,
            password: password,
            role: 'ADMIN',
          })
        )
          .then((clientInfo) => {
            console.log('ADMIN login', clientInfo);
            if (clientInfo === undefined) {
              throw new Error('login failed');
            }
            history.replace('/auth-manager');
          })
          .catch((error) => {
            console.log('login catch', error);
            alert(`로그인에 실패했습니다.
      이메일 또는 비밀번호를 확인해주세요.`);
            localStorage.setItem('count', setLoginFailure(LoginFailure + 1));
          });
      });
    console.log('confirm user', user);
  };

  const Intro = {
    title: '오늘도 즐겁게 수업 🖥',
    desc: `스마트한 오프라인 수업 운영과 학생케어 서비스 포커스팡 ai`,
    formTitle: '포커스팡 ai 선생님 로그인',
  };

  return (
    <div className={styles['container']}>
      <BaseCard>
        <div className={styles['inner-box']}>
          <div style={{ flex: 1 }}>
            <div className={styles['Span-greeting']}>{Intro.title}</div>
            <div className={styles['middle-text']}>{Intro.desc}</div>

            <div className={styles['form-container']}>
              <form onSubmit={onSubmit}>
                <div className={styles['form-title']}>{Intro.formTitle}</div>
                <MdInput name="email" type="email" label="이메일 주소 입력" />
                <Password name="password" label="비밀번호 입력" />
                <Button
                  variant="contained"
                  type="submit"
                  className={styles['submit-btn']}
                >
                  로그인 하기
                </Button>
              </form>
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  );
};

export default SignIn;
