import React, { useState, useEffect } from 'react';
import Sidebar from '../../components/Layout/Sidebar';
import { useHistory, useLocation } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { useSelector } from 'react-redux';
import ChatBot from 'react-simple-chatbot';
import { selectInviteCode } from '@store/selectors';
import FileDragDrop from '@components/DragDrop/FileDragDrop';
import CheckBox from '@components/Checkbox/Checkbox';
import styles from './add-students.module.scss';
import imgExcelExample from '@assets/images/chatbot/excelExample.png';
import excelFileExample from '@assets/files/엑셀양식파일.xlsx';
import CopyToClipboard from 'react-copy-to-clipboard';
import { QRCodeSVG } from 'qrcode.react';
import { setToken, getStorageData, useInterval } from '@api';
// import { encrypt, decrypt} from "vigenere-cipher";

const AddStudents = (props) => {
  const [inputs, setInputs] = useState({});
  const [endForm, setEndForm] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [selectedGroupKind, setSelectedGroupKind] = useState('CLASSROOM');
  const inviteCode = useSelector((state) => state.invite.inviteCode);
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);
  // const groupData = location.state.group;
  // setUrlLink("https://focuspangdownload.com/" + groupData.id);
  const InviteLink = (props) => {
    const codeJSON = JSON.parse(inviteCode);

    return (
      <div style={{ textAlign: 'center' }}>
        <br />
        <span style={{ fontSize: 80 }}>{codeJSON['code']}</span>
        <div style={{ marginTop: 8, color: 'gray' }}>
          학생들에게 초대코드를 알려주세요!
        </div>
        <br />
        <button
          onClick={() => props.triggerNextStep({ value: null, trigger: '1' })}
          className={'retry-button'}
        >
          학생 등록 방법 다시 선택하기
        </button>
      </div>
    );
  };

  const getValue = (e) => {
    console.log(e.target.value);
    setInputValue(e.target.value);
  };
  const inputAttributes = {
    onChange: getValue,
    value: inputValue,
  };

  const handleEnd = ({ steps }) => {
    setEndForm(true);
    // console.log(inputs);
    // console.log(steps);
    // console.log(steps.classType?.value);

    const updatedInput = {
      grade: steps.grade?.value,
      class_name: steps.classNum?.value,
      school: steps.schoolName?.value.id,
      kind: steps.classType?.value,
    };
    setInputs({
      ...inputs,
      ...updatedInput,
    });
  };

  const navigateTo = () => history.replace('/home');

  const steps = [
    // {
    //   id: "1",
    //   message:
    //     "학생들을 대상으로 서비스 이용약관, 개인정보 이용약관, 제3자제공 약관 및 선생님의 제3자 제공 약관 동의에 대한 가정통신문을 받으셨습니까?",
    //   trigger: "select",
    // },
    // {
    //   id: "select",
    //   options: [
    //     {
    //       value: "agree",
    //       label: "받았습니다.",
    //       trigger: "agree",
    //     },
    //     {
    //       value: "notagree",
    //       label: "아직 받지 않았습니다.",
    //       trigger: "notagree",
    //     },
    //   ],
    // },
    // {
    //   id: "agree",
    //   options: [
    //     {
    //       value: "inputmail",
    //       label:
    //         "Yoursucess@focuspang.com 으로 가정통신문을 보내주신 후 진행해주세요.",
    //       trigger: "inputmail",
    //     },
    //   ],
    // },
    {
      id: '1',
      message: '학생 명단을 등록할 방법을 선택해 주세요.',
      trigger: 'registrationType',
    },
    {
      id: 'notagree',
      message: '초대 링크를 통해 학생을 등록해주세요.',
      trigger: 'registrationType2',
    },
    // {
    //   id: "2",
    //   user: true,
    //   trigger: "registrationType",
    // },
    {
      id: 'registrationType2',
      options: [
        {
          value: 'invite',
          label: '학생에게 초대링크 보내기',
          trigger: 'invite',
        },
        {
          value: 'invite2',
          label: '학생 등록 방법 다시 선택하기',
          trigger: 'notagree',
        },
      ],
    },
    {
      id: 'registrationType',
      options: [
        {
          value: 'invite',
          label: '학생에게 초대링크 보내기',
          trigger: 'invite',
        },
        // {
        //   value: "neis",
        //   label: "NEIS에서 학생정보 가져오기",
        //   trigger: "neis-intro",
        // },
        // {
        //   value: "google_classroom",
        //   label: "구글클래스룸에서 가져오기",
        //   trigger: "google-classroom-intro",
        // },
        // {
        //   value: "teams",
        //   label: "MS 팀즈에서 가져오기(곧 만나요!)",
        //   trigger: "teams",
        // },
        {
          value: 'excel',
          label: 'EXCEL로 직접 등록하기',
          trigger: 'excel',
        },
        // {
        //   value: "invite3",
        //   label: "학생 등록 방법 다시 선택하기",
        //   trigger: "registrationType",
        // },
      ],
    },
    {
      id: 'invite',
      component: <InviteLink />,
      waitAction: true,
      trigger: 'end-message',
    },
    {
      id: 'neis-intro',
      message: 'NEIS에서 EXCEL파일을 다운로드 받아서 여기에 끌어다 놓아주세요.',
      trigger: 'neis',
    },
    {
      id: 'neis',
      message:
        '엑셀 파일에는 아래 양식 예시와 같이 학생들의 성명, 학생개인번호를 포함시켜 주세요',
      trigger: 'excel-example',
    },
    {
      id: 'google-classroom-intro',
      message:
        '구글클래스룸에서 만드신 과제 중 한 개를 클릭하셔서, "모든 성적을 CSV로 다운로드" 해주세요.',
      trigger: 'google-classroom',
    },
    {
      id: 'google-classroom',
      message: '이후 다운 받은 CSV파일을 여기에 끌어다 놓아주세요.',
      trigger: 'file-drag-drop',
    },
    // {
    //   id: "teams",
    //   message: "준비중입니다. 곧 사용 가능할 거예요!",
    //   // message: "MS 팀즈에서 CSV파일을 다운로드 받아서 여기에 끌어다 놓아주세요. ",
    //   trigger: "1",
    // },
    {
      id: 'excel',
      message:
        'EXCEL파일의 양식을 아래 양식 예시와 같이 작성해서 여기에 끌어다 놓아주세요. ',
      trigger: 'excel-example',
    },
    {
      id: 'excel-example',
      component: (
        <div className={styles['excel-example-container']}>
          <img
            src={imgExcelExample}
            alt="excelExample"
            style={{ width: '200px' }}
          />
          <a href={excelFileExample} className={styles['retry-button']}>
            엑셀 양식 파일 다운로드 받기
          </a>
        </div>
      ),
      trigger: 'file-drag-drop',
    },
    // {
    //   id: "checkbox",
    //   component: (
    //     <CheckBox />
    //   ),
    //   waitAction: true,
    //   trigger: "end-message",
    // },
    {
      id: 'file-drag-drop',
      component: <FileDragDrop group={location.state?.group} />,
      waitAction: true,
      trigger: 'end-message',
    },
    {
      id: 'end-message',
      message: '학생 추가가 완료되었습니다. 홈 화면으로 곧 이동해드릴게요.',
      end: true,
    },
  ];
  return (
    <div className={styles['container']}>
      <Sidebar auth={true} />
      <div className={styles['chatbot-container']}>
        {/* <div className="items-center justify-center w-1/2 space-x-40 bg-white pt-60"> */}
        {/* <div className="space-x-40 ">
            <p className="pl-40 mb-5 text-4xl">반가워요!</p>
            <div className="text-2xl text-[#535250] space-y-3"> */}
        <p className={styles['title']}>학생을 등록해주세요.</p>
        {/* </div>
          </div> */}
        {/* <div>
            <div className="space-y-2 text-red-300 pt-60">
              <p>수업시간 및 각 과목의 이름은 어디서 받는지</p>
              <p>반 이름이 숫자가 아닌 경우 존재(특히 분반수업)</p>
            </div>
          </div> */}
        {/* </div> */}

        <div className={styles['chatbot']}>
          <ThemeProvider theme={chatBotTheme}>
            <ChatBot
              background="#fff"
              headerTitle="학생 추가하기"
              width="400px"
              handleEnd={handleEnd}
              steps={steps}
              placeholder="내용을 입력하세요"
              hideBotAvatar={true}
              inputAttributes={inputAttributes}
              bubbleOptionStyle={bubbleOptionStyle}
            />
          </ThemeProvider>

          {endForm && setTimeout(navigateTo, 5000)
          // <div className="pl-[7rem]">
          //   <button
          //     onClick={navigateTo}
          //     className=" bg-[#c0cc84] hover:bg-[#84923d] text-white font-bold py-2 px-4 rounded mt-10 w-23 relative"
          //   >
          //     홈 화면으로
          //   </button>
          // </div>
          }
        </div>
      </div>
    </div>
  );
};

export default AddStudents;

const chatBotTheme = {
  background: '#faf9f7',
  headerBgColor: '#dee07e',
  headerFontColor: '#353433',
  userBubbleColor: '#ffffff',
  botBubbleColor: '#dee07e',
  footerBgColor: '#f4f2ef',
  headerFontSize: '16px',
};

const bubbleOptionStyle = {
  backgroundColor: chatBotTheme.userBubbleColor,
  border: '2px solid #dee07e',
  width: 'fit-content',
  maxwidth: '300px',
  float: 'left',
};
