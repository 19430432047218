import * as api from '@api'
import { call, put, takeLeading } from 'redux-saga/effects'
import { implementPromiseAction } from '@adobe/redux-saga-promise'
import {
  createQuizsetSessionByQuizFail,
  createQuizsetSessionByQuizPromise,
  createQuizsetSessionByQuizSuccess,
  createQuizsetSessionFail,
  createQuizsetSessionPromise,
  createQuizsetSessionSuccess,
  getQuizsetSessionHistoryFail,
  getQuizsetSessionHistoryPromise,
  getQuizsetSessionHistorySuccess,
  getQuizsetSessionResultFail,
  getQuizsetSessionResultPromise,
  getQuizsetSessionResultSuccess,
} from '@app/store/quizsetSession/actions'

function* getQuizsetSessionHistoryPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getQuizsetSessionHistories, action.payload);
      yield put(getQuizsetSessionHistorySuccess(response.data.quizsetSessionHistories));
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetSessionHistoryFail(error));
    }
  });
}

function* createQuizsetSessionPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuizsetSession, action.payload);
      yield put(createQuizsetSessionSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizsetSessionFail(error));
    }
  });
}

function* createQuizsetSessionByQuizPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createQuizsetSessionByQuiz, action.payload);
      yield put(createQuizsetSessionByQuizSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createQuizsetSessionByQuizFail(error));
    }
  });
}

function* getQuizsetSessionResultPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const { quizsetSessionId, quizIndex } = action.payload
      const response = yield call(api.getQuizsetSessionResult, quizsetSessionId, quizIndex);
      yield put(getQuizsetSessionResultSuccess({
        quizsetSessionId,
        result: response.data,
      }));
      return yield response.data;
    } catch (error) {
      yield put(getQuizsetSessionResultFail(error));
    }
  });
}

function* quizsetSessionSaga() {
  yield takeLeading(
    getQuizsetSessionHistoryPromise,
    getQuizsetSessionHistoryPromiseHandler,
  );
  yield takeLeading(
    createQuizsetSessionPromise,
    createQuizsetSessionPromiseHandler,
  );
  yield takeLeading(
    createQuizsetSessionByQuizPromise,
    createQuizsetSessionByQuizPromiseHandler,
  );
  yield takeLeading(
    getQuizsetSessionResultPromise,
    getQuizsetSessionResultPromiseHandler,
  );
}

export default quizsetSessionSaga;
