import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Modal } from 'react-bootstrap';
import styles from './ModalEditClassTimetable.module.scss';
import AddIcon from '@mui/icons-material/Add';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllMyGroups } from '@store/selectors';
import {
  createTimetablePromise,
  deleteTimetablePromise,
  updateTimetablePromise,
  createGroupPromise,
  updateGroupPromise,
  createMembershipPromise,
} from '@store/actions';
import BaseSelect from '../Select/baseSelect';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { isArray } from '@craco/craco/lib/utils';
import {
  deleteGroupPromise,
  getTimetablesByGroupPromise,
  setSocketData,
} from '@app/store/actions';
import { CLASS_TIME_LIST, WEEKDAYS } from '@app/Constants/groupTimetable';
import Loading from '../Loading/Loading';
import classNames from 'classnames';
import { Tooltip } from '@mui/material';

export const Dropdown = ({
  title = '',
  name,
  value,
  onChange = () => {},
  onClick = () => {},
  data = [],
  defaultValue,
}) => {
  return (
    <BaseSelect
      title={title}
      name={name}
      onChange={onChange}
      onClick={onClick}
      value={value}
      defaultValue={defaultValue}
    >
      <option style={{ display: 'none' }}>{title}</option>
      {data.map((d, idx) => {
        return (
          <option value={d[0]} id={idx} key={idx}>
            {d[1]}
          </option>
        );
      })}
    </BaseSelect>
  );
};

const gradeList = [
  [1, '1학년'],
  [2, '2학년'],
  [3, '3학년'],
  [4, '4학년'],
  [5, '5학년'],
  [6, '6학년'],
];

const classGrouplist = [
  [1, '1반'],
  [2, '2반'],
  [3, '3반'],
  [4, '4반'],
  [5, '5반'],
  [6, '6반'],
  [7, '7반'],
  [8, '8반'],
  [9, '9반'],
  [10, '10반'],
  [11, '11반'],
  [12, '12반'],
  [13, '13반'],
  [14, '14반'],
  [15, '15반'],
  [16, '16반'],
  [17, '17반'],
  [18, '18반'],
  [19, '19반'],
  [20, '20반'],
  [21, '21반'],
  [22, '22반'],
  [23, '23반'],
  [24, '24반'],
  [25, '25반'],
  [26, '26반'],
  [27, '27반'],
  [28, '28반'],
  [29, '29반'],
  [30, '30반'],
  [31, '31반'],
  [32, '32반'],
  [33, '33반'],
  [34, '34반'],
  [35, '35반'],
  [36, '36반'],
  [37, '37반'],
  [38, '38반'],
  [39, '39반'],
  [40, '40반'],
  [41, '41반'],
  [42, '42반'],
  [43, '43반'],
  [44, '44반'],
  [45, '45반'],
  [46, '46반'],
  [47, '47반'],
  [48, '48반'],
  [49, '49반'],
  [50, '50반'],
];

const classtimes = [
  [1, '1교시'],
  [2, '2교시'],
  [3, '3교시'],
  [4, '4교시'],
  [5, '5교시'],
  [6, '6교시'],
  [7, '7교시'],
  [8, '8교시'],
  [9, '9교시'],
];

const weekdays = [
  [1, '월요일'],
  [2, '화요일'],
  [3, '수요일'],
  [4, '목요일'],
  [5, '금요일'],
];

const initTimetableData = {
  week: '',
  period: '',
  startTime: '09:00',
  endTime: '09:45',
};
const initGradeData = {
  timetable: [{ ...initTimetableData }],
  groupType: 'SUBJECT',
  groupName: '',
  grade: '',
};
const ModalEditClassTimetable = ({ formData, show, onHide }) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const [subjectName, setSubjectName] = useState(formData.subjectName || '');
  const [creatingFields, setCreatingFields] = useState([
    {
      ...initGradeData,
      timetable: [
        {
          ...initTimetableData,
          week: formData.weekDay,
          period: formData.period,
        },
      ],
    },
  ]);
  const [editingFields, setEditingFields] = useState({
    groupType: formData.groupType || 'SUBJECT',
    groupName: formData.groupName,
    grade: formData.grade,
    week: formData.weekDay,
    period: formData.period,
    startTime: formData.startTime,
    endTime: formData.endTime,
  });
  const { clientId, schoolId } = useSelector((state) => state.user);
  const allMyGroups = useSelector((state) => selectAllMyGroups(state));
  const [loading, setLoading] = useState(false);
  const [startCheck, setStartCheck] = useState('09:00');
  const [endCheck, setEndCheck] = useState('09:45');
  const startH = startCheck.substring(0, 2) * 60;
  const startM = startCheck.substring(3, 5);
  const startSum = parseInt(startH) + parseInt(startM);
  const endH = endCheck.substring(0, 2) * 60;
  const endM = endCheck.substring(3, 5);
  const endSum = parseInt(endH) + parseInt(endM);
  // const [formCheck, setFormCheck] = useState(false);
  const [timeCheck, setTimeCheck] = useState(false);
  const [timeCheckArray, setTimeCheckArray] = useState([]);
  const [editingTimeCheck, setEditingTimeCheck] = useState(false);

  const findGroupId = async (grade, groupName, subjectName) => {
    const group = allMyGroups.find(
      (group) =>
        group.groupName.toString() === groupName &&
        group.grade.toString() === grade &&
        group.subjectName === subjectName
    );
    return group?.groupId;
  };

  useEffect(() => {
    creatingFields.map((field) => {
      field.timetable.map((time) => {
        const key = `${time.week}${time.period}`;
        if (time.endTime > time.startTime) {
          setTimeCheckArray((prev) => ({ ...prev, [key]: false }));
        }
        if (time.endTime <= time.startTime) {
          setTimeCheckArray((prev) => ({ ...prev, [key]: true }));
        }
      });
    });
  }, [creatingFields]);

  useEffect(() => {
    if (editingFields.endTime > editingFields.startTime) {
      setTimeCheck(false);
    }
    if (editingFields.endTime <= editingFields.startTime) {
      setTimeCheck(true);
    }
  }, [editingFields]);

  useEffect(() => {
    const check = Object.values(timeCheckArray).some((value) => value);
    setTimeCheck(check);
  }, [timeCheckArray]);

  const sendEditTimetableBySocket = useCallback((groupId) => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: groupId,
        clientId: user?.clientId,
        type: 'EDIT_TIMETABLE',
        data: '',
      })
    );
  });

  const onAddGroup = () => {
    setCreatingFields([
      ...creatingFields,
      {
        ...initGradeData,
        timetable: [{ ...initTimetableData }],
      },
    ]);
  };
  const onAddTimetable = (fieldIndex) => {
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable.push({ ...initTimetableData });
    setCreatingFields(_createFields);
  };
  const onRemoveTimetable = (fieldIndex, timeIndex) => {
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable.splice(timeIndex, 1);
    if (!_createFields[fieldIndex].timetable.length) {
      _createFields.splice(fieldIndex, 1);
    }
    setCreatingFields(_createFields);
  };
  const onChangeGroup = (fieldIndex, e) => {
    const { name, value } = e.target;
    let _createFields = [...creatingFields];
    _createFields[fieldIndex][name] = value;
    setCreatingFields(_createFields);
  };
  const onChangeTimetable = (fieldIndex, timeIndex, e) => {
    const { name, value } = e.target;
    let _createFields = [...creatingFields];
    _createFields[fieldIndex].timetable[timeIndex][name] = value;
    setCreatingFields(_createFields);
  };
  const onChangeEditData = (e) => {
    const { name, value } = e.target;
    setEditingFields((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  const constants = useMemo(() => {
    const grades = [...new Set(allMyGroups.map((group) => group.grade))];
    const myClassLists = [
      ...new Set(
        allMyGroups.map(
          (group) =>
            `${group.grade}학년 ${group.groupName}반 ${group.subjectName}수업`
        )
      ),
    ];
    grades.sort((a, b) => a - b);
    myClassLists.sort((a, b) => a - b);
    const _grades = grades.map((g) => [g, g.toString()]);
    const _myClassLists = myClassLists.map((g) => [g, g.toString()]);

    return {
      grades: _grades,
      myClassLists: _myClassLists,
      classGradelist: gradeList,
      classtimes,
      weekdays,
      classGrouplist: classGrouplist,
    };
  }, [allMyGroups]);

  const creatingTimetableDisabled = useMemo(
    () =>
      !!creatingFields.filter((field) => {
        if (!subjectName) {
          return true;
        }
        // if (formCheck) {
        //   return true;
        // }
        if (timeCheck) {
          return true;
        }
        const values = Object.values(field);
        return values.reduce((prev, curr) => {
          if (prev) return true;
          if (isArray(curr)) {
            return !!curr.filter((item) =>
              Object.values(item).some(
                (value) => value === undefined || value === ''
              )
            ).length;
          }
          return values.some((value) => value === undefined || value === '');
        }, false);
      }).length,
    [creatingFields, subjectName, timeCheck]
  );
  const editingTimetableDisabled = useMemo(() => {
    if (!subjectName) {
      return true;
    }
    if (timeCheck) {
      return true;
    }
    const values = Object.values(editingFields);
    return values.some((value) => value === undefined || value === '');
  }, [editingFields, subjectName, timeCheck]);

  const onCreate = async () => {
    setLoading(true);
    for (let i = 0; i < creatingFields.length; i++) {
      console.log('field', creatingFields[i]);
      const field = creatingFields[i];
      const groupData = {
        schoolId: schoolId,
        state: 'CREATED',
        groupType: field.groupType,
        subjectName,
        groupName: field.groupName,
        grade: field.grade,
      };
      // 중복된 학년과 반 그리고 수업 명이 있는지 확인하는 로직
      const duplicateGroup = await findGroupId(
        field.grade,
        field.groupName,
        subjectName
      );
      // 중복된 학년과 반 그리고 수업 명이 없다면 새로운 그룹을 생성한 뒤 그룹 데이터를 가지고 멤버쉽을 생성한다 그 다음 시간표를 생성한다.
      if (!duplicateGroup) {
        const createdGroup = await dispatch(createGroupPromise(groupData));
        dispatch(
          createMembershipPromise({
            groupId: createdGroup.groupId,
            clientId: user.clientId,
            role: 'TEACHER',
          })
        );

        await Promise.all(
          field.timetable.map(async (time) => {
            const timetableData = {
              groupId: createdGroup.groupId,
              weekday: time.week,
              period: time.period,
              startTime: time.startTime,
              endTime: time.endTime,
            };
            await dispatch(createTimetablePromise(timetableData));
            return true;
          })
        );
      } else {
        await Promise.all(
          field.timetable.map(async (time) => {
            const timetableData = {
              groupId: duplicateGroup,
              weekday: time.week,
              period: time.period,
              startTime: time.startTime,
              endTime: time.endTime,
            };
            await dispatch(createTimetablePromise(timetableData));
            return true;
          })
        );
      }
    }
    setLoading(false);
    onHide();
    // window.location.reload();
  };

  const editTimetable = () => {
    if (window.confirm('수업 정보를 수정하시겠습니까?')) {
      setLoading(true);
      const updatedGroupData = {
        groupId: formData.groupId,
        groupType: editingFields.groupType,
        subjectName,
        groupName: editingFields.groupName,
        grade: editingFields.grade,
        // clientId,
      };
      const updatedTimetableData = {
        timetableId: formData.timetableId,
        weekday: editingFields.week,
        period: editingFields.period,
        startTime: editingFields.startTime,
        endTime: editingFields.endTime,
      };
      dispatch(updateGroupPromise(updatedGroupData)).then((res) => {
        dispatch(updateTimetablePromise(updatedTimetableData)).then(() => {
          setLoading(false);
          onHide();
        });
        sendEditTimetableBySocket(res.groupId);
      });
    }
  };
  const onDeleteTimetable = async () => {
    setLoading(true);
    if (window.confirm('정말 삭제하시겠습니까?')) {
      await dispatch(deleteTimetablePromise(formData.timetableId));

      if (formData.timetables.length === 1) {
        dispatch(deleteGroupPromise(formData.groupId)).then(() => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: formData.groupId,
              clientId: user.clientId,
              type: 'CLASS_DELETE',
              data: '',
            })
          ).catch((err) => {
            console.error(err);
          });
        });
      }

      await dispatch(getTimetablesByGroupPromise(formData.groupId));
      setLoading(false);
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      className={styles['AddApp-container']}
      aria-labelledby="contained-modal-title-vcenter"
    >
      <Modal.Header>
        <div className={styles['title-css']}>
          {formData.isEdit ? '수업 정보를 변경합니다.' : `수업을 추가합니다.`}
        </div>
        {formData.isEdit ? (
          <div
            className={styles['delete-box']}
            type="button"
            onClick={onDeleteTimetable}
          >
            <DeleteIcon className={styles['delete-box-icon']} />
            <div className={styles['delete-box-title']}>수업삭제</div>
          </div>
        ) : null}
      </Modal.Header>
      <Modal.Body>
        {formData.isEdit ? (
          // 수업 정보 변경하기
          <div className={styles['body-main']}>
            <div className={styles['body-right']}>
              <div className={styles['modal-body']}>
                <div className={styles['modal-body-subject']}>
                  <div className={styles['body-title']}>과목명</div>
                  <input
                    className={styles['subject-input']}
                    id="subjectName"
                    name="subjectName"
                    placeholder="과목을 입력 해주세요"
                    onChange={(e) => setSubjectName(e.target.value)}
                    value={subjectName}
                    maxLength={25}
                  />
                </div>
              </div>
              <div className={styles['modal-body']}>
                <div className={styles['modal-body-grade']}>
                  <div className={styles['body-title']}>학년</div>
                  <Dropdown
                    title="학년"
                    name="grade"
                    value={editingFields.grade}
                    onChange={onChangeEditData}
                    data={constants.classGradelist}
                  />
                </div>
                <div className={styles['modal-body-group']}>
                  <div className={styles['body-title']}>반</div>
                  <Dropdown
                    className={styles['group-input']}
                    id="groupName"
                    name="groupName"
                    // placeholder="반"
                    title="반"
                    onChange={onChangeEditData}
                    value={editingFields.groupName}
                    data={constants.classGrouplist}
                  />
                </div>
              </div>
            </div>
            <div className={styles['left-container-addver']}>
              <div className={styles['body-left']}>
                <div className={styles['modal-body']}>
                  <div className={styles['modal-body-subjectTime']}>
                    <div className={styles['body-title']}>요일/교시</div>
                    <div className={styles['modal-body-daySetting']}>
                      <div className={styles['modal-body-week']}>
                        <Dropdown
                          title="요일"
                          name="week"
                          value={editingFields.week}
                          onChange={onChangeEditData}
                          data={WEEKDAYS}
                        />
                      </div>
                      <div className={styles['modal-body-period']}>
                        <Dropdown
                          title="교시"
                          name="period"
                          value={editingFields.period}
                          onChange={onChangeEditData}
                          data={CLASS_TIME_LIST}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className={styles['modal-body-left']}>
                  <div className={styles['modal-body-subjectTime']}>
                    <div className={styles['body-title']}>수업 시간</div>
                    <div className={styles['modal-body-timeSetting']}>
                      <input
                        className={styles['start-time']}
                        type="time"
                        id="start-time"
                        name="startTime"
                        placeholder="수업 시작 시간"
                        onChange={onChangeEditData}
                        onFocus={(e) => (e.target.type = 'time')}
                        value={editingFields.startTime}
                      />
                      <div className={styles['modal-text']}>-</div>
                      <div className={styles['modal-body-end']}>
                        <input
                          className={styles['end-time']}
                          type="time"
                          id="end-time"
                          name="endTime"
                          placeholder="수업 종료 시간"
                          onFocus={(e) => (e.target.type = 'time')}
                          onChange={onChangeEditData}
                          value={editingFields.endTime}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={styles['body-bottom']} style={{ marginTop: 6 }}>
                {timeCheck ? (
                  <div className={styles['class-time-check']}>
                    ⦁ 수업 종료 시간을 올바르게 설정해 주세요.
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        ) : (
          // 수업 추가하기
          <>
            <div
              className={styles['body-main']}
              style={{
                justifyContent: 'space-between',
                alignItems: 'flex-end',
              }}
            >
              <div className={styles['body-right']}>
                <div className={styles['modal-body']}>
                  <div className={styles['modal-body-subject']}>
                    <div className={styles['body-title']}>과목명</div>
                    <input
                      className={styles['subject-input']}
                      id="subjectName"
                      name="subjectName"
                      placeholder="과목을 입력 해주세요"
                      onChange={(e) => setSubjectName(e.target.value)}
                      value={subjectName}
                    />
                    {/* {formCheck ? (
                      <div className={styles['class-duplicate-chek']}>
                        중복된 수업이 존재합니다.
                      </div>
                    ) : (
                      <div></div>
                    )} */}
                  </div>
                </div>
              </div>
            </div>
            {creatingFields.map((createData, fieldIndex) => (
              <div key={fieldIndex} className={styles['body-main']}>
                <div className={styles['body-right']}>
                  <div className={styles['modal-body']}>
                    <div className={styles['modal-body-grade']}>
                      <div className={styles['body-title']}>학년</div>
                      <Dropdown
                        title="학년"
                        name="grade"
                        value={createData.grade}
                        onChange={(value) => onChangeGroup(fieldIndex, value)}
                        data={constants.classGradelist}
                      />
                    </div>
                    <div className={styles['modal-body-group']}>
                      <div className={styles['body-title']}>반</div>
                      <Dropdown
                        className={styles['group-input']}
                        id="groupName"
                        name="groupName"
                        // placeholder="반"
                        title="반"
                        onChange={(value) => onChangeGroup(fieldIndex, value)}
                        value={createData.groupName}
                        data={constants.classGrouplist}
                      />
                    </div>
                  </div>
                </div>
                <div className={styles['left-container-period']}>
                  {createData.timetable.map((timeData, timeIndex) => (
                    <div key={timeIndex} className={styles['body-left']}>
                      <div className={styles['modal-body-subjectTime']}>
                        <div className={styles['body-title']}>요일/교시</div>
                        <div className={styles['modal-body-daySetting']}>
                          <div className={styles['modal-body-week']}>
                            <Dropdown
                              title="요일"
                              name="week"
                              value={timeData.week}
                              onChange={(value) =>
                                onChangeTimetable(fieldIndex, timeIndex, value)
                              }
                              data={WEEKDAYS}
                            />
                          </div>
                          <div className={styles['modal-body-period']}>
                            <Dropdown
                              title="교시"
                              name="period"
                              value={timeData.period}
                              onChange={(value) =>
                                onChangeTimetable(fieldIndex, timeIndex, value)
                              }
                              data={CLASS_TIME_LIST}
                            />
                          </div>
                        </div>
                      </div>
                      <div className={styles['modal-body-left']}>
                        <div className={styles['modal-body-subjectTime']}>
                          <Tooltip
                            title="수업 시간의 종료 시간이 시작 시간보다 빠르거나 같지 않게 선택해 주세요."
                            arrow
                            placement="top"
                          >
                            <div
                              className={styles['body-title']}
                              style={{ width: 100 }}
                            >
                              수업 시간
                            </div>
                          </Tooltip>

                          <div className={styles['modal-body-timeSetting']}>
                            <input
                              className={styles['start-time']}
                              type="time"
                              id="start-time"
                              name="startTime"
                              placeholder="수업 시작 시간"
                              onChange={(value) => {
                                onChangeTimetable(fieldIndex, timeIndex, value);
                                setStartCheck(value.target.value);
                              }}
                              onFocus={(e) => (e.target.type = 'time')}
                              value={timeData.startTime}
                            />
                            <div className={styles['modal-text']}>-</div>
                            <div className={styles['modal-body-end']}>
                              <input
                                className={styles['end-time']}
                                type="time"
                                id="end-time"
                                name="endTime"
                                placeholder="수업 종료 시간"
                                onFocus={(e) => (e.target.type = 'time')}
                                onChange={(value) => {
                                  setEndCheck(value.target.value);
                                  onChangeTimetable(
                                    fieldIndex,
                                    timeIndex,
                                    value
                                  );
                                }}
                                value={timeData.endTime}
                              />
                            </div>
                            <RemoveCircleOutlineIcon
                              type="button"
                              className={styles['delete-modal']}
                              onClick={() =>
                                onRemoveTimetable(fieldIndex, timeIndex)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                  <div
                    className={styles['body-bottom']}
                    style={{ marginTop: 6 }}
                  >
                    {timeCheck ? (
                      <div className={styles['class-time-check']}>
                        ⦁ 수업 종료 시간을 올바르게 설정해 주세요.
                      </div>
                    ) : null}
                  </div>
                  <div className={styles['body-bottom']}>
                    <button onClick={() => onAddTimetable(fieldIndex)}>
                      <AddIcon
                        className={classNames(
                          styles['add-icon'],
                          styles['blinking']
                        )}
                      />
                      <div className={styles['modal-add']}>
                        수업 시간 추가하기
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </>
        )}
      </Modal.Body>
      {!formData.isEdit && (
        <div className={styles['modal-period-add']}>
          <div
            className={styles['modal-period-info']}
            type="button"
            onClick={onAddGroup}
          >
            <AddCircleIcon
              className={classNames(
                styles['modal-period-Icon']
                // , styles['blinking']
              )}
            />
            <div className={styles['modal-period-text']}>
              수업할 반을 추가하기
            </div>
          </div>
        </div>
      )}
      <div className={styles['modal-bg-footer']}>
        <button
          className={styles['footer-button-cancel']}
          type="button"
          onClick={onHide}
        >
          취소
        </button>
        {formData.isEdit ? (
          <button
            className={
              styles[
                editingTimetableDisabled
                  ? 'footer-button-disabled'
                  : 'footer-button'
              ]
            }
            type="button"
            disabled={editingTimetableDisabled}
            onClick={editTimetable}
          >
            변경
          </button>
        ) : (
          <button
            className={
              styles[
                creatingTimetableDisabled
                  ? 'footer-button-disabled'
                  : 'footer-button'
              ]
            }
            type="button"
            disabled={creatingTimetableDisabled}
            onClick={() => {
              onCreate();
              // ReactGA.event({
              //   action: 'create timetable click',
              //   category: 'Button',
              //   label: 'Timetable',
              // });
            }}
          >
            추가
          </button>
        )}
      </div>
      {/* {loading && <Loading />} */}
    </Modal>
  );
};

export default ModalEditClassTimetable;
