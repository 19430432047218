import { createSelector } from 'reselect';

export const selectAllParticipations = createSelector(
  (state) => state.allIds,
  (state) => state.byId,
  (allIds, byId) => {
    if (allIds.length === 0) {
      return [];
    }
    return allIds?.map((participation) => byId[participation]);
  }
);

export const selectParticipationsByClassroom = createSelector(
  (state) => state.participations,
  (state, classroomId) => classroomId,
  (state, classroomId) => {
    return selectAllParticipations(state).filter((participation) => {
      return participation.classroomId === classroomId;
    });
  }
);
