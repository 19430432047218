const processQuizItem = (item) => {
  if (!item) {
    return null;
  }
  return {
    ...item,
    choices: JSON.parse(item?.choices).map((data) => {
      const { answer, index, description, point } = data
      return { index, point, description, isAnswer: answer }
    }),
  }
}

export const selectClientQuizList = (state) => {
  return state.quiz.allIds.map((quizId) => processQuizItem(state.quiz.byId[quizId]))
}

export const selectClientQuiz = (state, quizId) => {
  return processQuizItem(state.quiz.byId[quizId])
}

export const selectOtherSchoolQuizList = (state) => {
  return state.quiz.otherSchoolAllIds.map((quizId) => processQuizItem(state.quiz.otherSchoolById[quizId]))
}
