import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  createProgramconfigPromise,
  deleteProgramconfigPromise,
  setSocketData,
} from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllPrograms, selectGroupProgramconfigs } from '@store/selectors';
import imgAppIcon from '@images/home/img_notice.png';
import styles from './ModalAddApp.module.scss';
import ICONS from '@images/appIcons/index.js';
import Checkbox from '@mui/material/Checkbox';

const ModalAddManagingApp = ({
  group,
  managingIdOfSchedule,
  newProgramIdsInWatchdogsManaging,
  setNewProgramIdsInWatchdogsManaging,
  programIdsInWatchdogsManaging,
  setProgramConfigData,
  programConfigData,
  ...props
}) => {
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const programs = useSelector((state) => selectAllPrograms(state));
  const frequentlyPrograms = programs.filter((program) => program.sorting === 0);
  const frequentlyProgramsFilter = frequentlyPrograms.filter((program) => program.alias !== 'samsungInternet').filter((program) => program.alias !== 'google');
  const mediaPrograms = programs.filter((program) => program.sorting === 1);
  const basicsPrograms = programs.filter((program) => program.sorting === 2);

  const [tempProgramConfigData, setTempProgramConfigData] = useState(
    programConfigData
  );

  const [
    tempProgramIdsInWatchdogsManaging,
    setTempProgramIdsInWatchdogsManaging,
  ] = useState(newProgramIdsInWatchdogsManaging);

  const saveManagingApp = async () => {
    setNewProgramIdsInWatchdogsManaging([...tempProgramIdsInWatchdogsManaging]);
    setProgramConfigData(tempProgramConfigData);
    props.onHide();
  };

  const toggleTempProgramIdInWatchdogsManaging = (programId) => {
    const alreadyExists = tempProgramIdsInWatchdogsManaging.includes(programId);
    const alreadyExistsTime = tempProgramConfigData.programId;

    if (alreadyExists) {
      setTempProgramIdsInWatchdogsManaging((prev) =>
        prev.filter((_programId) => _programId !== programId)
      );
    } else {
      setTempProgramIdsInWatchdogsManaging((prev) => prev.concat(programId));
    }

    if (alreadyExistsTime || alreadyExistsTime === 0) {
      let temp = tempProgramConfigData;
      delete temp[programId];
      setTempProgramConfigData(temp);
    } else {
      let temp2 = programConfigData;
      temp2[programId] = 0;
      setTempProgramConfigData(temp2);
    }
  };

  useEffect(() => {
    setTempProgramIdsInWatchdogsManaging([...programIdsInWatchdogsManaging]);
    setTempProgramConfigData(programConfigData);
  }, [managingIdOfSchedule]);

  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby='contained-modal-title-vcenter'
      centered
      className={styles['AddApp-container']}
      onEnter={() => {
        setTempProgramIdsInWatchdogsManaging([
          ...tempProgramIdsInWatchdogsManaging,
        ]);
      }}
    >
      <Modal.Header className={styles['modal-bg-header']}>
        <div className={styles['title-css']}>제어할 앱과 웹사이트 선택</div>
      </Modal.Header>
      <Modal.Body className={styles['modal-bg-body']}>
        <div className={styles['sorting-title']}>・ 자주 사용하는 앱과 웹사이트</div>
        <div className={styles['column-title-box']}>
          <div className={styles['column-title']}>이름</div>
          <div className={styles['column-title']}>제어 가능한 OS</div>
        </div>
        {frequentlyProgramsFilter.map((program) => {
          const pId = program.programId;
          return (
            <CardAppManaging
              key={pId}
              program={program}
              isInWatchdog={tempProgramIdsInWatchdogsManaging.includes(pId)}
              name={program.korName}
              alias={program.alias}
              toggleTempProgramIdInWatchdogsManaging={() =>
                toggleTempProgramIdInWatchdogsManaging(pId)
              }
              web={program?.web}
              android={program?.android}
            />
          );
        })}
        <div className={styles['sorting-title']} style={{marginTop: 30}}>・ 미디어</div>
        <div className={styles['column-title-box']}>
          <div className={styles['column-title']}>이름</div>
          <div className={styles['column-title']}>제어 가능한 OS</div>
        </div>
        {mediaPrograms.map((program) => {
          const pId = program.programId;
          return (
            <CardAppManaging
              key={pId}
              program={program}
              isInWatchdog={tempProgramIdsInWatchdogsManaging.includes(pId)}
              name={program.korName}
              alias={program.alias}
              toggleTempProgramIdInWatchdogsManaging={() =>
                toggleTempProgramIdInWatchdogsManaging(pId)
              }
              web={program?.web}
              android={program?.android}
            />
          );
        })}
        <div className={styles['sorting-title']} style={{marginTop: 30}}>・ 기타 앱과 웹사이트</div>
        <div className={styles['column-title-box']}>
          <div className={styles['column-title']}>이름</div>
          <div className={styles['column-title']}>제어 가능한 OS</div>
        </div>
        {basicsPrograms.map((program) => {
          const pId = program.programId;
          return (
            <CardAppManaging
              key={pId}
              program={program}
              isInWatchdog={tempProgramIdsInWatchdogsManaging.includes(pId)}
              name={program.korName}
              alias={program.alias}
              toggleTempProgramIdInWatchdogsManaging={() =>
                toggleTempProgramIdInWatchdogsManaging(pId)
              }
              web={program?.web}
              android={program?.android}
            />
          );
        })}

        <div className={styles['listRequestButton']}>
          <a href='https://focuspang.com/ask.html' target='_black'>
            앱/웹 리스트 추가 신청하기
          </a>
        </div>
      </Modal.Body>
      <div className={styles['modal-bg-footer']}>
        <button
          className={styles['footer-button-cancel']}
          onClick={() => {
            props.onHide();
          }}
        >
          취소
        </button>
        <button className={styles['footer-button']} onClick={saveManagingApp}>
          저장
        </button>
      </div>
    </Modal>
  );
};

const CardAppManaging = ({
  isInWatchdog = false,
  name = 'app name',
  alias,
  toggleTempProgramIdInWatchdogsManaging = () => {},
  web,
  android,
}) => {
  return (
    <>
      <button
        className={styles['app-card']}
        // onClick={() => {
        //   toggleProgramIdInWatchdogsManaging();
        // }}
      >
        <div className={styles['app-card-left']}>
          <div className={styles['app-icon-name']}>
            <img
              src={ICONS[alias] || imgAppIcon}
              alt='appicon'
              className={styles['img-div']}
            />
            <div className={styles['app-name-box']}>{name}</div>
          </div>
          
          <div className={styles['app-os']}>
            {android ? (
              web ? 'Web, Android, Chormebook, MS Windows' : 'Android'
            ) :  (
              web ? 'Web, Chromebook, MS Windows' : null
            )}
          </div>
        </div>
        <Checkbox
          checked={isInWatchdog}
          onChange={toggleTempProgramIdInWatchdogsManaging}
        />
      </button>
    </>
  );
};

export default ModalAddManagingApp;
