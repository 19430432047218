import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import styles from './ManagingClassList.module.scss';
import { selectManagingGroup } from '@app/store/selectors';
import { useHistory } from 'react-router-dom';
import { setSocketData, deleteGroupPromise, purgeReportList, setSelectedPage } from '@app/store/actions';
import { Tooltip } from '@mui/material';

const ManagingClassList = ({
  setModalShow,
  setIsUpdateModal,
  setManagingGroupForUpdate,
}) => {
  const user = useSelector((state) => state.user);
  const managingClassList = useSelector((state) => selectManagingGroup(state));
  const history = useHistory();
  const dispatch = useDispatch();

  const onDelete = (managingClass) => {
    if (window.confirm('해당 관리 그룹을 삭제하시겠습니까?')) {
      navigateToDeleteGroup(managingClass);
    } else {
      console.log('취소');
    }
  };

  const navigateToDeleteGroup = (managingClass) => {
    dispatch(deleteGroupPromise(managingClass?.groupId))
      .then(() => {
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: managingClass?.groupId,
            clientId: user.clientId,
            type: 'MANAGING_DELETE',
            data: '',
          })
        );
      })
      .then(() => {
        // dispatch(getMembershipByClientPromise(user.clientId));
        // dispatch(getGroupsPromise(user.clientId));
      });
  };

  // dispatch(getActiveManagingPromise(user.clientId))

  useEffect(()=>{
    dispatch(setSelectedPage('MANAGINGLIST'))
    dispatch(purgeReportList());
  },[])

  return (
    <>
      <div className={styles['main-container']}>
        <div className={styles['afterSchoolConfigControl']}>
          <div className={styles['control']}>
            <div className={styles['wrapper']}>
              <div className={styles['title-container']}>
                <div className={styles['title-container-text']}>
                  관리 그룹 목록
                </div>
                <div className={styles['display']}>
                  <Tooltip title='관리할 그룹을 생성하실 수 있습니다.' arrow>
                    <div
                      className={styles['add-container']}
                      type="button"
                      onClick={() => {
                        setIsUpdateModal(false);
                        setModalShow(true);
                      }}
                    >
                      <button className={styles['add-group']} type="button">
                        관리 그룹 추가
                      </button>
                      <AddCircleIcon className={styles['add-plus']} />
                    </div>
                  </Tooltip>
                </div>
              </div>
            </div>
            {managingClassList?.map((managingClass, index) => (
                <div key={index}>
                  <div className={styles['managing-container']}>
                    <div
                      className={styles['managing-box']}
                      onClick={() => {
                        history.replace(`/managing/${managingClass.groupId}`);
                      }}
                    >
                      <div className={styles['gradeName']}>
                        {managingClass?.grade}학년
                      </div>
                      <Tooltip title={managingClass?.subjectName} arrow>
                        <div className={styles['groupName']}>
                          {managingClass?.subjectName}
                        </div>
                      </Tooltip>
                    </div>
                    <div className={styles['managing-box']}>
                      <Tooltip title='관리 그룹의 정보를 수정하실 수 있습니다.' arrow>
                        <button
                          className={styles['update-button']}
                          onClick={() => {
                            setIsUpdateModal(true);
                            setModalShow(true);
                            setManagingGroupForUpdate(managingClass);
                          }}
                        >
                          수정
                        </button>
                      </Tooltip>
                      <Tooltip title='관리 그룹을 삭제하실 수 있습니다.' arrow>
                        <button
                          className={styles['delete-button']}
                          onClick={() => onDelete(managingClass)}
                        >
                          삭제
                        </button>
                      </Tooltip>
                    </div>
                  </div>
                </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ManagingClassList;
