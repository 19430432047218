import React, { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import BaseCard from "../../components/Card/baseCard";
import ModalGenericSimpleText from "../../components/Modal/ModalGenericSimpleText";
import Select, { components } from "react-select";
import Sidebar from "@components/Layout/Sidebar";
import AddIcon from "@mui/icons-material/Add";
import styles from "./index.module.scss";
import SearchInput from "@app/components/Input/SearchInput";
import { useParams } from "react-router-dom";
import {
  selectGroupActivity,
  selectStudentsWithGroupActivity,
  selectSubroupActivity,
} from "@app/store/selectors";
import {
  createGroupActivityPromise,
  getGroupActivityPromise,
  getSubgroupActivityPromise,
} from "@app/store/actions";
import { GroupActivityCreate } from "@app/components/GroupActivity/GroupActivityCreate";
import { GroupActivityDelete } from "@app/components/GroupActivity/GroupActivityDelete";
import { SubgroupActivityCreate } from "@app/components/GroupActivity/SubgroupActivityCreate";
import { SubgroupActivityList } from "@app/components/GroupActivity/SubgroupActivityList";
import Loading from "@app/components/Loading/Loading";
import { Tooltip } from "@mui/material";
import { GROUP_ACTIVITY_INDEX } from "@app/Constants/localStorage";

const bandActivityListStyles = {
  option: (provided) => ({
    ...provided,
    display: "flex",
    fontSize: "12px",
    lineHeight: "28px",
    letterSpacing: "1.25px",
    color: "rgba(0, 0, 0, 0.87)",
  }),
  control: (provided) => ({
    ...provided,
    width: 240,
  }),
};

const GroupActivity = () => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const isLoading = useSelector((state) => state.groupActivity.isLoading);
  const activityList = useSelector(selectGroupActivity);
  const subgroupList = useSelector(selectSubroupActivity);
  const studentList = useSelector((state) =>
    selectStudentsWithGroupActivity(state, groupId)
  );

  const [selectedActivity, setSelectedAcitivity] = useState();
  const [searchStudent, setSearchStudent] = useState("");
  const [addModal, setAddModal] = useState(false);

  useEffect(() => {
    if (groupId) {
      dispatch(getGroupActivityPromise(groupId));
    }
  }, [dispatch, groupId]);

  useEffect(() => {
    if (activityList.length && !selectedActivity) {
      const subgroupActivityId = localStorage.getItem(GROUP_ACTIVITY_INDEX);
      if (subgroupActivityId) {
        const findIndex = activityList.findIndex(
          (item) => item.subgroupActivityId === subgroupActivityId
        );
        setSelectedAcitivity(activityList[findIndex !== -1 ? findIndex : 0]);
      } else {
        setSelectedAcitivity(activityList[0]);
      }
    }
  }, [activityList, selectedActivity]);

  useEffect(() => {
    if (selectedActivity) {
      dispatch(getSubgroupActivityPromise(selectedActivity.subgroupActivityId));
      localStorage.setItem(
        GROUP_ACTIVITY_INDEX,
        selectedActivity.subgroupActivityId
      );
    } else {
      localStorage.removeItem(GROUP_ACTIVITY_INDEX);
    }
  }, [dispatch, selectedActivity]);

  const selectMenuButton = (props) => {
    return (
      <components.MenuList {...props}>
        {props.children}
        <Tooltip
          title='새로운 모둠 활동 주제를 추가를 위해선 이 버튼을 클릭해주세요.'
          arrow
        >
          <div
            className={styles["groupAddMenu"]}
            onClick={() => setAddModal(true)}
          >
            <AddIcon className={styles["addIcon"]} />
            <button className={styles["addGroup"]}>
              새 모둠 활동 주제 만들기
            </button>
          </div>
        </Tooltip>
      </components.MenuList>
    );
  };

  if (!activityList?.length && !isLoading) {
    return (
      <div className='flex w-100vw h-100vh'>
        <Sidebar inClassroom />
        <div className={styles["activityContainer"]}>
          <BaseCard
            style={{ minHeight: "100%", padding: "20px 40px", marginBottom: 8 }}
          >
            <div className={styles["header"]}>
              <div className={styles["headerTitle"]}>모둠 활동</div>
            </div>
            <GroupActivityCreate groupId={groupId} />
          </BaseCard>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='flex w-100vw h-100vh'>
        <Sidebar inClassroom />
        <div className={styles["activityContainer"]}>
          <BaseCard
            style={{ minHeight: "100%", padding: "20px 40px", marginBottom: 8 }}
          >
            <div className={styles["header"]}>
              <Tooltip title='오른쪽 선택 창을 누르시면 이 수업에 생성되어 있는 모둠 활동 주제들 중 한 모둠 활동 주제를 선택하여 화면 전환이 가능합니다. 여기서의 모둠 활동 주제란 여러개의 모둠 활동들이 속해 있는 한 그룹입니다.'>
                <div className={styles["headerTitle"]}>모둠 활동</div>
              </Tooltip>
              <Select
                styles={bandActivityListStyles}
                value={{
                  label: selectedActivity?.name,
                  value: selectedActivity?.subgroupActivityId,
                }}
                isSearchable={false}
                options={activityList.map((item) => ({
                  label: item.name,
                  value: item.subgroupActivityId,
                }))}
                components={{ MenuList: selectMenuButton }}
                onChange={(e) =>
                  setSelectedAcitivity(
                    activityList.find(
                      (item) => item.subgroupActivityId === e.value
                    )
                  )
                }
                isClearable={false}
              />
              <GroupActivityDelete
                selectedActivity={selectedActivity}
                onDelete={() => setSelectedAcitivity(undefined)}
              />
              <Tooltip
                title='학생 이름으로 찾기 기능입니다. 학생 이름을 입력해주세요.'
                arrow
              >
                <div className={styles["headerRight"]}>
                  <SearchInput
                    value={searchStudent}
                    onChange={(e) => setSearchStudent(e.target.value)}
                    placeholder='학생 이름으로 찾기'
                  />
                </div>
              </Tooltip>
            </div>
            <div className={styles["content-container"]}>
              <SubgroupActivityCreate
                studentList={studentList}
                subgroupActivityId={selectedActivity?.subgroupActivityId}
                subGroupList={subgroupList}
              />
              <SubgroupActivityList
                searchStudent={searchStudent}
                studentList={studentList}
                subgroupList={subgroupList}
              />
            </div>
          </BaseCard>
        </div>
      </div>

      {addModal && (
        <ModalGenericSimpleText
          title={"새 모둠 활동 만들기"}
          description={"만드실 모둠 활동 이름을 지어 주세요."}
          placeholder={"예) 중간 수행평가 모둠"}
          decideText={"만들기"}
          confirmModal={addModal}
          setConfirmModal={setAddModal}
          onHide={() => setAddModal(false)}
          onFire={(groupActivityName) => {
            dispatch(
              createGroupActivityPromise({
                groupId,
                name: groupActivityName,
              })
            ).then(setSelectedAcitivity);
            setAddModal(false);
          }}
        />
      )}
      {isLoading && <Loading />}
    </>
  );
};

export default memo(GroupActivity);
