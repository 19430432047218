import { useState, useEffect, useCallback } from 'react';
import { Stack, Typography, Divider, Button, Tooltip } from '@mui/material';
import axios from 'axios';
import BaseCard from '@components/Card/baseCard';
import Sidebar from '@components/Layout/Sidebar';
import { NoticeItem } from './NoticeItem';
import { NoticeCreate } from './NoticeCreate';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { selectGroupById } from '@app/store/selectors';
import { AssignmentCreate } from '../Assignment/AssignmentCreate';
import ReactGA from 'react-ga';

export const Notice = (props) => {
  const { groupId } = useParams();
  const group = useSelector((state) => selectGroupById(state, groupId));

  const [show, setShow] = useState(false);
  const [assignmentShow, setAssignmentShow] = useState(false);
  const [groupnotices, setGroupnotices] = useState([]);

  useEffect(() => {
    getGroupnoticeByGroup();
  }, []);

  const getGroupnoticeByGroup = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://ktor.focuspang.com/v2/groupnotice?groupId=${groupId}`
      );
      const sorted = response.data.groupnotices.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setGroupnotices(sorted);
    } catch (error) {
      setGroupnotices([]);
    }
  }, [groupId]);

  return (
    <>
      <Sidebar inClassroom group={group} />
      <Stack ml="280px" p="14px">
        <BaseCard style={{ minHeight: '100vh' }}>
          <Stack px="40px" py="20px" minHeight="100%" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">수업 공지사항</Typography>
              <Tooltip
                title="새로운 일반 공지를 등록 할 수 있으며 파일 첨부 또한 가능합니다."
                arrow
              >
                <Button
                  style={{
                    borderRadius: '24px',
                    backgroundColor: '#0894A0',
                    color: '#ffffff',
                    height: '28px',
                  }}
                  onClick={() => {
                    setShow(true);
                    // ReactGA.event({
                    //   action: "Register for New General Notice Click",
                    //   category: "Button",
                    //   label: "Notice",
                    // });
                  }}
                >
                  새 일반 공지 등록
                </Button>
              </Tooltip>

              <Tooltip
                title="새로운 과제 공지를 등록 할 수 있으며 제출기한 설정 및 파일 첨부 또한 가능합니다."
                arrow
              >
                <Button
                  style={{
                    borderRadius: '24px',
                    backgroundColor: '#0894A0',
                    color: '#ffffff',
                    height: '28px',
                  }}
                  onClick={() => {
                    setAssignmentShow(true);
                    // ReactGA.event({
                    //   action: "Register for New Assignment Notice Click",
                    //   category: "Button",
                    //   label: "Assignment Notice",
                    // });
                  }}
                >
                  새 과제 공지 등록
                </Button>
              </Tooltip>
            </Stack>
            <div style={{ fontSize: 14 }}>
              일반 공지는 학생들에게 안내만 하는 용도이며 과제 공지는 학생들에게
              기한 내에 과제를 제출하도록 하는 용도입니다.
            </div>
            <Divider />
            {/* <Typography variant="body2">등록된 공지가 없습니다. 새 공지 등록 버튼을 눌러서 공지를 등록해주세요.</Typography> */}

            {groupnotices.map((groupnotice) => (
              <NoticeItem
                key={groupnotice.groupnoticeId}
                groupnotice={groupnotice}
                getGroupnoticeByGroup={getGroupnoticeByGroup}
              />
            ))}
          </Stack>
        </BaseCard>
      </Stack>
      <NoticeCreate
        show={show}
        setShow={setShow}
        groupId={groupId}
        getGroupnoticeByGroup={getGroupnoticeByGroup}
      />
      <AssignmentCreate
        show={assignmentShow}
        groupId={groupId}
        setShow={setAssignmentShow}
        getGroupnoticeByGroup={getGroupnoticeByGroup}
      />
    </>
  );
};
