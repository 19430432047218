import {
  BottomContentLayer,
  HorizontalSpace4,
  HorizontalSpace8,
  VerticalSpace8,
  VerticalSpace4,
  MiddleMenuBar,
  Row,
  Row2,
  Subtitle1,
  SubTitle2,
  Subtitle4,
  Subtitle5,
  TabButtonInteractive,
} from '@app/pages/QuizPang/common';
import { Select } from '@app/pages/QuizPang/select';
import MenuItem from '@mui/material/MenuItem';
import { SearchInput } from '@app/pages/QuizPang/search-input';
import { QuizCard, QuizCardMyActions } from '@app/pages/QuizPang/quiz-card';
import React, { useEffect, useRef, useState } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { Button } from '@mui/material';
import { QuizModal } from '@app/pages/QuizPang/quiz-modal';
import {
  QuizModalStore,
  quizTypes,
  quizTypeToText,
} from '@app/pages/QuizPang/quiz-modal-store';
import { useDispatch, useSelector } from 'react-redux';
import {
  deleteQuizPromise,
  getQuizCategoryAllPromise,
  getQuizListByClientPromise,
} from '@app/store/quiz/actions';
import { selectClientQuizList } from '@app/store/quiz/selector';
import { QuizFilterStore } from '@app/pages/QuizPang/quiz-filter-store';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import filterIcon from '@app/assets/images/icons/tune.svg';

export const QuizTab = observer(() => {
  const [isLoading, setIsLoading] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalStore = useRef(new QuizModalStore());
  const filterStore = useRef(new QuizFilterStore());
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.user.clientId);
  const quizList = useSelector(selectClientQuizList);
  const [activeButton, setActiveButton] = useState(0);
  const [filterButton, setFilterButton] = useState(false);

  useEffect(() => {
    dispatch(getQuizListByClientPromise(clientId)).then(() =>
      setIsLoading(false)
    );
  }, [dispatch, clientId]);
  useEffect(() => {
    filterStore.current.setData(quizList);
  }, [quizList]);
  const counts = filterStore.current.typeCounts;
  useEffect(() => {
    dispatch(getQuizCategoryAllPromise())
      .then((res) => console.log('[quizCategoryAll response]'))
      .catch((error) => console.log('[quizCategoryAll error]', error));
  }, []);

  return (
    <>
      <MiddleMenuBar
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <Row style={{ alignItems: 'flex-end' }}>
          <Subtitle1 style={{ fontSize: 20 }}>My Questions</Subtitle1>
          <HorizontalSpace8 />
          <HorizontalSpace8 />
          <Subtitle4>
            퀴즈에 포함시킬 문제를 검색하고 만들 수 있습니다.
          </Subtitle4>
        </Row>
        <VerticalSpace8 />
        <VerticalSpace8 />
        <Row2>
          <button
            style={{
              backgroundColor: '#fff',
              padding: 10,
              borderWidth: 1,
              borderRadius: 4,
            }}
            onClick={() => {
              filterButton ? setFilterButton(false) : setFilterButton(true);
            }}
          >
            <img src={filterIcon} />
          </button>
          <HorizontalSpace8 />
          <SearchInput
            label="문제 찾기"
            value={filterStore.current.query}
            width="100%"
            onChange={(e) => filterStore.current.setQuery(e.target.value)}
          />
        </Row2>
        <VerticalSpace8 />
        {filterButton ? (
          <Row>
            <Subtitle5>검색필터 설정</Subtitle5>
            <HorizontalSpace8 />
            <Select
              width={110}
              value={filterStore.current.grade}
              onChange={(e) => filterStore.current.setGrade(e.target.value)}
            >
              <MenuItem value={-1}>학년 전체</MenuItem>
              {filterStore.current.availableGrades.map((x) => (
                <MenuItem key={x} value={x}>
                  {x}학년
                </MenuItem>
              ))}
            </Select>
            <HorizontalSpace8 />
            <Select
              width={150}
              value={filterStore.current.subject}
              onChange={(e) => filterStore.current.setSubject(e.target.value)}
            >
              <MenuItem value={-1}>과목 전체</MenuItem>
              {filterStore.current.availableSubjects.map((x) => (
                <MenuItem style={{ maxWidth: 300 }} key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
            <HorizontalSpace8 />
            <Select
              width={180}
              value={filterStore.current.category}
              onChange={(e) => filterStore.current.setCategory(e.target.value)}
            >
              <MenuItem value={-1}>교과서 출판사 전체</MenuItem>
              {filterStore.current.availableCategories.map((x) => (
                <MenuItem style={{ maxWidth: 300 }} key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
            <HorizontalSpace8 />
            <Select
              width={170}
              value={filterStore.current.keyword}
              onChange={(e) => filterStore.current.setKeyword(e.target.value)}
            >
              <MenuItem value={-1}>키워드 전체</MenuItem>
              {filterStore.current.availableKeywords.map((x) => (
                <MenuItem style={{ maxWidth: 300 }} key={x} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
            <HorizontalSpace8 />
            <Select
              width={140}
              value={filterStore.current.type}
              onChange={(e) => filterStore.current.setType(e.target.value)}
            >
              <MenuItem value={-1}>문제 유형 전체</MenuItem>
              {quizTypes.map((x) => (
                <MenuItem key={x} value={x}>
                  {quizTypeToText[x]}
                </MenuItem>
              ))}
            </Select>
          </Row>
        ) : null}
      </MiddleMenuBar>
      <BottomContentLayer>
        <Row>
          <Subtitle1>검색결과 설정</Subtitle1>
          <HorizontalSpace8 />
          <SortButton
            style={{
              color:
                activeButton === 0 || activeButton === 1
                  ? '#0894A0'
                  : '#00000099',
            }}
            onClick={() => setActiveButton(activeButton === 0 ? 1 : 0)}
          >
            문제 제작일{activeButton === 0 ? ' ▲' : ' ▼'}
          </SortButton>
          <HorizontalSpace4 />
          <HorizontalSpace4 />
          <SortButton
            style={{
              color:
                activeButton === 2 || activeButton === 3
                  ? '#0894A0'
                  : '#00000099',
            }}
            onClick={() => setActiveButton(activeButton === 2 ? 3 : 2)}
          >
            문제 질문{activeButton === 2 ? ' ▲' : ' ▼'}
          </SortButton>
          <HorizontalSpace4 />
        </Row>
        <VerticalSpace8 />
        <AddContainer
          onClick={() => {
            modalStore.current = new QuizModalStore();
            setIsModalOpen(true);
          }}
        >
          <Button startIcon={<AddCircleIcon />} style={{ width: 170 }}>
            퀴즈용 문제 만들기
          </Button>
        </AddContainer>
        <VerticalSpace8 />
        {isLoading ? (
          <SubTitle2>문제 목록 로딩 중입니다...</SubTitle2>
        ) : filterStore.current.filtered.length > 0 ? (
          activeButton === 0 ? (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
              .map((x) => {
                // console.log('문제 생성 순 x', x.createdAt)
                return (
                  <QuizCard
                    key={x.quizId}
                    actions={
                      <QuizCardMyActions
                        onEdit={() => {
                          modalStore.current = new QuizModalStore(x);
                          setIsModalOpen(true);
                        }}
                        onDelete={() => {
                          const res = window.confirm('정말 퀴즈를 삭제할까요?');
                          if (res) dispatch(deleteQuizPromise(x.quizId));
                        }}
                      />
                    }
                    data={x}
                    onClick={() => {
                      modalStore.current = new QuizModalStore(x);
                      setIsModalOpen(true);
                    }}
                  />
                );
              })
          ) : activeButton === 1 ? (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => b.createdAt.localeCompare(a.createdAt))
              .map((x) => {
                // console.log('문제 생성 역순 x', x.createdAt)
                return (
                  <QuizCard
                    key={x.quizId}
                    actions={
                      <QuizCardMyActions
                        onEdit={() => {
                          modalStore.current = new QuizModalStore(x);
                          setIsModalOpen(true);
                        }}
                        onDelete={() => {
                          const res = window.confirm('정말 퀴즈를 삭제할까요?');
                          if (res) dispatch(deleteQuizPromise(x.quizId));
                        }}
                      />
                    }
                    data={x}
                    onClick={() => {
                      modalStore.current = new QuizModalStore(x);
                      setIsModalOpen(true);
                    }}
                  />
                );
              })
          ) : activeButton === 2 ? (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => a.title.localeCompare(b.title))
              .map((x) => {
                // console.log('문제 질문 오름차순 x', x.title)
                return (
                  <QuizCard
                    key={x.quizId}
                    actions={
                      <QuizCardMyActions
                        onEdit={() => {
                          modalStore.current = new QuizModalStore(x);
                          setIsModalOpen(true);
                        }}
                        onDelete={() => {
                          const res = window.confirm('정말 퀴즈를 삭제할까요?');
                          if (res) dispatch(deleteQuizPromise(x.quizId));
                        }}
                      />
                    }
                    data={x}
                    onClick={() => {
                      modalStore.current = new QuizModalStore(x);
                      setIsModalOpen(true);
                    }}
                  />
                );
              })
          ) : (
            filterStore.current.filtered
              .slice()
              .sort((a, b) => b.title.localeCompare(a.title))
              .map((x) => {
                // console.log('문제 질문 내림차순 x', x.title)
                return (
                  <QuizCard
                    key={x.quizId}
                    actions={
                      <QuizCardMyActions
                        onEdit={() => {
                          modalStore.current = new QuizModalStore(x);
                          setIsModalOpen(true);
                        }}
                        onDelete={() => {
                          const res = window.confirm('정말 퀴즈를 삭제할까요?');
                          if (res) dispatch(deleteQuizPromise(x.quizId));
                        }}
                      />
                    }
                    data={x}
                    onClick={() => {
                      modalStore.current = new QuizModalStore(x);
                      setIsModalOpen(true);
                    }}
                  />
                );
              })
          )
        ) : (
          <SubTitle2>문제 만들기 버튼을 눌러서 문제를 만들어보세요.</SubTitle2>
        )}
      </BottomContentLayer>
      <QuizModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        store={modalStore.current}
      />
    </>
  );
});

const SortButton = styled.button`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  font-size: 12px;
`;

const AddContainer = styled(Row)`
  flex: 1;
  border: 1px dashed #0ba1ae;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 8px;
  background-color: #fafafa;
  transition: all 0.1s ease-out;
  align-items: center;
  justify-content: center;
`;
