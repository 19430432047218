import { ContactPageOutlined } from '@mui/icons-material';
import {
  getBackend,
  postBackend,
  patchBackend,
  deleteBackend,
  postBackendFormData,
  getBackendV3,
  postBackendV3,
  patchBackendV3,
} from './Backend';

/*
 * Description:
 * postBackendAlt is related with new Django API
 * postBackend is related with new Ktor API
 */

//// Account & Client
///// User specific start
// make anonymous true

export const signIn = async (userData) => {
  return await postBackend('signIn/email?role=TEACHER', userData, true);
};

export const updateUser = async (userData) => {
  return await patchBackend('account', userData, true);
};

export const getAccountToken = async (accountId) => {
  return await getBackend(`account/auth?accountId=${accountId}`);
};

///// User specific end

// update Client or Account Info
export const getClient = async (accountId) => {
  return await getBackend(`account?accountId=${accountId}`);
};

export const getClientsByGroup = async (groupId) => {
  return await getBackend(`membership?groupId=${groupId}`);
};

export const updateClient = async (clientData) => {
  return await patchBackend('client', clientData);
};

//// participant
export const createParticipation = async (participationData) => {
  return await postBackend('participation', participationData);
};

export const getParticipation = async (participationData) => {
  console.log('participationData', participationData);
  return await getBackend(
    `participation?classroomId=${participationData.classroomId}&clientId=${participationData.clientId}`
  );
};

export const getParticipationsByClassroom = async (classroomId) => {
  return await getBackend(`participation?classroomId=${classroomId}`);
};

export const getParticipationsByClient = async (clientId) => {
  return await getBackend('participation', clientId);
};

export const updateParticipation = async (participationData) => {
  return await patchBackend('participation', participationData);
};

export const deleteParticipation = async (deleteParticipationData) => {
  return await deleteBackend(
    `participation/${deleteParticipationData.classroomId}/${deleteParticipationData.clientId}`
  );
};

//// managed
export const createManaged = async (managedData) => {
  return await postBackend('managed', managedData);
};

export const getManaged = async (managedData) => {
  return await getBackend(
    `managed?managingId=${managedData.managingId}&clientId=${managedData.clientId}`
  );
};

export const getManagedsByManaging = async (managingId) => {
  return await getBackend(`managed?managingId=${managingId}`);
};

export const getManagedsByGroup = async (groupId) => {
  return await getBackend(`managed?groupId=${groupId}`);
};

export const getManagedsByClient = async (clientId) => {
  return await getBackend(`managed?clientId=${clientId}`);
};

export const updateManaged = async (managedData) => {
  return await patchBackend('managed', managedData);
};

export const deleteManaged = async (managingId, clientId) => {
  return await deleteBackend(`managed/${managingId}/${clientId}`);
};

///// classroom
export const getClassroom = async (classroomId) => {
  return await getBackend(`classroom?classroomId=${classroomId}`);
};

export const getActiveClassroom = async (clientId) => {
  return await getBackend(`classroom?clientId=${clientId}&active=true`);
};

export const createClassroom = async (createdClassroom) => {
  return await postBackend('classroom', createdClassroom);
};

export const updateClassroom = async (updatedClassroom) => {
  return await patchBackend('classroom', updatedClassroom);
};

export const deleteClassroom = async (classroomId) => {
  return await deleteBackend(`classroom/${classroomId}`);
};

///// managing
export const getManaging = async (managingId) => {
  return await getBackend(`managing?managingId=${managingId}`);
};

export const getManagingsByGroup = async (managingData) => {
  return await getBackend(`managing?groupId=${managingData}`);
};

export const getActiveManaging = async (clientId) => {
  return await getBackend(`managing?clientId=${clientId}&active=true`);
};

export const getActiveManagingByGroup = async (groupId) => {
  return await getBackend(`managing?groupId=${groupId}&active=true`);
};

export const createManaging = async (createdManaging) => {
  return await postBackend('managing', createdManaging);
};

export const updateManaging = async (updatedManaging) => {
  return await patchBackend('managing', updatedManaging);
};

export const deleteManaging = async (managingId) => {
  return await deleteBackend(`managing/${managingId}`);
};

//// membership
export const getMembership = async ({ clientId, groupId }) => {
  return await getBackend(
    `membership?clientId=${clientId}&groupId=${groupId}`,
    true
  );
};

export const getMembershipByClient = async (clientId) => {
  return await getBackend(`membership?clientId=${clientId}`);
};

export const getMembershipsByGroup = async (groupId) => {
  return await getBackend(`membership?groupId=${groupId}`);
};

export const createMembership = async (membership) => {
  return await postBackend('membership', membership);
};

export const deleteMembership = async (groupId, clientId) => {
  return await deleteBackend(`membership/${groupId}/${clientId}`);
};

//// group
export const getGroup = async (groupId) => {
  return await getBackend(`group?groupId=${groupId}`);
};

export const createInviteCode = async (groupId) => {
  return await getBackend(`group?generateInviteCode=true&groupId=${groupId}`);
};

export const getGroupByInviteCode = async (inviteCode) => {
  return await getBackend(`group?inviteCode=${inviteCode}`);
};

export const createGroup = async (group) => {
  return await postBackend('group', group);
};

export const updateGroup = async (updatedGroup) => {
  return await patchBackend('group', updatedGroup);
};

export const deleteGroup = async (groupId) => {
  return await deleteBackend(`group/${groupId}`);
};

//// Notification
export const getNotification = async (notificationId) => {
  return await getBackend(
    `notification?notificationId=${notificationId}`,
    true
  );
};

export const getNotifications = async (clientId) => {
  return await getBackend(`notification?clientId=${clientId}`);
};

export const createNotification = async (notification) => {
  return await postBackend('notification', notification);
};

export const updateNotification = async (updatedNotification) => {
  return await patchBackend('notification', updatedNotification);
};

export const deleteNotification = async (notificationId) => {
  return await deleteBackend(`notification/${notificationId}`);
};

///// program
export const getProgram = async (programId) => {
  return await getBackend(`program?programId=${programId}`);
};

export const getPrograms = async () => {
  return await getBackend('program/all');
};

///// programconfig
export const getProgramconfig = async (programconfigId) => {
  return await getBackend(
    `programconfig?programconfigId=${programconfigId}`,
    true
  );
};

export const getGroupProgramconfigs = async (groupId) => {
  return await getBackend(`programconfig?groupId=${groupId}`);
};

export const createProgramconfig = async (programconfigData) => {
  return await postBackend('programconfig', programconfigData);
};

export const updateProgramconfig = async (programconfigData) => {
  return await patchBackend('programconfig', programconfigData);
};

export const deleteProgramconfig = async (programconfigId) => {
  return await deleteBackend(`programconfig/${programconfigId}`);
};

///// school
export const getSchool = async (schoolId) => {
  return await getBackend(`school?schoolId=${schoolId}`);
};

export const getAllSchools = async (index) => {
  return await getBackend(`school/all?index=${index}`);
};

export const getAllSchoolsFilteredDevision = async (dvision, index) => {
  return await getBackend(`school/all?dvision=${dvision}&index=${index}`);
};

export const getAllSchoolsFilteredDvisionAndCity = async (
  dvision,
  schoolLevel,
  index
) => {
  return await getBackend(
    `school/all?devision=${dvision}&schoolLevel=${schoolLevel}&index=${index}`
  );
};

///// device
export const registerDevice = async (deviceData) => {
  return await postBackend('device/register', deviceData);
};

//// timetable
export const getTimetable = async (timetableId) => {
  return await getBackend(`timetable?timetableId=${timetableId}`);
};

export const getTimetablesByGroup = async (groupId) => {
  return await getBackend(`timetable?groupId=${groupId}`);
};

export const createTimetable = async (timetableData) => {
  return await postBackend('timetable', timetableData);
};

export const updateTimetable = async (timetableData) => {
  return await patchBackend('timetable', timetableData);
};

export const deleteTimetable = async (timetableId) => {
  return await deleteBackend(`timetable/${timetableId}`);
};

//// Filelink
export const createFilelink = async (filelinkData) => {
  return await postBackend('filelink', filelinkData);
};

export const getFilelink = async (filelinkId) => {
  return await getBackend(`filelink?filelinkId=${filelinkId}`);
};

export const getGroupFilelink = async (groupId) => {
  return await getBackend(`filelink?groupId=${groupId}`);
};

export const deleteFilelink = async (filelinkId) => {
  return await deleteBackend(`filelink/${filelinkId}`);
};

//// kakaocert
export const requestCert = async (certData) => {
  return await postBackend('cert/request/', certData);
};

export const checkResponse = async (receiptId) => {
  return await postBackend('cert/check/', receiptId);
};

export const updateProfileImage = async (image) => {
  return await postBackendFormData(`client/upload/`, { profile: image });
};

// quiz
export const getQuizListAll = async (index) => {
  return await getBackend(`quiz/all?index=${index}`);
};
// export const getQuizListAll = async (data) => {
//   console.log('request',data)
//   let queryString = 'quiz/all?index=' + data.index;

//   if (data.grade) {
//     queryString += '&grade=' + data.grade;
//   }

//   if (data.subject) {
//     queryString += '&subject=' + data.subject;
//   }

//   return await getBackend(queryString);
// }

export const getQuizListByClient = async (clientId) => {
  return await getBackend(`quiz?clientId=${clientId}`);
};

export const getQuizListBySchool = async (schoolId) => {
  return await getBackend(`quiz?schoolId=${schoolId}`);
};

export const createQuiz = async (quizData) => {
  return await postBackend('quiz', quizData);
};

export const updateQuiz = async (quizData) => {
  return await patchBackend('quiz', quizData);
};

export const deleteQuiz = async (quizId) => {
  return await deleteBackend(`quiz/${quizId}`);
};

export const createQuizCategory = async (quizCategory) => {
  return await postBackend('quizCategory', quizCategory);
};

export const getQuizCategory = async () => {
  return await getBackend('quizCategory/all');
};

// quizset
export const getQuizsetListAll = async (index) => {
  return await getBackend(`quizset/all?index=${index}`);
};
// export const getQuizsetListAll = async (data) => {
//   let queryString = 'quizset/all?index=' + data.index;

//   if (data.grade) {
//     queryString += '&grade=' + data.grade;
//   }

//   if (data.subject) {
//     queryString += '&subject=' + data.subject;
//   }

//   return await getBackend(queryString);
// }

export const getQuizsetListByClient = async (clientId) => {
  return await getBackend(`quizset?clientId=${clientId}`);
};

export const getQuizsetListBySchool = async (schoolId) => {
  return await getBackend(`quizset?schoolId=${schoolId}`);
};

export const createQuizset = async (quizsetData) => {
  return await postBackend('quizset', quizsetData);
};

export const updateQuizset = async (quizsetData) => {
  return await patchBackend('quizset', quizsetData);
};

export const deleteQuizset = async (quizsetId) => {
  return await deleteBackend(`quizset/${quizsetId}`);
};

// quizset session
export const getQuizsetSessionHistories = async (groupId, index) => {
  return await getBackend(
    `quizsetSession/history?groupId=${groupId}&index=${index}`
  );
};

export const createQuizsetSession = async ({
  quizsetId,
  groupId,
  clientId,
  startAt,
}) => {
  return await postBackend('quizsetSession', {
    quizsetId,
    groupId,
    clientId,
    startAt,
  });
};

export const createQuizsetSessionByQuiz = async ({
  quizId,
  groupId,
  clientId,
  startAt,
}) => {
  return await postBackend('quizsetSession/one', {
    quizId,
    groupId,
    clientId,
    startAt,
  });
};

export const getQuizsetSessionResult = async (quizsetSessionId, quizOrder) => {
  return await getBackend(
    `quizsetSession/result?quizsetSessionId=${quizsetSessionId}&quizOrder=${quizOrder -
      1}`
  );
};

export const finishQuizsetSession = async (quizsetSessionId) => {
  return await patchBackend('quizsetSession', {
    quizsetSessionId,
    state: 'FINISHED',
  });
};

// 모둠활동
export const getGroupActivity = async (groupId) => {
  return await getBackend(`subgroupActivity?groupId=${groupId}`);
};
export const createGroupActivity = async (params) => {
  return await postBackend('subgroupActivity', params);
};
export const deleteGroupActivity = async (subgroupActivityId) => {
  return await deleteBackend(`subgroupActivity/${subgroupActivityId}`);
};
export const getSubgroupActivity = async (subgroupActivityId) => {
  return await getBackend(`subgroup?subgroupActivityId=${subgroupActivityId}`);
};
export const createSubgroupActivity = async (params) => {
  return await postBackend('subgroup', params);
};
export const deleteSubgroupActivity = async (subgroupActivityId) => {
  return await deleteBackend(`subgroup/${subgroupActivityId}`);
};
export const updateSubgroupActivity = async (params) => {
  return await patchBackend(`subgroup`, params);
};
export const getSubgroupActivityStudent = async (subgroupId) => {
  return await getBackend(`subgroupStudent?subgroupId=${subgroupId}`);
};

//reportList
export const getReport = async (reportId) => {
  return await getBackend(`report?reportId=${reportId}`);
};

export const getReportsListByClient = async (data) => {
  return await getBackend(
    `report?clientId=${data.clientId}&offset=${data.offset}&amount=${data.amount}`
  );
};

export const getReportListByGroup = async (data) => {
  return await getBackend(
    `report?groupId=${data.groupId}&offset=${data.offset}&amount=${data.amount}`
  );
};

export const deleteReport = async (reportId) => {
  return await deleteBackend(`report/${reportId}`);
};

//reportEvents
export const createReportEvents = async (createdEvents) => {
  return await postBackend('log/screenShare/history', createdEvents);
};

// CommentsLayer
export const getCommentsLayer = async (classroomId) => {
  return await getBackend(`/commentsLayer?classroomId=${classroomId}`, true);
};
export const createCommentsLayer = async (data) => {
  return await postBackend(`/commentsLayer`, data, true);
};
export const updateCommentsLayer = async (data) => {
  return await patchBackend(`/commentsLayer`, data, true);
};
// Commentary
export const getCommentary = async (classroomId) => {
  return await getBackend(`/commentary?classroomId=${classroomId}`, true);
};
export const createCommentary = async (data) => {
  return await postBackend(`/commentary`, data, true);
};
export const updateCommentary = async (data) => {
  return await patchBackend(`/commentary`, data, true);
};

// ButtonHistory
export const createButtonHistory = async (data) => {
  return await postBackend(`/buttonEvent/history`, data, true);
};
