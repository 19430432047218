import { useState, useEffect, useCallback } from 'react';
import { Stack, Typography, Divider, Button, Tooltip } from '@mui/material';
import BaseCard from '@components/Card/baseCard';
import Sidebar from '@components/Layout/Sidebar';
import axios from 'axios';
import { AssignmentItem } from './AssignmentItem';
import { AssignmentCreate } from './AssignmentCreate';
import { useParams } from 'react-router-dom';
import ReactGA from 'react-ga';

export const Assignment = () => {
  const { groupId } = useParams();

  const [show, setShow] = useState(false);
  const [groupnotices, setGroupnotices] = useState([]);

  const getGroupnoticeByGroup = useCallback(async () => {
    try {
      const response = await axios.get(
        `https://ktor.focuspang.com/v2/groupnotice?groupId=${groupId}`
      );
      const submissions = response.data.groupnotices.filter(
        (groupnotice) => groupnotice.noticeType === 'SUBMISSION'
      );
      const sorted = submissions.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setGroupnotices(sorted);
    } catch (error) {
      setGroupnotices([]);
    }
  }, [groupId]);

  useEffect(() => {
    getGroupnoticeByGroup();
  }, [getGroupnoticeByGroup]);

  return (
    <>
      <Sidebar inClassroom />
      <Stack ml="280px" p="14px">
        <BaseCard style={{ minHeight: '100vh' }}>
          <Stack px="40px" py="20px" minHeight="100%" spacing={3}>
            <Stack direction="row" alignItems="center" spacing={3}>
              <Typography variant="h6">과제 관리</Typography>
              <Tooltip
                title="새로운 과제를 등록 할 수 있으며 제출기한 설정 및 파일 첨부 또한 가능합니다."
                arrow
              >
                <Button
                  style={{
                    borderRadius: '24px',
                    backgroundColor: '#0894A0',
                    color: '#ffffff',
                    height: '28px',
                  }}
                  onClick={() => {
                    setShow(true);
                    // ReactGA.event({
                    //   action: "Click Assignment registration in Assignment Management",
                    //   category: "Button",
                    //   label: "Assignment Notice",
                    // });
                  }}
                >
                  새 과제 등록
                </Button>
              </Tooltip>
            </Stack>
            <Divider />
            {groupnotices.map((groupnotice) => (
              <AssignmentItem
                key={groupnotice.groupnoticeId}
                groupnotice={groupnotice}
                getGroupnoticeByGroup={getGroupnoticeByGroup}
              />
            ))}
          </Stack>
        </BaseCard>
      </Stack>
      <AssignmentCreate
        show={show}
        groupId={groupId}
        setShow={setShow}
        getGroupnoticeByGroup={getGroupnoticeByGroup}
      />
    </>
  );
};
