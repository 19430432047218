import React from 'react';
import styled from '@emotion/styled';

const Layout = styled.div`
  width: 68px;
  padding: 4px;
  // background: #fafafa;
  // border: 1px solid #e0e0e0;
  border-radius: 4px;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
  letter-spacing: 0.1px;

  display: flex;
  align-items: center;
`;

const Period = styled.div`
  align-items: center;
  margin-right: 8px;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
`;

const Time = styled.div`
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  font-family: 'Noto Sans KR';
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 22px;
`;

const ClassTimeline = ({ period, timeString }) => {
  return (
    <Layout>
      <Period>{period}교시</Period>
      <Time>{timeString}</Time>
    </Layout>
  );
};

export default ClassTimeline;
