import { makeAutoObservable } from 'mobx'

const SUBJECT_KEY = 'subjects'

class SubjectStore {
  data = []

  constructor() {
    makeAutoObservable(this)
    const stored = localStorage.getItem(SUBJECT_KEY)
    if (stored) {
      this.data = JSON.parse(stored)
    }
  }

  add(subject) {
    this.data.push(subject)
    localStorage.setItem(SUBJECT_KEY, JSON.stringify(this.data))
  }
}

export const subjectStore = new SubjectStore()
