import React from 'react'
import Sidebar from '@components/Layout/Sidebar'
import { SideBarRest } from '@app/pages/QuizPang/common'
import { useSelector } from 'react-redux'
import { selectActiveClassroom } from '@app/store/classrooms/selector'
import { DuringClass } from '@app/pages/QuizPang/InClass/during-class'
import { BeforeClass } from '@app/pages/QuizPang/InClass/before-class'

const QuizPangInClass = () => {
  const activeClassroom = useSelector((state) => selectActiveClassroom(state))
  return <div className="flex w-100vw h-100vh">
    <Sidebar inClassroom/>
    <SideBarRest>
      {/* {!activeClassroom ? <BeforeClass/> : <DuringClass/>} */}
      <DuringClass/>
    </SideBarRest>
  </div>
}

export default QuizPangInClass
