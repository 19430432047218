import { createSelector } from 'reselect';

export const selectAllMemberships = (state) => {
  if (state.membership.allIds.length === 0) {
    return [];
  }
  return state.membership.allIds?.map(
    (membership) => state.membership.byId[membership]
  );
};

export const selectAllMembershipsVerCache = createSelector(
  (state) => state.allIds,
  (state) => state.byId,
  (allIds, byId) => {
    if (allIds.length === 0) {
      return [];
    }
    return allIds?.map((membership) => byId[membership]);
  }
);

export const selectTeacherMemberships = (state) => {
  return selectAllMemberships(state)
    .filter(
      (membership) =>
        membership.role === 'TEACHER' || membership.role === 'ADMIN'
    )
    .map((membership) => {
      return membership;
    });
};

export const selectTeacherMembershipByGroup = (state, group) => {
  return selectAllMemberships(state).find(
    (membership) =>
      membership.role === 'TEACHER' ||
      (membership.role === 'ADMIN' && group.groupId === membership.groupId)
  );
};

export const selectStudentsByGroup = createSelector(
  (state) => state.membership,
  (state, groupId) => groupId,
  (state) => state.clients,
  (stateMembership, groupId, stateClient) => {
    const memberships = selectAllMembershipsVerCache(stateMembership).filter(
      (membership) =>
        membership.role === 'STUDENT' && membership.groupId === groupId
    );
    const clientIds = new Set(memberships.map((x) => x.clientId));
    return Object.values(stateClient.byId).filter((x) =>
      clientIds.has(x.clientId)
    );
  }
);

export const selectStudentMemberships = (state) => {
  return selectAllMemberships(state)
    .filter((membership) => membership.role === 'STUDENT')
    .map((membership) => {
      return membership;
    });
};
