import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import {
  createProgramconfigPromise,
  deleteProgramconfigPromise,
  setSocketData,
} from '@store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllPrograms, selectGroupProgramconfigs } from '@store/selectors';
import imgAppIcon from '@images/home/img_notice.png';
import styles from './ModalAddApp.module.scss';
import ICONS from '@images/appIcons/index.js';
import Checkbox from '@mui/material/Checkbox';

const ModalAddApp = ({ group, managingIdOfSchedule, ...props }) => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();
  const programs = useSelector((state) => selectAllPrograms(state));
  const frequentlyPrograms = programs.filter((program) => program.sorting === 0);
  const mediaPrograms = programs.filter((program) => program.sorting === 1);
  const basicsPrograms = programs.filter((program) => program.sorting === 2);

  const programconfigs = useSelector((state) =>
    selectGroupProgramconfigs(state, group?.groupId)
  );

  const programconfigsManaging = useSelector((state) =>
    selectGroupProgramconfigs(state, managingIdOfSchedule)
  );

  const programIdsInWatchdogsManaging = programconfigsManaging.map(
    (x) => x.programId
  );

  const [
    newProgramIdsInWatchdogsManaging,
    setNewProgramIdsInWatchdogsManaging,
  ] = useState([...programIdsInWatchdogsManaging]);

  const programIdsInWatchdogs = programconfigs.map((x) => x.programId);
  const [newProgramIdsInWatchdogs, setNewProgramIdsInWatchdogs] = useState([
    ...programIdsInWatchdogs,
  ]);

  const saveManagingApp = async () => {
    setLoading(true);
    const toBeRemoved = programIdsInWatchdogsManaging.filter(
      (id) => !newProgramIdsInWatchdogsManaging.includes(id)
    );
    const toBeCreated = newProgramIdsInWatchdogsManaging.filter(
      (id) => !programIdsInWatchdogsManaging.includes(id)
    );
    const requestRemovings = [...new Set(toBeRemoved)].map((id) =>
      dispatch(
        deleteProgramconfigPromise(
          programconfigsManaging.find((x) => x.programId === id).programconfigId
        )
      ).then(() => {
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: group.groupId,
            clientId: user.clientId,
            type: 'DELETE_MANAGING_PROGRAMCONFIG',
            data: programconfigsManaging.find((x) => x.programId === id)
              .programconfigId,
            // programconfigs.find(
            //   (x) => x.programId === managingIdOfSchedule
            // ).programconfigId,
          })
        );
      })
    );
    const requestCreatings = [...new Set(toBeCreated)].map((id) =>
      dispatch(
        createProgramconfigPromise({
          programId: id,
          groupId: managingIdOfSchedule,
          clientId: user.clientId,
          dayMax: 0,
        })
      ).then((programconfig) => {
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: group.groupId,
            clientId: programconfig.clientId,
            type: 'CREATE_MANAGING_PROGRAMCONFIG',
            data: managingIdOfSchedule,
          })
        );
      })
    );
    await Promise.all([...requestRemovings, ...requestCreatings]);
    setLoading(false);
    props.onHide();
  };

  const save = async () => {
    setLoading(true);
    const toBeRemoved = programIdsInWatchdogs.filter(
      (id) => !newProgramIdsInWatchdogs.includes(id)
    );
    const toBeCreated = newProgramIdsInWatchdogs.filter(
      (id) => !programIdsInWatchdogs.includes(id)
    );
    const requestRemovings = [...new Set(toBeRemoved)].map((id) =>
      dispatch(
        deleteProgramconfigPromise(
          programconfigs.find((x) => x.programId === id).programconfigId
        )
      ).then(() => {
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: group.groupId,
            clientId: user.clientId,
            type: 'DELETE_PROGRAMCONFIG',
            data: programconfigs.find((x) => x.programId === id)
              .programconfigId,
          })
        );
      })
    );
    const requestCreatings = [...new Set(toBeCreated)].map((id) =>
      dispatch(
        createProgramconfigPromise({
          programId: id,
          groupId: group.groupId,
          clientId: user.clientId,
          dayMax: 0,
        })
      ).then((programconfig) => {
        dispatch(
          setSocketData({
            method: 'POST',
            uri: '/classroom/sendImage',
            groupId: programconfig.groupId,
            clientId: programconfig.clientId,
            type: 'CREATE_PROGRAMCONFIG',
            data: '',
          })
        );
      })
    );
    await Promise.all([...requestRemovings, ...requestCreatings]);
    setLoading(false);
    props.onHide();
  };

  const toggleProgramIdInWatchdogs = (programId) => {
    const alreadyExists = newProgramIdsInWatchdogs.includes(programId);

    if (alreadyExists) {
      setNewProgramIdsInWatchdogs((prev) =>
        prev.filter((_programId) => _programId !== programId)
      );
    } else {
      setNewProgramIdsInWatchdogs((prev) => prev.concat(programId));
    }
  };

  const toggleProgramIdInWatchdogsManaging = (programId) => {
    const alreadyExists = newProgramIdsInWatchdogsManaging.includes(programId);

    if (alreadyExists) {
      setNewProgramIdsInWatchdogsManaging((prev) =>
        prev.filter((_programId) => _programId !== programId)
      );
    } else {
      setNewProgramIdsInWatchdogsManaging((prev) => prev.concat(programId));
    }
  };

  return (
    <Modal
      {...props}
      // size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles['AddApp-container']}
      onEnter={() => setNewProgramIdsInWatchdogs([...programIdsInWatchdogs])}
    >
      <Modal.Header className={styles['modal-bg-header']}>
        <div className={styles['title-css']}>제어할 앱과 웹사이트 선택</div>
      </Modal.Header>
      <Modal.Body className={styles['modal-bg-body']}>
        <div className={styles['sorting-title']}>・ 자주 사용하는 앱과 웹사이트</div>
        <div className={styles['column-title-box']}>
          <div className={styles['column-title']}>이름</div>
          <div className={styles['column-title']}>제어 가능한 OS</div>
        </div>
        {frequentlyPrograms.map((program) => {
          const pId = program.programId;
          if (managingIdOfSchedule) {
            return (
              <CardAppManaging
                key={pId}
                program={program}
                isInWatchdog={newProgramIdsInWatchdogsManaging.includes(pId)}
                name={program.korName}
                alias={program.alias}
                toggleProgramIdInWatchdogsManaging={() =>
                  toggleProgramIdInWatchdogsManaging(pId)
                }
                web={program?.web}
                android={program?.android}
              />
            );
          }
          return (
            <CardApp
              key={pId}
              program={program}
              groupId={group?.groupId}
              isInWatchdog={newProgramIdsInWatchdogs.includes(pId)}
              name={program.korName}
              alias={program.alias}
              toggleProgramIdInWatchdogs={() => toggleProgramIdInWatchdogs(pId)}
              web={program?.web}
              android={program?.android}
            />
          );
        })}
        <div className={styles['sorting-title']} style={{marginTop: 30}}>・ 미디어</div>
        <div className={styles['column-title-box']}>
          <div className={styles['column-title']}>이름</div>
          <div className={styles['column-title']}>제어 가능한 OS</div>
        </div>
        {mediaPrograms.map((program) => {
          const pId = program.programId;
          if (managingIdOfSchedule) {
            return (
              <CardAppManaging
                key={pId}
                program={program}
                isInWatchdog={newProgramIdsInWatchdogsManaging.includes(pId)}
                name={program.korName}
                alias={program.alias}
                toggleProgramIdInWatchdogsManaging={() =>
                  toggleProgramIdInWatchdogsManaging(pId)
                }
                web={program?.web}
                android={program?.android}
              />
            );
          }
          return (
            <CardApp
              key={pId}
              program={program}
              groupId={group?.groupId}
              isInWatchdog={newProgramIdsInWatchdogs.includes(pId)}
              name={program.korName}
              alias={program.alias}
              toggleProgramIdInWatchdogs={() => toggleProgramIdInWatchdogs(pId)}
              web={program?.web}
              android={program?.android}
            />
          );
        })}
        <div className={styles['sorting-title']} style={{marginTop: 30}}>・ 기타 앱과 웹사이트</div>
        <div className={styles['column-title-box']}>
          <div className={styles['column-title']}>이름</div>
          <div className={styles['column-title']}>제어 가능한 OS</div>
        </div>
        {basicsPrograms.map((program) => {
          const pId = program.programId;
          if (managingIdOfSchedule) {
            return (
              <CardAppManaging
                key={pId}
                program={program}
                isInWatchdog={newProgramIdsInWatchdogsManaging.includes(pId)}
                name={program.korName}
                alias={program.alias}
                toggleProgramIdInWatchdogsManaging={() =>
                  toggleProgramIdInWatchdogsManaging(pId)
                }
                web={program?.web}
                android={program?.android}
              />
            );
          }
          return (
            <CardApp
              key={pId}
              program={program}
              groupId={group?.groupId}
              isInWatchdog={newProgramIdsInWatchdogs.includes(pId)}
              name={program.korName}
              alias={program.alias}
              toggleProgramIdInWatchdogs={() => toggleProgramIdInWatchdogs(pId)}
              web={program?.web}
              android={program?.android}
            />
          );
        })}
        {/* {programs.map((program) => {
          const pId = program.programId;
          if (managingIdOfSchedule) {
            return (
              <CardAppManaging
                key={pId}
                program={program}
                isInWatchdog={newProgramIdsInWatchdogsManaging.includes(pId)}
                name={program.korName}
                alias={program.alias}
                toggleProgramIdInWatchdogsManaging={() =>
                  toggleProgramIdInWatchdogsManaging(pId)
                }
              />
            );
          }
          return (
            <CardApp
              key={pId}
              program={program}
              groupId={group?.groupId}
              isInWatchdog={newProgramIdsInWatchdogs.includes(pId)}
              name={program.korName}
              alias={program.alias}
              toggleProgramIdInWatchdogs={() => toggleProgramIdInWatchdogs(pId)}
            />
          );
        })} */}
        <div className={styles['listRequestButton']}>
          <a href="https://focuspang.com/ask.html" target="_black">
            앱/웹 리스트 추가 신청하기
          </a>
        </div>
      </Modal.Body>
      <div className={styles['modal-bg-footer']}>
        <button
          className={styles['footer-button-cancel']}
          onClick={props.onHide}
        >
          취소
        </button>
        <button
          className={styles['footer-button']}
          onClick={managingIdOfSchedule ? saveManagingApp : save}
        >
          저장
        </button>
      </div>
    </Modal>
  );
};

const CardApp = ({
  isInWatchdog = false,
  name = 'app name',
  alias,
  toggleProgramIdInWatchdogs = () => {},
  web,
  android,
}) => {
  return (
    <>
      <button
        className={styles['app-card']}
        onClick={() => {
          toggleProgramIdInWatchdogs();
        }}
      >
        <div className={styles['app-card-left']}>
          <div className={styles['app-icon-name']}>
            <img
              src={ICONS[alias] || imgAppIcon}
              alt="appicon"
              className={styles['img-div']}
            />
            <div className={styles['app-name-box']}>{name}</div>
          </div>
          
          <div className={styles['app-os']}>
            {android ? (
              web ? 'Web, Android, Chormebook, MS Windows' : 'Android'
            ) :  (
              web ? 'Web, Chromebook, MS Windows' : null
            )}
          </div>
        </div>
        <Checkbox
          checked={isInWatchdog}
          onChange={toggleProgramIdInWatchdogs}
        />
      </button>
    </>
  );
};

const CardAppManaging = ({
  isInWatchdog = false,
  name = 'app name',
  alias,
  toggleProgramIdInWatchdogsManaging = () => {},
}) => {
  return (
    <>
      <button
        className={styles['app-card']}
        onClick={() => {
          toggleProgramIdInWatchdogsManaging();
        }}
      >
        <div className={styles['app-card-left']}>
          <img
            src={ICONS[alias] || imgAppIcon}
            alt="appicon"
            className={styles['img-div']}
          />
          <div className={styles['app-name-box']}>{name}</div>
        </div>
        <Checkbox
          checked={isInWatchdog}
          onChange={toggleProgramIdInWatchdogsManaging}
        />
      </button>
    </>
  );
};

export default ModalAddApp;
