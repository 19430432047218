import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import {
  Replay,
  Create,
  Visibility,
  FormatColorFill,
} from '@mui/icons-material';
import EraserIcon from '@icons/material/EraserIcon';
import { ReactSketchCanvas } from 'react-sketch-canvas';
import { GithubPicker } from 'react-color';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { selectActiveClassroom } from '@app/store/selectors';
import { CommentsLayerStudent } from './CommentsLayerStudent';
import {
  setCommentsSharedScreen,
  setCommentsStrokeColor,
  setScreenData,
} from '@app/store/actions';
import { Tooltip } from '@mui/material';

const CommentsLayerComponent = ({ screenImg = '', isVisible = false }) => {
  const dispatch = useDispatch();
  const { clientId } = useSelector((state) => state.user);
  const {
    commentsLayer,
    selectedClientIds,
    strokeColor,
    studentComments,
    studentSharedImage,
    studentSharedClientId,
  } = useSelector((state) => state.commentsLayer);
  const { selectedStd } = useSelector((state) => state.control);
  const { groupId, screenShared, sharedClientId } = useSelector((state) =>
    selectActiveClassroom(state)
  );

  const canvasRef = useRef(null);
  const [isEraserMode, setIsEraserMode] = useState(false);
  const [isVisibleColor, setIsVisibleColor] = useState(false);
  const [isVisibleStudent, setIsVisibleStudent] = useState(false);

  const onPathChange = useCallback(async () => {
    if (!canvasRef?.current) {
      return;
    }
    const path = await canvasRef.current.exportPaths();

    // 학생 화면 선택 후 코멘트 시점의 마지막 화면
    if (
      path.length &&
      studentSharedClientId !== sharedClientId &&
      !studentSharedImage
    ) {
      dispatch(
        setCommentsSharedScreen({
          sharedClientId,
          sharedImage: screenImg,
        })
      );
      dispatch(
        setScreenData({
          method: 'POST',
          uri: '/classroom/sendImage',
          type: 'COMMENTS_SHARED_SCREEN',
          clientId,
          groupId,
          data: screenImg,
        })
      );
    }
    const data = await canvasRef.current.exportImage('png');
    let targetClients = [];
    if (sharedClientId && sharedClientId !== clientId && !screenShared) {
      // 선택한 화면 학생에게 코멘트 공유
      targetClients = [sharedClientId];
    } else if (selectedClientIds.length) {
      // 소통하기 학생들에게 코멘트 공유
      targetClients = selectedClientIds;
    }
    dispatch(
      setScreenData({
        method: 'POST',
        uri: '/classroom/sendImage',
        type: 'COMMENTS_TEACHER',
        clientId,
        groupId,
        data: JSON.stringify({
          targetClients,
          image: data,
        }),
      })
    );
  }, [
    clientId,
    dispatch,
    groupId,
    screenImg,
    screenShared,
    selectedClientIds,
    sharedClientId,
    studentSharedClientId,
    studentSharedImage,
  ]);

  const onEraserMode = useCallback((isEraser) => {
    canvasRef?.current.eraseMode(isEraser);
    setIsEraserMode(isEraser);
  }, []);
  const onClear = useCallback(async () => {
    await canvasRef?.current.clearCanvas();
    onPathChange();
  }, [onPathChange]);

  // 스크린 선택 변경 시 Comments 초기화 및 전송
  useEffect(() => {
    if (!canvasRef?.current) {
      return;
    }

    const func = async () => {
      dispatch(
        setCommentsSharedScreen({
          sharedImage: '',
          sharedClientId: '',
        })
      );
      dispatch(
        setScreenData({
          method: 'POST',
          uri: '/classroom/sendImage',
          type: 'COMMENTS_SHARED_SCREEN',
          clientId,
          groupId,
          data: '',
        })
      );
      await canvasRef.current.clearCanvas();
      onPathChange();
    };
    func();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientId, dispatch, groupId, selectedStd]);

  // 소통대상 변경 시 Comments 전송
  useEffect(() => {
    onPathChange();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClientIds]);

  if (!commentsLayer.isActivated || !isVisible) {
    return null;
  }

  return (
    <StyledContainer>
      <StyledToolkitWrap>
        <Tooltip title='펜'>
          <StyledModeButton
            $isActive={!isEraserMode}
            onClick={() => onEraserMode(false)}
          >
            <Create />
          </StyledModeButton>
        </Tooltip>
        <Tooltip title='색깔'>
          <StyledModeButton
            $backgroundColor={strokeColor}
            onClick={() => setIsVisibleColor(!isVisibleColor)}
          >
            <FormatColorFill />
          </StyledModeButton>
        </Tooltip>
        {isVisibleColor && (
          <StyledGithubPicker
            triangle='top-right'
            color={strokeColor}
            onChangeComplete={(color) => {
              dispatch(setCommentsStrokeColor(color.hex));
              setIsVisibleColor(false);
            }}
          />
        )}
        <Tooltip title='지우개'>
          <StyledModeButton
            $isActive={isEraserMode}
            onClick={() => onEraserMode(true)}
          >
            <EraserIcon />
          </StyledModeButton>
        </Tooltip>
        <Tooltip title='모두 삭제'>
          <StyledModeButton onClick={onClear}>
            <Replay />
          </StyledModeButton>
        </Tooltip>
        <Tooltip title='학생 펜 보기'>
          <StyledModeButton
            onClick={() => setIsVisibleStudent(!isVisibleStudent)}
          >
            <Visibility />
          </StyledModeButton>
        </Tooltip>
      </StyledToolkitWrap>

      {!!studentSharedImage && (
        <StyledStudentLastImage>
          <img
            src={`data:image/webp;base64,${studentSharedImage}`}
            alt='student-last-screen'
          />
        </StyledStudentLastImage>
      )}
      {sharedClientId !== clientId && selectedStd === sharedClientId && (
        <StyledBackgroundComments
          key={`shared-${sharedClientId}`}
          $visible={!!studentComments[sharedClientId]}
        >
          <img
            src={studentComments[sharedClientId]}
            alt='student-shared-comments'
          />
        </StyledBackgroundComments>
      )}
      {selectedClientIds
        .filter((id) => id !== sharedClientId)
        .map((id) => (
          <StyledBackgroundComments
            key={`comments-${id}`}
            $visible={!!studentComments[id]}
          >
            <img src={studentComments[id]} alt='student-comments' />
          </StyledBackgroundComments>
        ))}
      {isVisibleStudent && (
        <CommentsLayerStudent onClosed={() => setIsVisibleStudent(false)} />
      )}
      <ReactSketchCanvas
        id='react-sketch-canvas-teacher'
        ref={canvasRef}
        style={{
          position: 'absolute',
          inset: 0,
          zIndex: 2,
        }}
        eraserWidth={80}
        strokeWidth={4}
        strokeColor={strokeColor}
        backgroundImage='none'
        onStroke={onPathChange}
      />
    </StyledContainer>
  );
};

const StyledContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: crosshair;
`;
const StyledToolkitWrap = styled.div`
  display: flex;
  position: absolute;
  top: 12px;
  right: 12px;
  padding: 8px 4px;
  color: white;
  z-index: 5;
  border-radius: 8px;
`;
const StyledModeButton = styled.button`
  padding: 8px;
  font-size: 12px;
  background-color: ${({ $backgroundColor }) => $backgroundColor || '#969696'};
  border-radius: 50%;
  margin: 0 4px;

  ${({ $isActive }) =>
    $isActive &&
    `
      background-color: white;
      color: black;
  `}
`;
const StyledGithubPicker = styled(GithubPicker)`
  position: absolute !important;
  right: 10px;
  bottom: -90px;
  width: 188px !important;
`;
const StyledStudentLastImage = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
  }
`;
const StyledBackgroundComments = styled.div`
  position: absolute;
  inset: 0;
  z-index: 1;

  img {
    width: 100%;
    height: 100%;
  }

  ${({ $visible }) =>
    !$visible &&
    `
    display: none;
  `}
`;

export const CommentsLayer = memo(CommentsLayerComponent);
