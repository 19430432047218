import { Psychology } from '@mui/icons-material';
import styled from 'styled-components';
import { ReactComponent as ABImg } from '@assets/images/icons/a-b.svg';

export const HomeButtonSec = styled.div`
  padding: 12px 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;

  //간격 20px
  margin-bottom: 20px;

  &:hover {
    cursor: pointer;
  }
`;

export const ImgSetSec = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 24px;
  object-fit: contain;
`;

export const SpanSidebar = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  cursor: pointer;
  text-align: left;
  letter-spacing: normal;
`;

function HomeButtonList({ location, history, onPressAfter }) {
  return (
    <>
      <HomeButtonSec
        style={
          location.pathname.includes('/managing')
            ? { backgroundColor: '#05869225' }
            : {}
        }
        onClick={onPressAfter}
      >
        <Psychology
          style={{
            width: '24px',
            height: '24px',
            marginRight: '24px',
            objectFit: 'contain',
          }}
          fill={location.pathname.includes('/managing') ? '#35B5C2' : '#ffffff'}
        />
        <SpanSidebar
          style={
            location.pathname === '/managing'
              ? { color: '#35B5C2' }
              : { color: '#ffffff' }
          }
        >
          수업 시간 외 앱/웹 사용 관리 (베타테스팅중)
        </SpanSidebar>
      </HomeButtonSec>

      {/* <HomeButtonSec
        style={
          location.pathname.includes('/quiz-pang')
            ? { backgroundColor: '#05869225' }
            : {}
        }
        onClick={() => history.replace('/quiz-pang')}
      >
        <ABImg
          style={{
            width: '24px',
            height: '24px',
            marginRight: '24px',
            objectFit: 'contain',
          }}
          fill={
            location.pathname.includes('/quiz-pang') ? '#35B5C2' : '#ffffff'
          }
        />
        <SpanSidebar
          style={
            location.pathname.includes('/quiz-pang')
              ? { color: '#35B5C2' }
              : { color: '#ffffff' }
          }
        >
          퀴즈팡 관리
        </SpanSidebar>
      </HomeButtonSec> */}
    </>
  );
}

export default HomeButtonList;
