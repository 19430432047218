import React from "react";
import FileDragDrop from '@components/DragDrop/FileDragDrop'

const DragSample = () => {
    return (
        <div>
            drag drop
            <FileDragDrop />
        </div>
    );
};

export default DragSample;