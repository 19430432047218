import * as React from "react";
// import {
//   useFirebase,
//   useFirestore,
//   useFirestoreConnect,
// } from "react-redux-firebase";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const COLLECTION_NAME = "Classroom";
const DOC = "MZd1p8j2QOzjX0lH2M4s";

const { useEffect, useState } = React;
export default function Dev() {
  // // const [firebase, firestore] = [useFirebase(), useFirestore()];
  // const user = useSelector((state) => state.user);

  // // const { storage, uploadFile } = firebase;
  // // const { get, add, onSnapshot, update } = firestore;

  // const [classroomData, setClassroomData] = useState(null);

  // // onSnapshot(`${COLLECTION_NAME}/${DOC}`, (snapshot) => {
  // //   // console.log("snapshot =", snapshot.data());
  // //   setClassroomData(snapshot.data());
  // // });

  // // useFirestoreConnect({
  // //   collection: COLLECTION_NAME,
  // //   doc: DOC,
  // // });

  // // const classroom = useSelector((state) => state);
  // // console.log("clasrrom =", classroom);

  // const getClassroom = async () => {
  //   try {
  //     const classroom = await get(`${COLLECTION_NAME}/${DOC}`);
  //     console.log("classroom =", classroom);
  //   } catch (error) {
  //     console.error("error =", error);
  //   }
  // };

  // // const classroom = useSelector(({firestore}) => )
  // // const classroom = useSelector(
  // //   ({ firestore: { data } }) =>
  // //     data[COLLECTION_NAME] && data[COLLECTION_NAME][DOC]
  // // );

  // console.log("firestoreClassroom =", classroom);

  // const useEffectHandler = () => {
  //   // getClassroom();
  //   console.log("something here");
  //   console.log("user =", user);
  //   console.log("classroom =", classroom);
  // };

  // useEffect(useEffectHandler, [classroom, user]);

  // const takeScreenShot = async () => {
  //   const displayMediaOptions = {
  //     video: {
  //       cursor: "always",
  //     },
  //     audio: false,
  //   };
  //   const stream = await navigator.mediaDevices.getDisplayMedia(
  //     displayMediaOptions
  //   );

  //   // captureStream = await navigator.mediaDevices.getDisplayMedia(displayMediaOptions);
  //   console.log("stream =", stream);
  //   // get correct video track
  //   const track = stream.getVideoTracks()[0];
  //   // init Image Capture and not Video stream
  //   const imageCapture = new ImageCapture(track);
  //   // take first frame only
  //   const bitmap = await imageCapture.grabFrame();
  //   // destory video track to prevent more recording / mem leak
  //   track.stop();

  //   const canvas = document.getElementById("fake");
  //   // this could be a document.createElement('canvas') if you want
  //   // draw weird image type to canvas so we can get a useful image
  //   canvas.width = bitmap.width;
  //   canvas.height = bitmap.height;
  //   const context = canvas.getContext("2d");
  //   context.drawImage(bitmap, 0, 0, bitmap.width, bitmap.height);
  //   const image = canvas.toDataURL();

  //   // this turns the base 64 string to a [File] object
  //   const res = await fetch(image);
  //   const buff = await res.arrayBuffer();
  //   // clone so we can rename, and put into array for easy proccessing
  //   const file = [
  //     new File([buff], `photo_${new Date()}.jpg`, {
  //       type: "image/jpeg",
  //     }),
  //   ];
  //   canvas.width = 0;
  //   canvas.height = 0;
  //   return file;
  // };

  // const takeAndUploadPhoto = async () => {
  //   try {
  //     console.log("here");
  //     const screenshot = await takeScreenShot();
  //     console.log("screenshot =", screenshot);
  //     // const path = STORAGE_PATHS.IMAGES;
  //     const path = "/images";
  //     // const { uid } = await auth().currentUser;
  //     // const uuid = generateUUID()
  //     const now = new Date();
  //     // const storageRef = ref(storage, screenshot);
  //     const ref = `${path}/${now.toISOString().split(".")[0]}_${user.id}_${
  //       user.email
  //     }_${String(uuidv4())}`;
  //     console.log("ref =", ref);
  //     const storageRef = storage().ref(ref);
  //     const uploadTask = storageRef.put(screenshot[0]);
  //     uploadTask.on(
  //       "state_changed",
  //       (snapshot) => {
  //         // Observe state change events such as progress, pause, and resume
  //         // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
  //         const progress =
  //           (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
  //         console.log("Upload is " + progress + "% done");
  //         switch (snapshot.state) {
  //           case "paused":
  //             console.log("Upload is paused");
  //             break;
  //           case "running":
  //             console.log("Upload is running");
  //             break;
  //           default:
  //             break;
  //         }
  //       },
  //       (error) => {
  //         // Handle unsuccessful uploads
  //         console.log("error =", error);
  //       },
  //       () => {
  //         // Handle successful uploads on complete
  //         // For instance, get the download URL: https://firebasestorage.googleapis.com/...
  //         uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
  //           console.log("File available at", downloadURL);
  //           const now = new Date();
  //           update(`${COLLECTION_NAME}/${DOC}`, {
  //             screenshots: [
  //               ...classroom?.screenshots,
  //               {
  //                 imageURI: downloadURL,
  //                 createdAt: now,
  //                 createdBy: user.id,
  //               },
  //             ],
  //           });
  //         });
  //       }
  //     );
  //   } catch (error) {
  //     console.log("error =", error);
  //   }
  // };

  // return (
  //   <div
  //     style={{
  //       borderWidth: 1,
  //       borderColor: "black",
  //       display: "flex",
  //       height: 1000,
  //     }}
  //   >
  //     {/* STUDENTS SCREEN FUNCTIONALITY HERE */}
  //     {/* <div style={{ flex: 1, borderWidth: 1, borderColor: "black" }}>
  //       <div>
  //         <canvas id="fake" />
  //         <button
  //           onClick={takeAndUploadPhoto}
  //           style={{
  //             width: "50%",
  //             borderWidth: 1,
  //             borderColor: "black",
  //             borderRadius: 2,
  //             margin: 4,
  //           }}
  //         >
  //           press
  //         </button>
  //       </div>
  //     </div> */}
  //     {/* TEACHERS SCREEN */}
  //     <div style={{ flex: 1, borderWidth: 1, borderColor: "black" }}>
  //       {classroom && JSON.stringify(classroom)}
  //       {classroom &&
  //         classroom.photo.map((img, idx) => {
  //           return (
  //             <a>
  //               <img
  //                 key={String(idx)}
  //                 src={img}
  //                 height={100}
  //                 width={100}
  //                 alt="img"
  //               />
  //             </a>
  //           );
  //         })}
  //     </div>
  //   </div>
  // );
}
