import React from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { updateClassroomPromise } from '@store/actions';
import { selectActiveClassroom } from '@store/selectors';
import styles from './ModalSharedScreenFull.module.scss';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import ProfileImage from '@app/components/ProfileImage';
import { profileImgPath } from '@api';
import ClassroomTimer from '@components/Card/CardClassroomTimer';
import LockIcon from '@mui/icons-material/Lock';
import ReplyIcon from '@mui/icons-material/Reply';
import { CommentsLayer } from '../CommentsLayer/CommentsLayer';
import { Tooltip } from '@mui/material';
import { recordButtonEvent } from '@app/infra/RecordButtonEvent/eventRecorder';

const SharedScreenFull = (props) => {
  const {
    screenImg,
    show,
    setShow,
    name,
    currentStudent,
    clickLockScreen,
    selectedStd,
    sendUpdatedClassroomBySocket,
    setModalShow,
  } = props;
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const user = useSelector((state) => state.user);
  const commentsLayerState = useSelector((state) => state.commentsLayer.commentsLayerState);
  const dispatch = useDispatch();

  return (
    <Modal
      show={show}
      onHide={() => setModalShow(false)}
      className={styles['modal']}
    >
      <Modal.Body className={styles['modal-body']}>
        <CommentsLayer screenImg={screenImg} isVisible={show} />
        {screenImg ? (
          <img
            alt="screenImg"
            src={`data:image/jpeg;base64,${screenImg}`}
            className={styles['shared-screen']}
          />
        ) : (
          commentsLayerState ? null : (
            <span className={styles['big-img']}>
              교사 컴퓨터 아이콘이나 학생 썸네일을 클릭하면 여기서 크게 볼 수 있으며
              <br /> 학생 전체에게 공유할 수 있습니다.
            </span>
          )
        )}
        <div className={styles['screen-buttons-container']}>
          <div className={styles['screen-student-name']}>
            <ProfileImage
              size={26}
              url={
                currentStudent?.clientId
                  ? profileImgPath(currentStudent.clientId)
                  : profileImgPath(user?.clientId)
              }
            />
            <Tooltip
              title={currentStudent?.userName || user?.userName + ' 선생님'}
              arrow
            >
              <div
                className={styles['screen-student-name-text']}
                style={{ marginLeft: 8 }}
              >
                {currentStudent?.userName || user?.userName + ' 선생님'}
              </div>
            </Tooltip>
            <ClassroomTimer key="fullscreen-timer" />
          </div>
          <div className={styles['screen-btn-container']}>
            <div
              className={styles['screen-btn']}
              onClick={() => clickLockScreen()}
            >
              <div className={styles['inner-div']}>
                {activeClassroom?.screenLocked ? (
                  <>
                    <div className={styles['lock-icon-active-box']}>
                      <LockIcon
                        style={{
                          color: '#ffffff',
                          width: 20,
                          height: 20,
                        }}
                      />
                    </div>
                    <div>학생 화면 잠금 해제</div>
                  </>
                ) : (
                  <>
                    <div className={styles['lock-icon-disabled-box']}>
                      <LockIcon
                        style={{
                          color: '#ffffff',
                          width: 20,
                          height: 20,
                        }}
                      />
                    </div>
                    <div>학생 화면 잠금</div>
                  </>
                )}
                {/*                 
                <LockIcon
                  style={{
                    color: '#ffffff',
                    width: 20,
                    height: 20,
                  }}
                />
                <div>
                  {activeClassroom?.screenLocked
                    ? '학생 화면 잠금 해제'
                    : '학생 화면 잠금'}
                </div> */}
              </div>
            </div>
            <div
              className={styles['screen-btn']}
              onClick={() => {
                // TODO: 공유 대상이 선생님이면 선생님 client id, 학생이면 학생 client id
                const sharedClientId = null;
                if (!activeClassroom?.screenShared) {
                  dispatch(
                    updateClassroomPromise({
                      classroomId: activeClassroom.classroomId,
                      screenShared: true,
                      sharedClientId: selectedStd,
                    })
                  ).then(() => {
                    sendUpdatedClassroomBySocket();
                  });
                  // .catch((error) => alert(error
                } else {
                  dispatch(
                    updateClassroomPromise({
                      classroomId: activeClassroom.classroomId,
                      screenShared: false,
                      sharedClientId: user.clientId,
                    })
                  )
                    .then(() => {
                      sendUpdatedClassroomBySocket();
                    })
                    .catch((error) => console.error(error));
                }
              }}
            >
              <div className={styles['inner-div']}>
                {activeClassroom?.screenShared ? (
                  <>
                    <div className={styles['reply-icon-active-box']}>
                      <ReplyIcon
                        style={{
                          color: '#ffffff',
                          width: 20,
                          height: 20,
                          transform: 'scaleX(-1)',
                        }}
                      />
                    </div>
                    <div>공유 해제</div>
                  </>
                ) : (
                  <>
                    <div className={styles['reply-icon-disabled-box']}>
                      <ReplyIcon
                        style={{
                          color: '#ffffff',
                          width: 20,
                          height: 20,
                          transform: 'scaleX(-1)',
                        }}
                      />
                    </div>
                    <div>학생들에게 공유</div>
                  </>
                )}

                {/* <ReplyIcon
                  style={{
                    color: '#ffffff',
                    width: 20,
                    height: 20,
                    transform: 'scaleX(-1)',
                  }}
                />
                <div
                  className={
                    !activeClassroom?.screenShared ? styles['long-text'] : ''
                  }
                >
                  {activeClassroom?.screenShared
                    ? '공유해제'
                    : '학생들에게 공유'}
                </div> */}
              </div>
            </div>
            <div className={styles['screen-btn']}>
              <div
                onClick={() => {
                  setModalShow(false);
                  // recordButtonEvent('USER_CLICKED_CLASS_FULLSCREEN_OFF', user.clientId, activeClassroom?.groupId);
                }}
                className={styles['inner-div']}
              >
                <div className={styles['fullscreen-icon-active-box']}>
                  <Tooltip title='선생님의 전체화면을 종료하고 원래의 수업 화면으로 돌아갈 수 있습니다.' arrow>
                  <FullscreenIcon
                    style={{
                      color: '#ffffff',
                      width: 20,
                      height: 20,
                    }}
                  />
                  </Tooltip>
                </div>
                전체 화면 종료
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SharedScreenFull;
