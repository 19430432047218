import {
  Body2,
  Column,
  Divider,
  HeaderSection,
  Heading6,
  MainContentLayer,
  Subtitle1,
} from '@app/pages/QuizPang/common';
import { SearchInput } from '@app/pages/QuizPang/search-input';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { getQuizsetSessionHistoryPromise } from '@app/store/quizsetSession/actions';
import { QuizsetSessionHistory } from '@app/pages/QuizPang/InClass/quizset-session-history';
import styled from 'styled-components';
import { getQuizsetListByClientPromise } from '@app/store/actions';
import { selectClientQuizsetList } from '@app/store/selectors';

export const BeforeClass = () => {
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const clientId = useSelector((state) => state.user.clientId);
  const _historyList = useSelector((state) => state.quizsetSession.histories);
  const [query, setQuery] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const queryTrimmed = query.trim();

  // filtered historyList로 전달받은 _historyList의 배열에 groupId와 같은 것만 필터링
  const filteredHistoryList = _historyList.filter((x) => x.groupId === groupId);

  const historyList =
    queryTrimmed.length > 0
      ? _historyList.filter((x) => x.quizsetTitle.includes(queryTrimmed))
      : _historyList;
  const _quizsetList = useSelector(selectClientQuizsetList);
  const quizsetList =
    queryTrimmed.length > 0
      ? _quizsetList.filter((x) => x.title.includes(queryTrimmed))
      : _quizsetList;

  useEffect(() => {
    dispatch(getQuizsetSessionHistoryPromise(groupId)).then(() =>
      setIsLoading(false)
    );
    dispatch(getQuizsetListByClientPromise(clientId));
  }, [dispatch, groupId, clientId]);

  return (
    <div>
      <Column style={{ padding: '0px 24px' }}>
        <Subtitle1>퀴즈팡 사용 기록</Subtitle1>
        {isLoading ? (
          <Body2 style={{ marginTop: 16 }}>퀴즈팡 기록 로딩 중입니다...</Body2>
        ) : historyList.length ? (
          historyList.map((x, i) => <QuizsetSessionHistory key={i} data={x} />)
        ) : (
          <Body2 style={{ marginTop: 16 }}>
            아직 퀴즈팡 사용 기록이 없습니다. 퀴즈팡을 사용해 보세요!
          </Body2>
        )}
      </Column>
    </div>
  );
};

export const BaseText = styled.span`
  font-family: 'Noto Sans KR', 'Roboto', sans-serif;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`;
export const SubTitle = styled(BaseText)`
  font-weight: 500;
  font-size: 16px;
  line-height: 32px;
  padding: 0 24px;
  margin-bottom: 8px;
  margin-top: -6px;
`;
export const MovePage = styled.button`
  border-width: 1px;
  border-radius: 24px;
  padding: 8px 18px;
  background-color: #0894a0;
  color: #fff;
  &:hover {
    background-color: #16747cd7;
  }
`;
