import styled from "@emotion/styled";

const BaseInput = styled.input`
font-family: 'Noto Sans KR';
font-weight: 500;
font-size: 14px;
line-height: 18px;
letter-spacing: 0.25px;
color: rgba(0, 0, 0, 0.6);
border: solid 1px #E0E0E0;
border-radius: 4px;
padding: 10px 16px;
transition: border-color 0.2s ease;
width: 100%;

:hover {
    border-color: #0BA1AE;
}

:focus {
    border-color: #0BA1AE;
    outline: none;
}
`;

export default BaseInput;