import React, { useState, useEffect } from "react";
import styles from "./CheckableStudentList.module.scss";
import ProfileImage from "../ProfileImage";
import { profileImgPath } from "@api";

const CheckableStudentList = ({
  students,
  studentsWithGroup,
  onCheckedChange,
  defaultChecked,
  initialStudents,
  studentFilter,
}) => {
  const [bandedStudents, setBandedStudents] = useState(initialStudents);

  useEffect(() => {
    onCheckedChange(bandedStudents);
  }, [bandedStudents, onCheckedChange]);

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["student-list"]}>
        <form>
          {students.map((student, idx) => {
            return (
              <Student
                key={idx}
                student={student}
                alreadyInGroup={studentsWithGroup.includes(student.clientId)}
                defaultValue={defaultChecked.includes(student.clientId)}
                toHide={!studentFilter(student)}
                setChecked={(b) => {
                  if (b)
                    setBandedStudents(bandedStudents.concat(student.clientId));
                  else
                    setBandedStudents(
                      bandedStudents.filter((x) => {
                        return x != student.clientId;
                      })
                    );
                }}
              />
            );
          })}
        </form>
      </div>
    </div>
  );
};

const Student = ({
  student,
  alreadyInGroup,
  setChecked,
  defaultValue,
  toHide,
  ...rest
}) => {
  const setBoolean = setChecked;

  return (
    <div
      className={styles["div-std-list"]}
      style={
        toHide
          ? { display: "none" }
          : alreadyInGroup
          ? { backgroundColor: "#FAFAFA" }
          : {}
      }
    >
      <div className={styles["left"]}>
        <input
          type={"checkbox"}
          defaultChecked={defaultValue}
          onChange={(e) => setBoolean(e.currentTarget.checked)}
          disabled={alreadyInGroup}
        />
        <div className={styles["std-number"]}>
          {Number(student.studentNumber)}
        </div>
        <div className={styles["profile-box"]}>
          <div className={styles["std-profile-wrapper"]}>
            <ProfileImage size={28} url={profileImgPath(student.clientId)} />
          </div>
          <div className={styles["std-name"]}>{student.userName}</div>
        </div>
      </div>
      {alreadyInGroup && (
        <div className={styles["informaion-container"]}>모둠 있음</div>
      )}
    </div>
  );
};
export default CheckableStudentList;
