import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import styles from './ModalConfirm.module.scss';
import { selectActiveClassroom, selectGroup } from '@app/store/selectors';
import {
  deleteMembershipPromise,
  getMembershipsByGroupPromise,
  getParticipationsByClassroomPromise,
  setSocketData,
} from '@app/store/actions';
import Loading from '../Loading/Loading';
import { deleteParticipation } from '@api';

const ModalConfirm = ({
  confirmModal,
  setConfirmModal,
  propUsername,
  propStudentNum,
  propUserId,
  groupId,
  user,
}) => {
  const dispatch = useDispatch();
  const groupInfo = useSelector((state) => selectGroup(state, groupId));
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const [loading, setLoading] = useState(false);

  if (activeClassroom) {
    console.log('activeClassroom', activeClassroom.classroomId);
  }
  const onKick = async () => {
    setLoading(true);
    if (activeClassroom) {
      await dispatch(
        deleteMembershipPromise({
          groupId: groupInfo.groupId,
          clientId: propUserId,
        })
      );
      await deleteParticipation({
        classroomId: activeClassroom.classroomId,
        clientId: propUserId,
      });
      await dispatch(
        setSocketData({
          method: 'POST',
          uri: '/classroom/sendImage',
          groupId: groupId,
          clientId: user?.clientId,
          type: 'KICK_STUDENT',
          data: propUserId,
        })
      );
      await dispatch(getMembershipsByGroupPromise(groupInfo.groupId));
      await dispatch(
        getParticipationsByClassroomPromise(activeClassroom.classroomId)
      );
      setLoading(false);
      setConfirmModal(false);
      window.location.reload();
    } else {
      await dispatch(
        deleteMembershipPromise({
          groupId: groupInfo.groupId,
          clientId: propUserId,
        })
      );
      await dispatch(
        setSocketData({
          method: 'POST',
          uri: '/classroom/sendImage',
          groupId: groupId,
          clientId: user?.clientId,
          type: 'KICK_STUDENT',
          data: propUserId,
        })
      );
      await dispatch(getMembershipsByGroupPromise(groupInfo.groupId));
      setLoading(false);
      setConfirmModal(false);
      window.location.reload();
    }
  };

  const onHide = () => {
    setConfirmModal(false);
  };

  return (
    <Modal
      show={confirmModal}
      onHide={() => {
        setConfirmModal(false);
      }}
      centered
      className={styles['editInfomation-container']}
    >
      <div onSubmit={onKick} className={styles['modal-cotainer']}>
        <div className={styles['infoContainer']}>
          <div className={styles['title-css']}>학생을 내보내시겠어요?</div>
          <div className={styles['studentInfo']}>
            {/* {groupInfo.grade}학년 {groupInfo.groupName}반 /  */}
            {propStudentNum}번 {propUsername} 내보내기
          </div>
        </div>
        <div className={styles['button-container']}>
          <button
            type="button"
            className={styles['cancle-button']}
            style={{ border: '1px solid #e0e0e0', borderRadius: '4px' }}
            onClick={() => {
              onHide();
            }}
          >
            취소
          </button>
          <button
            type="button"
            className={styles['kick-button']}
            style={{ border: '1px solid #e0e0e0', borderRadius: '4px' }}
            onClick={() => {
              onKick();
            }}
          >
            내보내기
          </button>
        </div>
      </div>
      {loading && <Loading />}
    </Modal>
  );
};

export default ModalConfirm;
