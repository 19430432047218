import { createPromiseAction } from '@adobe/redux-saga-promise';
import {
  PURGE_REPORT,
  GET_REPORT_SUCCESS,
  GET_REPORT_FAIL,
  GET_REPORTS_LIST_BY_CLIENT_FAIL,
  GET_REPORTS_LIST_BY_CLIENT_SUCCESS,
  GET_REPORTS_LIST_BY_GROUP_FAIL,
  GET_REPORTS_LIST_BY_GROUP_SUCCESS,
} from './actionTypes';

export const purgeReportList = () => ({
  type: PURGE_REPORT,
});

//GET_REPORT
export const getReportPromise = createPromiseAction('GET_REPORT_PROMISE');

export const getReportSuccess = (report) => ({
  type: GET_REPORT_SUCCESS,
  report: report,
});

export const getReportFail = (error) => ({
  type: GET_REPORT_FAIL,
  payload: error,
});

//GET_REPORTS_LIST_BY_CLIENT
export const getReportsListByClientPromise = createPromiseAction(
  'GET_REPORTS_LIST_BY_CLIENT_PROMISE'
);
export const getReportsListByClientSuccess = (reports) => {
  let byId = {};
  reports.forEach((report) => {
    byId[`${report.reportId}`] = report;
  });
  const allIds = reports.map((report) => {
    return `${report.reportId}`;
  });
  return {
    type: GET_REPORTS_LIST_BY_CLIENT_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

//GET_REPORTS_LIST_BY_GROUP
export const getReportsListByClientFail = (error) => ({
  type: GET_REPORTS_LIST_BY_CLIENT_FAIL,
  payload: error,
});

//GET_REPORTS_LIST_BY_GROUP
export const getReportsListByGroupPromise = createPromiseAction(
  'GET_REPORTS_LIST_BY_GROUP_PROMISE'
);

export const getReportsListByGroupSuccess = (reports) => {
  let byId = {};
  reports.forEach((report) => {
    byId[report.reportId] = report;
  });
  const allIds = reports.map((report) => {
    return `${report.reportId}`;
  });
  return {
    type: GET_REPORTS_LIST_BY_GROUP_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getReportsListByGroupFail = (error) => ({
  type: GET_REPORTS_LIST_BY_GROUP_FAIL,
  payload: error,
});
