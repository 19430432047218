import {
  clearNetworkState,
  getAccountTokenPromise,
  purgeAll,
  setNewtorkState,
  setRefreshstate,
} from '@app/store/actions';
import React, { useEffect } from 'react';
import { useInterval } from 'react-hookedup';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

const RefreshManager = () => {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const reduxRefreshDate = useSelector((state) => state.control.refreshDate);
  const today = new Date();
  const dispatch = useDispatch();
  const networkCounter = useSelector((state) => state.control.networkState);

  const checkNetwork = () => {
    if (!navigator.onLine && user.signedIn) {
      dispatch(setNewtorkState(networkCounter + 1));
    }
    if (navigator.onLine && networkCounter > 0 && user.signedIn) {
      dispatch(clearNetworkState());
    }
  };

  const checkToekn = () => {
    if (!user || !user.accountId) {
      return;
    }
    if (!localStorage.getItem('token') && user.signedIn) {
      dispatch(getAccountTokenPromise(user.accountId));
    }
  };

  useInterval(() => {
    checkNetwork();
    checkToekn();
  }, 5000);

  useEffect(() => {
    if (networkCounter > 3) {
      alert('네트워크 연결을 확인해주세요.');
      dispatch(purgeAll());
      localStorage.clear();
      history.replace('/auth');
    }
  }, [networkCounter]);

  useEffect(() => {
    if (!user || !user.signedIn) {
      return;
    }
    if (!user.accountId) {
      dispatch(purgeAll());
      localStorage.clear();
      history.replace('/auth');
    }
    if (!localStorage.getItem('token')) {
      dispatch(getAccountTokenPromise(user.accountId));
    }
    if (reduxRefreshDate === 0) {
      dispatch(setRefreshstate(today.getDate()));
    }
    if (today.getDate() !== reduxRefreshDate && reduxRefreshDate !== 0) {
      dispatch(purgeAll());
      localStorage.clear();
      history.replace('/auth');
    }
  }, [dispatch, user.clientId, reduxRefreshDate, networkCounter]);

  return <div />;
};

export default RefreshManager;
