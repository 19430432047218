import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './CardLectureInfo.module.scss';
import BaseCard from './baseCard';
import ClassTimeline from './classTimeline';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tooltip } from '@material-ui/core';
import { recordButtonEvent } from '@app/infra/RecordButtonEvent/eventRecorder';
import { useSelector } from 'react-redux';

const CardLectureInfo = ({
  timetable,
  group,
  modal_func,
  prop_tables,
  prop_weekday,
}) => {
  const user = useSelector((state) => state.user);
  const history = useHistory();

  const navigateToClassRoom = () => {
    if (group.groupType === 'AFTER') {
      history.push({
        pathname: `/after-school/${group?.groupId}`,
        state: { group, timetable },
      });
    } else {
      history.push({
        pathname: `/classroom/${group?.groupId}`,
        state: { group, timetable },
      });
    }
  };

  return (
    <BaseCard
      className={styles['container']}
      style={{ backgroundColor: '#FFF' }}
    >
      <div
        style={{ cursor: 'pointer' }}
        onClick={() => {
          navigateToClassRoom();
        }}
      >
        <div className={styles['title']}>
          <div className={styles['title-top']} type="button">
            <div className={styles['information']}>
              <ClassTimeline
                period={timetable.period}
                timeString={`${timetable.startTime}`}
              />
            </div>
            <Tooltip title={group.subjectName} arrow>
              <div className={styles['subject']}>
                <div
                  style={{
                    maxWidth: '80px',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {group.subjectName}
                </div>
              </div>
            </Tooltip>
            <Tooltip title="해당 수업 정보를 수정 하실 수 있습니다." arrow>
              <div
                className={styles['setting-block']}
                onClick={(e) => {
                  e.stopPropagation();
                  modal_func({
                    timetables: prop_tables,
                    weekDay: prop_weekday,
                    period: timetable.period,
                    startTime: timetable.startTime,
                    endTime: timetable.endTime,
                    group: group,
                    timetableId: timetable.timetableId,
                  });
                  // recordButtonEvent(
                  //   'USER_CLICKED_TIMETABLE_SETTING',
                  //   user.clientId
                  // );
                }}
              >
                <SettingsIcon className={styles['setting-icon']} />
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={styles['title-under']}>
          <div className={styles['title-under-text']}>
            {group.grade} - {group.groupName}
          </div>
        </div>
      </div>
    </BaseCard>
  );
};

export default CardLectureInfo;
