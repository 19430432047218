import React, { useState } from 'react';
import styles from './SubgroupActivityCreate.module.scss';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import ModalAssembleBand from '../Modal/ModalAssembleBand';
import { useDispatch } from 'react-redux';
import { createSubgroupActivityPromise } from '@app/store/actions';
import { Tooltip } from '@mui/material';

export const SubgroupActivityCreate = ({
  studentList,
  subgroupActivityId,
  subGroupList = [],
}) => {
  const dispatch = useDispatch();
  const [assembleModal, setAssembleModal] = useState(false);

  const onSubmit = (name, clientIds) => {
    const students = JSON.stringify(
      clientIds.map((clientId) => ({ clientId }))
    );
    dispatch(
      createSubgroupActivityPromise({
        subgroupActivityId,
        name,
        students,
      })
    ).then(() => {
      setAssembleModal(false);
    });
  };

  return (
    <>
      <div className={styles['contentHeader']}>
        <Tooltip
          title="클릭 시 학생들을 한 그룹으로 묶어 모둠을 생성하실 수 있습니다. 여기서의 모둠이란 학생들의 하나의 모둠활동을 뜻합니다."
          arrow
        >
          <div
            className={styles['subGroupCreate']}
            onClick={() => setAssembleModal(true)}
          >
            <AddCircleIcon />
            모둠 만들기
          </div>
        </Tooltip>
        {subGroupList.length === 0 && (
          <div className={styles['headerHint']}>
            현재 편성된 모둠이 없습니다. 왼쪽의 모둠 만들기 버튼을 눌러서 모둠을
            편성해주세요.
          </div>
        )}
      </div>
      {assembleModal && (
        <ModalAssembleBand
          confirmModal={assembleModal}
          setConfirmModal={setAssembleModal}
          onHide={() => setAssembleModal(false)}
          onAssembleBandActivity={onSubmit}
          students={studentList}
          studentsWithGroup={studentList
            .filter((item) => item.subgroupId)
            .map((item) => item.clientId)}
          editMode={false}
          previousBandName={''}
          previousBandMember={[]}
        />
      )}
    </>
  );
};
