import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Sidebar from '@components/Layout/Sidebar';
import styles from './StudentsNormal.module.scss';
import BaseCard from '../../components/Card/baseCard';
import ModalUpdateProfileImage from '@components/Modal/ModalUpdateProfileImage';
import StudentList from '@components/Layout/StudentList';
import { getMembershipsByGroupPromise } from '@store/actions';
import { setStudentListUpdate } from '@app/store/actions';
import { useParams } from 'react-router-dom';

const StudentsNormal = (props) => {
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const [modalShow, setModalShow] = useState(false);

  const stdUpdateRequest = useSelector(
    (state) => state.control.stdUpdateRequest
  );

  const [students, setStudents] = useState([]);

  useEffect(() => {
    if (!groupId) {
      return;
    }

    dispatch(getMembershipsByGroupPromise(groupId))
      .then((clientMembershipsData) => {
        const clients = clientMembershipsData?.map((clientMembershipData) => {
          return clientMembershipData.client;
        });

        const filteredClients = clients.filter((client) => {
          return client.role === 'STUDENT';
        });
        setStudents(filteredClients);
      })
      .then(() => {
        dispatch(setStudentListUpdate(false));
      });
  }, [groupId, stdUpdateRequest]);

  useEffect(() => {
    if (!groupId) {
      return;
    }
    if (stdUpdateRequest === false) {
      return;
    }
    dispatch(getMembershipsByGroupPromise(groupId))
      .then((clientMembershipsData) => {
        const clients = clientMembershipsData?.map((clientMembershipData) => {
          return clientMembershipData.client;
        });

        const filteredClients = clients.filter((client) => {
          return client.role === 'STUDENT';
        });
        setStudents(filteredClients);
      })
      .then(() => {
        dispatch(setStudentListUpdate(false));
      });
  }, [stdUpdateRequest]);


  const user = useSelector((state) => state.user);

  return (
    <>
      <ModalUpdateProfileImage
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
      <div className='flex w-100vw h-100vh'>
        <Sidebar inClassroom />
        <div
          style={{
            marginLeft: '280px',
            width: '100%',
            padding: 14,
            background: '#f9f9f9',
            minHeight: '100vh',
          }}
          className={styles['settings-container']}
        >
          <BaseCard style={{ minHeight: '100%', padding: '20px 40px' }}>
            <StudentList key={groupId} groupId={groupId} students={students} />
          </BaseCard>
        </div>
      </div>
    </>
  );
};

export default StudentsNormal;
