import React, { useState } from 'react'
import { Switch } from '@mui/material'
import { Caption, HorizontalSpace8 } from '@app/pages/QuizPang/common'
import styled from 'styled-components'
import { updateQuizsetPromise } from '@app/store/quizset/actions'
import { useDispatch } from 'react-redux'

export const QuizsetOptionSwitch = ({ quizset }) => {
  const [isAutoPassed, setIsAutoPassed] = useState(quizset.isAutoPassed)
  const [isShowRank, setIsShowRank] = useState(quizset.isShowRank)
  const dispatch = useDispatch()
  const asyncHandleOption = async (v, setV, key) => {
    const { quizsetId } = quizset
    try {
      await dispatch(updateQuizsetPromise({
        quizsetId,
        [key]: v,
      }))
      setV(v)
    } catch (e) {
      alert(`퀴즈 옵션 수정 실패!\n${e}`)
    }
  }
  return <>
    <SwitchText>자동으로 다음 문제 넘어가기</SwitchText>
    <Switch
      checked={isAutoPassed}
      onChange={(e) => asyncHandleOption(e.target.checked, setIsAutoPassed, 'isAutoPassed')}
    />
    <HorizontalSpace8/>
    <SwitchText>학생에게 순위 보여주기</SwitchText>
    <Switch
      checked={isShowRank}
      onChange={(e) => asyncHandleOption(e.target.checked, setIsShowRank, 'isShowRank')}
    />
  </>
}

const SwitchText = styled(Caption)`
  color: rgba(0, 0, 0, 0.87);
`
