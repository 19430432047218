import React, { memo, useEffect, useMemo } from 'react';
import styles from './ManagingSidebar.module.scss';

import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';
import imgHomeIcon from '@images/home/ic-home-24.svg';
import imgHomeActiveIcon from '@images/home/ic_round-home.svg';
import { setSidebarState } from '@store/actions';
import { profileImgPath } from '@api';
import SettingsIcon from '@mui/icons-material/Settings';
import { selectManagingGroup, selectGroupById } from '@app/store/selectors';
import ManagingSidebarTap from './ManagingSidebarTap';
import { Tooltip } from '@mui/material';
import TopUserSection from '../Drawer/TopUserSection';
import InclassInviteCode from '../Drawer/InclassInviteCode';
import HomeButtonList from '../Buttons/Sidebar/HomeButtonList';

const ManagingSidebar = (props) => {
  const { inClassroom } = props;
  const { groupId } = useParams();

  const group = useSelector((state) => selectGroupById(state, groupId));

  const dispatch = useDispatch();
  const control = useSelector((state) => state.control);

  const user = useSelector((state) => state.user);
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (user.state === 'REGISTERED_APPROVED') {
      if (control.sidebarState === false) dispatch(setSidebarState());
    } else {
      //dispatch(setSidebarState(false));
    }
  }, [user, control.sidebarState]);

  const onPressStart = () => {
    history.replace('/home');
    return;
  };

  const onPressSettings = () => {
    history.replace('/settings');
    return;
  };

  const onPressAfter = () => {
    history.replace(`/managing`);
    return;
  };

  return (
    <div className={styles['side-screen']}>
      <div className={styles['inner-side']}>
        <TopUserSection />
        <div
          className={classNames(
            control.sidebarState !== true && styles['block-click']
          )}
        >
          <>
            <div className={styles['home-block']}>
              <div className={styles['home-block-inner-box']}>
                {location.pathname.includes(`${groupId}`) ? (
                  <div
                    className={styles['home-button']}
                    style={
                      location.pathname === '/home'
                        ? { backgroundColor: '#05869225' }
                        : {}
                    }
                    onClick={() => history.replace('/managing')}
                  >
                    <img
                      className={styles['img-set']}
                      src={
                        location.pathname === '/home'
                          ? imgHomeActiveIcon
                          : imgHomeIcon
                      }
                      alt="home"
                    />

                    <div
                      className={styles['span-sidebar']}
                      style={
                        location.pathname === '/home'
                          ? { color: '#35B5C2' }
                          : {}
                      }
                    >
                      이전으로
                    </div>
                  </div>
                ) : (
                  <div
                    className={styles['home-button']}
                    style={
                      location.pathname === '/home'
                        ? { backgroundColor: '#05869225' }
                        : {}
                    }
                    onClick={onPressStart}
                  >
                    <img
                      className={styles['img-set']}
                      src={
                        location.pathname === '/home'
                          ? imgHomeActiveIcon
                          : imgHomeIcon
                      }
                      alt="home"
                    />

                    <div
                      className={styles['span-sidebar']}
                      style={
                        location.pathname === '/home'
                          ? { color: '#35B5C2' }
                          : {}
                      }
                    >
                      처음으로
                    </div>
                  </div>
                )}
              </div>
              {group && (
                <>
                  <div className={styles['groupDetail-block']}>
                    <Tooltip title={group.subjectName} arrow>
                      <div className={styles['groupName-block']}>
                        <span className={styles['num-size']}>
                          {group.grade}
                        </span>
                        학년
                        <div
                          style={{
                            maxWidth: '30%',
                            textOverflow: 'ellipsis',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            marginLeft: '4px',
                            marginRight: '4px',
                          }}
                        >
                          {group.subjectName}
                        </div>
                        관리그룹
                      </div>
                    </Tooltip>
                  </div>
                  <InclassInviteCode />
                </>
              )}
            </div>
            <div className={styles['menu-block']}>
              {location.pathname.includes(`${groupId}`) ? (
                <div />
              ) : (
                <div>
                  <HomeButtonList
                    location={location}
                    history={history}
                    onPressAfter={onPressAfter}
                  />
                </div>
              )}
            </div>
          </>
        </div>
        {inClassroom ? <ManagingSidebarTap groupId={groupId} /> : null}

        {!inClassroom && (
          <div className={styles['bottom-container']}>
            <div
              className={styles['setting-block']}
              onClick={() => onPressSettings()}
            >
              <SettingsIcon
                style={{
                  color: '#ffffff',
                  width: 20,
                  height: 20,
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default memo(ManagingSidebar);
