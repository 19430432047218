import React from "react";
import { Modal } from "react-bootstrap";
import styles from "./ModalGenericConfirm.module.scss";

const ModalGenericConfirm = ({
  confirmModal,
  setConfirmModal,
  title,
  description,
  onFire,
  confirmText,
  dimmed,
}) => {
  const onKick = onFire;

  const onHide = () => {
    setConfirmModal(false);
  };

  return (
    <Modal
      animation={!dimmed}
      show={confirmModal}
      onHide={() => {
        setConfirmModal(false);
      }}
      centered
      className={styles["editInfomation-container"]}
    >
      <div onSubmit={onKick} className={styles["modal-cotainer"]}>
        <div className={styles["infoContainer"]}>
          <div className={styles["title-css"]}>{title}</div>
          <div className={styles["studentInfo"]}>{description}</div>
        </div>
        <div className={styles["button-container"]}>
          <button
            type='button'
            className={styles["cancle-button"]}
            onClick={() => {
              onHide();
            }}
          >
            취소
          </button>
          <button
            type='button'
            className={styles["kick-button"]}
            onClick={() => {
              onKick();
            }}
          >
            {confirmText}
          </button>
        </div>
      </div>
      {dimmed && <div className={styles["addDimmed"]} />}
    </Modal>
  );
};

export default ModalGenericConfirm;
