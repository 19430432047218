export const PURGE_TIMETABLE = 'PURGE_TIMETABLE';

export const GET_TIMETABLE_SUCCESS = 'GET_TIMETABLE_SUCCESS';
export const GET_TIMETABLE_FAIL = 'GET_TIMETABLE_FAIL';

export const GET_TIMETABLES_BY_GROUP_SUCCESS = 'GET_TIMETABLES_BY_GROUP_SUCCESS';
export const GET_TIMETABLES_BY_GROUP_FAIL = 'GET_TIMETABLES_BY_GROUP_FAIL';

export const CREATE_TIMETABLE_SUCCESS = 'CREATE_TIMETABLE_SUCCESS';
export const CREATE_TIMETABLE_FAIL = 'CREATE_TIMETABLE_FAIL';

export const UPDATE_TIMETABLE_SUCCESS = 'UPDATE_TIMETABLE_SUCCESS';
export const UPDATE_TIMETABLE_FAIL = 'UPDATE_TIMETABLE_FAIL';

export const DELETE_TIMETABLE_SUCCESS = 'DELETE_TIMETABLE_SUCCESS';
export const DELETE_TIMETABLE_FAIL = 'DELETE_TIMETABLE_FAIL';
