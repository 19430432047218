import React, { useRef, useState } from 'react'
import Sidebar from '@components/Layout/Sidebar'
import {
  ArrowBackIconContainer,
  BottomContentLayer,
  Divider,
  HeaderSection,
  HeaderSectionHeading,
  HorizontalSpace16,
  HorizontalSpace8,
  MainContentLayer,
  SideBarRest,
} from '@app/pages/QuizPang/common'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { useHistory, useParams } from 'react-router-dom'
import { selectClientQuizset } from '@app/store/quizset/selector'
import { useDispatch, useSelector } from 'react-redux'
import { QuizsetTimeInfo } from '@app/pages/QuizPang/quizset-card'
import { Button } from '@mui/material'
import { QuizCard, QuizCardMyActions } from '@app/pages/QuizPang/quiz-card'
import CreateIcon from '@mui/icons-material/Create'
import DeleteIcon from '@mui/icons-material/Delete'
import { QuizsetModal } from '@app/pages/QuizPang/quizset-modal'
import { QuizsetModalStore } from '@app/pages/QuizPang/quizset-modal-store'
import { deleteQuizsetPromise } from '@app/store/quizset/actions'
import { QuizsetOptionSwitch } from '@app/pages/QuizPang/quizset-option-switch'
import { QuizModalStore } from './quiz-modal-store'
import { deleteQuizPromise } from '@app/store/actions'
import { QuizModal } from './quiz-modal'

function handleMouseOver(event) {
  event.target.style.backgroundColor = '#05869225';
}
function handleMouseOut(event) {
  event.target.style.backgroundColor = 'transparent';
}

const QuizsetDetail = () => {
  const { quizsetId } = useParams()
  const history = useHistory()
  const quizset = useSelector(store => selectClientQuizset(store, quizsetId))
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isModalOpen2, setIsModalOpen2] = useState(false)
  const modalStore = useRef(new QuizsetModalStore())
  const modalStore2 = useRef(new QuizModalStore())
  const dispatch = useDispatch()
  return <div className="flex w-100vw h-100vh">
    <Sidebar auth={true}/>
    <SideBarRest>
      <MainContentLayer>
        <HeaderSection>
          <ArrowBackIconContainer onClick={() => history.goBack()}>
            <ArrowBackIcon 
              onMouseOver={handleMouseOver} 
              onMouseOut={handleMouseOut}  
              style={{color:'#0894A0'}}
            />
          </ArrowBackIconContainer>
          <HeaderSectionHeading style={{ marginRight: 0 }}>
            {quizset.title} ({quizset.quizzes.length}개의 문제)
          </HeaderSectionHeading>
          <HorizontalSpace16/>
          <QuizsetTimeInfo data={quizset}/>
          <HorizontalSpace16/>
          <HorizontalSpace8/>
          <Button startIcon={<CreateIcon/>} onClick={() => {
            modalStore.current = new QuizsetModalStore(quizset)
            setIsModalOpen(true)
          }}>
            수정
          </Button>
          <HorizontalSpace8/>
          <Button startIcon={<DeleteIcon/>} onClick={() => {
            const res = window.confirm('정말 퀴즈를 삭제할까요?')
            if (res) {
              history.goBack()
              dispatch(deleteQuizsetPromise(quizset.quizsetId))
            }
          }}>
            삭제
          </Button>
        </HeaderSection>
        <HeaderSection style={{ paddingBottom: 16 }}>
          <QuizsetOptionSwitch quizset={quizset}/>
        </HeaderSection>
        <Divider/>
        <BottomContentLayer>
          {quizset.quizzes.map(x => <QuizCard 
            key={x.quizId} 
            // actions={
            //   <QuizCardMyActions
            //     onEdit={() => {
            //       modalStore2.current = new QuizModalStore(x)
            //       setIsModalOpen2(true)
            //     }}
            //     onDelete={() => {
            //       const res = window.confirm('정말 퀴즈를 삭제할까요?')
            //       if (res) dispatch(deleteQuizPromise(x.quizId))
            //     }}
            //   />
            // }
            data={x}
            // onClick={() => {
            //   modalStore2.current = new QuizModalStore(x)
            //   setIsModalOpen2(true)
            // }}
          />)}
        </BottomContentLayer>
      </MainContentLayer>
    </SideBarRest>
    <QuizsetModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}
                  store={modalStore.current}/>
    {/* <QuizModal isOpen={isModalOpen2} onClose={() => setIsModalOpen2(false)}
               store={modalStore2.current}/> */}
  </div>
}

export default QuizsetDetail
