import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import BaseCardAfter from './baseCardAfter';

const Wrapper = styled.div`
  padding: 16px;
  margin-bottom: 8px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Body = styled.div`
  margin-top: 16px;
`;

const ToggleButton = styled.div`
  color: #0ba1ae;
  display: flex;
  align-items: center;
  cursor: pointer;

  font-weight: 500;
  font-size: 12px;
  letter-spacing: 1.25px;
`;

const CollapseCardAfter = ({ title, children, initialOpen }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (initialOpen) {
      setOpen(true);
    }
  }, [initialOpen]);

  return (
    <BaseCardAfter>
      <Wrapper>
        <Header>
          <div>{title}</div>
          <ToggleButton onClick={() => setOpen((prev) => !prev)}>
            {open ? '접기' : '펼치기'}
            <KeyboardArrowDownIcon
              fontSize="small"
              style={{
                transform: `rotate(${open ? 180 : 0}deg)`,
                color: '#0BA1AE',
              }}
            />
          </ToggleButton>
        </Header>
        <Body open={open}>{children}</Body>
      </Wrapper>
    </BaseCardAfter>
  );
};

export default CollapseCardAfter;
