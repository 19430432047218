import {
  PURGE_CONTROL,
  SET_DIRTY,
  SET_SELECTED_PAGE,
  SET_SELECTED_CLASS,
  SET_APPROVAL_COMPLETED,
  SET_SIDEBAR_STATE,
  SET_DEV_MODE,
  SET_CLASSROOM_STATE,
  SET_FILELINK_STATE,
  SET_SOCKET_DATA,
  SET_REPORT_DATA,
  CLEAR_REPORT_DATA,
  SET_SCREEN_DATA,
  SET_STUDENT_IMAGES,
  SET_SELECTED_STUDENT,
  SET_STUDENTLIST_UPDATE_REQUEST,
  CLEAR_SCREEN_DATA,
  CLEAR_STUDENT_IMAGES,
  SET_BIG_URI,
  CLEAR_BIG_URI,
  SET_REFRESH_STATE,
  SET_SELECTED_GROUP,
  SET_NETWORK_STATE,
  CLEAR_NETWORK_STATE,
} from './actionTypes';

const initialState = {
  refreshDate: 0,
  dirty: 0,
  selectedPage: '',
  selectedClassId: null,
  selectedGroup: '',

  approvalCompleted: false,
  sidebarState: false,

  devMode: false,
  classroomState: false,
  filelinkState: 0,
  socketData: {},
  reportData: [],
  screenData: {},
  selectedStd: '',
  images: [],
  classroomData: '',
  stdUpdateRequest: false,
  bigUri: '',
  networkState: 0,
};

const controls = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CONTROL:
      return initialState;

    case SET_DIRTY:
      return { ...state, dirty: state.dirty + 1 };

    case SET_SELECTED_PAGE:
      return { ...state, selectedPage: action.selectedPage };
    case SET_SELECTED_GROUP:
      return { ...state, selectedGroup: action.selectedGroup };

    case SET_SELECTED_CLASS:
      return { ...state, selectedClassId: action.selectedClassId };

    case SET_APPROVAL_COMPLETED:
      return { ...state, approvalCompleted: true };
    case SET_SIDEBAR_STATE:
      return { ...state, sidebarState: true };

    case SET_DEV_MODE:
      return { ...state, devMode: action.devMode };
    case SET_CLASSROOM_STATE:
      return { ...state, classroomState: action.classState };
    case SET_FILELINK_STATE:
      return { ...state, filelinkState: state.filelinkState + 1 };
    case SET_SOCKET_DATA:
      return { ...state, socketData: action.socketData };
    case SET_REPORT_DATA:
      state.reportData[state.reportData.length] = action.reportData;
      return state;
    case CLEAR_REPORT_DATA:
      return { ...state, reportData: [] };
    case SET_SCREEN_DATA:
      return { ...state, screenData: action.screenData };
    case CLEAR_SCREEN_DATA:
      return { ...state, screenData: {} };
    case SET_STUDENT_IMAGES:
      return { ...state, images: action.images };
    case CLEAR_STUDENT_IMAGES:
      return { ...state, images: [] };
    case SET_SELECTED_STUDENT:
      return { ...state, selectedStd: action.selectedStd };
    case SET_STUDENTLIST_UPDATE_REQUEST:
      return { ...state, stdUpdateRequest: action.stdUpdateRequest };
    case SET_BIG_URI:
      return { ...state, bigUri: action.bigUri };
    case CLEAR_BIG_URI:
      return { ...state, bigUri: '' };
    case SET_REFRESH_STATE:
      return { ...state, refreshDate: action.date };
    case SET_NETWORK_STATE:
      return { ...state, networkState: action.networkState };
    case CLEAR_NETWORK_STATE:
      return { ...state, networkState: 0 };
    default:
      break;
  }
  return state;
};

export default controls;
