import React, { useEffect } from 'react'
import styled from 'styled-components'
import { useCountdown } from 'usehooks-ts'
import progressWheel from '@images/quiz-pang/progress-wheel.png'

export const Spinner = ({ start, onZero, isSmall = false }) => {
  const [count, { startCountdown }] = useCountdown({ countStart: start })
  useEffect(() => {
    startCountdown()
  }, [startCountdown])
  useEffect(() => {
    if (count === 0) onZero()
  }, [count])
  return <SpinnerBox isSmall={isSmall}>
    <SpinnerImage src={progressWheel} isSmall={isSmall}/>
    <SpinnerText isSmall={isSmall}>{count}</SpinnerText>
  </SpinnerBox>
}

const SpinnerImage = styled.img`
  @keyframes spin {
    100% {
      transform: rotate(360deg);
    }
  }

  width: ${p => p.isSmall ? 48 : 96}px;
  height: ${p => p.isSmall ? 48 : 96}px;
  position: absolute;
  animation: spin 1s linear infinite;
`

const SpinnerBox = styled.div`
  width: ${p => p.isSmall ? 48 : 96}px;
  height: ${p => p.isSmall ? 48 : 96}px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
`

const SpinnerText = styled.div`
  position: absolute;
  font-weight: 500;
  font-size: ${p => p.isSmall ? 24 : 48}px;
  letter-spacing: 0.25px;
  color: rgba(0, 0, 0, 0.87);
`
