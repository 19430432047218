import React, { useEffect, useMemo, useState } from 'react';
import CardLectureInfo from '@components/Card/CardLectureInfo';
import CardLectureInfo_Empty from '@components/Card/CardLectureInfo_Empty';
import { useDispatch, useSelector } from 'react-redux';
import { selectAllTimetables } from '@store/selectors';
import styles from './ClassList.module.scss';
import ModalEditClassTimetable from '../Modal/ModalEditClassTimetable';
import { selectAllMyGroups } from '@app/store/groups/selector';
import { getTimetablesByGroupListPromise } from '@store/actions';
import { setSelectedGroup, setSelectedPage } from '@app/store/actions';

const ClassList = () => {
  const allMyGroups = useSelector((state) => selectAllMyGroups(state));
  const allTimetables = useSelector((state) => selectAllTimetables(state));
  const [modalShow, setModalShow] = useState(false);
  const [formData, setFormData] = useState();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSelectedGroup(''));
    dispatch(setSelectedPage('TIMETABLE'));
  }, [allMyGroups?.length]);

  const handleCreateModal = ({ timetables, weekDay, period = '' }) => {
    setFormData({
      timetables: [],
      timetableId: '',
      weekDay,
      period,
      subjectName: '',
      groupName: '',
      grade: '',
      groupId: '',
      startTime: '',
      endTime: '',
      isEdit: false,
    });
    setModalShow(true);
  };

  const handleEditModal = ({
    timetables,
    weekDay,
    period,
    startTime,
    endTime,
    group,
    timetableId,
  }) => {
    setFormData({
      timetables: allTimetables.filter(
        (timetable) => timetable.groupId === group.groupId
      ),
      timetableId,
      weekDay,
      period,
      groupType: group.groupType,
      subjectName: group.subjectName,
      groupName: group.groupName,
      startTime,
      endTime,
      grade: group.grade,
      groupId: group.groupId,
      isEdit: true,
    });
    setModalShow(true);
  };

  const weekdayToKorean = (weekday) => {
    if (weekday === null || weekday === undefined) {
      return '';
    }
    switch (weekday) {
      case 'monday':
        return '월요일';
      case 'tuesday':
        return '화요일';
      case 'wednesday':
        return '수요일';
      case 'thursday':
        return '목요일';
      case 'friday':
        return '금요일';
      default:
        return '';
    }
  };

  const classesByWeekday = useMemo(() => {
    const _classesByWeekday = {
      monday: [],
      tuesday: [],
      wednesday: [],
      thursday: [],
      friday: [],
    };

    allTimetables.forEach((timeTable) => {
      if (timeTable === null || timeTable === undefined) {
        return '';
      }
      switch (timeTable?.weekday) {
        case 1:
          _classesByWeekday.monday.push(timeTable);
          break;
        case 2:
          _classesByWeekday.tuesday.push(timeTable);
          break;
        case 3:
          _classesByWeekday.wednesday.push(timeTable);
          break;
        case 4:
          _classesByWeekday.thursday.push(timeTable);
          break;
        case 5:
          _classesByWeekday.friday.push(timeTable);
          break;
      }
    });
    _classesByWeekday.monday.sort((a, b) => a.period - b.period);
    _classesByWeekday.tuesday.sort((a, b) => a.period - b.period);
    _classesByWeekday.wednesday.sort((a, b) => a.period - b.period);
    _classesByWeekday.thursday.sort((a, b) => a.period - b.period);
    _classesByWeekday.friday.sort((a, b) => a.period - b.period);

    return _classesByWeekday;
  }, [allTimetables]);

  return (
    <>
      <div className={styles['container']}>
        <div className={styles['list-wrapper']}>
          {Object.entries(classesByWeekday).map(
            ([weekDay, timeTables], index) => {
              var timeTables2 = [];
              var previousClass = 0;
              for (var i = 0; i < timeTables.length; i++) {
                var this_entry = timeTables[i];
                var currentClass = this_entry.period;
                while (previousClass + 1 < currentClass) {
                  var tbe_key = weekDay + '-' + (previousClass + 1);
                  var empty_entry = {
                    timetableId: tbe_key,
                    groupId: null,
                    period: previousClass + 1,
                    startTime: '',
                    endTime: '',
                  };
                  timeTables2.push(empty_entry);
                  previousClass = previousClass + 1;
                }
                timeTables2.push(this_entry);
                previousClass = currentClass;
              }

              var maxClass = 8;
              while (previousClass + 1 <= maxClass) {
                const tbe_key = weekDay + '-' + (previousClass + 1);
                const empty_entry = {
                  timetableId: tbe_key,
                  groupId: null,
                  period: previousClass + 1,
                  startTime: '',
                  endTime: '',
                };
                timeTables2.push(empty_entry);
                previousClass = previousClass + 1;
              }

              return (
                <div className={styles['column']} key={String(weekDay)}>
                  <div className={styles['top']}>
                    <div className={styles['weekday']}>
                      {weekdayToKorean(weekDay)}
                    </div>
                  </div>
                  {timeTables2.map((timeTable) => {
                    const targetGroup = allMyGroups.find(
                      (group) => group.groupId === timeTable.groupId
                    );
                    if (!targetGroup) {
                      if (timeTable.groupdId != null) return null;
                      var targetGroup_empty = {
                        grade: 0,
                        groupName: 'empty_slot',
                        subjectName: '',
                      };
                      return (
                        <CardLectureInfo_Empty
                          key={timeTable.timetableId}
                          timetable={timeTable}
                          group={targetGroup_empty}
                          modal_func={handleCreateModal}
                          prop_tables={timeTables}
                          prop_weekday={index + 1}
                        />
                      );
                    }

                    return (
                      <CardLectureInfo
                        key={timeTable.timetableId}
                        timetable={timeTable}
                        group={targetGroup}
                        modal_func={handleEditModal}
                        prop_tables={timeTables}
                        prop_weekday={index + 1}
                      />
                    );
                  })}
                </div>
              );
            }
          )}
        </div>
      </div>
      {modalShow && (
        <ModalEditClassTimetable
          show={modalShow}
          onHide={() => setModalShow(false)}
          formData={formData}
        />
      )}
    </>
  );
};

export default ClassList;
