import React from 'react';
import PropTypes from 'prop-types';
import Tooltip from '@material-ui/core/Tooltip';
import styled from 'styled-components';

const GroupDetailBlockWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  padding: 8px;
  border-top: solid 1px #d0cccc;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const GroupNameBlock = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  width: auto;
  gap: 4px;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const NumSize = styled.span`
  display: flex;
  font-size: 24px;
`;

const GroupInfoBlock = styled.div`
  width: auto;
  font-size: 18px;
  color: #ffffff;
  font-weight: 500;

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const InClassInformation = ({ group }) => {
  return (
    <GroupDetailBlockWrapper>
      <Tooltip title={group.subjectName} arrow>
        <GroupNameBlock>
          <NumSize>{group.grade}</NumSize> 학년{' '}
          <NumSize>{group.groupName}</NumSize> 반{' '}
          <GroupInfoBlock>{group.subjectName}</GroupInfoBlock>
        </GroupNameBlock>
      </Tooltip>
    </GroupDetailBlockWrapper>
  );
};

export default InClassInformation;
