import {
  createInviteCodePromise,
  getGroupFilelinkPromise,
  getGroupProgramconfigsPromise,
  getProgramsPromise,
  purgeFilelink,
  purgeProgram,
  purgeProgramconfig,
} from '@app/store/actions';
import { selectAllManaging } from '@app/store/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useDeepCompareEffect from 'use-deep-compare-effect';

const ClassroomManager = () => {
  const dispatch = useDispatch();
  const selectedGroupOfControl = useSelector(
    (state) => state.control.selectedGroup
  );
  const selectedPageOfControl = useSelector(
    (state) => state.control.selectedPage
  );
  const reduxRefreshDate = useSelector((state) => state.control.refreshDate);
  const myManagings = useSelector((state) => selectAllManaging(state));

  useEffect(() => {
    if (selectedGroupOfControl === '') {
      dispatch(purgeProgramconfig());
      dispatch(purgeFilelink());
    }
    if (selectedPageOfControl === 'CLASSROOM') {
      dispatch(createInviteCodePromise(selectedGroupOfControl));
      dispatch(getProgramsPromise(selectedGroupOfControl));
      dispatch(getGroupProgramconfigsPromise(selectedGroupOfControl));
      dispatch(getGroupFilelinkPromise(selectedGroupOfControl));
    }

    if (selectedPageOfControl === 'MANAGING') {
      dispatch(createInviteCodePromise(selectedGroupOfControl));
    }
  }, [selectedGroupOfControl, reduxRefreshDate]);

  useDeepCompareEffect(() => {
    if (selectedPageOfControl === 'MANAGING') {
      if (myManagings?.length === 0) {
        return;
      }
      myManagings?.map((managings) => {
        dispatch(getGroupProgramconfigsPromise(managings?.managingId));
      });
    }
  }, [myManagings, selectedPageOfControl]);

  return <div />;
};

export default ClassroomManager;
