import React, { memo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './SidebarClassRoom.module.scss';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// import BellImg from "../../assets/images/icons/bell.png";
// import ProfileCardImg from "../../assets/images/icons/profile-card.png";
// import HatImg from "../../assets/images/icons/hat.png";
// import PeopleImg from "../../assets/images/icons/people.png";
// import ABImg from "../../assets/images/icons/a-b.png";
// import HomeworkImg from "../../assets/images/icons/homework.png";
// import BookImg from "../../assets/images/icons/book.png";
// import SettingImg from "../../assets/images/icons/setting.png";

import { selectGroupById } from '@store/selectors';
import { ReactComponent as PeopleImg } from '../../assets/images/icons/people.svg';
import { ReactComponent as BellImg } from '../../assets/images/icons/bell.svg';
import { ReactComponent as ABImg } from '../../assets/images/icons/a-b.svg';
import { ReactComponent as HomeworkImg } from '../../assets/images/icons/homework.svg';
import { ReactComponent as HatImg } from '../../assets/images/icons/hat.svg';
import classNames from 'classnames';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import { useHistory, useLocation, useParams } from 'react-router-dom';

const tabs = [
  {
    id: '수업 공지사항',
    text: '수업 공지사항',
    iconWidth: 18,
    path: '/notice',
  },
  {
    id: '학생 명단',
    text: '학생 명단',
    iconWidth: 24,
    path: '/students',
  },
  {
    id: '수업 하기',
    text: '수업 관리',
    iconWidth: 24,
    path: '/classroom',
  },
  {
    id: '모둠 활동',
    text: '모둠 활동',
    iconWidth: 24,
    path: '/group-activity',
  },
  
  // {
  //   id: '아리송팡',
  //   text: '아리송팡 (upcoming)',
  //   iconWidth: 20,
  //   // path:
  // },
  {
    id: '과제 관리',
    text: '과제 관리',
    iconWidth: 18,
    path: '/assignment',
  },
  {
    id: '퀴즈팡',
    text: '퀴즈팡(coming soon)',
    iconWidth: 20,
    // path: '/quiz-pang',
  },
  // {
  //   id: '수업 관리',
  //   text: '수업 관리',
  //   iconWidth: 22,
  //   subTabs: [
  //     {
  //       id: '수업 설정',
  //       text: '수업 설정',
  //       iconWidth: 20,
  //     },
  //   ],
  // },
];
const PATH_WITH_GROUP_ID = [
  '/notice',
  '/classroom',
  '/students',
  '/group-activity',
  '/assignment',
  '/quiz-pang',
];

const TabItem = ({ tab, onClick, open, subTab, isActive }) => {
  const Icon = ({ color }) => {
    switch (tab.id) {
      case '수업 공지사항':
        return <BellImg fill={color} />;
      case '학생 명단':
        // return <ProfileCardImg fill={color} />;
        return <GroupAddIcon fill={color} />;
      case '수업 하기':
        return (
          <DesktopWindowsIcon
            style={{
              color,
              width: 24,
              height: 24,
            }}
          />
        );
      case '학생 활동 관리':
        return <HatImg fill={color} />;
      case '모둠 활동':
        return <PeopleImg fill={color} />;
      case '퀴즈팡':
        return <ABImg fill={color} />;
      case '아리송팡':
        return <ABImg fill={color} />;
      case '과제 관리':
        return <HomeworkImg fill={color} />;
      // case '수업 관리':
      //   return <BookImg fill={color} />;
      // case '수업 설정':
      //   return <SettingImg fill={color} />;
    }
  };

  return (
    <div
      className={styles[subTab ? 'side-class-menu-subTab' : 'side-class-menu']}
      key={tab.id}
    >
      <div
        className={classNames(
          styles['side-class-menu-wrapper'],
          isActive && styles['wrapper-active']
        )}
        onClick={onClick}
      >
        <div className={styles['side-class-menu-left']}>
          <div className={styles['side-class-menu-icon-wrapper']}>
            <div style={{ marginLeft: (24 - tab.iconWidth) / 2 }}>
              <Icon color={isActive ? '#35B5C2' : '#EEEEEE'} />
            </div>
          </div>
          <div className={styles['side-class-menu-title']}>{tab.text}</div>
        </div>
        {tab.subTabs && (
          <KeyboardArrowDownIcon
            fontSize="medium"
            style={{
              transform: `rotate(${open ? 180 : 0}deg)`,
              color: '#ffffff',
            }}
          />
        )}
      </div>
    </div>
  );
};

const SidebarClassRoom = () => {
  const [openTabs, setOpenTabs] = useState([]);
  const history = useHistory();
  const { state } = useLocation();
  const { groupId } = useParams();
  const group = useSelector((state) => selectGroupById(state, groupId));

  const toggleTab = (tabId) => {
    setOpenTabs((prev) => {
      if (prev.includes(tabId)) {
        return prev.filter((_tabId) => _tabId !== tabId);
      }
      return prev.concat(tabId);
    });
  };

  const getIsOpen = (tabId) => {
    return openTabs.includes(tabId);
  };

  useEffect(() => {
    tabs.forEach((tab) =>
      tab.subTabs?.forEach((subTab) => {
        if (history.location.pathname.includes(subTab.path)) {
          toggleTab(tab.id);
        }
      })
    );
  }, [history.location.pathname]);

  return (
    <div className={styles['side-class-menu-wrap']}>
      {tabs.map((tab) => {
        return (
          <div key={tab.id}>
            <TabItem
              tab={tab}
              onClick={() => {
                let pathname = tab.path;
                if (tab.subTabs) {
                  toggleTab(tab.id);
                  return;
                }
                if (!tab.path || history.location.pathname === tab.path) {
                  return;
                }
                if (PATH_WITH_GROUP_ID.includes(tab.path)) {
                  pathname = `${tab.path}/${group.groupId}`;
                }
                history.push({
                  pathname,
                  state,
                });
              }}
              open={getIsOpen(tab.id)}
              isActive={history.location.pathname.includes(tab.path)}
            />
            {tab.subTabs && getIsOpen(tab.id) && (
              <div
                style={{ background: '#090909', margin: 16, borderRadius: 8 }}
              >
                {tab.subTabs.map((subTab) => {
                  return (
                    <TabItem
                      key={subTab.id}
                      tab={subTab}
                      onClick={() => {
                        if (!subTab.path) {
                          return;
                        }
                        if (PATH_WITH_GROUP_ID.includes(subTab.path)) {
                          subTab.path = `${subTab.path}/${group.groupId}`;
                        }
                        history.push({
                          pathname: subTab.path,
                          state,
                        });
                      }}
                      subTab
                      isActive={history.location.pathname.includes(subTab.path)}
                    />
                  );
                })}
              </div>
            )}
          </div>
        );
      })}
      {/* <div className={styles["side-class-menu"]}>{classes(allMyGroups)}</div> */}
    </div>
  );
};

export default memo(SidebarClassRoom);
