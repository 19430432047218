import React, { memo, useEffect, useState } from 'react';
import styles from './ClassManagementSmall.module.scss';
import ModalAddApp from '@components/Modal/ModalAddApp';
import CardClassManagementsSmall from '@components/Card/CardClassManagementsSmall';
import CollapseCard from '@components/Card/CollapseCard';
// import { SignalWifiStatusbarConnectedNoInternet4Outlined } from '@mui/icons-material';
// import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import SettingsIcon from '@mui/icons-material/Settings';
import { Tooltip, tooltipClasses } from '@mui/material';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectActiveClassroom,
  selectAllPrograms,
  selectGroupProgramconfigs,
} from '@app/store/selectors';
import { useParams } from 'react-router-dom';
import { setSocketData } from '@app/store/actions';

// const BootstrapTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} arrow classes={{ popper: className }} />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.arrow}`]: {
//     color: '#fff',
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: theme.palette.common.black,
//   },
// }));

const ProgramManagementPanel = ({ blockAppList = [], programs = [] }) => {
  const user = useSelector((state) => state.user);
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const allPrograms = useSelector((state) => selectAllPrograms(state));
  const programconfigs = useSelector((state) =>
    selectGroupProgramconfigs(state, groupId)
  );
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));

  const blockedProgramconfigs = programconfigs.filter(
    (programconfig) => programconfig.isAllowed !== true
  );

  const blockedPrograms = blockedProgramconfigs?.map(
    (blockedProgramconfig) =>
      allPrograms?.filter(
        (program) => program.programId === blockedProgramconfig.programId
      )[0]
  );

  const buildProgramconfigWebPageList = (blockedPrograms) => {
    if (!blockedPrograms) {
      return;
    }
    if (!activeClassroom) {
      return;
    }
    const blockedWebPageList = blockedPrograms?.map((blockedProgram) => {
      if (blockedProgram?.web == undefined) {
        return '_____';
      }
      return blockedProgram?.web;
    });

    const blockedWebPageListString = blockedWebPageList.join(',');

    setTimeout(() => {
      dispatch(
        setSocketData({
          method: 'POST',
          uri: '/classroom/sendImage',
          groupId: groupId,
          clientId: user?.clientId,
          type: 'UPDATE_FORBID_LIST',
          data: blockedWebPageListString,
        })
      );
    }, 800);
  };

  useEffect(() => {
    buildProgramconfigWebPageList(blockedPrograms);
  }, [blockAppList]);

  return (
    <div name="program" className={styles['panel']}>
      {blockAppList?.map((live) => {
        const program = programs.find((x) => x.programId === live.programId);
        return (
          <CardClassManagementsSmall
            key={live.programconfigId}
            id={live.programconfigId}
            enabled={live.isAllowed}
            name={program?.korName}
            alias={program?.alias}
            groupId={live.groupId}
          />
        );
      })}
    </div>
  );
};

const ClassManagementSmall = ({ group, programconfigs, programs }) => {
  const [modalShow, setModalShow] = useState(false);
  const [webSocket, setWebSocket] = useState();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const webSocketTestButtonOne = () => {
    console.log('1');
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: user?.clientId,
        clientId: user.clientId,
        type: 'TEST_BUTTON',
        data: '',
      })
    );
  };
  const webSocketTestButtonTwo = () => {
    console.log('2');
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: user?.clientId,
        clientId: user.clientId,
        type: 'TEST_BUTTON',
        data: '',
      })
    );
  };
  const webSocketTestButtonThree = () => {
    console.log('3');
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: null,
        clientId: user.clientId,
        type: 'TEST_BUTTON',
        data: '',
      })
    );
  };

  return (
    <>
      <CollapseCard
        title={
          <div className={styles['header']}>
            학생의 사용을 통제할 앱과 웹 사이트
            <Tooltip
              arrow
              title="제어할 앱 또는 웹 사이트를 선택하실 수 있습니다."
            >
              <button
                className={styles['setting-button']}
                onClick={() => {
                  setModalShow(true);
                }}
              >
                {/* <SettingsApplicationsIcon fontSize="small" /> */}
                {/* <ControlPointIcon fontSize="small" /> */}
                <SettingsIcon fontSize="small" />
                <div>제어할 앱/웹 사이트 선택</div>
              </button>
            </Tooltip>
            {/* <button
              onClick={() => {
                webSocketTestButtonOne();
              }}
              className={styles['setting-button']}
            >
              groupId = userClientId 전송버튼
            </button>
            <button
              onClick={() => {
                webSocketTestButtonTwo();
              }}
              className={styles['setting-button']}
            >
              groupId = ''전송버튼
            </button>
            <button
              onClick={() => {
                webSocketTestButtonThree();
              }}
              className={styles['setting-button']}
            >
              groupId = null전송버튼
            </button> */}
          </div>
        }
        initialOpen={true}
      >
        <div>
          <ProgramManagementPanel
            key="applications"
            programs={programs}
            blockAppList={programconfigs}
          />
        </div>
      </CollapseCard>
      <ModalAddApp
        show={modalShow}
        onHide={() => setModalShow(false)}
        group={group}
      />
    </>
  );
};

export default memo(ClassManagementSmall, (prev, next) => {
  return (
    JSON.stringify(prev.group) === JSON.stringify(next.group) &&
    JSON.stringify(prev.programconfigs) ===
      JSON.stringify(next.programconfigs) &&
    JSON.stringify(prev.programs) === JSON.stringify(next.programs)
  );
});
