import { makeFreshAllIds } from '../helpers';
import user from '../user/reducer';
import {
  PURGE_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAIL,
  GET_CLIENTS_SUCCESS,
  GET_CLIENTS_FAIL,
  UPDATE_CLIENT_FAIL,
  UPDATE_CLIENT_SUCCESS,
  DELETE_CLIENT_SUCCESS,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const clients = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_CLIENT:
      return initialState;

    case UPDATE_CLIENT_SUCCESS:
    case GET_CLIENT_SUCCESS:
      return {
        byId: {
          ...state.byId,
          [action.payload.clientId]: action.payload,
        },
        allIds: makeFreshAllIds(state.allIds, [action.payload.clientId]),
      };

    case GET_CLIENTS_SUCCESS:
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case DELETE_CLIENT_SUCCESS:
      const newAllIds = [...state.allIds.filter((id) => id !== action.payload)];
      const newById = { ...state.byId };

      if (action.payload in newById) {
        delete newById[action.payload];
      }
      return {
        ...state,
        allIds: newAllIds,
        byId: newById,
      };
    case UPDATE_CLIENT_FAIL:
    case GET_CLIENT_FAIL:
    case GET_CLIENTS_FAIL:
      console.error('[Client Reducer] ', action.payload);
      return state;
    default:
      break;
  }
  return state;
};

export default clients;
