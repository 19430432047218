import React, { useEffect, useState } from 'react';
import styles from './ManagedStudents.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { getParticipationsByClassroomPromise } from '@store/actions';
import {
  selectGroupById,
  selectClassroomGroup,
  selectGroupTimetables,
} from '@store/selectors';
import BaseCard from '../Card/baseCard';
import ModalUpdateProfileImage from '@components/Modal/ModalUpdateProfileImage';
import { getActiveClassroomPromise, setSocketData } from '@store/actions';
import moment from 'moment';
import {
  selectActiveManaging,
  selectManagingGroup,
} from '@app/store/selectors';
import {
  createManagingPromise,
  deleteManagingPromise,
  getActiveManagingPromise,
  getMembershipsByGroupPromise,
  setStudentListUpdate,
} from '@app/store/actions';
import ManagedStudentList from '@app/components/Layout/ManagedStudentList';
import ManagingSidebar from '@app/components/Layout/ManagingSidebar';
import { useParams } from 'react-router-dom';

const ManagedStudents = (props) => {
  const [classState, setClassState] = useState(false);
  const dispatch = useDispatch();
  const { groupId } = useParams();

  const group = useSelector((state) => selectGroupById(state, groupId));
  const [modalShow, setModalShow] = useState(false);

  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const activeClassroomGroup = useSelector((state) =>
    selectClassroomGroup(state, activeManaging)
  );
  const stdUpdateRequest = useSelector(
    (state) => state.control.stdUpdateRequest
  );
  const [students, setStudents] = useState([]);

  useEffect(() => {
    if (!groupId) {
      return;
    }
    dispatch(getMembershipsByGroupPromise(groupId))
      .then((clientMembershipsData) => {
        const clients = clientMembershipsData?.map((clientMembershipData) => {
          return clientMembershipData.client;
        });

        const filteredClients = clients.filter((client) => {
          return client.role === 'STUDENT';
        });
        setStudents(filteredClients);
      })
      .then(() => {
        dispatch(setStudentListUpdate(false));
      });
  }, [groupId, stdUpdateRequest]);

  useEffect(() => {
    if (!activeManaging) {
      setClassState(false);
      return;
    }
    setClassState(true);
    dispatch(getParticipationsByClassroomPromise(activeManaging?.classroomId));

    const timer = setInterval(() => {
      const diff = (new Date(activeManaging.finishAt) - new Date()) / 1000;

      if (diff <= 0) {
        dispatch(deleteManagingPromise(activeManaging.classroomId)).then(() => {
          dispatch(
            setSocketData({
              method: 'POST',
              uri: '/classroom/sendImage',
              groupId: activeManaging?.groupId,
              clientId: user.clientId,
              type: 'MANAGING_END',
              data: '',
            })
          );
          dispatch(getActiveClassroomPromise(user.clientId));
        });
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [activeManaging]);

  const now = new Date();

  const user = useSelector((state) => state.user);

  const sendCreatedClassroomBySocket = (classroomId) => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: groupId,
        clientId: user.clientId,
        type: 'MANAGING_START',
        data: classroomId,
      })
    );
  };

  const sendDeletedClassroomBySocket = () => {
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        groupId: groupId,
        clientId: user.clientId,
        type: 'MANAGING_END',
        data: '',
      })
    );
  };

  const [finishTime, setFinishTime] = useState(
    now.getHours < 8
      ? new Date(now.getFullYear(), now.getMonth(), now.getDate(), 8)
      : new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1, 8)
  );
  // 디폴트 종료시간을 아침 8시로
  const [classDuration, setClassDuration] = useState(
    (finishTime - now) / 60 / 1000
  );

  const clickClassStart = (event) => {
    event.preventDefault();
    if (activeManaging && activeManaging.groupId !== group.groupId) {
      const activeGroup = activeClassroomGroup;
      alert(
        `${activeGroup.grade}학년 ${activeGroup.groupName}반 ${activeGroup.subjectName} 수업이 아직 진행중이에요. ${activeGroup.subjectName} 수업을 먼저 끝내고 다시 시도해주세요`
      );
      return;
    }

    if (!activeManaging) {
      dispatch(
        createManagingPromise({
          groupId: group.groupId,
          startAt: moment().toISOString(),
          finishAt: moment()
            .add(classDuration, 'minutes')
            .toISOString(),
        })
      )
        .then((createdAfterClassroom) => {
          sendCreatedClassroomBySocket(createdAfterClassroom.managingId);
        })
        .then(() => {
          return dispatch(getActiveManagingPromise(user.clientId));
        })
        .catch((err) => {
          alert(err);
        });
    } else {
      dispatch(deleteManagingPromise(activeManaging?.managingId)).then(() => {
        sendDeletedClassroomBySocket();
        return dispatch(getActiveManagingPromise(user.clientId));
      });
    }
  };

  // useEffect(() => {
  //   dispatch(
  //     deleteAfterClassroomPromise(activeAfterClassroom?.classroomId)
  //   ).then(() => {
  //     sendDeletedClassroomBySocket();
  //     return dispatch(getActiveAfterClassroomPromise(user.clientId));
  //   });
  // }, []);

  // 관리중인 수업이 있을때는, 일반 수업이 있을수 없는데 있는경우가 있어서 추가.
  // const activeClassroom = useSelector((state) => selectActiveClassroom(state));

  // useEffect(() => {
  //   if (activeClassroom) {
  //     dispatch(deleteClassroomPromise(activeClassroom?.classroomId)).then(
  //       () => {
  //         return dispatch(getActiveClassroomPromise(user.clientId));
  //       }
  //     );
  //   }
  //   console.log('ERASE UNEXPECTED ACTIVE CLASS');
  // }, []);

  return (
    <>
      <ModalUpdateProfileImage
        show={modalShow}
        onHide={() => setModalShow(false)}
        setModalShow={setModalShow}
      />
      <div className="flex w-100vw h-100vh">
        <ManagingSidebar
          auth={true}
          inClassroom
          clickClassStart={clickClassStart}
          inClass={classState}
          group={group}
        />
        <div
          style={{
            marginLeft: '280px',
            width: '100%',
            padding: 14,
            background: '#f9f9f9',
            minHeight: '100vh',
          }}
          className={styles['settings-container']}
        >
          <BaseCard style={{ minHeight: '100%', padding: '20px 40px' }}>
            <ManagedStudentList
              key={groupId}
              groupId={groupId}
              students={students}
            />
          </BaseCard>
        </div>
      </div>
    </>
  );
};

export default ManagedStudents;
