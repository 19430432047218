import React from 'react';
import Sidebar from '@app/components/Layout/Sidebar';
import { MiddleMenuBar, SideBarRest } from '@app/pages/QuizPang/common';
import { QuizCard } from '../quiz-card';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getQuizListAll } from '@api';

const QuizpangCommunity = () => {
  // 인피니트 쿼리 사용
  const { data: quizList, fetchNextPage, hasNextPage } = useInfiniteQuery(
    ['quizAllList'],
    getQuizListAll,
    {
      getNextPageParam: (lastPage, pages) => {
        if (lastPage.hasNextPage) {
          return lastPage.nextPage;
        }
        if (pages.length === 1) {
          return { index: pages[0].quizList[pages[0].quizList.length - 1].id };
        }
        return false;
      },
      queryFnParams: (lastPage, pages) => {
        if (lastPage.hasNextPage) {
          return [JSON.stringify(lastPage.nextPage)];
        }
        if (pages.length === 1) {
          return [pages[0].quizList[pages[0].quizList.length - 1].id];
        }
        return [false];
      },
    }
  );

  // 스크롤 이벤트
  React.useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop !==
        document.documentElement.offsetHeight
      )
        return;
      if (hasNextPage) {
        fetchNextPage();
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasNextPage, fetchNextPage]);

  return (
    <div className="flex w-100vw h-100vh">
      <Sidebar auth={true} />
      <SideBarRest>
        <MiddleMenuBar>
          <div className="flex flex-col w-full">
            {quizList?.pages.map((page) => {
              return page.quizList.map((quiz, index) => {
                return <QuizCard key={index} quiz={quiz} />;
              });
            })}
          </div>
        </MiddleMenuBar>
      </SideBarRest>
    </div>
  );
};

export default QuizpangCommunity;
