import styled from 'styled-components'

export const QuizInput = styled.input`
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  width: 540px;
  height: 52px;
  padding: 8px;
  margin-bottom: 8px;
  position: relative;
  text-align: center;
`
