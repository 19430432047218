import React, { memo } from 'react';
import SearchInput from '../Input/SearchInput';
import styles from './StudentScreenListHeader.module.scss';
import { ReactComponent as Grid1Img } from '@assets/images/icons/grid-1.svg';
import { ReactComponent as Grid2Img } from '@assets/images/icons/grid-2.svg';
import { Tooltip } from '@mui/material';
import Select from 'react-select';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useDispatch, useSelector } from 'react-redux';
import {
  createCommentsLayerPromise,
  setScreenData,
  updateCommentsLayerPromise,
} from '@app/store/actions';
import { selectActiveClassroom } from '@app/store/selectors';

const sortOptions = [
  { value: 1, label: '학번 순' },
  { value: 2, label: '이름 순' },
  { value: 3, label: '모둠 순' },
];

const StudentScreenListHeaderComponent = ({
  screenSortOption,
  resetSort,
  searchValue,
  onSortChange,
  onSearch,
  gridState,
  setGridState,
  selectedActivity,
  activityList,
  setSelectedAcitivity,
  onShareTeacherScreen,
}) => {
  const dispatch = useDispatch();
  const { clientId } = useSelector((state) => state.user);
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const { commentsLayer } = useSelector((state) => state.commentsLayer);
  const isTwoColumn = gridState === 'TWO_COLUMN';

  const onActivityChange = (event) => {
    setSelectedAcitivity(
      activityList.find((item) => item.subgroupActivityId === event.value)
    );
  };
  const onCommentsLayer = () => {
    let isActivated = !commentsLayer?.isActivated;
    if (!commentsLayer?.commentsLayerId) {
      dispatch(
        createCommentsLayerPromise({
          classroomId: activeClassroom.classroomId,
          clientId,
          layerWidth: 720,
          layerHeight: 720,
          isActivated,
        })
      );
      // dispatch(
      //   createCommentaryPromise({
      //     commentaryFrom: clientId,
      //     commentaryState: "PUBLIC",
      //     commentaryTo: JSON.stringify(),
      //     commentaryType: "PEN",
      //     commentaries: JSON.stringify(),
      //   })
      // );
    } else {
      dispatch(
        updateCommentsLayerPromise({
          commentsLayerId: commentsLayer.commentsLayerId,
          isActivated,
        })
      );
    }
    dispatch(
      setScreenData({
        method: 'POST',
        uri: '/classroom/sendImage',
        type: isActivated ? 'COMMENTS_LAYER_START' : 'COMMENTS_LAYER_END',
        clientId,
        groupId: activeClassroom?.groupId,
        data: '',
      })
    );
  };

  return (
    <div className={styles['header-wrap']}>
      <div className={styles['header-common-wrap']}>
        <div className={styles['header-text']}>
          <div className={styles['button-area']}>
            <div className={styles['layout-button-wrapper']}>
              <Tooltip title="선생님화면과 학생 썸네일보기" arrow>
                <button
                  className={styles[isTwoColumn ? 'button-active' : 'button']}
                  onClick={() => setGridState('TWO_COLUMN')}
                >
                  <Grid1Img fill={isTwoColumn ? '#0BA1AE' : '#424242'} />
                </button>
              </Tooltip>
              <Tooltip title="학생 썸네일만 보기" arrow>
                <button
                  className={styles[!isTwoColumn ? 'button-active' : 'button']}
                  onClick={() => setGridState('ONE_COLUMN')}
                >
                  <Grid2Img fill={!isTwoColumn ? '#0BA1AE' : '#424242'} />
                </button>
              </Tooltip>
            </div>
          </div>
          <div className={styles['title']}>학생 화면 목록</div>
          <div className={styles['sub-container']}>
            <Select
              isSearchable={false}
              defaultValue={screenSortOption}
              value={sortOptions.filter(function(option) {
                return option.value === screenSortOption;
              })}
              options={sortOptions}
              onChange={(e) => onSortChange(e.value)}
              isClearable={false}
            />
            <div className={styles['reset-sort']} onClick={resetSort}>
              <RefreshIcon className={styles['reset-icon']} />
              정렬
            </div>
          </div>
          <SearchInput
            value={searchValue}
            onChange={(e) => onSearch(e.target.value)}
            placeholder="이름으로 찾기"
          />
        </div>
      </div>
      <div className={styles['activity-header']}>
        {screenSortOption === 3 && (
          <>
            <Select
              className={styles['activity-select']}
              value={{
                label: selectedActivity?.name,
                value: selectedActivity?.subgroupActivityId,
              }}
              placeholder="모둠선택"
              isSearchable={false}
              options={activityList.map((item) => ({
                label: item.name,
                value: item.subgroupActivityId,
              }))}
              onChange={onActivityChange}
              isClearable={false}
            />
            <div
              className={styles['teacher-screen-directive']}
              onClick={onShareTeacherScreen}
            >
              선생님 화면 선택
            </div>
          </>
        )}
        {/* <Tooltip title='대화면 위에 주석을 달 수 있으며 학생들과 실시간으로 공유할 수 있는 서비스입니다.' arrow>
          <button
            className={styles['btn-comments-layer']}
            onClick={onCommentsLayer}
          >
            주석달기 {commentsLayer?.isActivated ? 'OFF' : 'ON'} (베타)
          </button>
        </Tooltip> */}
      </div>
    </div>
  );
};
export const StudentScreenListHeader = memo(StudentScreenListHeaderComponent);
