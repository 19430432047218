import styled from 'styled-components'
import { Column } from '@app/pages/QuizPang/common'


export const ImageContainer = styled(Column)`
  background: #F5F5F5;
  border-radius: 8px;
  width: 480px;
  
  position: relative;
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`
