import {
  CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL,
  CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS,
  CREATE_QUIZSET_SESSION_FAIL,
  CREATE_QUIZSET_SESSION_SUCCESS,
  GET_QUIZSET_SESSION_HISTORY_FAIL,
  GET_QUIZSET_SESSION_HISTORY_SUCCESS,
  GET_QUIZSET_SESSION_RESULT_FAIL,
  GET_QUIZSET_SESSION_RESULT_SUCCESS,
  PURGE_QUIZSET_SESSION,
} from './actionTypes'

const initialState = {
  histories: [],
  sessionsById: {},
  resultsById: {},
}

const quizsetSession = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_QUIZSET_SESSION:
      return initialState

    case GET_QUIZSET_SESSION_HISTORY_SUCCESS:
      return {
        ...state,
        histories: action.data,
      }

    case CREATE_QUIZSET_SESSION_SUCCESS:
    case CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS:
      return {
        ...state,
        sessionsById: {
          ...state.sessionsById,
          [action.data.quizsetSession.quizsetSessionId]: action.data,
        },
      }

    case GET_QUIZSET_SESSION_RESULT_SUCCESS:
      return {
        ...state,
        resultsById: {
          ...state.resultsById,
          [action.data.quizsetSessionId]: action.data.result,
        },
      }

    case GET_QUIZSET_SESSION_HISTORY_FAIL:
    case CREATE_QUIZSET_SESSION_FAIL:
    case CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL:
    case GET_QUIZSET_SESSION_RESULT_FAIL:
      console.error('[QuizsetSession Reducer] ', action.payload)
      return state

    default:
      break
  }
  return state
}

export default quizsetSession
