import React, { useState } from 'react';
import styles from './ManagingCreate.module.scss';

import GroupIcon from '@images/home/GroupIcon.png';
import LockIcon from '@mui/icons-material/Lock';
import { Dropdown } from '../Modal/ModalEditClassTimetable';
import { GRADE_LIST } from '@app/Constants/groupTimetable';
import { useDispatch, useSelector } from 'react-redux';
import { async } from '@firebase/util';
import {
  createGroupPromise,
  createMembershipPromise,
  getMembershipByClientPromise,
} from '@app/store/actions';

// import { Dropdown } from 'react-bootstrap';

const ManagingCreate = () => {
  const dispatch = useDispatch();
  const { schoolId } = useSelector((state) => state.user);
  const user = useSelector((state) => state.user);
  const [subjectName, setSubjectName] = useState('');
  const [grade, setGrade] = useState('');

  const initGradeData = {
    // groupType: 'AFTER',
    // groupName: '',
    grade: '',
  };
  const [creatingFields, setCreatingFields] = useState([
    {
      ...initGradeData,
    },
  ]);

  const onChangeGroup = (fieldIndex, e) => {
    const { name, value } = e.target;
    let _createFields = [...creatingFields];
    _createFields[fieldIndex][name] = value;
    setCreatingFields(_createFields);
  };

  const onCreate = async () => {
    await Promise.all(
      creatingFields.map(async (field) => {
        const groupData = {
          schoolId: schoolId,
          state: 'CREATED',
          groupType: 'AFTER',
          subjectName: subjectName,
          groupName: subjectName,
          grade: field.grade,
        };
        await dispatch(createGroupPromise(groupData)).then(
          async (createdGroup) => {
            dispatch(
              createMembershipPromise({
                groupId: createdGroup.groupId,
                clientId: user.clientId,
                role: 'TEACHER',
              })
            );
            await dispatch(getMembershipByClientPromise(user.clientId));
          }
        );
        return true;
      })
    );
  };

  return (
    <>
      {creatingFields.map((createData, fieldIndex) => (
        <div className={styles['manage-container']}>
          <div className={styles['icon-container']}>
            <LockIcon className={styles['LockIcon']} />
            <img
              src={GroupIcon}
              alt="icon"
              className={styles['GroupIcon-img']}
            />
          </div>
          <div className={styles['main-title']}>
          수업 시간 외에 학생들의 앱/웹사이트 사용을 관리해보세요.
          </div>
          <div className={styles['announe-title']}>
          학생용 안드로이드에만 적용되며, 학생용 포팡 앱을 재설치후에 사용하실 수 있습니다.
          </div>
          <div className={styles['choice-title']}>
            관리하실 학년을 선택해주세요.
          </div>
          <div className={styles['grade-drop']}>
            <Dropdown
              title="학년"
              name="grade"
              value={createData.grade}
              onChange={(value) => onChangeGroup(fieldIndex, value)}
              data={GRADE_LIST}
            />
          </div>
          <div className={styles['choice-title']}>
            관리 그룹 이름을 지어주세요.
          </div>
          <input
            className={styles['group-input']}
            name="subjectName"
            type={'text'}
            placeholder="자기 관리 연습"
            onChange={(e) => setSubjectName(e.target.value)}
            value={subjectName}
            maxLength="25"
          />
          <button
            className={styles['start-button']}
            type="button"
            onClick={onCreate}
          >
            수업 시간 외 앱/웹사이트 관리 시작하기
          </button>
        </div>
      ))}
    </>
  );
};
export default ManagingCreate;
