import { makeStyles } from '@material-ui/core/styles';
import { TextField } from '@mui/material';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiFilledInput-root': {
      marginBottom: 20,
      borderBottom: 'none',
    },
    '& .MuiFilledInput-root.Mui-focused': {
      backgroundColor: '#0BA1AE15',
    },
    '& .MuiFilledInput-root:before': {
      borderBottom: 'none',
    },
    '& .MuiFilledInput-root:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
  },
}));

const NameInput = (props) => {
  const { name, label, variant, inputProps } = props;

  const classes = useStyles();

  return (
    <TextField
      {...props}
      name={name}
      label={label}
      type="text"
      fullWidth
      variant={variant ?? 'filled'}
      InputProps={inputProps}
      className={classes.root}
    />
  );
};

export default NameInput;
