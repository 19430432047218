import React, { useMemo } from 'react';
import ProfileImage from '../ProfileImage';
import { useSelector } from 'react-redux';
import { profileImgPath } from '@api';
import styled from 'styled-components';

export const TopUserSectionWrapper = styled.div`
  width: 100%;
  background-color: #212121;
  padding: 16px;
  display: flex;
  align-items: center;
`;

export const ProfileImageWrapper = styled.div`
  padding: 8px;
  display: flex;
`;

export const UserInfoWrapper = styled.div`
  padding: 8px;
  width: 175px;
  display: flex;
  flex-direction: column;
`;

export const UserName = styled.div`
  font-size: 28px;
  font-weight: 500;
  font-size: 20px;
  letter-spacing: 0.25px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const UserMail = styled.div`
  font-size: 12px;
  line-height: 20px;
  color: #bdbdbd;
  letter-spacing: 0.4px;
`;

const TopUserSection = () => {
  const user = useSelector((state) => state.user);

  const myProfileImage = useMemo(() => {
    if (!user) {
      return undefined;
    }
    return profileImgPath(user.clientId);
  }, [user]);

  const subscribeImageChange = useMemo(() => user?.subscribeImageChange, [
    user,
  ]);

  return (
    <TopUserSectionWrapper>
      <ProfileImageWrapper>
        <ProfileImage
          size={44}
          url={myProfileImage}
          subscribeImageChange={user?.subscribeImageChange}
        />
      </ProfileImageWrapper>
      <UserInfoWrapper>
        <UserName>{user.userName}</UserName>
        <UserMail>{user?.email}</UserMail>
      </UserInfoWrapper>
    </TopUserSectionWrapper>
  );
};

export default TopUserSection;
