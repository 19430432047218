import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  setStudentImages,
  getMembershipsByGroupPromise,
  getActiveClassroomPromise,
  getParticipationsByClassroomPromise,
} from '@store/actions';
import {
  selectActiveClassroom,
  selectActiveManaging,
  selectAllTimetables,
  selectParticipationsByClassroom,
} from '@app/store/selectors';
import { useInterval } from '@api';
import {
  clearStudentImages,
  getManagedsByClientPromise,
  getManagedsByManagingPromise,
  setCommentsStudent,
  setReportData,
  setReportEventPromise,
  setStudentListUpdate,
} from '@app/store/actions';
import { RECEIVE_SHARING_SCREEN } from '@app/Constants/eventHistoryType';
import { RECEIVE_SHARING_SCREEN_DATA } from '@app/Constants/eventHistoryData';
import { STUDENT, TEACHER } from '@app/Constants/eventHistoryRole';
import moment from 'moment';
import { object } from 'prop-types';

const WebSocketContext = createContext(null);

export { WebSocketContext };

const ConnectionManager = React.memo(() => {
  const user = useSelector((state) => state.user);
  const membership = useSelector((state) => state.membership.membership);
  const [images, setImages] = useState({});
  const [imageCounts, setImageCounts] = useState({});
  const [webSocket, setWebSocket] = useState();
  const [webSocketReady, setWebSocketReady] = useState(false);
  const [serverMessage, setServerMessage] = useState('');
  const activeClassroom = useSelector((state) => selectActiveClassroom(state));
  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const screenData = useSelector((state) => state.control.screenData);
  const socketData = useSelector((state) => state.control.socketData);
  const allTimetables = useSelector((state) => selectAllTimetables(state));
  const [lastTimestamp, setLastTimestamp] = useState(new Date());
  const [socketConnectCount, setSocketConnectCount] = useState(0);
  const _participations = useSelector((state) =>
    selectParticipationsByClassroom(state, activeClassroom?.classroomId)
  );
  const [studentList, setStudentList] = useState([]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!user || !user.clientId) {
      return;
    }
    try {
      console.log('[Connection Manager] Socket Connect');
      setWebSocket(
        new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
      );
    } catch (e) {
      console.log('[ConnectionManager]: setWebSocket error', e);
    }
  }, [user.clientId, membership]);

  // useEffect(() => {
  //   if (!user || !user.signedIn || !webSocket || !user.clientId) {
  //     return;
  //   }

  //   webSocket.onopen = () => {
  //     console.log('[Connection Manager] Socket Open');
  //     setWebSocketReady(true);
  //   };
  //   webSocket.onmessage = (event) => {
  //     setServerMessage(JSON.parse(event.data));
  //   };
  //   webSocket.onclose = (event) => {
  //     console.log('[Connection Manager] Socket Close Code', event.code);
  //     if (event.code === 4000) {
  //       return;
  //     }
  //     setWebSocketReady(false);
  //     setTimeout(() => {
  //       try {
  //         console.log('----- [Connection Manager] Socket Reconnect -----');
  //         setWebSocket(
  //           new WebSocket(
  //             `${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`
  //           )
  //         );
  //       } catch (e) {
  //         console.log('[Connection Manager] Socket Reconnect Error', e);
  //       } finally {
  //         console.log('----- [Connection Manager] Socket Reconnect -----');
  //       }
  //     }, 3000);
  //   };
  //   webSocket.onerror = (e) => {
  //     console.log('[Connection Manager] Socket Error', e, new Date());
  //     try {
  //       setWebSocketReady(false);
  //       webSocket.close();
  //     } catch (e) {
  //       console.log('[Connection Manager] Socket Error', e, new Date());
  //     }
  //   };

  //   return () => {
  //     try {
  //       console.log('[Connection Manager] Socket Disconnect');
  //       webSocket.close(4000, 'reset');
  //     } catch (e) {
  //       console.log('[Connection Manager] Socket Disconnect Error', e);
  //     }
  //   };
  // }, [user.clientId, user.signedIn, webSocket]);

  useEffect(() => {
    let retries = 0;

    const setupWebSocket = () => {
      if (!user || !user.signedIn || !webSocket || !user.clientId) {
        return;
      }

      webSocket.onopen = () => {
        console.log('[Connection Manager] Socket Open');
        setWebSocketReady(true);
      };
      webSocket.onmessage = (event) => {
        setServerMessage(JSON.parse(event.data));
      };
      webSocket.onclose = (event) => {
        console.log('[Connection Manager] Socket Close Code', event.code);
        if (event.code === 4000) {
          return;
        }
        setWebSocketReady(false);
        setTimeout(() => {
          try {
            console.log('----- [Connection Manager] Socket Reconnect -----');
            setWebSocket(
              new WebSocket(
                `${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`
              )
            );
          } catch (e) {
            console.log('[Connection Manager] Socket Reconnect Error', e);
          } finally {
            console.log('----- [Connection Manager] Socket Reconnect -----');
          }
          retries++;
        }, 3000 * retries);
      };
      webSocket.onerror = (e) => {
        console.log('[Connection Manager] Socket Error', e, new Date());
        setWebSocketReady(false);
        webSocket?.close();
      };
    };

    setupWebSocket();

    return () => {
      console.log('[Connection Manager] Socket Disconnect');
      webSocket?.close(4000, 'reset');
    };
  }, [user.clientId, user.signedIn, webSocket]);

  useEffect(() => {
    if (webSocketReady && webSocket) {
      try {
        webSocket.send(JSON.stringify(socketData));
      } catch (e) {
        console.log('[Connection Manager] Socket send error', e);
      }
    }
  }, [webSocketReady, webSocket, socketData]);

  useEffect(() => {
    if (
      webSocketReady &&
      screenData &&
      Object.keys(screenData)?.length &&
      activeClassroom?.groupId
    ) {
      try {
        webSocket.send(JSON.stringify(screenData));
      } catch (e) {
        console.log('[Connection Manager] Socket send error', e);
      }
    }
  }, [screenData, webSocket, webSocketReady, activeClassroom?.groupId]);

  //

  useInterval(() => {
    if (!user || !allTimetables) {
      return;
    }
    const nowTime = moment().format('HH:mm');
    const timeTableCheker = allTimetables?.map((timetable) => {
      const startTime = moment(timetable.startTime, 'HH:mm');
      const endTime = moment(timetable.endTime, 'HH:mm');
      const now = moment(nowTime, 'HH:mm');
      const diff = now.diff(startTime, 'minutes');
      const diffEndTime = now.diff(endTime, 'minutes');
      const weekday = moment().weekday();
      if (diff >= -1 && diffEndTime <= 0 && weekday === timetable.weekday) {
        return timetable.groupId;
      }
      return null;
      // return timetable.groupId
    });

    const timetableArray = timeTableCheker.filter((item) => item !== null);
    if (!activeClassroom && timetableArray.length > 0) {
      const aliveData = {
        clientId: user.clientId,
        groupId: timetableArray[0],
        method: 'POST',
        uri: '/pulse',
      };
      try {
        webSocket?.send(JSON.stringify(aliveData));
      } catch (e) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        console.log('[Connection Manager] Socket send error', e);
      }
      const diff = (new Date() - lastTimestamp) / 1000;
      const diffSecond = parseInt(diff);

      if (diffSecond >= 10 && !user.clientId) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        setLastTimestamp(new Date());
        console.log('reconnet to socket');
      }
    }
    if (activeClassroom) {
      const aliveData = {
        clientId: user.clientId,
        groupId: activeClassroom?.groupId,
        method: 'POST',
        uri: '/pulse',
      };
      try {
        webSocket?.send(JSON.stringify(aliveData));
      } catch (e) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        console.log('[Connection Manager] Socket send error', e);
      }
      const diff = (new Date() - lastTimestamp) / 1000;
      const diffSecond = parseInt(diff);

      if (diffSecond >= 10 && !user.clientId) {
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
        setLastTimestamp(new Date());
        console.log('reconnet to socket');
      }
    }
    if (!serverMessage?.now && !user.clientId) {
      return;
    }
  }, 5000);

  useEffect(() => {
    if (!activeClassroom) {
      setSocketConnectCount(0);
      setImageCounts({});
      dispatch(clearStudentImages());
    }
    if (activeClassroom) {
      dispatch(setStudentImages(images));
    }
  }, [dispatch, images, Object.keys(images).length, activeClassroom]);

  useEffect(() => {
    if (!activeClassroom) {
      setImages({});
    }
  }, [activeClassroom]);

  useInterval(() => {
    if (!activeClassroom) {
      return;
    }
    Object.keys(imageCounts).forEach((key) => {
      if (imageCounts[key] >= 3) {
        delete imageCounts[key];
        delete images[key];
        return;
      }
      imageCounts[key] += 1;
    });
  }, 2000);

  //webSocketChecker
  const filteredParticipations = useMemo(() => {
    //조건식 설명: 상태가 "ABSENT"이고, 선생님 본인은 제외 >> 그러면 상태가 "ATTEND"이고 학생인 계정만 필터됨
    return (
      _participations?.filter(
        (participation) =>
          participation.state !== 'ABSENT' &&
          participation.clientId !== user.clientId
      ) || []
    );
  }, [_participations, user.clientId]);

  useInterval(() => {
    if (!activeClassroom) {
      return;
    }
    if (activeClassroom && filteredParticipations.length === 0) {
      dispatch(getMembershipsByGroupPromise(activeClassroom.groupId)).then(() => {
        dispatch(setStudentListUpdate(true));
      });
      dispatch(
        getParticipationsByClassroomPromise(activeClassroom?.classroomId)
      );
    }
    if (
      filteredParticipations.length !== 0 &&
      Object.keys(images).length === 0
    ) {
      // setSocketConnectCount 증가
      setSocketConnectCount((prevState) => prevState + 1);
      // setSocketConnectCount가 3이상이면
      if (socketConnectCount >= 2) {
        // setWebSocket 재연결
        setWebSocket(
          new WebSocket(`${process.env.REACT_APP_WS_DEV_ADDR}/${user.clientId}`)
        );
      }
      if (socketConnectCount >= 4) {
        // setSocketConnectCount를 먼저 초기화 한 후 새로고침
        setSocketConnectCount(0);
        window.location.reload();
      }
    }
  }, 5000);

  useEffect(() => {
    if (!user || !user.clientId) {
      return;
    }
    if (!serverMessage?.type) {
      setLastTimestamp(new Date(serverMessage.now));
      return;
    }
    switch (serverMessage?.type) {
      case 'REPORT':
        if (!activeClassroom) {
          return;
        }
        console.log('[ConnectionManager]', 'REPORT', serverMessage);
        dispatch(setReportData(serverMessage));

        break;

      case 'CLASS_START':
      case 'CLASS_END':
        dispatch(getActiveClassroomPromise(user.clientId));
        break;
      case 'IMAGE_STUDENT':
        if (!activeClassroom) {
          return;
        }
        if(activeClassroom) {
          if (serverMessage.groupId !== activeClassroom.groupId) {
            return;
          }
          setImages((prevState) => ({
            ...prevState,
            [serverMessage.clientId]: serverMessage.data,
          }));
          imageCounts[serverMessage.clientId] = 0;
          const reportEvents = [
            {
              groupId: activeClassroom?.groupId,
              sender: user.clientId,
              receiver: serverMessage?.clientId,
              senderRole: TEACHER,
              receiverRole: STUDENT,
              eventType: RECEIVE_SHARING_SCREEN,
              data: RECEIVE_SHARING_SCREEN_DATA,
              reportedAt: new Date(),
            },
          ];
          dispatch(setReportEventPromise(reportEvents));
        }
        
        
        break;
      case 'JOIN_GROUP':
        console.log('[ConnectionManager]', 'JOIN!', serverMessage.groupId);
        dispatch(getMembershipsByGroupPromise(serverMessage.groupId)).then(
          () => {
            dispatch(setStudentListUpdate(true));
          }
        );
        dispatch(
          getParticipationsByClassroomPromise(activeClassroom?.classroomId)
        );
        dispatch(getManagedsByClientPromise(serverMessage.groupId)).then(() => {
          dispatch(setStudentListUpdate(true));
        });
        break;
      case 'ATTEND_CLASS':
        console.log('[ConnectionManager]', 'ATTEND!', serverMessage.clientId);
        dispatch(
          getParticipationsByClassroomPromise(activeClassroom?.classroomId)
        );
        break;
      case 'COMMENTS_STUDENT':
        dispatch(
          setCommentsStudent({
            image: serverMessage.data,
            clientId: serverMessage.clientId,
          })
        );
        break;

      case 'IMAGE_TEACHER':
        break;
      case 'CREATE_FILELINK':
        break;
      case 'DELETE_FILELINK':
        break;
      case 'CREATE_PROGRAMCONFIG':
        break;
      case 'DELETE_PROGRAMCONFIG':
        break;
      case 'UPDATE_PROGRAMCONFIG':
        break;
      case 'DEV_NOTIFICATION':
        console.log('Dev Notification');
        break;
      default:
      // console.log('[Connection Manager] Default Message', serverMessage);
    }
  }, [dispatch, serverMessage, user.clientId]);

  return <div />;
});

export default ConnectionManager;
