import * as React from 'react'
import _FormControl from '@mui/material/FormControl'
import _Select from '@mui/material/Select'
import styled from 'styled-components'

export const Select = ({ children, value, onChange, width }) => {
  return <FormControl sx={{ width }}>
    <_Select
      value={value}
      onChange={onChange}
      autoWidth
    >
      {children}
    </_Select>
  </FormControl>
}

const FormControl = styled(_FormControl)`
  background-color: #fff;

  .MuiSelect-select {
    padding: 10px 16px;
    font-family: 'Noto Sans KR', 'Roboto', sans-serif;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.25px;
    color: rgba(0, 0, 0, 0.87);
    min-height: unset;
  }

  fieldset {
    top: 0;
    border-color: #e0e0e0;

    legend {
      display: none;
    }
  }
`
