import { makeAutoObservable } from 'mobx'

const CATEGORY_KEY = 'categories'

class CategoryStore {
  data = []

  constructor() {
    makeAutoObservable(this)
    const stored = localStorage.getItem(CATEGORY_KEY)
    if (stored) {
      this.data = JSON.parse(stored)
    }
  }

  add(category) {
    this.data.push(category)
    localStorage.setItem(CATEGORY_KEY, JSON.stringify(this.data))
  }
}

export const categoryStore = new CategoryStore()
