import { createSelector } from 'reselect';

export const selectAllTimetables = (state) => {
  if (state.timetable.allIds.length === 0) {
    return [];
  }

  return state.timetable.allIds
    ?.filter(
      (timetableId) =>
        !state.timetable.byId[timetableId].hasOwnProperty('deleted') &&
        state.timetable.byId[timetableId].state !== 'DELETED'
    )
    .map((timetableId) => state.timetable.byId[timetableId]);
};

export const selectAllTimetablesCacheVer = createSelector(
  (state) => state.allIds,
  (state) => state.byId,
  (allIds, byId) => {
    if (allIds.length === 0) {
      return [];
    }

    return allIds
      ?.filter(
        (timetableId) =>
          !byId[timetableId].hasOwnProperty('deleted') &&
          byId[timetableId].state !== 'DELETED'
      )
      .map((timetableId) => byId[timetableId]);
  }
);

export const selectGroupTimetables = createSelector(
  (state) => state.timetable,
  (state, groupId) => groupId,
  (state, groupId) => {
    return selectAllTimetablesCacheVer(state).filter(
      (timetable) =>
        timetable.groupId === groupId && timetable.state !== 'DELETED'
    );
  }
);
