import { call, put, takeLeading } from 'redux-saga/effects';
import {
  getClientPromise,
  getClientSuccess,
  getClientFail,
  getClientsPromise,
  getClientsSuccess,
  getClientsFail,
  updateClientFail,
  updateClientsPromise,
  updateClientSuccess,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getClient, action.payload);
      yield put(getClientSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getClientFail(error));
    }
  });
}

function* getClientsPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getClientsByGroup, action.payload);
      yield put(getClientsSuccess(response.data.clients));
    } catch (error) {
      yield put(getClientsFail(error));
    }
  });
}

//// TODO need the fix

function* updateClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateClient, action.payload);
      yield put(updateClientSuccess(response.data));
    } catch (error) {
      yield put(updateClientFail(error));
    }
  });
}

function* clientSaga() {
  yield takeLeading(getClientPromise, getClientPromiseHandler);
  yield takeLeading(getClientsPromise, getClientsPromiseHandler);
  yield takeLeading(updateClientsPromise, updateClientPromiseHandler);
}

export default clientSaga;
