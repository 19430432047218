import { all, call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  createTimetableFail,
  createTimetablePromise,
  createTimetableSuccess,
  deleteTimetableFail,
  deleteTimetablePromise,
  deleteTimetableSuccess,
  getTimetableFail,
  getTimetablePromise,
  getTimetablesByGroupListPromise,
  getTimetablesByGroupPromise,
  getTimetablesByGroupSuccess,
  getTimetableSuccess,
  updateTimetableFail,
  updateTimetablePromise,
  updateTimetableSuccess,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';


function* getTimetablePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getTimetable, action.payload);

      yield put(getTimetableSuccess(response.data));
      return yield response.data;
    } catch (error) {
      // yield put(getTimetableFail(error));
    }
  });
}

function* updateGroupTimeTable(groupId) {
  try {
    const response = yield call(api.getTimetablesByGroup, groupId);
    yield put(getTimetablesByGroupSuccess(response.data.timetables));
  } catch (e) {
    // ignore
  }
}

function* getTimetablesByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    yield call(updateGroupTimeTable, action.payload);
  });
}

function* getTimetablesByGroupListPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    const groupIds = action.payload;
    yield all(groupIds.map(id => call(updateGroupTimeTable, id)));
  });
}

function* createTimetablePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createTimetable, action.payload);
      yield put(createTimetableSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createTimetableFail(error));
    }
  });
}

function* updateTimetablePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.updateTimetable, action.payload);
      yield put(updateTimetableSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(updateTimetableFail(error));
    }
  });
}

function* deleteTimetablePromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.deleteTimetable, action.payload);
      yield put(deleteTimetableSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(deleteTimetableFail(error));
    }
  });
}


function* timetableSaga() {
  yield takeLeading(getTimetablePromise, getTimetablePromiseHandler);
  yield takeLeading(getTimetablesByGroupPromise, getTimetablesByGroupPromiseHandler);
  yield takeLeading(getTimetablesByGroupListPromise, getTimetablesByGroupListPromiseHandler);
  yield takeEvery(createTimetablePromise, createTimetablePromiseHandler);
  yield takeLeading(updateTimetablePromise, updateTimetablePromiseHandler);
  yield takeLeading(deleteTimetablePromise, deleteTimetablePromiseHandler);
}

export default timetableSaga;
