import React from 'react';
import { Redirect } from 'react-router-dom';

// Pages Component
import Home from '@pages/Home';
import Classroom from '@pages/Classroom';
import GroupActivity from '@pages/GroupActivity';
import Students from '@pages/Students';
import AddClassroom from '@pages/Classroom/add-classroom';
import AddStudents from '@pages/Classroom/add-students';
import SignIn from '@pages/Auth';
import AuthManager from '@managers/AuthManager';
import TestButton from '@pages/Auth/test_button';
import DragSample from '@pages/Dev/drag-sample';
import Settings from '@pages/Settings/index';
import EditClassroom from '@pages/Classroom/edit-classroom';
import Dev from '@pages/Dev';
import ThumbnailClassroom from '@pages/Classroom/thumbnail-classroom';
import Renewal from '@pages/Auth/renewal';
import Managing from '@pages/Managing';
import { Notice } from '@pages/Notice';
import { Assignment } from '@pages/Assignment';
import QuizPang from '@pages/QuizPang';
import OtherSchool from '@pages/QuizPang/OtherSchool';
import QuizsetDetail from '@pages/QuizPang/quizset-detail';
import QuizPangInClass from '@pages/QuizPang/InClass';
import QuizsetDetailInClass from '@pages/QuizPang/InClass/quizset-detail';
import QuizsetSession from '@pages/QuizPang/InClass/quizset-session';
import QuizpangCommunity from '@pages/QuizPang/Community';

const authProtectedRoutes = [
  { path: '/home', component: Home },
  { path: '/add-students', component: AddStudents },
  { path: '/add-classroom', component: AddClassroom },
  { path: '/edit-classroom', component: EditClassroom },
  { path: '/classroom/:groupId', component: Classroom },
  { path: '/group-activity/:groupId', component: GroupActivity },
  { path: '/students/:groupId', component: Students },
  { path: '/auth', component: SignIn },
  { path: '/auth-manager', component: AuthManager },
  { path: '/test_button', component: TestButton },
  { path: '/dev-dragsample', component: DragSample },
  { path: '/settings', component: Settings },
  { path: '/dev', component: Dev },
  { path: '/renewal', component: Renewal },
  { path: '/quiz-pang', component: QuizPang },
  { path: '/quiz-pang/quizset-detail/:quizsetId', component: QuizsetDetail },
  { path: '/quiz-pang/other-school', component: OtherSchool },
  { path: '/quiz-pang/:groupId', component: QuizPangInClass },
  {
    path: '/quiz-pang/:groupId/quizset-detail/:quizsetId',
    component: QuizsetDetailInClass,
  },
  {
    path:
      '/quiz-pang/:groupId/quizset-detail/:quizsetId/session/:sessionId/:index/:result?',
    component: QuizsetSession,
  },
  // { path: '/prepare', component: ClassPrepare },
  // { path: '/auth-registered-cert', component: AuthRegisteredCert },

  // 공지사항
  { path: '/notice/:groupId', component: Notice },
  // 과제관리
  { path: '/assignment/:groupId', component: Assignment },

  // 임시 수업 시간 외 사용 관리 Path
  { path: '/managing', component: Managing },
  { path: '/managing/:groupId', component: Managing },
  { path: '/students-managed/:groupId', component: Students },

  { path: '/thumbnail-classroom', component: ThumbnailClassroom },

  // 퀴즈팡 테스트
  { path: '/test-quiz', component: QuizpangCommunity },
  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: '/',
    exact: true,
    component: () => (
      <Redirect
        to={{
          pathname: '/auth-manager',
        }}
      />
    ),
  },
];

const publicRoutes = [
  { path: '/classroom', component: Classroom },
  // { path: "/dev", component: Dev },
];

export { authProtectedRoutes, publicRoutes };
