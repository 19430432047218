import {
  CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL,
  CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS,
  CREATE_QUIZSET_SESSION_FAIL,
  CREATE_QUIZSET_SESSION_SUCCESS,
  GET_QUIZSET_SESSION_HISTORY_FAIL,
  GET_QUIZSET_SESSION_HISTORY_SUCCESS,
  GET_QUIZSET_SESSION_RESULT_FAIL,
  GET_QUIZSET_SESSION_RESULT_SUCCESS,
  PURGE_QUIZSET_SESSION,
} from './actionTypes'
import { createPromiseAction } from '@adobe/redux-saga-promise'

export const purgeQuizsetSession = () => ({
  type: PURGE_QUIZSET_SESSION,
});

export const getQuizsetSessionHistoryPromise = createPromiseAction('GET_QUIZSET_SESSION_HISTORY_PROMISE');
export const getQuizsetSessionHistorySuccess = (data) => {
  return {
    type: GET_QUIZSET_SESSION_HISTORY_SUCCESS,
    data,
  };
};
export const getQuizsetSessionHistoryFail = (error) => ({
  type: GET_QUIZSET_SESSION_HISTORY_FAIL,
  payload: error,
});

export const createQuizsetSessionPromise = createPromiseAction('CREATE_QUIZSET_SESSION_PROMISE');
export const createQuizsetSessionSuccess = (data) => {
  return {
    type: CREATE_QUIZSET_SESSION_SUCCESS,
    data,
  };
};
export const createQuizsetSessionFail = (error) => ({
  type: CREATE_QUIZSET_SESSION_FAIL,
  payload: error,
});

export const createQuizsetSessionByQuizPromise = createPromiseAction('CREATE_QUIZSET_SESSION_BY_QUIZ_PROMISE');
export const createQuizsetSessionByQuizSuccess = (data) => {
  return {
    type: CREATE_QUIZSET_SESSION_BY_QUIZ_SUCCESS,
    data,
  };
};
export const createQuizsetSessionByQuizFail = (error) => ({
  type: CREATE_QUIZSET_SESSION_BY_QUIZ_FAIL,
  payload: error,
});

export const getQuizsetSessionResultPromise = createPromiseAction('GET_QUIZSET_SESSION_RESULT_PROMISE');
export const getQuizsetSessionResultSuccess = (data) => {
  return {
    type: GET_QUIZSET_SESSION_RESULT_SUCCESS,
    data,
  };
};
export const getQuizsetSessionResultFail = (error) => ({
  type: GET_QUIZSET_SESSION_RESULT_FAIL,
  payload: error,
});
