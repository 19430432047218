import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './CardClassManagementsSmall.module.scss';
import imgNotiIcon from '@images/home/img_notice.png';
import ICONS from '@images/appIcons/index.js';
import Switch from '@mui/material/Switch';
import { updateProgramconfigPromise, setSocketData } from '@store/actions';

const CardClassManagementsSmall = ({ id, enabled, name, alias, groupId }) => {
  const [enable, setEnable] = useState(enabled);
  const [enableState, setEnableState] = useState('차단중');
  const dispatch = useDispatch();

  const handleToggle = () => {
    const newEnable = !enable;
    setEnable(newEnable);
    dispatch(
      updateProgramconfigPromise({
        programconfigId: id,
        isAllowed: newEnable,
      })
    ).then((programconfig) => {
      dispatch(
        setSocketData({
          method: 'POST',
          uri: '/classroom/sendImage',
          groupId: programconfig?.groupId,
          clientId: programconfig?.clientId,
          type: 'UPDATE_PROGRAMCONFIG',
          data: '',
        })
      );
    });
  };

  useEffect(() => {
    if (!enable) {
      setEnableState('차단중');
    }
    if (enable) {
      setEnableState('허용중');
    }
  }, [enable]);

  return (
    <div className={styles['applist']} onClick={handleToggle}>
      <div className={styles['icon-wrap']}>
        {enable ? (
          <img
            className={styles['icon-active']}
            src={ICONS[alias] || imgNotiIcon}
            alt="icon"
          />
        ) : (
          <img
            className={styles['icon-diable']}
            src={ICONS[alias] || imgNotiIcon}
            alt="icon"
          />
        )}

        <span>{name}</span>
      </div>
      <div className={styles['switch-wrap']}>
        <Switch checked={enable} onChange={handleToggle} value={enabled} />
      </div>
      <div className={styles['switch-state-wrap']}>
        {enable ? (
          <div className={styles['switch-state-active']}>{enableState}</div>
        ) : (
          <div className={styles['switch-state-disable']}>{enableState}</div>
        )}
      </div>
    </div>
  );
};

export default CardClassManagementsSmall;
