import {
  PURGE_MANAGING,
  GET_MANAGING_SUCCESS,
  GET_MANAGING_FAIL,
  GET_MANAGINGS_BY_GROUP_SUCCESS,
  GET_MANAGINGS_BY_GROUP_FAIL,
  GET_ACTIVE_MANAGING_SUCCESS,
  GET_ACTIVE_MANAGING_FAIL,
  SET_ACTIVE_MANAGING_EXIST,
  SET_ACTIVE_MANAGING_NOT_EXIST,
  CREATE_MANAGING_SUCCESS,
  CREATE_MANAGING_FAIL,
  UPDATE_MANAGING_SUCCESS,
  UPDATE_MANAGING_FAIL,
  UPDATE_MANAGING_LOADING,
  DELETE_MANAGING_SUCCESS,
  DELETE_MANAGING_FAIL,
  GET_ACTIVE_MANAGING_BY_GROUP_SUCCESS,
  GET_ACTIVE_MANAGING_BY_GROUP_FAIL,
  SET_ACTIVE_MANAGING_SESSION,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeManaging = () => ({
  type: PURGE_MANAGING,
});

///// GET_MANAGING
export const getManagingPromise = createPromiseAction('GET_MANAGING_PROMISE');

export const getManagingSuccess = (managingData) => ({
  type: GET_MANAGING_SUCCESS,
  managing: managingData,
});

export const getManagingFail = (error) => ({
  type: GET_MANAGING_FAIL,
  payload: error,
});

// GET_MANAGINGS_BY_GROUP_PROMISE
export const getManagingsByGroupPromise = createPromiseAction(
  'GET_MANAGINGS_BY_GROUP_PROMISE'
);

export const getManagingsByGroupSuccess = (managingsData) => {
  let byId = {};
  managingsData.forEach((managingData) => {
    if (managingData.state !== 'DELETED') {
      byId[`${managingData.managingId}`] = managingData;
    }
  });

  const allIds = managingsData
    .filter((managingData) => managingData.state !== 'DELETED')
    .map((managingData) => managingData.managingId);

  return {
    type: GET_MANAGINGS_BY_GROUP_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getManagingsByGroupFail = (error) => ({
  type: GET_MANAGINGS_BY_GROUP_FAIL,
  payload: error,
});

// GET_ACTIVE_MANAGING
export const getActiveManagingPromise = createPromiseAction(
  'GET_ACTIVE_MANAGING_PROMISE'
);

export const getActiveManagingSuccess = (managingManagedData) => {
  const managing = managingManagedData.managing;
  const managed = managingManagedData.managed;
  return {
    type: GET_ACTIVE_MANAGING_SUCCESS,
    managing: managing,
    managed: managed,
  };
};
export const getActiveManagingFail = (error) => {
  return {
    type: GET_ACTIVE_MANAGING_FAIL,
    payload: error,
  };
};

// GET_ACTIVE_MANAGING
export const getActiveManagingByGroupPromise = createPromiseAction(
  'GET_ACTIVE_MANAGING_PROMISE'
);

export const getActiveManagingByGroupSuccess = (managingManagedData) => {
  const managing = managingManagedData.managing;
  const managed = managingManagedData.managed;

  return {
    type: GET_ACTIVE_MANAGING_BY_GROUP_SUCCESS,
    managing: managing,
    managed: managed,
  };
};
export const getActiveManagingByGroupFail = (error) => {
  return {
    type: GET_ACTIVE_MANAGING_BY_GROUP_FAIL,
    payload: error,
  };
};

export const setActiveManagingExist = (managingData) => ({
  type: SET_ACTIVE_MANAGING_EXIST,
  managingData: managingData,
});

export const setActiveManagingNotExist = () => ({
  type: SET_ACTIVE_MANAGING_NOT_EXIST,
});

export const setActiveManagingSession = (activeManagings) => {
  return {
    type: SET_ACTIVE_MANAGING_SESSION,
    activeManagings: activeManagings,
  };
};

//// CREATE_MANAGING
export const createManagingPromise = createPromiseAction(
  'CREATE_MANAGING_PROMISE'
);

export const createManagingSuccess = (managingData) => ({
  type: CREATE_MANAGING_SUCCESS,
  managing: managingData,
});

export const createManagingFail = (error) => ({
  type: CREATE_MANAGING_FAIL,
  payload: error,
});

//// UPDATE_MANAGING
export const updateManagingPromise = createPromiseAction(
  'UPDATE_MANAGING_PROMISE'
);

export const updateManagingLoading = (isManagingLoading) => ({
  type: UPDATE_MANAGING_LOADING,
  payload: isManagingLoading,
});
export const updateManagingSuccess = (managingData) => ({
  type: UPDATE_MANAGING_SUCCESS,
  managing: managingData,
});

export const updateManagingFail = (error) => ({
  type: UPDATE_MANAGING_FAIL,
  payload: error,
});

//// DELETE_MANAGING
export const deleteManagingPromise = createPromiseAction(
  'DELETE_MANAGING_PROMISE'
);

export const deleteManagingSuccess = (managingData) => ({
  type: DELETE_MANAGING_SUCCESS,
  managing: managingData,
});

export const deleteManagingFail = (error) => ({
  type: DELETE_MANAGING_FAIL,
  payload: error,
});
