import { makeFreshAllIds } from '../helpers';
import {
  PURGE_PROGRAMCONFIG,
  GET_PROGRAMCONFIG_SUCCESS,
  GET_PROGRAMCONFIG_FAIL,
  GET_GROUP_PROGRAMCONFIGS_SUCCESS,
  GET_GROUP_PROGRAMCONFIGS_FAIL,
  CREATE_PROGRAMCONFIG_SUCCESS,
  CREATE_PROGRAMCONFIG_FAIL,
  UPDATE_PROGRAMCONFIG_SUCCESS,
  UPDATE_PROGRAMCONFIG_FAIL,
  DELETE_PROGRAMCONFIG_SUCCESS,
  DELETE_PROGRAMCONFIG_FAIL,
} from './actionTypes';

const initialState = {
  byId: {},
  allIds: [],
};

const programconfigs = (state = initialState, action) => {
  switch (action.type) {
    case PURGE_PROGRAMCONFIG:
      return initialState;

    case GET_PROGRAMCONFIG_SUCCESS:
    case CREATE_PROGRAMCONFIG_SUCCESS:
    case UPDATE_PROGRAMCONFIG_SUCCESS:
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programconfig.programconfigId]: action.programconfig,
        },
        allIds: makeFreshAllIds(state.allIds, [
          action.programconfig.programconfigId,
        ]),
      };

    case GET_GROUP_PROGRAMCONFIGS_SUCCESS:
      
      return {
        ...state,
        byId: { ...state.byId, ...action.byId },
        allIds: makeFreshAllIds(state.allIds, action.allIds),
      };

    case DELETE_PROGRAMCONFIG_SUCCESS:
      // 개발 서버에서 현재 삭제시 응답값을 안 내려줌
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.programconfig.programconfigId]: action.programconfig,
        },
        allIds: state.allIds.filter(
          (programconfigId) => programconfigId !== action.programconfig
        ),
      };

    case CREATE_PROGRAMCONFIG_FAIL:
    case UPDATE_PROGRAMCONFIG_FAIL:
    case GET_PROGRAMCONFIG_FAIL:
    case DELETE_PROGRAMCONFIG_FAIL:
      console.error('[Programconfig Reducer] ', action.payload);
      return state;

    case GET_GROUP_PROGRAMCONFIGS_FAIL:
      // program configs not found, purge it
      // console.log('[Programconfig Reducer] ', 'No Programconfigs with this Group');
      return state;

    default:
      break;
  }
  return state;
};

export default programconfigs;
