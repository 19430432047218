import React from 'react'
import styled from 'styled-components'
import {
  Body2,
  Caption,
  Column,
  Row,
  Subtitle2,
} from '@app/pages/QuizPang/common'
import avatar from '@images/quiz-pang/avatar.png'

export const QuizsetSessionResultRankingItem = ({ data, index, rank }) => {
  return <Container
    style={{ backgroundColor: index % 2 === 0 ? '#FAFAFA' : '#fff' }}>
    <Column style={{ width: 80 }}>
      <Text>{rank}</Text>
    </Column>
    <Column style={{ width: 168 }}>
      <Row>
        <img src={avatar}
             style={{ marginRight: 8, width: 32, height: 32 }}/>
        <Text>
          {data.studentName}
        </Text>
      </Row>
    </Column>
    <Column style={{ width: 80, alignItems: 'flex-end' }}>
      <Caption>{data.correctAnswerCount}문제 정답</Caption>
    </Column>
    <Column style={{ width: 48, alignItems: 'flex-end' }}>
      <ScoreText>{data.score}점</ScoreText>
    </Column>
  </Container>
}


const Container = styled(Row)`
  padding: 14px 16px;
  border-bottom: 1px solid #EEEEEE;
`

const Text = styled(Body2)`
  color: rgba(0, 0, 0, 0.87);
`

const ScoreText = styled(Subtitle2)`
  color: #056B75;
`
