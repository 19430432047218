import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import ProfileImage from '../ProfileImage';
import styles from './ModalUpdateProfileImage.module.scss';
import UploadFileRoundedIcon from '@mui/icons-material/UploadFileRounded';
import { Button } from '@mui/material';
import { getProfileImageKey, uploadImage } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import { updateUserProfile } from '@app/store/actions';

const ModalUpdateProfileImage = ({ show, onHide, currentProfileImage }) => {
  const [file, setFile] = useState();
  const [preview, setPreview] = useState();
  const [img, setImg] = useState();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const handleImage = (e) => {
    const file = e.target.files[0];
    const url = URL.createObjectURL(file);
    setFile(file);
    setPreview(url);

    setImg({
      uri: url,
      type: file.type,
      name: file.name,
    });
  };

  const save = async () => {
    try {
      uploadImage(file, getProfileImageKey(user.clientId)).then(() => {
        dispatch(updateUserProfile());
        alert('프로필 사진이 변경되었습니다.');
        onHide();
      });
    } catch (e) {
      // alert(e);
      console.error(e);
      onHide();
    }
  };

  return (
    <Modal
      show={show}
      onHide={onHide}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className={styles['AddApp-container']}
    >
      <Modal.Header className={styles['modal-bg-header']}>
        <div className={styles['title-css']}>프로필 사진 변경</div>
      </Modal.Header>
      <Modal.Body className={styles['modal-bg-body']}>
        <div className={styles['body-wrapper']}>
          <div className={styles['profile-image']}>
            {/* NOTE: 기본 프로필 이미지 적용 필요 */}
            <ProfileImage
              size={120}
              url={preview ? preview : currentProfileImage}
              subscribeImageChange={user.subscribeImageChange}
            />
          </div>
          <label htmlFor="contained-button-file">
            <input
              accept="image/*"
              id="contained-button-file"
              multiple
              type="file"
              style={{ opacity: 0, width: 0 }}
              onChange={handleImage}
            />
            <Button
              variant="contained"
              startIcon={<UploadFileRoundedIcon />}
              component="span"
            >
              이미지 불러오기
            </Button>
          </label>
        </div>
      </Modal.Body>
      <div className={styles['modal-bg-footer']}>
        <button className={styles['footer-button']} onClick={onHide}>
          취소
        </button>
        <button className={styles['footer-button']} onClick={save}>
          변경
        </button>
      </div>
    </Modal>
  );
};

export default ModalUpdateProfileImage;
