import ManagedStudents from '@app/components/StudentsList/ManagedStudents';
import StudentsNormal from '@app/components/StudentsList/StudentsNormal';
import { useLocation } from 'react-router-dom';

const Students = (props) => {
  const location = useLocation();
  const isManagingClass = location.pathname.startsWith('/students-managed');

  if (isManagingClass) {
    return <ManagedStudents />;
  }
  return <StudentsNormal />;
};

export default Students;
