import { authProtectedRoutes } from '@routes';
import React, { useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { store } from '@store/index';
import { Switch, useHistory } from 'react-router-dom';
import AppRoute from '@routes/route';
import { getStorageData, setToken } from '@api';
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MasterManager from './managers';
import ReactGA from 'react-ga';

const theme = createTheme({
  palette: {
    primary: {
      main: '#0BA1AE',
    },
    secondary: {
      main: '#FFFFFF',
      contrastText: '#0894A0',
    },
    _red: {
      main: '#FF5252',
    },
    _green: {
      main: '#4CAF50',
    },
    _orange: {
      main: '#4CAF50',
    },
  },
  typography: {
    fontFamily: "'Noto Sans KR', sans-serif",
  },
});

const App = (props) => {
  const [loading, setLoading] = useState(true);
  // const refreshToken = async () => {
  //   const user_raw = await getStorageData("@user");
  //   const user = JSON.parse(user_raw);
  //   console.log("GET USER INFO: ", user);
  //   if (user == null) {
  //     console.log("fresh install...");
  //     setLoading(false);
  //   } else {
  //     if (user.token !== null && user.token !== undefined) {
  //       console.log("restoring user...");
  //       setToken(user.token).then(() => {
  //         console.log("token restored.");
  //         setLoading(false);
  //       });
  //     }
  //   }
  // };

  const history = useHistory();
  function getOS() {
    var userAgent = window.navigator.userAgent,
      platform =
        window.navigator?.userAgentData?.platform ?? window.navigator.platform,
      macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
      windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
      iosPlatforms = ['iPhone', 'iPad', 'iPod'],
      os = null;

    if (macosPlatforms.indexOf(platform) !== -1) {
      os = 'Mac OS';
    } else if (iosPlatforms.indexOf(platform) !== -1) {
      os = 'iOS';
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
      os = 'Windows';
    } else if (/Android/.test(userAgent)) {
      os = 'Android';
    } else if (!os && /Linux/.test(platform)) {
      os = 'Linux';
    }

    return os;
  }

  const persistor = persistStore(
    store,
    {}
    // refreshToken
  );
  var isIOSChrome = window.navigator.userAgent.match('CriOS');
  var isChromium = window.chrome;
  var vendorName = window.navigator.vendor;
  var isOpera = typeof window.opr !== 'undefined';
  var isIEedge = window.navigator.userAgent.indexOf('Edg') > -1;
  useEffect(() => {
    const presentOs = getOS();
    if (isIOSChrome) {
      alert('IOS와 크롬브라우저로 해당서비스를 이용할 수 없습니다.');
      history.replace('/');
    } else if (
      isChromium !== null &&
      typeof isChromium !== 'undefined' &&
      vendorName === 'Google Inc.' &&
      isOpera === false &&
      isIEedge === false
    ) {
      if (presentOs === 'Android') {
        alert('안드로이드와 크롬브라우저로 해당서비스를 이용할 수 없습니다.');
        history.replace('/');
      }
    }
  }, []);

  // useEffect(() => {
  //   ReactGA.initialize('G-562P3BY10K');
  //   history.listen((location) => {
  //     ReactGA.set({ page: location.pathname, title: location.pathname }); // Update the user's current page
  //     ReactGA.pageview(location.pathname); // Record a pageview for the given page
  //   });
  //   // ReactGA.pageview(window.location.pathname + window.location.search);
  // }, []);

  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <PersistGate persistor={persistor}>
          <Switch>
            {authProtectedRoutes.map((route, idx) => {
              return (
                <AppRoute
                  path={route.path}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                  exact
                />
              );
            })}
          </Switch>
          <MasterManager />
        </PersistGate>
      </ThemeProvider>
    </Provider>
  );
};

export default App;
