import {
  BottomContentLayer,
  HorizontalSpace8,
  VerticalSpace8,
  MiddleMenuBar,
  Row,
  Row2,
  Subtitle1,
  SubTitle2,
  Subtitle5,
  TabButtonInteractive,
} from '@app/pages/QuizPang/common';
import { Select } from '@app/pages/QuizPang/select';
import MenuItem from '@mui/material/MenuItem';
import { SearchInput } from '@app/pages/QuizPang/search-input';
import React, { useEffect, useRef, useState } from 'react';
import {
  QuizCard,
  QuizCardOtherSchoolActions,
} from '@app/pages/QuizPang/quiz-card';
import { useAllSchools } from '@app/pages/QuizPang/hooks';
import { useDispatch, useSelector } from 'react-redux';
import {
  createQuizPromise,
  getQuizListAllPromise,
  getQuizListBySchoolPromise,
  purgeOtherSchoolQuiz,
} from '@app/store/quiz/actions';
import { QuizFilterStore } from '@app/pages/QuizPang/quiz-filter-store';
import { selectOtherSchoolQuizList } from '@app/store/quiz/selector';
import {
  QuizModalStore,
  quizTypes,
  quizTypeToText,
} from '@app/pages/QuizPang/quiz-modal-store';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { QuizModal } from '@app/pages/QuizPang/quiz-modal';
import filterIcon from '@app/assets/images/icons/tune.svg';
import styled from 'styled-components';
import { OtherSchoolQuizsetTab } from './quizset-tab';

const getCopyableQuiz = (quiz, user) => {
  const copied = toJS(quiz);
  delete copied.quizId;
  delete copied.clientId;
  delete copied.clientName;
  delete copied.schoolId;
  delete copied.schoolName;
  delete copied.state;
  delete copied.createdAt;
  delete copied.updatedAt;
  copied.choices = JSON.stringify(copied.choices);
  copied.clientId = user.clientId;
  copied.schoolId = user.schoolId;
  return copied;
};

export const OtherSchoolQuizTab = observer(() => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const modalStore = useRef(new QuizModalStore());
  const { data: schoolData } = useAllSchools();
  const [schoolId, setSchoolId] = useState(-1);
  const filterStore = useRef(new QuizFilterStore());
  const dispatch = useDispatch();
  const quizList = useSelector(selectOtherSchoolQuizList);
  const user = useSelector((state) => state.user);
  const counts = filterStore.current.typeCounts;
  const [selectType, setSelectType] = useState('문제 + 퀴즈');
  const [filterButton, setFilterButton] = useState(false);
  const [indexCount, setIndexCount] = useState(0);
  const [quizTotalCount, setQuizTotalCount] = useState(0);
  // console.log('filterStore퀴즈탭', filterStore)
  const schoolId1 = 'dbf66a7d-a26a-4802-a958-de1fbdbf637f'; //포커스팡학교
  const schoolId2 = '0b75db26-f506-4820-9197-ccfe6a419127'; //포커스팡고등학교

  useEffect(() => {
    dispatch(getQuizListAllPromise(indexCount))
      .then((res) => {
        console.log('QuizListAll');
        setIsLoading(false);
        setQuizTotalCount(res.totalCount);
        if (res === undefined) {
          throw new Error();
        }
      })
      .catch((error) => console.log('[QuizListAll]', error));
  }, [indexCount]);

  useEffect(() => {
    if (schoolId === -1) {
      dispatch(purgeOtherSchoolQuiz());
    } else {
      dispatch(getQuizListBySchoolPromise(schoolId));

      // dispatch(getQuizListBySchoolPromise(schoolId1))
      // .then((res) => console.log('schoolId1', res))

      // dispatch(getQuizListBySchoolPromise(schoolId2))
      // .then((res) => console.log('schoolId2', res))
    }
  }, [schoolId]);

  useEffect(() => {
    filterStore.current.setData(quizList);
  }, [quizList]);

  const onCountPlus = () => {
    setIndexCount(indexCount + 1);
  };

  const onCountMinus = () => {
    if (indexCount > 0) {
      setIndexCount(indexCount - 1);
    }
  };

  return (
    <>
      <MiddleMenuBar
        style={{ flexDirection: 'column', alignItems: 'flex-start' }}
      >
        <Row2>
          <button
            style={{
              backgroundColor: '#fff',
              padding: 10,
              borderWidth: 1,
              borderRadius: 4,
            }}
            onClick={() => {
              filterButton ? setFilterButton(false) : setFilterButton(true);
            }}
          >
            <img src={filterIcon} />
          </button>
          <HorizontalSpace8 />
          <SearchInput
            label="문제 찾기"
            value={filterStore.current.query}
            width="100%"
            onChange={(e) => filterStore.current.setQuery(e.target.value)}
          />
        </Row2>
        {filterButton ? (
          <Row style={{ marginTop: 8 }}>
            <Subtitle5>검색필터 설정</Subtitle5>
            <HorizontalSpace8 />
            <Select
              width={120}
              value={selectType}
              onChange={(e) => setSelectType(e.target.value)}
            >
              <MenuItem value={'문제 + 퀴즈'}>문제 + 퀴즈</MenuItem>
              <MenuItem value={'문제'}>문제</MenuItem>
              <MenuItem value={'퀴즈'}>퀴즈</MenuItem>
            </Select>
            <HorizontalSpace8 />
            <Select
              width={160}
              value={schoolId}
              onChange={(e) => setSchoolId(e.target.value)}
            >
              <MenuItem value={-1}>학교 전체</MenuItem>
              {schoolData &&
                schoolData.schools.map((x) => {
                  return (
                    <MenuItem key={x.schoolId} value={x.schoolId}>
                      {x.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </Row>
        ) : null}
      </MiddleMenuBar>
      <BottomContentLayer>
        {selectType !== '퀴즈' && (
          <>
            <Subtitle1>공유 문제 목록</Subtitle1>
            <VerticalSpace8 />
            {isLoading ? (
              <SubTitle2>문제 목록 로딩 중입니다...</SubTitle2>
            ) : filterStore.current.filtered.length > 0 ? (
              filterStore.current.filtered.map((x) => (
                <QuizCard
                  key={x.quizId}
                  actions={
                    <QuizCardOtherSchoolActions
                      onPreview={() => {
                        modalStore.current = new QuizModalStore(x);
                        modalStore.current.setForcePreview();
                        setIsModalOpen(true);
                      }}
                      onAdd={() => {
                        if (
                          !window.confirm('이 문제를 퀴즈용 문제에 추가할까요?')
                        )
                          return;
                        dispatch(
                          createQuizPromise(getCopyableQuiz(x, user))
                        ).then((res) => {
                          if (res) alert('문제가 추가되었습니다!');
                        });
                      }}
                    />
                  }
                  data={x}
                />
              ))
            ) : (
              <SubTitle2>공유된 문제가 없습니다.</SubTitle2>
            )}
            {!isLoading && (
              <MoreContainer>
                {indexCount > 0 ? (
                  <MoreButton onClick={onCountMinus}>이전</MoreButton>
                ) : (
                  <MoreDisabledButton>이전</MoreDisabledButton>
                )}
                <HorizontalSpace8 />
                {/* <div style={{margin: 8}}>{indexCount+1}</div> */}
                {`${quizTotalCount}개 중 ${indexCount * 20 + 1}-${Math.min(
                  (indexCount + 1) * 20,
                  quizTotalCount
                )}`}
                <HorizontalSpace8 />
                {(indexCount + 1) * 20 >= quizTotalCount ? (
                  <MoreDisabledButton>다음</MoreDisabledButton>
                ) : (
                  <MoreButton onClick={onCountPlus}>다음</MoreButton>
                )}
              </MoreContainer>
            )}
          </>
        )}
        {selectType !== '문제' && (
          <>
            <Subtitle1>공유 퀴즈 목록</Subtitle1>
            <VerticalSpace8 />
            <OtherSchoolQuizsetTab />
          </>
        )}
      </BottomContentLayer>
      <QuizModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        store={modalStore.current}
      />
    </>
  );
});

export const MoreContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
`;
export const MoreButton = styled.div`
  cursor: pointer;
  border-radius: 4px;
  background-color: #0ba1ae;
  color: #fff;
  padding: 4px 10px;
  font-size: 14px;

  &:hover {
    background-color: #16747cd7;
  }
`;
export const MoreDisabledButton = styled.div`
  border-radius: 4px;
  background-color: #b9f2f9;
  color: #fff;
  padding: 4px 10px;
  font-size: 14px;
`;
