import { profileImgPath } from "@api";
import React from "react";
import ProfileImage from "../ProfileImage";
import styles from "./SubgroupActivityStudentList.module.scss";

export const SubgroupActivityStudentList = ({ studentList }) => {
  return (
    <table className={styles["studentTable"]}>
      <colgroup>
        <col width='80px' />
        <col width='auto' />
      </colgroup>
      <thead>
        <tr>
          <th>학번</th>
          <th>이름</th>
        </tr>
      </thead>
      <tbody>
        {studentList.map((student) => (
          <tr key={student.clientId}>
            <td>{student.studentNumber}</td>
            <td style={{display: 'flex', flexDirection: 'row'}}>
              <ProfileImage
                className={styles["studentName"]}
                size={24}
                url={profileImgPath(student.clientId)}
              />
              {student.userName}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
