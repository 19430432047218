import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

const FullScreenDialog = styled(Dialog)`
  & .MuiDialog-paper {
    height: 100%;
    width: 100%;
    max-height: none;
    max-width: none;
  }
`;



const FullScreenDialogTitle = styled(DialogTitle)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: #000;
`;

const FullScreenDialogTitleContent = styled(DialogTitle)`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #000;

  @media (min-width: 3840px) {
    font-size: 88px !important;
  }
  @media (min-width: 2560px) and (max-width: 3839px) {
    font-size: 58px !important;
  }

  @media (min-width: 1980px) and (max-width: 2559px) {
    font-size: 38px !important;
  }

  @media (min-width: 1440px) and (max-width: 1979px) {
    font-size: 28px !important;
  }

  @media (max-width: 1439px) {
    font-size: 24px !important;
  }
`;

const FullScreenDialogContent = styled(DialogContent)`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;



const CloseButton = styled(IconButton)`
  color: #000;
`;

function FullScreenInviteCodeModal({ children, open, onClose }) {
  const [showContent, setShowContent] = useState(open);

  useEffect(() => {
    setShowContent(open);
  }, [open]);

  const handleClose = () => {
    setShowContent(false);
    onClose();
  };

  return (
    <FullScreenDialog fullScreen open={showContent} onClose={handleClose}>
      <FullScreenDialogTitle>
        <CloseButton onClick={handleClose}>
          <Close />
        </CloseButton>
      </FullScreenDialogTitle>
      <FullScreenDialogTitleContent>
        선생님은 학생들에게 화면에 보여진 초대코드를 알려주시면 학생들이 수업에 참여할 수 있습니다.
      </FullScreenDialogTitleContent>
      <FullScreenDialogContent onClick={handleClose}>
        {children}
      </FullScreenDialogContent>
    </FullScreenDialog>
  );
}

export default FullScreenInviteCodeModal;
