const processQuizsetItem = (item) => {
  const quizzes = JSON.parse(item.quizzes)
  const timelimit = quizzes.reduce((sum, x) => sum + x.timelimit, 0)
  let timeDisplay = ''
  if (timelimit >= 60) {
    timeDisplay += `${parseInt(timelimit / 60)}분 `
  }
  const secondRemainder = timelimit % 60
  if (secondRemainder > 0) {
    timeDisplay += `${secondRemainder}초`
  }
  if (timelimit === 0) {
    timeDisplay = '0초'
  }
  timeDisplay = timeDisplay.trim()
  return {
    ...item,
    quizzes,
    timelimit,
    timeDisplay,
  }
}


export const selectClientQuizsetList = (state) => {
  return state.quizset.allIds.map((quizsetId) => processQuizsetItem(state.quizset.byId[quizsetId]))
}

export const selectClientQuizset = (state, quizsetId) => {
  return processQuizsetItem(state.quizset.byId[quizsetId])
}

export const selectOtherSchoolQuizsetList = (state) => {
  return state.quizset.otherSchoolAllIds.map((quizsetId) => processQuizsetItem(state.quizset.otherSchoolById[quizsetId]))
}
