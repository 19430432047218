import {
  PURGE_FILELINK,
  GET_GROUP_FILELINK_SUCCESS,
  GET_GROUP_FILELINK_FAIL,
  CREATE_FILELINK_SUCCESS,
  CREATE_FILELINK_FAIL,
  DELETE_FILELINK_SUCCESS,
  DELETE_FILELINK_FAIL,
} from './actionTypes';
import { createPromiseAction } from '@adobe/redux-saga-promise';

export const purgeFilelink = () => ({
  type: PURGE_FILELINK,
});

export const getFilelinkPromise = createPromiseAction('GET_FILELINK_PROMISE');

export const getGroupFilelinkPromise = createPromiseAction(
  'GET_GROUP_FILELINK_PROMISE'
);

export const getGroupFilelinkSuccess = (filelinksData) => {
  let byId = {};
  filelinksData.forEach((filelinkData) => {
    byId[filelinkData.filelinkId] = filelinkData;
  });

  const allIds = filelinksData.map((filelinkData) => filelinkData.filelinkId);
  return {
    type: GET_GROUP_FILELINK_SUCCESS,
    byId: byId,
    allIds: allIds,
  };
};

export const getGroupFilelinkFail = (error) => ({
  type: GET_GROUP_FILELINK_FAIL,
  payload: error,
});

// CREATE FILELINK
export const createFilelinkPromise = createPromiseAction(
  'CREATE_FILELINK_PROMISE'
);
export const createFilelinkSuccess = (filelinkData) => ({
  type: CREATE_FILELINK_SUCCESS,
  filelink: filelinkData,
});

export const createFilelinkFail = (error) => ({
  type: CREATE_FILELINK_FAIL,
  payload: error,
});

// DELETE FILELINK
export const deleteFilelinkPromise = createPromiseAction(
  'DELETE_FILELINK_PROMISE'
);
export const deleteFilelinkSuccess = (filelinkData) => ({
  type: DELETE_FILELINK_SUCCESS,
  filelink: filelinkData,
});

export const deleteFilelinkFail = (error) => ({
  type: DELETE_FILELINK_FAIL,
  payload: error,
});
