import { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Stack,
  Typography,
  Divider,
  Button,
  TextField,
  Table,
  TableBody,
  TableHead,
  TableContainer,
  TableCell,
  TableRow,
  FormControlLabel,
  Checkbox,
} from '@mui/material';
import axios from 'axios';
import { Modal } from 'react-bootstrap';
import { selectGroupById, selectAllStudents } from '@store/selectors';
import { Error as ErrorIcon } from '@mui/icons-material';
import 'react-datepicker/dist/react-datepicker.css';
import { TextEditor } from '@app/components/Input/TextEditor';
import { DateTimePicker } from '@app/components/Input/DateTimePicker';
import { format, isAfter } from 'date-fns';
import { selectStudentsByGroup } from '@app/store/selectors';
import styled from 'styled-components';

const AssignmentItemDelete = ({
  show,
  setShow,
  groupnoticeId,
  getGroupnoticeByGroup,
}) => {
  const deleteGroupnotice = useCallback(async () => {
    try {
      // 공지 삭제
      await axios.delete(
        `https://ktor.focuspang.com/v2/groupnotice/${groupnoticeId}`
      );
      // 모달 닫기
      setShow(false);
      getGroupnoticeByGroup();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Stack spacing={3} pt={7} pb={1}>
        <Stack alignItems="center">
          <Typography>과제 공지를 정말 삭제하시겠어요?</Typography>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            style={{ border: '1px solid #e0e0e0' }}
            onClick={() => setShow(false)}
          >
            취소
          </Button>
          <Button
            style={{ border: '1px solid #e0e0e0', marginRight: '16px' }}
            color="_red"
            onClick={deleteGroupnotice}
          >
            삭제
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

const AssignmentItemRead = ({
  setUpdate,
  groupnotice,
  getGroupnoticeByGroup,
}) => {
  const user = useSelector((state) => state.user);
  const { userName } = { ...user };

  const {
    groupnoticeId,
    title,
    body,
    submitAt,
    groupId,
    releaseAt,
  } = groupnotice;
  const { grade, groupName, subjectName } = useSelector((state) =>
    selectGroupById(state, groupId)
  );
  const students = useSelector((state) =>
    selectStudentsByGroup(state, groupId)
  );

  const [show, setShow] = useState(false);
  const [open, setOpen] = useState(false);

  const [clientSubmissions, setClientSubmissions] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const response = await axios.get(
          `https://ktor.focuspang.com/v2/submission?groupnoticeId=${groupnoticeId}`
        );
        setClientSubmissions(response.data.clientSubmissions);
      } catch (error) {}
    })();
  }, []);

  return (
    <>
      <Stack border="1px solid #E0E0E0" borderRadius="4px" p={3} spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography>{title}</Typography>
          <Stack direction="row" spacing={1}>
            <Button
              style={{ border: '1px solid #e0e0e0' }}
              onClick={() => setUpdate(true)}
            >
              수정
            </Button>
            <Button
              color="_red"
              style={{ border: '1px solid #e0e0e0' }}
              onClick={() => setShow(true)}
            >
              삭제
            </Button>
          </Stack>
        </Stack>

        <Stack direction="row" flexWrap="wrap" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} mr={2}>
            <Typography
              variant="caption"
              bgcolor="#F5F5F5"
              px={1}
              py={0.5}
              borderRadius="4px"
              color="#00000099"
            >
              {subjectName}
            </Typography>
            <Typography color="#00000099" variant="body2">
              {releaseAt && format(new Date(releaseAt), 'yyyy-MM-dd HH:mm')}
            </Typography>
            <Typography variant="body2">{`${grade}학년 ${groupName}반`}</Typography>
            <Typography color="green" variant="body2">
              {isAfter(new Date(), new Date(releaseAt)) ? '공개' : '비공개'}
            </Typography>
          </Stack>
          <Typography color="#00000099" variant="body2">
            &#183; {userName} 선생님 공지
          </Typography>
        </Stack>

        <Divider />

        <Stack direction="row">
          <Typography
            variant="body2"
            bgcolor="#F5F5F5"
            px={1}
            py={0.5}
            borderRadius="4px"
          >
            {`${grade}학년 ${groupName}반 제출 기한 : ${submitAt &&
              format(new Date(submitAt), 'yyyy-MM-dd HH:mm')}`}
          </Typography>
        </Stack>
        <StyledContentWrap>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </StyledContentWrap>

        {open && (
          <TableContainer sx={{ borderRadius: '4px 4px 0 0' }}>
            <Table sx={{ minWidth: 650 }}>
              <TableHead sx={{ bgcolor: '#424242' }}>
                <TableRow>
                  <TableCell>
                    <Typography variant="caption" color="white">
                      학번
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color="white">
                      이름
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color="white">
                      제출여부
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color="white">
                      제출내용
                    </Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="caption" color="white">
                      제출일시
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {students.map((student) => {
                  const { studentNumber, userName, clientId } = student;

                  const clientSubmission = clientSubmissions.filter(
                    (clientSubmission) =>
                      clientSubmission.client.clientId === clientId
                  );
                  const submitted = Boolean(clientSubmission.length);
                  const { submission } = { ...clientSubmission[0] };
                  const { title, submittedAt = '', filelink } = {
                    ...submission,
                  };

                  return (
                    <TableRow key={clientId}>
                      <TableCell component="th" scope="row">
                        <Typography variant="body2" color="#000000DE">
                          {studentNumber}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2">{userName}</Typography>
                      </TableCell>
                      <TableCell>
                        <Typography
                          variant="body2"
                          color={submitted ? '#388E3C' : '#FF1744'}
                        >
                          {submitted ? '제출' : '미제출'}
                        </Typography>
                      </TableCell>
                      <TableCell>
                        {submitted && (
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={1}
                          >
                            <a
                              href={filelink}
                              style={{
                                color: '#0894A0',
                                textDecoration: 'none',
                              }}
                            >
                              <Typography
                                variant="body2"
                                style={{
                                  padding: 4,
                                  borderWidth: 1,
                                  borderRadius: 4,
                                  borderColor: '#0894A0',
                                }}
                              >
                                다운로드
                              </Typography>
                            </a>
                            <Typography variant="body2">{title}</Typography>
                          </Stack>
                        )}
                      </TableCell>
                      <TableCell>
                        <Typography variant="body2" color="#00000099">
                          {submittedAt &&
                            format(new Date(submittedAt), 'yyyy-MM-dd HH:mm')}
                        </Typography>
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        )}

        <Button variant="outlined" onClick={() => setOpen((prev) => !prev)}>
          과제 제출 현황 보기
        </Button>
      </Stack>
      <AssignmentItemDelete
        show={show}
        setShow={setShow}
        groupnoticeId={groupnoticeId}
        getGroupnoticeByGroup={getGroupnoticeByGroup}
      />
    </>
  );
};

const AssignmentItemUpdate = ({
  setUpdate,
  groupnotice,
  getGroupnoticeByGroup,
}) => {
  const {
    groupnoticeId,
    title,
    body,
    submitAt,
    releaseAt,
    noticeType,
    groupId,
  } = groupnotice;

  const type = noticeType === 'NOTICE' ? '일반 공지' : '과제 공지';

  const [newTitle, setNewTitle] = useState(title);
  const [newBody, setNewBody] = useState(body);
  const [newSubmitAt, setNewSubmitAt] = useState(new Date(submitAt));
  const [newReleaseAt, setNewReleaseAt] = useState(new Date(releaseAt));
  const [checked, setChecked] = useState(false);

  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (newTitle && newBody) setValidation(false);
  }, [newTitle, newBody]);

  useEffect(() => {
    if (checked) setNewReleaseAt(new Date());
  }, [checked]);

  const updateGroupnotice = useCallback(async () => {
    if (!newTitle || !newBody) return setValidation(true);
    try {
      // 공지 수정
      const response = await axios.patch(
        'https://ktor.focuspang.com/v2/groupnotice',
        {
          groupnoticeId,
          title: newTitle,
          body: newBody,
          submitAt: newSubmitAt,
          releaseAt: newReleaseAt,
        }
      );
      console.log(response);
      // 수정 완료
      getGroupnoticeByGroup();
      setUpdate(false);
    } catch (error) {
      console.log(error);
    }
  }, [newTitle, newBody, newSubmitAt, newReleaseAt]);

  return (
    <Stack border="1px solid #E0E0E0" borderRadius="4px">
      <Stack p={3} spacing={3}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Stack direction="row" spacing={3} alignItems="center" flex={1}>
            <Typography>{type}</Typography>
            <Stack flex={1}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Button onClick={() => setUpdate(false)}>취소</Button>
            <Button variant="contained" onClick={updateGroupnotice}>
              수정완료
            </Button>
          </Stack>
        </Stack>
        {validation && (
          <Typography
            variant="body2"
            alignSelf="flex-end"
            color="#FF1744"
            display="flex"
            alignItems="center"
          >
            <ErrorIcon fontSize="10px" sx={{ mr: 0.5 }} />
            {!newTitle
              ? '공지 제목을 입력해주세요'
              : '공지 내용을 입력해주세요'}
          </Typography>
        )}
      </Stack>

      <Divider />
      <Stack p={3} spacing={3}>
        <Stack direction="row" spacing={3}>
          <Stack>
            <Typography>과제 제출 기한</Typography>
            <Stack height={80} justifyContent="center">
              <Stack border="1px solid #bbb" borderRadius="4px" p={1}>
                <DateTimePicker value={newSubmitAt} onChange={setNewSubmitAt} />
              </Stack>
            </Stack>
          </Stack>

          <Stack>
            <Typography>과제 공개 일시</Typography>
            <Stack height={80} direction="row" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
                label="등록 시 바로 공개"
              />
              {!checked && (
                <Stack border="1px solid #bbb" borderRadius="4px" p={1}>
                  <DateTimePicker
                    value={newReleaseAt}
                    onChange={setNewReleaseAt}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Typography>공지 내용을 입력해주세요.</Typography>
        <TextEditor value={newBody} onChange={setNewBody} />
      </Stack>
    </Stack>
  );
};

export const AssignmentItem = ({
  groupnotice,
  groupId,
  getGroupnoticeByGroup,
}) => {
  const [update, setUpdate] = useState(false);

  return !update ? (
    <AssignmentItemRead
      setUpdate={setUpdate}
      groupnotice={groupnotice}
      getGroupnoticeByGroup={getGroupnoticeByGroup}
    />
  ) : (
    <AssignmentItemUpdate
      setUpdate={setUpdate}
      groupId={groupId}
      groupnotice={groupnotice}
      getGroupnoticeByGroup={getGroupnoticeByGroup}
    />
  );
};

const StyledContentWrap = styled.div`
  font-size: 14px;

  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
`;
