import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  memo,
  useMemo,
} from 'react';
import BaseCard from '../Card/baseCard';
import styles from './StudentScreenList.module.scss';
import DesktopWindowsIcon from '@mui/icons-material/DesktopWindows';
import { ButtonBase, Tooltip } from '@mui/material';
import DiscordImg from '@images/appIcons/discord.png';
import { profileImgPath } from '@api';
import { useDispatch, useSelector } from 'react-redux';
import {
  getGroupActivityPromise,
  getSubgroupActivityPromise,
  setSocketData,
  updateClassroomPromise,
  updateSubgroupActivityStudent,
  setScreenSortOption,
} from '@app/store/actions';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useDrag, useDrop } from 'react-dnd';
import update from 'immutability-helper';
import ProfileImage from '../ProfileImage';
import OpenWithIcon from '@mui/icons-material/OpenWith';
import {
  selectGroupActivity,
  selectStudentsWithGroupActivity,
  selectSubroupActivity,
} from '@app/store/selectors';
import { GROUP_ACTIVITY_INDEX } from '@app/Constants/localStorage';
import { StudentScreenListHeader } from './StudentScreenListHeader';

// const Wrapper = styled.div`
//   padding: 16px;
//   flex: 1;
// `;

const ItemTypes = {
  CARD: 'card',
};

const Card = ({
  item,
  currentStudent,
  setCurrentStudent,
  activeClassroom,
  isOnline,
  setSelectedStd,
  selectedStd,
  images,
  imageKey,
  setBigURI,
  showApplication,
  id,
  index,
  moveCard,
  dispatch,
  searchValue,
  user,
}) => {
  const ref = useRef(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(elem, monitor) {
      if (!ref.current) return;
      const dragIndex = elem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) return;

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) return;
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) return;
      moveCard(dragIndex, hoverIndex);
      elem.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => ({ id, index }),
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  const opacity = isDragging ? 0 : 1;

  return (
    <button
      ref={ref}
      data-handler-id={handlerId}
      key={item.clientId}
      className={styles['student-screen']}
      type="button"
      style={{
        border: `solid 1px ${
          item.clientId === currentStudent ? '#40C3D1' : 'transparent'
        }`,
        opacity,
        display: item.userName.includes(searchValue) ? 'initial' : 'none',
      }}
      onClick={() => {
        setCurrentStudent(item.clientId);
      }}
    >
      <div className={styles['student-screen-box']}>
        <OpenWithIcon
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            display: isDragging ? 'initial' : 'none',
          }}
        />
        {activeClassroom &&
          (isOnline ? (
            <ButtonBase
              onClick={() => {
                setSelectedStd(item.clientId);
                dispatch(
                  updateClassroomPromise({
                    classroomId: activeClassroom?.classroomId,
                    screenShared: activeClassroom?.screenShared,
                    sharedClientId: item.clientId,
                  })
                );
                dispatch(
                  setSocketData({
                    method: 'POST',
                    uri: '/classroom/sendImage',
                    type: 'SELECT_STUDENT',
                    clientId: user.clientId,
                    groupId: activeClassroom?.groupId,
                    data: item.clientId,
                  })
                );
              }}
            >
              <img
                src={`data:image/webp;base64,${images[imageKey]}`}
                alt={imageKey}
                onClick={() => setBigURI(images[imageKey])}
              />
            </ButtonBase>
          ) : showApplication ? (
            <div className={styles['student-application']}>
              <div className={styles['student-application-header']}>
                <img src={DiscordImg} width={24} />
                Discord
              </div>
              <div className={styles['student-application-description']}>
                한글 설명 3줄 한글 설명 3줄 한글 설명 3줄 한글 설명 3줄 한글
                설명 3줄 한글 설명 3줄 한글 설명 3줄 한글 설명 3줄
              </div>
            </div>
          ) : (
            <div>
              <div className={styles['screenEmptyBox']} />
            </div>
          ))}
      </div>
      {/* {activeClassroom && isOnline && isScreenShared && ( */}
      {selectedStd === item.clientId && (
        <div className={styles['online-mark']}>화면 공유 중</div>
      )}
      <div
        className={styles['student-statusbar']}
        style={isOnline ? { background: '#056B75', opacity: 0.8 } : {}}
      >
        {/* <div className={styles[isOnline ? "status-circle" : "status-circle-gray"]} /> */}
        <div className={styles['student-statusbar-left']}>
          <ProfileImage size={28} url={profileImgPath(item.clientId)} />
          <Tooltip title={item.userName} placement="top" arrow>
            <span className={styles['text']}>
              {item.studentNumber} {item.userName}
            </span>
          </Tooltip>
          {/* <img src={imgHomeIcon} alt="home" width={18} /> */}
          {/* <span className={styles["text"]}>
                  {studentGrade}
                  {studentClass <= 10 ? "0" + studentClass : studentClass}
                  {item.student_number}
                </span> */}
        </div>
        {/* <InfoIcon
          style={{
            fill: '#ffffff',
            width: 20,
            height: 20,
          }}
        /> */}
      </div>
    </button>
  );
};

const LightDesk = ({
  activeClassroom,
  setSelectedStd,
  persons,
  images,
  currentStudent,
  setCurrentStudent,
  setBigURI,
  showApplication,
  label,
  group,
  saveCurrentPeople,
  dispatch,
  searchValue,
  user,
}) => {
  const [people, setPeople] = useState(persons);

  const moveCard = useCallback(
    (dragIndex, hoverIndex) => {
      const updateList = update(people, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, people[dragIndex]],
        ],
      });
      setPeople(updateList);
      saveCurrentPeople(group.subgroupId, updateList);
    },
    [group.subgroupId, people, saveCurrentPeople]
  );

  useEffect(() => {
    setPeople(persons);
  }, [persons]);

  return (
    <>
      <div className={styles['desk-band-name']}>{label}</div>
      <div className={styles['light-grid']}>
        {persons.length > 0 &&
          group.students.map((item, idx) => {
            const imageKey =
              images !== undefined &&
              images !== null &&
              Object.keys(images).find((key) => key === item.clientId);
            const isOnline = imageKey !== undefined && imageKey !== false;

            return (
              <Card
                item={item}
                currentStudent={currentStudent}
                setCurrentStudent={setCurrentStudent}
                activeClassroom={activeClassroom}
                isOnline={isOnline}
                setSelectedStd={setSelectedStd}
                images={images}
                imageKey={imageKey}
                setBigURI={setBigURI}
                showApplication={showApplication}
                key={item.clientId}
                index={idx}
                id={item.clientId}
                moveCard={moveCard}
                dispatch={dispatch}
                searchValue={searchValue}
                user={user}
              />
            );
          })}
      </div>
    </>
  );
};

const Desk = ({
  activeClassroom,
  setSelectedStd,
  selectedStd,
  _students,
  _setStudents,
  images,
  currentStudent,
  setCurrentStudent,
  setBigURI,
  showApplication,
  dispatch,
  user,
  searchValue,
  onShareTeacherScreen,
}) => {
  const moveCard = useCallback((dragIndex, hoverIndex) => {
    _setStudents((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      })
    );
  }, []);

  return (
    <div className={styles['desk-container']}>
      <button
        className={styles['student-screen']}
        onClick={onShareTeacherScreen}
      >
        <Tooltip title="학생들에게 공유하실 화면을 선택할 수 있습니다." arrow>
          <div
            style={{
              width: '100%',
              height: '100%',
              top: 0,
              right: 0,
              bottom: 0,
              left: 0,
              background: '#00000060',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <div style={{ textAlign: 'center' }}>
              <DesktopWindowsIcon
                fontSize="large"
                style={{
                  color: '#FFF176',
                  width: 48,
                  height: 48,
                }}
              />
              <div className={styles['teacher-text']}>선생님 화면</div>
            </div>
          </div>
        </Tooltip>
      </button>
      {_students.map((item, idx) => {
        const imageKey =
          images !== undefined &&
          images !== null &&
          Object.keys(images).find((key) => key === item.clientId);
        const isOnline = imageKey !== undefined && imageKey !== false;
        return (
          <Card
            item={item}
            currentStudent={currentStudent}
            setCurrentStudent={setCurrentStudent}
            activeClassroom={activeClassroom}
            isOnline={isOnline}
            setSelectedStd={setSelectedStd}
            selectedStd={selectedStd}
            images={images}
            imageKey={imageKey}
            setBigURI={setBigURI}
            showApplication={showApplication}
            key={item.clientId}
            index={idx}
            id={item.clientId}
            moveCard={moveCard}
            dispatch={dispatch}
            searchValue={searchValue}
            user={user}
          />
        );
      })}
    </div>
  );
};

const StudentScreenList = ({
  students,
  images,
  activeClassroom,
  studentClass,
  onceClicked,
  stopCapture,
  streamScreen,
  setOnceClicked,
  setSelectedStd,
  selectedStd,
  setBigURI,
  studentGrade,
  gridState,
  setGridState,
}) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  const activityList = useSelector(selectGroupActivity);
  const subgroupList = useSelector(selectSubroupActivity);
  const studentList = useSelector((state) =>
    selectStudentsWithGroupActivity(state, activeClassroom.groupId)
  );
  const { screenSortOption } = useSelector((state) => state.classrooms);
  const [searchValue, setSearchValue] = useState('');
  const [currentStudent, setCurrentStudent] = useState();
  const [selectedActivity, setSelectedAcitivity] = useState();

  const subgroupListWithNoGroup = useMemo(() => {
    const noGroupStudent = studentList.filter((student) => !student.subgroupId);
    if (noGroupStudent.length) {
      return [
        ...subgroupList,
        {
          name: '미그룹',
          students: noGroupStudent,
        },
      ];
    }
    return subgroupList;
  }, [subgroupList, studentList]);

  // TODO: to be removed
  const showApplication = false;

  const [_students, _setStudents] = useState(
    students
      .filter((student) => student.userName.includes(searchValue))
      .sort(function(a, b) {
        return a.userName < b.userName ? -1 : a.userName > b.userName ? 1 : 0;
      })
  );

  const filteredStudents = (students) => {
    return students
      .filter((student) => student.userName.includes(searchValue))
      .sort(function(a, b) {
        return a.userName < b.userName ? -1 : a.userName > b.userName ? 1 : 0;
      });
  };

  useEffect(() => {
    const filteredStudentsData = filteredStudents(students);
    _setStudents(filteredStudentsData);
  }, [students]);

  const sortFunctions = useMemo(
    () => [
      (a, b) =>
        Number(a.studentNumber) < Number(b.studentNumber)
          ? -1
          : Number(a.studentId) > Number(b.studentId)
          ? 1
          : 0, // 모둠 순에서도 모둠 내부의 정렬은 학번 순
      (a, b) => {
        return Number(a.studentNumber) < Number(b.studentNumber)
          ? -1
          : Number(a.studentNumber) > Number(b.studentNumber)
          ? 1
          : 0;
      },
      (a, b) =>
        a.userName < b.userName ? -1 : a.userName > b.userName ? 1 : 0,
    ],
    []
  );

  const onSortChange = useCallback(
    (value) => {
      value
        ? _setStudents(
            students
              .filter((student) => student.userName.includes(searchValue))
              .sort(sortFunctions[value])
          )
        : _setStudents(
            students.filter((student) => student.userName.includes(searchValue))
          );
      dispatch(setScreenSortOption(value));
    },
    [dispatch, searchValue, sortFunctions, students]
  );
  const onShareTeacherScreen = useCallback(() => {
    setSelectedStd(user.clientId);
    dispatch(
      setSocketData({
        method: 'POST',
        uri: '/classroom/sendImage',
        type: 'SELECT_STUDENT',
        clientId: user.clientId,
        groupId: activeClassroom?.groupId,
        data: user.clientId,
      })
    );
    if (activeClassroom && onceClicked) {
      try {
        const videoElem = document.getElementById('video');
        stopCapture(videoElem, activeClassroom?.screenShared);
      } catch (err) {
        console.log(err);
      }
      streamScreen();
    }
    setOnceClicked(true);
  }, [
    activeClassroom,
    dispatch,
    onceClicked,
    setOnceClicked,
    setSelectedStd,
    stopCapture,
    streamScreen,
    user.clientId,
  ]);
  const onSortGroupStudents = useCallback(
    (subgroupId, studentNumbers) => {
      const _subgroupList = subgroupList.map((group) => {
        if (group.subgroupId !== subgroupId) {
          return group;
        }
        return {
          ...group,
          students: studentNumbers.map((studentNumber) =>
            group.students.find(
              (student) => student.studentNumber === studentNumber
            )
          ),
        };
      });
      dispatch(updateSubgroupActivityStudent(_subgroupList));
    },
    [dispatch, subgroupList]
  );

  useEffect(() => {
    if (activityList.length && !selectedActivity) {
      const subgroupActivityId = localStorage.getItem(GROUP_ACTIVITY_INDEX);
      if (subgroupActivityId) {
        const findIndex = activityList.findIndex(
          (item) => item.subgroupActivityId === subgroupActivityId
        );
        setSelectedAcitivity(activityList[findIndex !== -1 ? findIndex : 0]);
      } else {
        setSelectedAcitivity(activityList[0]);
      }
    }
  }, [activityList, selectedActivity]);

  useEffect(() => {
    if (activeClassroom.groupId && screenSortOption === 3) {
      dispatch(getGroupActivityPromise(activeClassroom.groupId));
    }
  }, [activeClassroom.groupId, screenSortOption, dispatch]);
  useEffect(() => {
    if (selectedActivity) {
      dispatch(getSubgroupActivityPromise(selectedActivity.subgroupActivityId));
      localStorage.setItem(
        GROUP_ACTIVITY_INDEX,
        selectedActivity.subgroupActivityId
      );
    }
  }, [dispatch, selectedActivity]);

  return (
    <BaseCard radius={8}>
      <div
        className={
          styles[
            `${gridState === 'ONE_COLUMN' ? 'wrapper-one-column' : 'wrapper'}`
          ]
        }
      >
        <StudentScreenListHeader
          activityList={activityList}
          selectedActivity={selectedActivity}
          screenSortOption={screenSortOption}
          searchValue={searchValue}
          gridState={gridState}
          setGridState={setGridState}
          setSelectedAcitivity={setSelectedAcitivity}
          resetSort={() => onSortChange(1)}
          onSortChange={onSortChange}
          onSearch={setSearchValue}
          onShareTeacherScreen={onShareTeacherScreen}
        />
        <DndProvider backend={HTML5Backend}>
          {screenSortOption === 3 ? (
            <div className={styles['desk-container-flex']}>
              {subgroupListWithNoGroup.map((group, index) => (
                <div key={index} className={styles['desk-band']}>
                  <LightDesk
                    activeClassroom={activeClassroom}
                    onceClicked={onceClicked}
                    stopCapture={stopCapture}
                    streamScreen={streamScreen}
                    setOnceClicked={setOnceClicked}
                    setSelectedStd={setSelectedStd}
                    label={group.name}
                    persons={group.students.map(
                      (student) => student.studentNumber
                    )}
                    user={user}
                    images={images}
                    currentStudent={currentStudent}
                    setCurrentStudent={setCurrentStudent}
                    setBigURI={setBigURI}
                    showApplication={showApplication}
                    group={group}
                    dispatch={dispatch}
                    searchValue={searchValue}
                    saveCurrentPeople={onSortGroupStudents}
                  />
                </div>
              ))}
            </div>
          ) : (
            <Desk
              activeClassroom={activeClassroom}
              setSelectedStd={setSelectedStd}
              selectedStd={selectedStd}
              _students={_students}
              _setStudents={_setStudents}
              images={images}
              currentStudent={currentStudent}
              setCurrentStudent={setCurrentStudent}
              setBigURI={setBigURI}
              showApplication={showApplication}
              dispatch={dispatch}
              user={user}
              searchValue={searchValue}
              onShareTeacherScreen={onShareTeacherScreen}
            />
          )}
        </DndProvider>
      </div>
    </BaseCard>
  );
};

export default memo(StudentScreenList);
