import { call, put, takeEvery, takeLeading } from 'redux-saga/effects';
import {
  getMembershipPromise,
  getMembershipSuccess,
  getMembershipFail,
  getMembershipByClientPromise,
  getMembershipByClientSuccess,
  getMembershipByClientFail,
  getMembershipsByGroupPromise,
  getMembershipsByGroupSuccess,
  getMembershipsByGroupFail,
  createMembershipPromise,
  createMembershipSuccess,
  createMembershipFail,
  getGroupsByMembershipSuccess,
  getClientsByMembershipSuccess,
  deleteMembershipFail,
  deleteMembershipPromise,

  // deleteClientSucceess,
} from '@store/actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';
import { deleteClientSuccess, deleteMembershipSuccess } from '../actions';

function* getMembershipPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getMembership, action.payload);
      yield put(getMembershipSuccess(response.data));
    } catch (error) {
      yield put(getMembershipFail(error));
    }
  });
}

function* getMembershipByClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getMembershipByClient, action.payload);
      yield put(getGroupsByMembershipSuccess(response.data.groupMemberships));
      yield put(getMembershipByClientSuccess(response.data.groupMemberships));
    } catch (error) {
      yield put(getMembershipByClientFail(error));
    }
  });
}

function* getMembershipsByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getMembershipsByGroup, action.payload);
      yield put(getClientsByMembershipSuccess(response.data.clientMemberships));
      yield put(getMembershipsByGroupSuccess(response.data.clientMemberships));
      return yield response.data.clientMemberships;
    } catch (error) {
      yield put(getMembershipsByGroupFail(error));
    }
  });
}

function* createMembershipPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.createMembership, action.payload);
      yield put(createMembershipSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(createMembershipFail(error));
    }
  });
}

function* deleteMembershipPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(
        api.deleteMembership,
        action.payload.groupId,
        action.payload.clientId
      );
      yield put(deleteMembershipSuccess(action.payload.clientId));
      yield put(deleteClientSuccess(action.payload.clientId));
      return;
    } catch (error) {
      yield put(deleteMembershipFail(error));
    }
  });
}

function* membershipSaga() {
  yield takeLeading(getMembershipPromise, getMembershipPromiseHandler);
  yield takeLeading(getMembershipByClientPromise, getMembershipByClientPromiseHandler);
  yield takeEvery(getMembershipsByGroupPromise, getMembershipsByGroupPromiseHandler);
  yield takeLeading(createMembershipPromise, createMembershipPromiseHandler);
  yield takeLeading(deleteMembershipPromise, deleteMembershipPromiseHandler);
}

export default membershipSaga;
