import React from 'react';
import GroupManager from '@managers/GroupManager';
import ClassManager from '@managers/ClassManager';
import AuthManager from '@managers/AuthManager';
import ConnectionManager from '@managers/ConnectionManager';
// import ReportManager from './ReportManager';
import ClassroomManager from './ClassroomManager';
import RefreshManager from './RefreshManager';

const MasterManager = () => {
  return (
    <AuthManager>
      {/* <ReportManager /> */}
      <RefreshManager />
      <GroupManager />
      <ClassManager />
      <ClassroomManager />
      <ConnectionManager />
    </AuthManager>
  );
};

export default MasterManager;
