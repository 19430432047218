import { createSelector } from 'reselect';

export const selectAllManaged = createSelector(
  (state) => state.allIds,
  (state) => state.byId,
  (allIds, byId) => {
    if (allIds.length === 0) {
      return [];
    }
    return allIds?.map((managed) => byId[managed]);
  }
);

export const selectManagedByManaging = createSelector(
  (state) => state.managed,
  (state, managingId) => managingId,
  (state, managingId) => {
    return selectAllManaged(state).filter((managed) => {
      return managed.managingId === managingId;
    });
  }
);

// export const selectManagedByGroup = createSelector(
//   (state) => state.managed,
//   (state, groupId) => groupId,
//   (state) => state,
//   (stateManaged, groupId, state) => {
//     const manageds = selectAllManaged(stateManaged).filter(
//       (managed) => managed.managedData.managing.groupId === groupId
//     );
//     console.log('manageds', manageds);
//     return manageds;
//   }
// );

// export const selectStudentsByGroup = createSelector(
//   (state) => state.managed,
//   (state, groupId) => groupId,
//   (state) => state.client,
//   (stateManageds, groupId, stateClient) => {
//     const memberships = selectAllManaged(stateManageds).filter(
//       (managed) =>
//         managed.role === 'STUDENT' && managed.groupId === groupId
//     );
//     const clientIds = new Set(memberships.map((x) => x.clientId));
//     return Object.values(stateClient.byId).filter((x) =>
//       clientIds.has(x.clientId)
//     );
//   }
// );

export const selectAllManageds = (state) => {
  if (state.managed.allIds.length === 0) {
    return [];
  }

  return state.managed.allIds.map((managed) => state.managed.byId[managed]);
};
