import React from 'react';
import styled from 'styled-components';

const ErrorMessageWrapper = styled.div`
  font-family: 'Noto Sans KR', sans-serif;
  font-style: normal;
  font-size: 12px;
  color: #f44336;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.25px;
`;
const ErrorMessage = ({ message }) => {
  return <ErrorMessageWrapper>{message}</ErrorMessageWrapper>;
};

export default ErrorMessage;
