import { useState, useEffect, useCallback } from 'react';
import {
  Stack,
  Typography,
  Divider,
  Button,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import { Modal } from 'react-bootstrap';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { selectGroupById } from '@store/selectors';
import { Error as ErrorIcon } from '@mui/icons-material';
import DatePicker from 'react-datepicker';
import { format, isAfter } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { TextEditor } from '@app/components/Input/TextEditor';
import { DateTimePicker } from '@app/components/Input/DateTimePicker';
import styled from 'styled-components';

const NoticeItemDelete = ({
  show,
  setShow,
  groupnoticeId,
  getGroupnoticeByGroup,
}) => {
  const deleteGroupnotice = useCallback(async () => {
    try {
      // 공지 삭제
      await axios.delete(
        `https://ktor.focuspang.com/v2/groupnotice/${groupnoticeId}`
      );
      // 모달 닫기
      setShow(false);
      getGroupnoticeByGroup();
    } catch (error) {
      console.log(error);
    }
  }, []);

  return (
    <Modal show={show} onHide={() => setShow(false)} centered>
      <Stack spacing={3} pt={7} pb={1}>
        <Stack alignItems="center">
          <Typography>공지를 정말 삭제하시겠어요?</Typography>
        </Stack>
        <Stack direction="row" spacing={1} justifyContent="flex-end">
          <Button
            style={{ border: '1px solid #e0e0e0' }}
            onClick={() => setShow(false)}
          >
            취소
          </Button>
          <Button
            style={{ border: '1px solid #e0e0e0', marginRight: '16px' }}
            color="_red"
            onClick={deleteGroupnotice}
          >
            삭제
          </Button>
        </Stack>
      </Stack>
    </Modal>
  );
};

const NoticeItemRead = ({ setUpdate, groupnotice, getGroupnoticeByGroup }) => {
  const user = useSelector((state) => state.user);
  const { userName } = { ...user };

  const {
    groupnoticeId,
    noticeType,
    title,
    body,
    submitAt,
    groupId,
    releaseAt,
  } = groupnotice;
  const { grade, groupName, subjectName } = useSelector((state) =>
    selectGroupById(state, groupId)
  );
  const type = noticeType === 'NOTICE' ? '일반 공지' : '과제 공지';

  const [show, setShow] = useState(false);

  return (
    <>
      <Stack border="1px solid #E0E0E0" borderRadius="4px" p={3} spacing={3}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack direction="row" spacing={1} alignItems="center">
            <Typography
              variant="caption"
              bgcolor="#F5F5F5"
              px={1}
              py={0.5}
              borderRadius="4px"
              color="#00000099"
            >
              {type}
            </Typography>
            <Typography>{title}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Tooltip
              title="공지의 해당 내용을 수정이 가능하며 수정된 사항을 학생들에게 다시 공지가 가능합니다."
              arrow
            >
              <Button
                style={{ border: '1px solid #e0e0e0' }}
                onClick={() => setUpdate(true)}
              >
                수정
              </Button>
            </Tooltip>
            <Tooltip
              title="선택한 공지를 리스트에서 삭제할 수 있으며 삭제된 공지는 학생들에게도 사라집니다."
              arrow
            >
              <Button
                style={{ border: '1px solid #e0e0e0' }}
                color="_red"
                onClick={() => setShow(true)}
              >
                삭제
              </Button>
            </Tooltip>
          </Stack>
        </Stack>

        <Stack direction="row" flexWrap="wrap" alignItems="center">
          <Stack direction="row" alignItems="center" spacing={1} mr={2}>
            <Typography
              variant="caption"
              bgcolor="#F5F5F5"
              px={1}
              py={0.5}
              borderRadius="4px"
              color="#00000099"
            >
              {subjectName}
            </Typography>
            <Typography color="#00000099" variant="body2">
              {format(new Date(releaseAt), 'yyyy-MM-dd HH:mm')}
            </Typography>
            <Typography variant="body2">{`${grade}학년 ${groupName}반`}</Typography>
            <Typography color="green" variant="body2">
              {isAfter(new Date(), new Date(releaseAt)) ? '공개' : '비공개'}
            </Typography>
          </Stack>
          <Typography color="#00000099" variant="body2">
            &#183; {userName} 선생님 공지
          </Typography>
        </Stack>

        <Divider />

        {noticeType === 'SUBMISSION' && (
          <Stack direction="row">
            <Typography
              variant="body2"
              bgcolor="#F5F5F5"
              px={1}
              py={0.5}
              borderRadius="4px"
            >
              {`${grade}학년 ${groupName}반 제출 기한 : ${submitAt &&
                format(new Date(submitAt), 'yyyy-MM-dd HH:mm')}`}
            </Typography>
          </Stack>
        )}
        <StyledContentWrap>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </StyledContentWrap>
      </Stack>
      <NoticeItemDelete
        show={show}
        setShow={setShow}
        groupnoticeId={groupnoticeId}
        getGroupnoticeByGroup={getGroupnoticeByGroup}
      />
    </>
  );
};

const NoticeItemUpdate = ({
  setUpdate,
  groupnotice,
  getGroupnoticeByGroup,
}) => {
  const {
    groupnoticeId,
    title,
    body,
    submitAt,
    releaseAt,
    noticeType,
    groupId,
  } = groupnotice;

  const type = noticeType === 'NOTICE' ? '일반 공지' : '과제 공지';

  const [newTitle, setNewTitle] = useState(title);
  const [newBody, setNewBody] = useState(body);
  const [newSubmitAt, setNewSubmitAt] = useState(new Date(submitAt));
  const [newReleaseAt, setNewReleaseAt] = useState(new Date(releaseAt));
  const [checked, setChecked] = useState(false);
  const [validation, setValidation] = useState(null);

  useEffect(() => {
    if (newTitle && newBody) setValidation(false);
  }, [newTitle, newBody]);

  useEffect(() => {
    if (checked) setNewReleaseAt(new Date());
  }, [checked]);

  const updateGroupnotice = useCallback(async () => {
    if (!newTitle || !newBody) return setValidation(true);
    try {
      // 공지 수정
      const response = await axios.patch(
        'https://ktor.focuspang.com/v2/groupnotice',
        {
          groupnoticeId,
          title: newTitle,
          body: newBody,
          submitAt: newSubmitAt,
          releaseAt: newReleaseAt,
        }
      );
      console.log(response);
      // 수정 완료
      getGroupnoticeByGroup();
      setUpdate(false);
    } catch (error) {
      console.log(error);
    }
  }, [newTitle, newBody, newSubmitAt, newReleaseAt]);

  return (
    <Stack border="1px solid #E0E0E0" borderRadius="4px">
      <Stack p={3} spacing={3}>
        <Stack direction="row" alignItems="center" spacing={3}>
          <Stack direction="row" spacing={3} alignItems="center" flex={1}>
            <Typography>{type}</Typography>
            <Stack flex={1}>
              <TextField
                variant="standard"
                size="small"
                fullWidth
                value={newTitle}
                onChange={(e) => setNewTitle(e.target.value)}
              />
            </Stack>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Tooltip title="수정을 취소합니다." arrow>
              <Button onClick={() => setUpdate(false)}>취소</Button>
            </Tooltip>
            <Tooltip
              title="수정사항을 저장하며 수정사항이 공지에 반영이 되어 학생이 수정된 내용을 확인이 가능합니다."
              arrow
            >
              <Button variant="contained" onClick={updateGroupnotice}>
                수정완료
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        {validation && (
          <Typography
            variant="body2"
            alignSelf="flex-end"
            color="#FF1744"
            display="flex"
            alignItems="center"
          >
            <ErrorIcon fontSize="10px" sx={{ mr: 0.5 }} />
            {!newTitle
              ? '공지 제목을 입력해주세요'
              : '공지 내용을 입력해주세요'}
          </Typography>
        )}
      </Stack>

      <Divider />
      <Stack p={3} spacing={3}>
        <Stack direction="row" spacing={3}>
          {noticeType === 'SUBMISSION' && (
            <Stack>
              <Typography>과제 제출 기한</Typography>
              <Stack height={80} justifyContent="center">
                <Stack border="1px solid #bbb" borderRadius="4px" p={1}>
                  <DateTimePicker
                    value={newSubmitAt}
                    onChange={setNewSubmitAt}
                  />
                </Stack>
              </Stack>
            </Stack>
          )}
          <Stack>
            <Typography>
              {noticeType === 'NOTICE' ? '공지 공개 일시' : '과제 공개 일시'}
            </Typography>
            <Stack height={80} direction="row" alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    onChange={(e) => setChecked(e.target.checked)}
                  />
                }
                label="등록 시 바로 공개"
              />
              {!checked && (
                <Stack border="1px solid #bbb" borderRadius="4px" p={1}>
                  <DateTimePicker
                    value={newReleaseAt}
                    onChange={setNewReleaseAt}
                  />
                </Stack>
              )}
            </Stack>
          </Stack>
        </Stack>

        <Typography>공지 내용을 입력해주세요.</Typography>
        <TextEditor value={newBody} onChange={setNewBody} />
      </Stack>
    </Stack>
  );
};

export const NoticeItem = ({ groupnotice, getGroupnoticeByGroup }) => {
  const [update, setUpdate] = useState(false);

  return !update ? (
    <NoticeItemRead
      setUpdate={setUpdate}
      groupnotice={groupnotice}
      getGroupnoticeByGroup={getGroupnoticeByGroup}
    />
  ) : (
    <NoticeItemUpdate
      setUpdate={setUpdate}
      groupnotice={groupnotice}
      getGroupnoticeByGroup={getGroupnoticeByGroup}
    />
  );
};

const StyledContentWrap = styled.div`
  font-size: 14px;

  ol {
    list-style: decimal;
  }
  ul {
    list-style: disc;
  }
`;
