import styled from 'styled-components'
import { Heading6 } from '@app/pages/QuizPang/common'

export const QuizChoice = (
  { index, description, active = false, onClick = () => {} }
) => {
  return <ChoiceContainer
    className={active ? 'active' : ''}
    onClick={() => onClick()}
    style={{wordWrap: 'break-word', whiteSpace: 'pre-wrap'}}
  >
    <IndexBox>{index}</IndexBox>
    <Heading6
      style={active ? { color: '#056B75', width: 475, marginLeft: 10 } 
      : {width: 475, marginLeft: 10}}>
        {description}</Heading6>
  </ChoiceContainer>
}

const ChoiceContainer = styled.div`
  border: 2px solid #E0E0E0;
  border-radius: 8px;
  width: 540px;
  min-height: 52px;
  padding: 8px;
  margin-bottom: 8px;

  text-align: center;
  display: flex;


  :hover {
    cursor: pointer;
    border: 2px solid #BDBDBD;
    background-color: #F5F5F5;
  }

  &.active {
    border: 2px solid #0894A0;
    background-color: #E4F6F8;
  }
`

const IndexBox = styled(Heading6)`

  width: 32px;
  height: 32px;
  left: 8px;
  text-align: center;
  border: 1px solid #E0E0E0;
  background-color: white;
  border-radius: 4px;
`
