import React, { memo, useState } from 'react';
import { useSelector } from 'react-redux';
import styles from './index.module.scss';
import {
  selectManagingGroup,
  selectActiveManaging,
} from '@app/store/selectors';
import { useLocation } from 'react-router-dom';
import ManagingControl from '@app/components/Layout/ManagingControl';
import ManagingCreate from '@app/components/Layout/ManagingCreate';
import ManagingSidebar from '@app/components/Layout/ManagingSidebar';
import ManagingClassList from '@app/components/Layout/ManagingClassList';
import ModalAddManage from '@app/components/Modal/ModalAddManage';

const Managing = () => {
  const managingSchoolGroup = useSelector((state) =>
    selectManagingGroup(state)
  );
  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const group = useSelector((state) => selectManagingGroup(state))
  const [modalShow, setModalShow] = useState(false);

  const location = useLocation();
  const inManageRoom = location.pathname !== '/managing';

  const [isUpdateModal, setIsUpdateModal] = useState(false);
  const [managingGroupForUpdate, setManagingGroupForUpdate] = useState();

  // useEffect(() => {
  //   if (
  //     selectManagingIdByGroups !== undefined ||
  //     selectManagingIdByGroups !== '' ||
  //     selectManagingIdByGroups !== null
  //   ) {
  //     return;
  //   }
  //   selectManagingIdByGroups.forEach((managingId) => {
  //     dispatch(getGroupProgramconfigsPromise(managingId));
  //   });
  // }, [selectManagingIdByGroups]);

  return !managingSchoolGroup[0] ? (
    <div className={styles['containerNull']}>
      <ManagingSidebar auth={true} />
      <ManagingCreate />
    </div>
  ) : (
    <div className={styles['container']}>
      <ManagingSidebar
        auth={true}
        inClassroom={inManageRoom}
        inClass={!!activeManaging}
        group={group}
      />
      {inManageRoom ? (
        <ManagingControl />
      ) : (
        <ManagingClassList
          setModalShow={setModalShow}
          setIsUpdateModal={setIsUpdateModal}
          setManagingGroupForUpdate={setManagingGroupForUpdate}
        />
      )}
      <ModalAddManage
        isUpdateModal={isUpdateModal}
        managingGroupForUpdate={managingGroupForUpdate}
        setManagingGroupForUpdate={setManagingGroupForUpdate}
        show={modalShow}
        onHide={() => setModalShow(false)}
      />
    </div>
  );
};

export default memo(Managing);
