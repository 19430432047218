import React, { useEffect, useMemo, useRef, useState } from 'react';
import styles from './ManagedStudentList.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { createInviteCodePromise } from '@store/actions';
import { selectStudentsByGroup } from '@store/selectors';
import ModalAddStudent from '@components/Modal/ModalAddStudent';
import ModalStudentUpdate from '@components/Modal/ModalStudentUpdate';
import ModalConfirm from '@components/Modal/ModalConfirm';
import ProfileImage from '../ProfileImage';
import moment from 'moment';
import CloseIcon from '@mui/icons-material/Close';
import PersonIcon from '@mui/icons-material/Person';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import LateIcon from '@assets/images/icons/late.png';
import Pencil from '@assets/images/icons/pencil.png';
import studentListEmpty from '@assets/images/studentList/studentListEmpty.png';
import ManagedStudentRow from './ManagedStudentRow';
import {
  selectActiveManaging,
  selectManagedByManaging,
} from '@app/store/selectors';

const ManagedStudentList = ({ groupId, students }) => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);
  // const students = useSelector((state) =>
  //   selectStudentsByGroup(state, groupId)
  // );

  const [modalShow, setModalShow] = useState(false);
  const membership = useSelector((state) => state.membership);
  const activeManaging = useSelector((state) => selectActiveManaging(state));
  const _manageds = useSelector((state) =>
    selectManagedByManaging(state, activeManaging?.managingId)
  );

  const filteredManageds = useMemo(() => {
    //조건식 설명: 상태가 "ABSENT"이고, 선생님 본인은 제외 >> 그러면 상태가 "ATTEND"이고 학생인 계정만 필터됨
    return (
      _manageds?.filter(
        (managed) =>
          managed.state !== 'ABSENT' && managed.clientId !== user.clientId
      ) || []
    );
  }, [_manageds, user.clientId]);

  students?.sort((a, b) => {
    if (a.studentNumber > b.studentNumber) {
      return 1;
    } else if (a.studentNumber < b.studentNumber) {
      return -1;
    } else {
      return 0;
    }
  });

  return (
    <div className={styles['wrapper']}>
      <div className={styles['title-container']}>
        <div className={styles['title-container-text']}>
          학생 명단 (총원 {students.length}명)
        </div>
      </div>
      <div className={styles['header']}>
        <div className={styles['student-number']}>학번</div>
        <div className={styles['student-name']}>이름</div>
        <div className={styles['student-attendTime']}>출석시간</div>
        <div className={styles['student-edit']}>학생 정보 수정</div>
      </div>

      <div className={styles['student-list']}>
        {students.map((student, idx) => {
          return (
            <ManagedStudentRow
              key={student.clientId}
              attendTime={
                filteredManageds?.find((managed) => {
                  return managed.clientId === student.clientId;
                })?.attendedAt
              }
              student={student}
              groupId={groupId}
              exposeEdit={true}
            />
          );
        })}
        {/* {students.length === 0 && (
          <>
            <div className={styles['studentList-empty']}>
              <div className={styles['studentList-empty-title']}>
                관리그룹에 등록된 학생이 없습니다.
              </div>
              <div className={styles['studentList-empty-subText']}>
                등록을 원하시면 학생 추가하기 버튼을 눌러서 학생에게 관리그룹
                코드를 알려주세요.
              </div>
              <div className={styles['studentList-empty-img']}>
                <img src={studentListEmpty} width={600} />
              </div>
            </div>
          </>
        )} */}
      </div>

      <ModalAddStudent
        show={modalShow}
        onHide={() => setModalShow(false)}
        groupId={groupId}
        setModalShow={setModalShow}
      />
    </div>
  );
};
export default ManagedStudentList;
