import {
  BottomContentLayer,
  HorizontalSpace8,
  MiddleMenuBar,
  Row,
  SubTitle2,
} from '@app/pages/QuizPang/common'
import { SearchInput } from '@app/pages/QuizPang/search-input'
import React, { useEffect, useRef, useState } from 'react'
import { Select } from '@app/pages/QuizPang/select'
import MenuItem from '@mui/material/MenuItem'
import {
  QuizsetCard,
  QuizsetCardOtherSchoolActions,
} from '@app/pages/QuizPang/quizset-card'
import { useAllSchools } from '@app/pages/QuizPang/hooks'
import { useDispatch, useSelector } from 'react-redux'
import {
  createQuizsetPromise,
  getQuizsetListAllPromise,
  getQuizsetListBySchoolPromise,
  purgeOtherSchoolQuizset,
} from '@app/store/quizset/actions'
import { QuizsetFilterStore } from '@app/pages/QuizPang/quizset-filter-store'
import { selectOtherSchoolQuizsetList } from '@app/store/quizset/selector'
import { observer } from 'mobx-react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

const getCopyableQuizset = (quizset, user) => {
  const { title, grade, quizzes } = quizset
  const { clientId, schoolId } = user
  return {
    clientId,
    schoolId,
    title,
    grade,
    quizzes: JSON.stringify(quizzes.map(x => ({ quizId: x.quizId }))),
  }
}

export const OtherSchoolQuizsetTab = observer(({}) => {
  const [isLoading, setIsLoading] = useState(true);
  const { data: schoolData } = useAllSchools()
  const [schoolId, setSchoolId] = useState(-1)
  const filterStore = useRef(new QuizsetFilterStore())
  const dispatch = useDispatch()
  const history = useHistory()
  const quizsetList = useSelector(selectOtherSchoolQuizsetList)
  const user = useSelector((state) => state.user)
  const [indexCount, setIndexCount] = useState(0);
  const [quizsetTotalCount, setQuizsetTotalCount] = useState(0);
  const schoolId1 = 'dbf66a7d-a26a-4802-a958-de1fbdbf637f'; //포커스팡학교
  const schoolId2 = '0b75db26-f506-4820-9197-ccfe6a419127'; //포커스팡고등학교

  useEffect(() => {
    dispatch(getQuizsetListAllPromise(indexCount))
    .then((res) => {
      console.log('QuizsetListAll');
      setIsLoading(false);
      setQuizsetTotalCount(res.totalCount);
      if(res === undefined) {
        throw new Error()
      }
    }).catch((error) => console.log('[QuizsetListAll]', error));
  }, [indexCount]);

  useEffect(() => {
    if (schoolId === -1) {
      dispatch(purgeOtherSchoolQuizset())
    } else {
      dispatch(getQuizsetListBySchoolPromise(schoolId))
      // dispatch(getQuizsetListBySchoolPromise(schoolId1))
      // .then((res) => console.log('schoolId1', res))
      // dispatch(getQuizsetListBySchoolPromise(schoolId2))
      // .then((res) => console.log('schoolId2', res))
    }
  }, [schoolId])
  
  useEffect(() => {
    filterStore.current.setData(quizsetList)
  }, [quizsetList])

  const onCountPlus = () => {
    setIndexCount(indexCount + 1);
  };

  const onCountMinus = () => {
    if (indexCount > 0) {
      setIndexCount(indexCount - 1);
    }
  };

  return <>
    {/* <MiddleMenuBar>
      <Row>
        <Select width={160} value={schoolId}
                onChange={(e) => setSchoolId(e.target.value)}>
          <MenuItem value={-1}>학교 전체</MenuItem>
          {schoolData && schoolData.schools
            .map(x => {
              return <MenuItem key={x.schoolId}
                               value={x.schoolId}>{x.name}</MenuItem>
            })}
        </Select>
        <HorizontalSpace8/>
        <Select width={120} value={filterStore.current.grade}
                onChange={(e) => filterStore.current.setGrade(e.target.value)}>
          <MenuItem value={-1}>학년 전체</MenuItem>
          {filterStore.current.availableGrades.map(x =>
            <MenuItem key={x} value={x}>{x}학년</MenuItem>,
          )}
        </Select>
      </Row>
      <Row>
        <SearchInput label="퀴즈 찾기" value={filterStore.current.query}
                     onChange={(e) => filterStore.current.setQuery(e.target.value)}/>
      </Row>
    </MiddleMenuBar> */}
    {/* <BottomContentLayer> */}
      {
        isLoading ? <SubTitle2>퀴즈 목록 로딩 중입니다...</SubTitle2> :
        filterStore.current.filtered.length > 0 ?
          filterStore.current.filtered.map(x => <QuizsetCard
            key={x.quizsetId}
            actions={
              <QuizsetCardOtherSchoolActions
                onAdd={() => {
                  if (!window.confirm('이 퀴즈를 퀴즈목록(관리 홈)에 추가할까요?')) return
                  dispatch(createQuizsetPromise(getCopyableQuizset(x, user))).then((res) => {
                    if (res) alert('퀴즈가 추가되었습니다!')
                  })
                }}
              />
            }
            data={x}
            // onClickBottomButton={() => {
            //   history.push(`/quiz-pang/quizset-detail/${x.quizsetId}`)
            // }}
            />) :
          <SubTitle2>공유된 퀴즈가 없습니다.</SubTitle2>
      }
      {!isLoading &&
      <MoreContainer>
        {indexCount > 0 ? <MoreButton onClick={onCountMinus}>이전</MoreButton> 
        : <MoreDisabledButton>이전</MoreDisabledButton>}
        <HorizontalSpace8 />
        {`${quizsetTotalCount}개 중 ${indexCount * 20 + 1}-${Math.min((indexCount + 1) * 20, quizsetTotalCount)}`}
        <HorizontalSpace8 />
        {((indexCount + 1) * 20 >= quizsetTotalCount) ? <MoreDisabledButton>다음</MoreDisabledButton> : 
        <MoreButton onClick={onCountPlus}>다음</MoreButton>}
      </MoreContainer>}
    {/* </BottomContentLayer> */}
  </>
})

export const MoreContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: center;
`
export const MoreButton = styled.div`
cursor: pointer;
border-radius: 4px;
background-color: #0BA1AE;
color: #fff;
padding: 4px 10px;
font-size: 14px;

&:hover {
    background-color: #16747cd7;
  }
`
export const MoreDisabledButton = styled.div`
border-radius: 4px;
background-color: #B9F2F9;
color: #fff;
padding: 4px 10px;
font-size: 14px;
`