import { call, put, takeLeading } from 'redux-saga/effects';
import {
  getReportPromise,
  getReportSuccess,
  getReportFail,
  getReportsListByClientPromise,
  getReportsListByClientSuccess,
  getReportsListByClientFail,
  getReportsListByGroupPromise,
  getReportsListByGroupSuccess,
  getReportsListByGroupFail,
} from './actions';
import * as api from '@api';
import { implementPromiseAction } from '@adobe/redux-saga-promise';

function* getReportPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getReport, action.payload);
      yield put(getReportSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getReportFail(error));
    }
  });
}

function* getReportsListByClientPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getReportsListByClient, action.payload);
      yield put(getReportsListByClientSuccess(response.data));
      return yield response.data;
    } catch (error) {
      yield put(getReportsListByClientFail(error));
    }
  });
}

function* getReportsListByGroupPromiseHandler(action) {
  yield call(implementPromiseAction, action, function*() {
    try {
      const response = yield call(api.getReportListByGroup, action.payload);
      yield put(getReportsListByGroupSuccess(response.data.reports));
      return yield response.data;
    } catch (error) {
      yield put(getReportsListByGroupFail(error));
    }
  });
}

function* reportListSaga() {
  yield takeLeading(getReportPromise, getReportPromiseHandler);
  yield takeLeading(
    getReportsListByClientPromise,
    getReportsListByClientPromiseHandler
  );
  yield takeLeading(
    getReportsListByGroupPromise,
    getReportsListByGroupPromiseHandler
  );
}

export default reportListSaga;
