import React, { useEffect, useState } from 'react';
import Sidebar from '@components/Layout/Sidebar';
import { ThemeProvider } from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import ChatBot from 'react-simple-chatbot';
import { selectFilteredSchools, selectAllMyGroups } from '@store/selectors';
import { createGroupPromise, createInviteCodePromise, purgeSchool } from '@store/actions';
import { setToken } from '@api';
import { useHistory, useLocation } from 'react-router-dom';
import Review from '@components/ClassRoom/Review';
import styles from "./add-classroom.module.scss";
import BaseCard from "@components/Card/baseCard";

const AfterShowReview = (inputs) => {
  console.log('[AFTERSCHOOL : ]', inputs);
  const data = {
    classAfterName: inputs.steps.classNumAfter?.value,
    classAfterGrade: inputs.steps.gradeAfter?.value,
    schoolName: inputs.steps.schoolName.value?.name,
  };
  return (
    <div style={{ width: '100%' }}>
      <strong>{data.schoolName} </strong>{' '}
      <strong>{data.classAfterGrade}</strong>
      학년 <strong>{data.classAfterName}</strong>
      <br />
      일과후 자기관리 선생님
    </div>
  );
};

const ShowReview = (inputs) => {
  // console.log(inputs); .match(/\d+/) is for only digits
  const data = {
    classNum: inputs.steps.classNum?.value
      ? inputs.steps.classNum?.value
      : inputs.steps.classNumHR?.value
      ? inputs.steps.classNumHR?.value
      : inputs.steps.classNumAfter?.value,
    grade:
      inputs.steps.grade?.value === undefined
        ? inputs.steps.gradeHR?.value
          ? inputs.steps.gradeHR?.value
          : inputs.steps.gradeAfter?.value
        : inputs.steps.grade?.value,
    subjectName:
      inputs.steps.subjectName?.value === undefined
        ? inputs.steps.classNumAfter
          ? '학교 일과 후'
          : '담임'
        : `${inputs.steps.subjectName?.value} `,
    schoolName: inputs.steps.schoolName.value?.name,
  };
  // console.log(data);
  return <Review data={data} />;
};

const SearchSchool = (input) => {
  // console.log(input);
  const [search, setSearch] = useState([]);
  const dispatch = useDispatch();
  const keyword = input.steps.userInput.value;
  const filteredSchools = useSelector((state) => selectFilteredSchools(state));

  useEffect(() => {
    setSearch(keyword);
    const updateSearch = (keyword) => {
      if (keyword) {
        if (keyword.length >= 1) {
          // TODO(gogo): replace
          // dispatch(getFilteredSchools(keyword));
        } else {
          dispatch(purgeSchool());
        }
      }
    };
    updateSearch(keyword);
  }, [dispatch, search]);
  // console.log(filteredSchools);
  return (
    <div className={styles['search-school-container']}>
      {filteredSchools.length === 0 ? (
        <div className={styles['fail-bubble']}>
          <h4 className={styles['message']}>
            학교 검색에 실패했어요. 다시 검색해주세요.
          </h4>
          {/* <button
            onClick={() => input.triggerNextStep({ value: null, trigger: "1" })}
            className={styles["fail-btn"]}
          >
            다시 검색하기
          </button> */}
        </div>
      ) : filteredSchools.length > 5 ? (
        <div className={styles['fail-bubble']}>
          <h4 className={styles['message']}>
            검색된 학교가 너무 많아요. 다시 검색해주세요.
          </h4>
          {/* <button
            onClick={() => input.triggerNextStep({ value: null, trigger: "1" })}
            className={styles["fail-btn"]}
          >
            다시 검색하기
          </button> */}
        </div>
      ) : (
        <div>
          <h4 className={styles['message']}>학교를 선택해주세요</h4>
          <ul className="space-y-1">
            {filteredSchools.map((item, id) => (
              <li key={item.id}>
                <button
                  onClick={() =>
                    input.triggerNextStep({
                      value: { name: item.name, id: item.id },
                      trigger: '5',
                    })
                  }
                  className={styles['school-option']}
                >
                  <p className={styles['name']}>{item.name}</p>
                  <p className={styles['address']}>{item.address}</p>
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <button
        onClick={() => input.triggerNextStep({ value: null, trigger: '1' })}
        className={styles['fail-btn']}
      >
        다시 검색하기
      </button>
    </div>
  );
};

const AddClassroom = (props) => {
  const [inputs, setInputs] = useState({});
  const [endForm, setEndForm] = useState(false);
  const [selectedGroupKind, setSelectedGroupKind] = useState('CLASSROOM');
  const [group, setGroup] = useState();

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const user = useSelector((state) => state.user);

  if (user.token !== undefined && user.token !== null) {
    setToken(user.token).then(() => {
    });
  }
  const GotoAddStudents = (props) => {
    console.log('props : ', props);
    const group = props.steps['group-create'].value;
    console.log('group : ', group);
    const getInviteCode = () => {
      dispatch(createInviteCodePromise(group.id))
        .then((code) => {
          history.replace({
            pathname: '/add-students',
            state: {
              group: group,
            },
          });
        })
        .catch((err) => {
          console.error(err);
          alert(
            '학생 추가하기 과정에서 오류가 발생했어요. \n다음에 다시 시도해주세요'
          );
        });
    };
    return (
      <div className={styles['goto-add-students-container']}>
        <button onClick={() => getInviteCode()} className={styles['btn']}>
          학생 추가하러 가기
        </button>
      </div>
    );
  };

  const SendInfo = (input) => {
    var classNumAfter = input.steps.classNumAfter ? true : false;
    var classNum = input.steps.classNum?.value
      ? input.steps.classNum?.value
      : input.steps.classNumHR?.value;

    if (classNum === undefined) {
    } else {
      if (classNum.slice(-1) === '반') {
        classNum = classNum.slice(0, -1);
      }
    }

    var grade = input.steps.gradeHR?.value;
    var class_name = classNum;
    var school = input.steps.schoolName?.value.id;
    var kind = input.steps.classType?.value;
    var name = 'HR';
    if (
      input.steps.classType?.value !== 'HR' &&
      input.steps.classType?.value === 'SUBJECT'
    ) {
      grade = input.steps.grade?.value;
      name = input.steps.subjectName?.value;
    } else if (input.steps.classType?.value === 'AFTER') {
      grade = input.steps.gradeAfter?.value;
      name = '학교 일과 후';
      class_name = input.steps.classNumAfter?.value;
      if (class_name.slice(-1) === '반') {
        class_name = class_name.slice(0, -1);
      }
    }
    // console.log("grade: ", grade);
    // console.log("class_name: ", class_name);
    // console.log("school: ", school);
    // console.log("kind:", kind);
    // console.log("name: ", name);
    // //
    useEffect(() => {
      dispatch(
        createGroupPromise({
          kind: kind,
          grade: grade,
          school: school,
          class_name: class_name,
          name: name,
        })
      )
        .then((groupData) => {
          if (groupData === undefined) {
            throw new Error('server responded with error');
          }
          setGroup(groupData);
          input.triggerNextStep({
            value: groupData,
            trigger: 'goto-add-students',
          });
        })
        .catch((err) => {
          input.triggerNextStep({ value: null, trigger: 'group-create-error' });
        });
    }, []);

    return <></>;
  };

  const allMyGroups = useSelector((state) => selectAllMyGroups(state));

  const handleEnd = ({ steps }) => {
    setEndForm(true);

    // var classNum = steps.classNum?.value
    //   ? steps.classNum?.value
    //   : steps.classNumHR?.value;

    // console.log("classnum : ", classNum);
    // if (classNum.slice(-1) === "반") {
    //   classNum = classNum.slice(0, -1);
    // }

    // if (steps.classType?.value === "HR") {
    //   const updatedInput = {
    //     grade: steps.gradeHR?.value,
    //     class_name: classNum,
    //     school: steps.schoolName?.value.id,
    //     kind: steps.classType?.value,
    //     name: "HR",
    //   };
    //   setInputs({
    //     ...inputs,
    //     ...updatedInput,
    //   });
    // } else {
    //   const updatedInput = {
    //     grade: steps.grade?.value,
    //     class_name: classNum,
    //     school: steps.schoolName?.value.id,
    //     kind: steps.classType?.value,
    //     name: steps.subjectName?.value,
    //   };
    //   setInputs({
    //     ...inputs,
    //     ...updatedInput,
    //   });
    // }
  };

  useEffect(() => {
    const isFromSignIn =
      location.state?.isFromSignIn === undefined
        ? false
        : location.state.isFromSignIn;
    if ((allMyGroups.length === 0 && isFromSignIn) || !isFromSignIn) {
      // console.log("all groups, isfromsignin :", allMyGroups, isFromSignIn);
      history.replace('/add-classroom');
    } else {
      history.replace('/home');
    }
  }, []);

  const steps = [
    {
      id: '1',
      message: `선생님 안녕하세요? ${'\n'}수업은 한 반씩 각각 개설해주세요. 먼저 어느 학교에서 근무하시나요? (학교 이름을 정확히 써주세요)`,
      trigger: 'userInput',
    },
    {
      id: 'userInput',
      user: true,
      trigger: 'schoolName',
    },
    {
      id: 'schoolName',
      component: <SearchSchool />,
      // asMessage: true,
      waitAction: true,
      trigger: '5',
    },
    {
      id: '5',
      message: '어떤 반을 개설하시나요?',
      trigger: 'classType',
    },
    {
      id: 'classType',
      options: [
        {
          value: 'HR',
          label: '담임 선생님이에요',
          trigger: '7-HR',
        },
        {
          value: 'SUBJECT',
          label: '교과목을 가르칠 반입니다',
          trigger: '7',
        },
        {
          value: 'AFTER',
          label: '학교 일과 후 자기관리 연습 (안드로이드만 지원)',
          trigger: '7-AFTER',
        },
      ],
    },
    {
      id: '7',
      message: '몇 학년에서 개설하시나요?',
      trigger: 'grade',
    },
    {
      id: '7-HR',
      message: '몇 학년에서 개설하시나요?',
      trigger: 'gradeHR',
    },
    {
      id: '7-AFTER',
      message: '몇 학년에서 개설하시나요?',
      trigger: 'gradeAfter',
    },
    {
      id: 'grade',
      options: [
        { value: 1, label: '1', trigger: '8' },
        { value: 2, label: '2', trigger: '8' },
        { value: 3, label: '3', trigger: '8' },
        { value: 4, label: '4', trigger: '8' },
        { value: 5, label: '5', trigger: '8' },
        { value: 6, label: '6', trigger: '8' },
      ],
    },
    {
      id: 'gradeHR',
      options: [
        { value: 1, label: '1', trigger: '8-HR' },
        { value: 2, label: '2', trigger: '8-HR' },
        { value: 3, label: '3', trigger: '8-HR' },
        { value: 4, label: '4', trigger: '8-HR' },
        { value: 5, label: '5', trigger: '8-HR' },
        { value: 6, label: '6', trigger: '8-HR' },
      ],
    },
    {
      id: 'gradeAfter',
      options: [
        { value: 1, label: '1', trigger: '8-AFTER' },
        { value: 2, label: '2', trigger: '8-AFTER' },
        { value: 3, label: '3', trigger: '8-AFTER' },
        { value: 4, label: '4', trigger: '8-AFTER' },
        { value: 5, label: '5', trigger: '8-AFTER' },
        { value: 6, label: '6', trigger: '8-AFTER' },
      ],
    },
    {
      id: '8',
      message: '몇 반에서 개설하시나요? ',
      trigger: 'classNum',
    },
    {
      id: '8-HR',
      message: '몇 반에서 개설하시나요? ',
      trigger: 'classNumHR',
    },
    {
      id: '8-AFTER',
      message: '학교 일과 후 자기관리 그룹 이름을 지어주세요 ',
      trigger: 'classNumAfter',
    },
    {
      id: 'classNum',
      user: true,
      trigger: '9',
    },
    {
      id: 'classNumHR',
      user: true,
      trigger: '11',
    },
    {
      id: 'classNumAfter',
      user: true,
      trigger: '11-AFTER',
    },
    {
      id: 'classAFTER',
      options: [
        { value: 1, label: 'A반', trigger: '11-AFTER' },
        { value: 2, label: 'B반', trigger: '11-AFTER' },
        { value: 3, label: 'C반', trigger: '11-AFTER' },
      ],
    },
    { id: '9', message: '어떤 과목을 가르치시나요?', trigger: 'subjectName' },
    {
      id: 'subjectName',
      user: true,
      trigger: '11',
    },
    {
      id: '11',
      message: '선생님 감사합니다! 선생님께서 입력하신 정보는 다음과 같습니다.',
      trigger: '12',
    },
    {
      id: '11-AFTER',
      message: '선생님 감사합니다! 선생님께서 입력하신 정보는 다음과 같습니다.',
      trigger: '12-AFTER',
    },
    {
      id: '12',
      component: <ShowReview />,
      asMessage: true,
      trigger: '13',
    },
    {
      id: '12-AFTER',
      component: <AfterShowReview />,
      asMessage: true,
      trigger: '13',
    },
    {
      id: '13',
      message: '수정하실 부분이 있으신가요? 처음부터 다시 입력해야해요',
      trigger: '14',
    },
    {
      id: '14',
      options: [
        { value: '아니요', label: '제출하기', trigger: 'group-create' },
        { value: '네', label: '다시 작성하기', trigger: '1' },
      ],
    },
    {
      id: 'group-create',
      component: <SendInfo />,
      waitAction: true,
      trigger: 'goto-add-students',
    },
    {
      id: 'group-create-error',
      message:
        '이미 생성된 반입니다.\n수업 리스트에 생성하려고 하셨던 반이 이미 있는지 확인해 주세요',
      end: true,
    },
    {
      id: 'goto-add-students',
      message: '선생님! 아래의 학생 추가버튼을 눌러주세요',
      trigger: 'end-message',
      // end: true,
    },
    {
      id: 'end-message',
      component: <GotoAddStudents />,
      end: true,
    },
  ];

  return (
    <div className="flex w-100vw h-100vh">
      <Sidebar />
      <div
        style={{ marginLeft: "280px", width: "100%", padding: 14, background: "#f9f9f9", minHeight: "100vh" }}
      >
        <BaseCard style={{ minHeight: "100%", padding: "20px 40px" }}>
          <span className={styles["add-classroom-title"]}>가르치는 반 추가하기</span>
          <hr />
          <ThemeProvider theme={chatBotTheme}>
            <ChatBot
              background="#fff"
              headerTitle="수업 개설하기"
              width="400px"
              handleEnd={handleEnd}
              steps={steps}
              placeholder="내용을 입력하세요"
              hideBotAvatar={true}
              customStyle={customStyle}
              bubbleOptionStyle={bubbleOptionStyle}
            />
          </ThemeProvider>
        </BaseCard>
      </div>
    </div>
  );
};

export default AddClassroom;

const chatBotTheme = {
  background: '#faf9f7',
  headerBgColor: '#dee07e',
  headerFontColor: '#353433',
  userBubbleColor: '#ffffff',
  // botBubbleColor: "#d5ddab",
  botBubbleColor: '#dee07e',
  footerBgColor: '#f4f2ef',
  headerFontSize: '16px',
};

const customStyle = {
  backgroundColor: 'rgba(0, 0, 0, 0)',
  borderRadius: '0',
  boxShadow: '0 0 0 0',
  padding: '0',
  justifyContent: 'flex-start',
  width: '400px',
};

const bubbleOptionStyle = {
  backgroundColor: chatBotTheme.userBubbleColor,
  border: '2px solid #dee07e',
};
