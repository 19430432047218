import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectGroupProgramconfigs } from '@store/selectors';
import styles from './ManagingControl.module.scss';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LinearProgress from '@mui/material/LinearProgress';
import { ViewState } from '@devexpress/dx-react-scheduler';
import {
  Scheduler,
  WeekView,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  ViewSwitcher,
  TodayButton,
  AllDayPanel,
  CurrentTimeIndicator,
} from '@devexpress/dx-react-scheduler-material-ui';
import AddIcon from '@mui/icons-material/Add';
import Fab from '@mui/material/Fab';
import moment from 'moment';
import FileLinkSide from '@app/components/Layout/FileLinkSide';
import { useParams } from 'react-router-dom';
import { selectGroupById, selectManagingsByGroup } from '@app/store/selectors';
import ModalAddManageApp from '../Modal/ModalAddManageApp';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { Tooltip } from '@mui/material';
import {
  getReportsListByGroupPromise,
  setSelectedGroup,
  setSelectedPage,
} from '@app/store/actions';
import { useInterval } from '@api';
import { selectReportList } from '@app/store/report/selector';
import CardReport from '@app/components/Card/CardReport';

const PREFIX = 'Demo';

const classes = {
  toolbarRoot: `${PREFIX}-toolbarRoot`,
  progress: `${PREFIX}-progress`,
  addButton: `${PREFIX}-addButton`,
};

const StyledDiv = styled('div')({
  [`&.${classes.toolbarRoot}`]: {
    position: 'relative',
  },
});

const StyledLinearProgress = styled(LinearProgress)(() => ({
  [`&.${classes.progress}`]: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    left: 0,
  },
}));
const StyledFab = styled(Fab)(({ theme }) => ({
  [`&.${classes.addButton}`]: {
    width: '36px',
    height: '36px',
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
  },
}));

const PUBLIC_KEY = 'AIzaSyBnNAISIUKe6xdhq1_rjor2rxoI3UlMY7k';
const CALENDAR_ID = 'f7jnetm22dsjc3npc2lu3buvu4@group.calendar.google.com';

const getData = (setData, setLoading) => {
  const dataUrl = [
    'https://www.googleapis.com/calendar/v3/calendars/',
    CALENDAR_ID,
    '/events?key=',
    PUBLIC_KEY,
  ].join('');
  setLoading(true);

  return fetch(dataUrl)
    .then((response) => response.json())
    .then((data) => {
      setTimeout(() => {
        setData(data.items);
        setLoading(false);
      }, 600);
    });
};

const ToolbarWithLoading = ({ children, ...restProps }) => (
  <StyledDiv className={classes.toolbarRoot}>
    <Toolbar.Root {...restProps}>{children}</Toolbar.Root>
    <StyledLinearProgress className={classes.progress} />
  </StyledDiv>
);

const usaTime = (date) =>
  new Date(date).toLocaleString('en-US', { timeZone: 'America/Los_Angeles' });

const mapAppointmentData = (appointment) => ({
  id: appointment.id,
  startDate: usaTime(appointment.start.dateTime),
  endDate: usaTime(appointment.end.dateTime),
  title: appointment.summary,
});

const initialState = {
  data: [],
  loading: false,
  currentDate: moment(),
  currentViewName: 'Week',
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'setLoading':
      return { ...state, loading: action.payload };
    case 'setData':
      return { ...state, data: action.payload.map(mapAppointmentData) };
    case 'setCurrentViewName':
      return { ...state, currentViewName: action.payload };
    case 'setCurrentDate':
      return { ...state, currentDate: action.payload };
    default:
      return state;
  }
};

export default () => {
  const [state, dispatch] = React.useReducer(reducer, initialState); //todo: rename a dispatch
  const { data, loading, currentViewName, currentDate } = state;
  const getDispatch = useDispatch(); //real dispatch ^^

  const setCurrentViewName = React.useCallback(
    (nextViewName) =>
      dispatch({
        type: 'setCurrentViewName',
        payload: nextViewName,
      }),
    [dispatch]
  );
  const setData = React.useCallback(
    (nextData) =>
      dispatch({
        type: 'setData',
        payload: nextData,
      }),
    [dispatch]
  );
  const setCurrentDate = React.useCallback(
    (nextDate) =>
      dispatch({
        type: 'setCurrentDate',
        payload: nextDate,
      }),
    [dispatch]
  );
  const setLoading = React.useCallback(
    (nextLoading) =>
      dispatch({
        type: 'setLoading',
        payload: nextLoading,
      }),
    [dispatch]
  );

  React.useEffect(() => {
    getData(setData, setLoading);
  }, [setData, currentViewName, currentDate]);

  const currentWeek = () => {
    if (
      moment(currentDate).format('YYYY년 MM월') ===
      moment(currentDate)
        .add(7, 'days')
        .format('YYYY년 MM월')
    ) {
      return `${moment(currentDate).format('YYYY년 MM월 DD일 -')}${moment(
        currentDate
      )
        .add(7, 'days')
        .format('DD일')}`;
    }
    return `${moment(currentDate).format('YYYY년 MM월 DD일 - ')}${moment(
      currentDate
    )
      .add(7, 'days')
      .format('MM월 DD일')}`;
  };

  const CustomOpenButtonComponent = ({ ...restProps }) => {
    return (
      <DateNavigator.OpenButton
        {...restProps}
        text={
          currentViewName === 'Month'
            ? moment(currentDate).format('YYYY년 MM월')
            : currentWeek(currentDate)
        }
      />
    );
  };

  const [modalShow, setModalShow] = useState(false);

  const { groupId } = useParams();

  const group = useSelector((state) => selectGroupById(state, groupId));

  const managingSchedule = useSelector((state) =>
    selectManagingsByGroup(state, groupId)
  );

  useEffect(() => {
    getDispatch(setSelectedPage('MANAGING'));
    getDispatch(setSelectedGroup(groupId));
  }, [groupId]);

  const managingScheduleData = managingSchedule?.map((data) => {
    let repeatRule = data.rRule;
    if (repeatRule === 'rRule') {
      repeatRule = '';
    }
    return {
      id: data.managingId,
      startDate: data.startDate,
      endDate: data.endDate,
      title: data.title,
      rRule: repeatRule,
    };
  });

  const stateStore = useSelector((state) => state);

  const [managingIdOfSchedule, setManagingIdOfSchedule] = useState();

  const AppointmentTest = ({ children, ...restProps }) => {
    const { data } = restProps;

    const programconfigs = useSelector((state) =>
      selectGroupProgramconfigs(state, data.id)
    );

    return (
      <>
        <Appointments.AppointmentContent {...restProps}>
          {children}
        </Appointments.AppointmentContent>
        <Appointments.AppointmentContent {...restProps}>
          <div>
            <div>사용시간 제어</div>
            {/* {console.log('data', data)} */}
            <div>{data?.description}</div>
            <div style={{ fontSize: '1px' }}>
              {programconfigs.map((watchdog, index) => {
                const program = stateStore.programs?.byId[watchdog.programId];
                return (
                  <div>{`${program?.korName} (${watchdog?.dayMax /
                    3600})`}</div>
                );
              })}
            </div>
          </div>
        </Appointments.AppointmentContent>
      </>
    );
  };

  const [isUpdateOrDeleteModal, setIsUpdateOrDeleteModal] = useState(false);

  const AppointmentForClick = ({ children, style, ...restProps }) => {
    const scheduleData = restProps.data;

    const isCurrentSchedule =
      moment(scheduleData.startDate) < moment() &&
      moment() < moment(scheduleData.endDate);

    return (
      <Appointments.Appointment
        {...restProps}
        onClick={(e) => {
          setModalShow(true);
          setIsUpdateOrDeleteModal(true);
          setManagingIdOfSchedule(restProps.data.id);
        }}
        style={{
          ...style,
          backgroundColor: isCurrentSchedule ? '#4caf50' : '#0894a0',
        }}
      >
        {children}
      </Appointments.Appointment>
    );
  };

  const reportData = useSelector((state) => selectReportList(state));
  const [offset, setOffset] = useState(0);
  const [amount, setAmount] = useState(10);

  const moreClick = () => {
    setOffset(offset + 10);
    setAmount(amount + 10);
  };

  useEffect(() => {
    getDispatch(
      getReportsListByGroupPromise({
        groupId: groupId,
        offset: offset,
        amount: amount,
      })
    )
      .then((res) => {
        // console.log('[ManagingControl report]', res.reports);
      })
      .catch((error) => {
        console.log('[ManagingControl Error]', error);
      });
  }, [offset, amount]);

  return (
    <>
      <div className={styles['main-container']}>
        <div className={styles['managingConfigControl']}>
          <div className={styles['control']}>
            <div className={styles['controlTitle-section']}>
              <div className={styles['controlTitle']}>
                자기관리 일정
                <ArrowForwardIosIcon className={styles['arrowFowardIcon']} />
                {group?.subjectName}
              </div>
              <Tooltip title="관리 그룹의 일정을 추가하실 수 있습니다." arrow>
                <StyledFab
                  className={classes.addButton}
                  onClick={() => {
                    setModalShow(true);
                  }}
                >
                  <AddIcon />
                </StyledFab>
              </Tooltip>
            </div>
            <Paper>
              <Scheduler
                data={managingScheduleData}
                height={500}
                locale={'kr-KR'}
              >
                <ViewState
                  currentDate={currentDate}
                  currentViewName={currentViewName}
                  onCurrentViewNameChange={setCurrentViewName}
                  onCurrentDateChange={setCurrentDate}
                />
                <WeekView
                  // startDayHour={7.5}
                  // endDayHour={24.0}
                  displayName={'주'}
                  onClick={() => console.log('hi')}
                />
                <MonthView
                  // startDayHour={7.5}
                  // endDayHour={24.0}
                  displayName={'월'}
                />
                <Appointments
                  {...{ appointmentComponent: AppointmentForClick }}
                  {...{ appointmentContentComponent: AppointmentTest }}
                />
                <AllDayPanel messages={{ allDay: '하루 종일' }} />
                <Toolbar
                  {...(loading ? { rootComponent: ToolbarWithLoading } : null)}
                />
                <DateNavigator
                  {...{ openButtonComponent: CustomOpenButtonComponent }}
                />
                <TodayButton messages={{ today: '오늘' }} />
                <ViewSwitcher />

                <CurrentTimeIndicator />

                {/* <AppointmentTooltip
                  showOpenButton
                  showCloseButton
                  showDeleteButton
                /> */}
              </Scheduler>

              <ModalAddManageApp
                show={modalShow}
                onHide={() => setModalShow(false)}
                group={group}
                isUpdateOrDeleteModal={isUpdateOrDeleteModal}
                setIsUpdateOrDeleteModal={setIsUpdateOrDeleteModal}
                managingIdOfSchedule={managingIdOfSchedule}
                setManagingIdOfSchedule={setManagingIdOfSchedule}
              />
            </Paper>
          </div>

          <div className={styles['real-time-report']}>
            <div className={styles['report-title']}>
              앱/웹사이트 사용 리포트(업그레이드중)
            </div>
            <div className={styles['column-box']}>
              <div className={styles['column-time']}>접속 시간</div>
              <div className={styles['column-name']}>학생 이름</div>
              <div className={styles['column-app']}>사용한 앱/웹</div>
              <Tooltip
                title="접속한 앱웹의 상태입니다. ex)허용중/차단중 시도/기타 "
                arrow
              >
                <div className={styles['column-info']}>분류</div>
              </Tooltip>
            </div>
            <div className={styles['report-box']}>
              {reportData?.map((data) => (
                <CardReport
                  studentName={data?.clientId}
                  appName={data?.content}
                  time={data.reportedAt}
                  status={data.status}
                />
              ))}
              {reportData.length != 0 ? (
                <div className={styles['report-more']}>
                  <Tooltip title="리포트 목록을 더 확인할 수 있습니다." arrow>
                    <button
                      onClick={moreClick}
                      className={styles['more-button']}
                    >
                      더보기
                    </button>
                  </Tooltip>
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className={styles['file-link']}>
          <FileLinkSide group={group} />
        </div>
      </div>
    </>
  );
};
