export const PURGE_MANAGED = 'PURGE_MANAGED';

export const GET_MANAGED_SUCCESS = 'GET_MANAGED_SUCCESS';
export const GET_MANAGED_FAIL = 'GET_MANAGED_FAIL';

export const GET_MANAGEDS_BY_CLIENT_SUCCESS = 'GET_MANAGEDS_BY_CLIENT_SUCCESS';
export const GET_MANAGEDS_BY_CLIENT_FAIL = 'GET_MANAGEDS_BY_CLIENT_FAIL';

export const GET_MANAGEDS_BY_MANAGING_SUCCESS =
  'GET_MANAGEDS_BY_MANAGING_SUCCESS';
export const GET_MANAGEDS_BY_MANAGING_FAIL = 'GET_MANAGEDS_BY_MANAGING_FAIL';

export const CREATE_MANAGED_SUCCESS = 'CREATE_MANAGED_SUCCESS';
export const CREATE_MANAGED_FAIL = 'CREATE_MANAGED_FAIL';

export const UPDATE_MANAGED_SUCCESS = 'UPDATE_MANAGED_SUCCESS';
export const UPDATE_MANAGED_FAIL = 'UPDATE_MANAGED_FAIL';

export const DELETE_MANAGED_SUCCESS = 'DELETE_MANAGED_SUCCESS';
export const DELETE_MANAGED_FAIL = 'DELETE_MANAGED_FAIL';

export const GET_MANAGEDS_BY_GROUP_SUCCESS = 'GET_MANAGEDS_BY_GROUP_SUCCESS'
export const GET_MANAGEDS_BY_GROUP_FAIL = 'GET_MANAGEDS_BY_GROUP_FAIL'
